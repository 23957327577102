"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _Spacer = _interopRequireDefault(require("../Spacer"));

var _Spinner = _interopRequireDefault(require("../Spinner"));

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  height: 1rem;\n  object-fit: contain;\n  width: 1rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 1.25rem;\n  height: 2.5rem;\n  justify-content: center;\n  width: 2.5rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: transparent;\n  cursor: inherit;\n  text-align: center;\n  text-transform: capitalize;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", ";\n  align-items: center;\n  background-position-y: 0%;\n  background-size: 100% 100%;\n  border-radius: 2rem;\n  color: ", ";\n  cursor: ", ";\n  font-size: 0.8125rem;\n  font-weight: 700;\n  justify-content: space-between;\n  padding: 0.3125rem 0.5rem;\n  transition: background-size 200ms, background-position 250ms;\n\n  &:active,\n  &:hover {\n    background-position-y: 100%;\n    background-size: 200% 400%;\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.primaryGradient;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.isDisabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var Label = _styledComponents["default"].span(_templateObject3());

var IconWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
});

var StyledArrowRight = (0, _styledComponents["default"])(_reactFeather.ArrowRight)(_templateObject5(), function (props) {
  return props.theme.white;
});
/**
 * We purposely did not add a prop for the button's gradient, because the implementation would've been janky.
 * If you want to change it, override this with styled component.
 */

function SidebarButton(_ref) {
  var color = _ref.color,
      isDisabled = _ref.isDisabled,
      isLoading = _ref.isLoading,
      label = _ref.label,
      onClick = _ref.onClick,
      showIcon = _ref.showIcon,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["color", "isDisabled", "isLoading", "label", "onClick", "showIcon"]);
  return _react["default"].createElement(Wrapper, (0, _extends2["default"])({
    isDisabled: isDisabled,
    onClick: onClick
  }, otherProps), showIcon && _react["default"].createElement(_Spacer["default"], {
    width: 1.5
  }), _react["default"].createElement(Label, null, label), showIcon && _react["default"].createElement(IconWrapper, null, isLoading ? _react["default"].createElement(_Spinner["default"], {
    color: color,
    delay: 0,
    size: 1.125
  }) : _react["default"].createElement(StyledArrowRight, {
    color: color
  })));
}

SidebarButton.propTypes = {
  color: _propTypes["default"].string,
  isDisabled: _propTypes["default"].bool,
  isLoading: _propTypes["default"].bool,
  label: _propTypes["default"].string,
  onClick: _propTypes["default"].func,
  showIcon: _propTypes["default"].bool
};
SidebarButton.defaultProps = {
  color: _theme.colors.primaryColor,
  isDisabled: false,
  isLoading: false,
  label: "",
  onClick: function onClick() {},
  showIcon: true
};
var _default = SidebarButton;
exports["default"] = _default;