"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _reactFeather = require("react-feather");

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  object-fit: contain;\n  transition: color 100ms;\n  user-select: none;\n  width: 1rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n         display: none;\n       "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", " !important;\n    border: 1px solid ", " !important;\n    border-radius: 1.5rem;\n    height: 3rem;\n    width: 3rem;\n\n    & > * {\n      color: ", " !important;\n    }\n\n     ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 1.25rem;\n  cursor: pointer;\n  height: 2.5rem;\n  justify-content: center;\n  margin-right: 0.6rem;\n  transition: background-color 100ms, border 100ms;\n  width: 2.5rem;\n\n  ", "\n\n  &:hover,\n  &:active {\n    background-color: ", ";\n    border: 1px solid ", ";\n    transition: background-color 200ms, border 200ms;\n\n    & > * {\n      color: ", ";\n      transition: color 200ms;\n    }\n  }\n\n  &:last-of-type {\n    margin-right: 0;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
});

var Action = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3(), props.theme.darkGunmetalBlack, props.theme.darkGunmetalBlack, props.theme.white, props.type === "link" && (0, _styledComponents.css)(_templateObject4()));
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.white;
});

var iconCss = (0, _styledComponents.css)(_templateObject5(), function (props) {
  return props.theme.darkGunmetalBlack;
});
var IconLink = (0, _styledComponents["default"])(_reactFeather.Link)(_templateObject6(), iconCss);
var IconArrow = (0, _styledComponents["default"])(_reactFeather.ArrowRight)(_templateObject7(), iconCss);

function ItemActions(_ref) {
  var actions = _ref.actions,
      value = _ref.value;
  var onLink = (0, _react.useCallback)(function () {
    var fun = (0, _typy["default"])(actions, "onLink").safeFunction;
    fun(value);
  }, [actions, value]);
  var onVisit = (0, _react.useCallback)(function () {
    var fun = (0, _typy["default"])(actions, "onVisit").safeFunction;
    fun(value);
  }, [actions, value]);
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Action, {
    type: "link",
    title: "View links",
    onClick: onLink
  }, _react["default"].createElement(IconLink, null)), _react["default"].createElement(Action, {
    type: "visit",
    title: "View stream",
    onClick: onVisit
  }, _react["default"].createElement(IconArrow, null)));
}

ItemActions.propTypes = {
  actions: _propTypes["default"].shape({}),
  value: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string])
};
var _default = ItemActions;
exports["default"] = _default;