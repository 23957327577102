"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: ", "rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px dashed ", ";\n  height: ", "rem;\n  width: 1px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background: ", ";\n  border-radius: 0.5rem;\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 0.46875rem;\n  height: 0.9375em;\n  width: 0.9375rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  margin-right: 0.5rem;\n  margin-top: 0.1875rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var Dot = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.glitterGray;
});

var GradientDot = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.primaryGradient;
});

var Trail = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.glitterGray;
}, function (props) {
  return props.height;
});

var GradientTrail = (0, _styledComponents["default"])(Trail)(_templateObject6(), function (props) {
  return props.height;
});

function DashedTrail(_ref) {
  var _children = _ref.children,
      gradientTrailHeight = _ref.gradientTrailHeight,
      length = _ref.length,
      showGradientDot = _ref.showGradientDot,
      trailHeight = _ref.trailHeight,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "gradientTrailHeight", "length", "showGradientDot", "trailHeight"]);

  if (length < 2) {
    return null;
  }

  return _react["default"].createElement(Wrapper, otherProps, showGradientDot ? _react["default"].createElement(_react["default"].Fragment, null, (0, _toConsumableArray2["default"])(Array(length - 1).keys()).map(function (i) {
    return _react["default"].createElement(_react["default"].Fragment, {
      key: i
    }, _react["default"].createElement(Dot, null), i === length - 2 ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(GradientTrail, {
      height: gradientTrailHeight
    }), _react["default"].createElement(GradientDot, null)) : _react["default"].createElement(Trail, {
      height: trailHeight
    }));
  })) : _react["default"].createElement(_react["default"].Fragment, null, (0, _toConsumableArray2["default"])(Array(length).keys()).map(function (i) {
    return _react["default"].createElement(_react["default"].Fragment, {
      key: i
    }, _react["default"].createElement(Dot, null), i === length - 1 ? null : _react["default"].createElement(Trail, {
      height: trailHeight
    }));
  })));
}

DashedTrail.propTypes = {
  gradientTrailHeight: _propTypes["default"].number,
  length: _propTypes["default"].number,
  showGradientDot: _propTypes["default"].bool,
  trailHeight: _propTypes["default"].number
};
DashedTrail.defaultProps = {
  gradientTrailHeight: 13,
  length: 3,
  showGradientDot: true,
  trailHeight: 6
};
var _default = DashedTrail;
exports["default"] = _default;