"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation-fill-mode: forwards;\n  background-color: ", ";\n  height: 100vh;\n  left: 0;\n  pointer-events: none;\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  z-index: -100;\n\n  &[data-visible=\"true\"] {\n    animation: ", " 450ms ease-in;\n    z-index: 1000000;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    opacity: 0;\n  },\n  1% {\n    opacity: 0;\n    pointer-events: none;\n  },\n  100%{\n    opacity: 1;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var AnimationFadeIn = (0, _styledComponents.keyframes)(_templateObject());

var Wrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.backgroundColor;
}, AnimationFadeIn);

function PageTransition(_ref) {
  var isVisible = _ref.isVisible,
      showOn = _ref.showOn,
      children = _ref.children;
  return _react["default"].createElement(Wrapper, {
    "data-type": (0, _typy["default"])(showOn).safeString,
    "data-visible": (0, _typy["default"])(isVisible).safeBoolean
  }, children);
}

PageTransition.propTypes = {
  isVisible: _propTypes["default"].bool,
  showOn: _propTypes["default"].oneOf(["enter", "exit"])
};
PageTransition.defaultProps = {
  isVisible: false,
  showOn: "none"
};
var _default = PageTransition;
exports["default"] = _default;