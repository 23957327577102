"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("@sablier/theme");

var _contexts = require("@sablier/contexts");

var _reactI18next = require("react-i18next");

var _v = _interopRequireDefault(require("../Button/v2"));

var _Modal = _interopRequireDefault(require("../Modal"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  align-items:center;\n  padding: 2rem;\n  & > * {\n    min-width: 10rem;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 2rem;\n\n  & h4 {\n    font-size: 1.1875rem;\n    font-weight: 600;\n    margin-top: 0rem;\n  }\n\n  & h5 {\n    font-size: 1rem;\n    font-weight: 400;\n    margin: 0rem;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  margin: 0rem;\n  padding: 0rem;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var UpperWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var ContentWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var dialogLabelId = "processing-modal-label";

function ProcessingModal() {
  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProcessingModalMa = (0, _contexts.useProcessingModalManager)(),
      isOpen = _useProcessingModalMa.isOpen,
      toggle = _useProcessingModalMa.toggle;

  return _react["default"].createElement(_Modal["default"], {
    dialogLabelId: dialogLabelId,
    isOpen: isOpen,
    onDismiss: function onDismiss() {
      return toggle();
    },
    minHeight: null
  }, _react["default"].createElement(Wrapper, null, _react["default"].createElement(UpperWrapper, null, _react["default"].createElement("h4", {
    id: dialogLabelId
  }, translation("modals.processing.title")), _react["default"].createElement("h5", null, translation("modals.processing.subtitle"))), _react["default"].createElement(ContentWrapper, null, _react["default"].createElement(_v["default"], {
    onClick: toggle,
    theme: "outline",
    title: translation("words.close"),
    type: "button"
  })), _react["default"].createElement(_theme.TopRightCloseIcon, {
    onClick: toggle
  })));
}

var _default = ProcessingModal;
exports["default"] = _default;