"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useAddressFromENSName: true,
  useENSName: true,
  useEagerConnect: true,
  useInactiveListener: true
};
exports.useAddressFromENSName = useAddressFromENSName;
exports.useENSName = useENSName;
exports.useEagerConnect = useEagerConnect;
exports.useInactiveListener = useInactiveListener;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _typy = _interopRequireDefault(require("typy"));

var _connectors = require("@sablier/connectors");

var _reactDeviceDetect = require("react-device-detect");

var _core = require("@web3-react/core");

var _web3React = require("./web3React");

Object.keys(_web3React).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _web3React[key];
    }
  });
});

var _contracts = require("./contracts");

Object.keys(_contracts).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _contracts[key];
    }
  });
});

function useAddressFromENSName(ENSName) {
  var _useWeb3React = (0, _web3React.useWeb3React)(),
      library = _useWeb3React.library;

  var _useState = (0, _react.useState)(ENSName),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      addressFromENSName = _useState2[0],
      setAddressFromENSName = _useState2[1];

  (0, _react.useEffect)(function () {
    var controller = new AbortController();

    if ((0, _typy["default"])(ENSName).isTruthy) {
      if ((0, _typy["default"])(ENSName).isEthereumAddress) {
        /** If it already is a valid 0x address, no need to resolve it */
        setAddressFromENSName(ENSName);
      } else if ((0, _typy["default"])(ENSName).isENSAddress) {
        /** If it is an ENS address, try to resolve it */
        library.resolveName(ENSName).then(function (address) {
          if (!controller.signal.aborted) {
            if (address) {
              setAddressFromENSName(address);
            } else {
              throw new Error("Resolver couldn't find the address.");
            }
          }
        })["catch"](function (e) {
          console.error(e);
          setAddressFromENSName(undefined);
        });
      } else {
        setAddressFromENSName(undefined);
      }
      /** Input identifier was not supported */

    } else {
      setAddressFromENSName(undefined);
    }
    /** Input identifier was missing */


    return function () {
      controller.abort();
    };
  }, [library, ENSName]);
  return addressFromENSName;
}

function useENSName(address) {
  var _useWeb3React2 = (0, _web3React.useWeb3React)(),
      library = _useWeb3React2.library;

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      ENSName = _useState4[0],
      setENSName = _useState4[1];

  (0, _react.useEffect)(function () {
    if ((0, _typy["default"])(address).isEthereumAddress) {
      var stale = false;
      library.lookupAddress(address).then(function (name) {
        if (!stale) {
          if (name) {
            setENSName(name);
          } else {
            setENSName(null);
          }
        }
      })["catch"](function () {
        if (!stale) {
          setENSName(null);
        }
      });
      return function () {
        stale = true;
        setENSName();
      };
    }

    return undefined;
  }, [library, address]);
  return ENSName;
}

function useEagerConnect() {
  /* Specifically using useWeb3React because of what this Hook does */
  var _useWeb3ReactCore = (0, _core.useWeb3React)(),
      activate = _useWeb3ReactCore.activate,
      active = _useWeb3ReactCore.active;

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      tried = _useState6[0],
      setTried = _useState6[1];

  (0, _react.useEffect)(function () {
    _connectors.injected.isAuthorized().then(function (isAuthorized) {
      if (isAuthorized) {
        activate(_connectors.injected, undefined, true)["catch"](function () {
          setTried(true);
        });
      } else {
        if (_reactDeviceDetect.isMobile && (0, _typy["default"])(window, "ethereum").isTruthy) {
          activate(_connectors.injected, undefined, true)["catch"](function () {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      }
    });
  }, [activate]);
  /* Intentionally only running on mount (make sure it's only mounted once :)) */

  /* If the connection worked, wait until we get confirmation of that to flip the flag */

  (0, _react.useEffect)(function () {
    if (active) {
      setTried(true);
    }
  }, [active]);
  return tried;
}
/* Use for network and injected connectors - logs user in and out after checking what chain they're on */


function useInactiveListener() {
  var suppress = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  /* Specifically using useWeb3React because of what this Hook does */

  var _useWeb3ReactCore2 = (0, _core.useWeb3React)(),
      activate = _useWeb3ReactCore2.activate,
      active = _useWeb3ReactCore2.active,
      error = _useWeb3ReactCore2.error;

  (0, _react.useEffect)(function () {
    var ethereum = (0, _typy["default"])(window, "ethereum").safeObject;

    if ((0, _typy["default"])(ethereum).isFalsy) {
      return undefined;
    }

    if ((0, _typy["default"])(ethereum, "on").isTruthy && !active && (0, _typy["default"])(error).isFalsy && !suppress) {
      var handleChainChanged = function handleChainChanged() {
        /* Eat errors */
        activate(_connectors.injected, undefined, true)["catch"](function () {});
      };

      var handleNetworkChanged = function handleNetworkChanged() {
        /* Eat errors */
        activate(_connectors.injected, undefined, true)["catch"](function () {});
      };

      var handleAccountsChanged = function handleAccountsChanged(accounts) {
        if (accounts.length > 0) {
          /* Eat errors */
          activate(_connectors.injected, undefined, true)["catch"](function () {});
        }
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("networkChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      return function () {
        if ((0, _typy["default"])(ethereum, "removeListener").isTruthy) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }

    return undefined;
  }, [activate, active, error, suppress]);
}