"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnknownErrorLabel = exports.TopRightCloseIcon = exports.Link = exports.GreenCircle = exports.FlexRowNoWrap = exports.FlexColumnNoWrap = exports.CentringWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactFeather = require("react-feather");

var _colors = _interopRequireDefault(require("./colors"));

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-weight: 600;\n  margin: 0.75rem 1.5rem;\n  max-width: 30rem;\n  text-align: center;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    top: 1.1rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.375rem;\n  position: absolute;\n  right: 1.25rem;\n  top: 1.25rem;\n  width: 1.375rem;\n\n  &:active,\n  &:hover {\n    cursor: pointer;\n    opacity: 0.6;\n  }\n\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  cursor: pointer;\n  text-decoration: none;\n\n  &:focus {\n    outline: none;\n  }\n\n  &:active {\n    text-decoration: none;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 50%;\n  height: 0.5rem;\n  width: 0.5rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-flow: row nowrap;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-flow: column nowrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  align-items: center;\n  display: flex;\n  flex-flow: row nowrap;\n  flex-grow: 1;\n  height: 100%;\n  justify-content: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var CentringWrapper = _styledComponents["default"].div(_templateObject());

exports.CentringWrapper = CentringWrapper;

var FlexColumnNoWrap = _styledComponents["default"].div(_templateObject2());

exports.FlexColumnNoWrap = FlexColumnNoWrap;

var FlexRowNoWrap = _styledComponents["default"].div(_templateObject3());

exports.FlexRowNoWrap = FlexRowNoWrap;

var GreenCircle = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.crayolaGreen;
});

exports.GreenCircle = GreenCircle;

var Link = _styledComponents["default"].a.attrs({
  target: "_blank",
  rel: "noopener noreferrer"
})(_templateObject5());

exports.Link = Link;
var TopRightCloseIcon = (0, _styledComponents["default"])(_reactFeather.X).attrs({
  color: _colors["default"].darkGunmetalBlack
})(_templateObject6(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
});
exports.TopRightCloseIcon = TopRightCloseIcon;

var UnknownErrorLabel = _styledComponents["default"].span(_templateObject8(), _colors["default"].rustyRed);

exports.UnknownErrorLabel = UnknownErrorLabel;