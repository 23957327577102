"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isError = isError;
exports.isWalletError = isWalletError;

var _typy = _interopRequireDefault(require("typy"));
/* https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js */


function isError(error) {
  if ((0, _typy["default"])(error).isFalsy) {
    return false;
  }

  if ((0, _typy["default"])(error, "stack").isFalsy) {
    return false;
  }

  if (typeof (0, _typy["default"])(error, "stack").safeObject !== "string") {
    return false;
  }

  if ((0, _typy["default"])(error, "message").isFalsy) {
    return false;
  }

  if (typeof (0, _typy["default"])(error, "message").safeObject !== "string") {
    return false;
  }

  return true;
}
/* We found out the codes below by empirically testing the dapp with MetaMask, Coinbase Wallet, Argent and Rainbow */


function isWalletError(error) {
  if ((0, _typy["default"])(error).isFalsy) {
    return false;
  }

  var errorCode = (0, _typy["default"])(error, "code").safeNumber;
  var errorMessage;

  if ((0, _typy["default"])(error, "toString").isFunction) {
    errorMessage = error.toString();
  } else {
    errorMessage = error;
  }
  /* MetaMask */


  if (errorCode === 4001) {
    return true;
  }
  /* Coinbase Wallet */


  if (errorCode === -32603) {
    return true;
  }
  /* Argent */


  if (typeof errorMessage === "string") {
    if (errorMessage === "Request rejected") {
      return true;
    }
    /* Rainbow */


    if (errorMessage === "User rejected request") {
      return true;
    }
    /* Any wallet with an unsupported chain id */


    if (errorMessage.includes("Unsupported chain id")) {
      return true;
    }
  }

  return false;
}