"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _polished = require("polished");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 0.5rem;\n  "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 1.75rem;\n\n  ", "\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 1.125rem;\n    width: 1.125rem;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.8rem;\n  margin: 0.25rem;\n  object-fit: contain;\n  width: 1.8rem;\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0.25rem;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 1.75rem;\n  padding: 0.6125rem;\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0.25rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background: ", ";\n  border-radius: 1.75rem;\n  padding: 0.6125rem;\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        border-width: 0px 1px 0px 0px;\n      "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      /* Using a simple background attribute (instead of background-color) here to override the gradient template. */\n      border: 1px solid ", ";\n      background: ", ";\n      transition: background 200ms ease-in-out;\n\n      &:active,\n      &:hover {\n        background: ", ";\n      }\n\n      ", "\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex-grow: 1;\n    font-size: 0.75rem;\n    height: 4.5rem;\n    margin: 0rem;\n    padding: 0.5rem 0rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-position-y: 0%;\n    background-size: 100% 100%;\n    border-radius: 1rem;\n    font-size: 0.875rem;\n    height: 9rem;\n    margin: 0rem 0rem 10rem -0.6125rem;\n    transition: background-size 200ms, background-position 200ms;\n    width: 6rem;\n\n    &:active,\n    &:hover {\n      background-position-y: 100%;\n      background-size: 100% 200%;\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", ";\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  font-weight: 600;\n  justify-content: center;\n\n  ", "\n\n  ", "\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.primaryGradient;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.mediumAndUp(_templateObject2());
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
}, function (props) {
  return !props.isActive && (0, _styledComponents.css)(_templateObject4(), props.theme.aliceBlue, props.theme.white, props.theme.backgroundColor, props.theme.mediaWidth.upToMedium(_templateObject5()));
});

var IconWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return (0, _polished.rgba)(props.theme.white, 0.25);
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
});

var IconWrapperInactive = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject9());
});

var iconCss = (0, _styledComponents.css)(_templateObject10(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject11());
});
var WithdrawIcon = (0, _styledComponents["default"])(_reactFeather.Download)(_templateObject12(), iconCss);
var CheckIcon = (0, _styledComponents["default"])(_reactFeather.Check)(_templateObject13(), iconCss);
var CancelIcon = (0, _styledComponents["default"])(_reactFeather.Power)(_templateObject14(), iconCss);

var Label = _styledComponents["default"].span(_templateObject15(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject16());
});

var LabelInactive = (0, _styledComponents["default"])(Label)(_templateObject17(), function (props) {
  return props.theme.darkGunmetalBlack;
});

function ActionTab(_ref) {
  var action = _ref.action,
      onClick = _ref.onClick;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;
  /** Memoized values **/


  var isCancelDisabled = (0, _react.useMemo)(function () {
    var status = (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
    return status === _devConstants.STREAM_STATUS.ended || status === _devConstants.STREAM_STATUS.cancelled;
  }, [proxyStream]);
  var isWithdrawDisabled = (0, _react.useMemo)(function () {
    var status = (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
    var isEndedAndFullyWithdrawn = status === _devConstants.STREAM_STATUS.ended && (0, _typy["default"])(proxyStream, "stream.bundles.withdrawable.units").safeObject.isZero();
    return isEndedAndFullyWithdrawn || status === _devConstants.STREAM_STATUS.cancelled;
  }, [proxyStream]);
  var isActive = (0, _react.useMemo)(function () {
    switch (action) {
      case _devConstants.STREAM_ACTION_TYPE.withdrawal:
        return !isWithdrawDisabled;

      case _devConstants.STREAM_ACTION_TYPE.cancellation:
        return !isCancelDisabled;

      default:
        return true;
    }
  }, [action, isCancelDisabled, isWithdrawDisabled]);
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(Wrapper, {
    isActive: isActive,
    onClick: onClick
  }, function () {
    switch (action) {
      case _devConstants.STREAM_ACTION_TYPE.withdrawal:
        {
          return isWithdrawDisabled ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(IconWrapperInactive, null, _react["default"].createElement(CheckIcon, {
            color: _theme.colors.primaryColor
          })), _react["default"].createElement(LabelInactive, null, translation("words.withdrawn"))) : _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(IconWrapper, null, _react["default"].createElement(WithdrawIcon, {
            color: _theme.colors.white
          })), _react["default"].createElement(Label, null, translation("words.withdraw")));
        }

      case _devConstants.STREAM_ACTION_TYPE.cancellation:
        {
          return isCancelDisabled ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(IconWrapperInactive, null, _react["default"].createElement(CancelIcon, {
            color: _theme.colors.primaryColor
          })), _react["default"].createElement(LabelInactive, null, translation("words.cancel"))) : _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(IconWrapper, null, _react["default"].createElement(CancelIcon, {
            color: _theme.colors.white
          })), _react["default"].createElement(Label, null, translation("words.cancel")));
        }

      default:
        {
          return _react["default"].createElement(Wrapper, null);
        }
    }
  }()));
}

ActionTab.propTypes = {
  action: _propTypes["default"].number,
  onClick: _propTypes["default"].func
};
ActionTab.defaultProps = {
  action: 0,
  onClick: function onClick() {}
};
var _default = ActionTab;
exports["default"] = _default;