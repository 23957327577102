"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.token = exports.time = exports.rate = exports.duration = exports.date = exports.amount = void 0;
var amount = {
  error: null,
  value: null
};
exports.amount = amount;
var date = {
  error: null,
  day: null,
  month: null,
  year: null,
  label: null,
  totalSeconds: null
};
exports.date = date;
var duration = {
  error: null,
  days: 0,
  minutes: 0,
  hours: 0,
  totalSeconds: null,
  label: null
};
exports.duration = duration;
var rate = {
  amount: null,
  error: null,
  label: null
};
exports.rate = rate;
var time = {
  error: null,
  hours: null,
  minutes: null,
  label: null,
  totalSeconds: null
};
exports.time = time;
var token = {
  address: "",
  decimals: 0,
  error: null,
  name: null,
  symbol: null,
  value: null
};
exports.token = token;