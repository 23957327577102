"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _polished = require("polished");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      &:hover,\n      &:active {\n        &:before {\n          transform:translateX(0);\n          transition: transform 300ms;\n        }\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    align-items: flex-start;\n    background-color: ", ";\n    border: 1px solid ", ";\n    border-radius: 0.5rem;\n    padding: 0;\n    box-shadow: 0rem 0.25rem 1rem -0.5rem ", ";\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  min-height: 4rem;\n  overflow-x: hidden;\n  position: relative;\n  width: 100%;\n\n  ", "\n\n  ", "\n\n  & > * {\n    z-index: 200;\n  }\n\n  &:before {\n    background-color: ", ";\n    content: \"\";\n    height: 100%;\n    position: absolute;\n    right: 0;\n    transform: translateX(12rem);\n    transition: transform 200ms;\n    width: 10rem;\n    z-index: 100;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].a(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.flexColumnNoWrap, props.theme.white, props.theme.aliceBlue, (0, _polished.rgba)(props.theme.darkGunmetalBlack, 0.15));
}, function (props) {
  return props.theme.mediaWidth.mediumAndUp(_templateObject3());
}, function (props) {
  return props.theme.backgroundColor;
});

function Row(_ref) {
  var children = _ref.children,
      onClick = _ref.onClick;
  return _react["default"].createElement(Wrapper, {
    onClick: onClick
  }, children);
}

Row.propTypes = {
  onClick: _propTypes["default"].func
};
Row.defaultProps = {
  onClick: function onClick() {}
};
var _default = Row;
exports["default"] = _default;