"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDesigner = exports.behaviour = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _theme = require("@sablier/theme");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var behaviour = {
  gradient: "gradient",
  outlined: "outlined",
  solid: "solid"
};
exports.behaviour = behaviour;
var base = {
  background: _theme.colors.darkGunmetalBlack,
  backgroundHover: null,
  behaviour: behaviour.solid,
  border: null,
  color: _theme.colors.white
};
var baseOutlined = {
  background: _theme.colors.white,
  backgroundHover: null,
  behaviour: behaviour.outlined,
  border: _theme.colors.aliceBlue,
  color: _theme.colors.darkGunmetalBlack
};

var useDesigner = function useDesigner(theme) {
  var result = (0, _react.useMemo)(function () {
    switch (theme) {
      case "blue":
      case "secondary":
        return _objectSpread({}, base, {
          background: _theme.colors.secondaryColor
        });

      case "dark":
        return _objectSpread({}, base, {
          background: _theme.colors.darkGunmetalBlack
        });

      case "ghost-white":
        return _objectSpread({}, base, {
          background: _theme.colors.ghostWhite,
          backgroundHover: _theme.colors.aliceBlue
        });

      case "gradient":
      case "gradient-primary":
        return _objectSpread({}, base, {
          background: _theme.colors.primaryGradient,
          behaviour: behaviour.gradient
        });

      case "gradient-secondary":
        return _objectSpread({}, base, {
          background: _theme.colors.secondaryColor,
          behaviour: behaviour.gradient
        });

      case "kyber":
        return _objectSpread({}, base, {
          background: _theme.colors.coralOrange
        });

      case "outline":
        return _objectSpread({}, baseOutlined);

      case "outline-primary":
        return _objectSpread({}, baseOutlined, {
          border: _theme.colors.primaryColor
        });

      case "outline-secondary":
        return _objectSpread({}, baseOutlined, {
          border: _theme.colors.secondaryColor
        });

      case "ramp":
        return _objectSpread({}, base, {
          background: _theme.colors.rampGreen
        });

      default:
        return base;
    }
  }, [theme]);
  return result;
};

exports.useDesigner = useDesigner;