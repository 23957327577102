"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useProxyStream = useProxyStream;
exports.useProxyStreamPolling = useProxyStreamPolling;
exports.useRecipientProxyStreams = useRecipientProxyStreams;
exports.useSenderProxyStreams = useSenderProxyStreams;

var _react = require("react");

var _typy = _interopRequireDefault(require("typy"));

var _apollo = require("@sablier/apollo");

var _devConstants = require("@sablier/dev-constants");

var _reactRouterDom = require("react-router-dom");

var _reactHooks = require("@apollo/react-hooks");

var _core = require("@web3-react/core");
/**
 * Uses the id from the url, e.g. for id = 20: https://app.sablier.finance/stream/20/
 * This is the app intended to be used by employees, who are obviously the recipient of streams
 */


function useProxyStream() {
  var params = (0, _reactRouterDom.useParams)();

  var _useQuery = (0, _reactHooks.useQuery)(_apollo.GET_PROXY_STREAM, {
    variables: {
      id: (0, _typy["default"])(params, "id").safeString
    }
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      loading = _useQuery.loading,
      _useQuery$refetch = _useQuery.refetch,
      refetch = _useQuery$refetch === void 0 ? function () {} : _useQuery$refetch,
      _useQuery$startPollin = _useQuery.startPolling,
      startPolling = _useQuery$startPollin === void 0 ? function () {} : _useQuery$startPollin,
      _useQuery$stopPolling = _useQuery.stopPolling,
      stopPolling = _useQuery$stopPolling === void 0 ? function () {} : _useQuery$stopPolling;

  var proxyStream = (0, _typy["default"])(data, "proxyStream").safeObject;
  return {
    error: error,
    loading: loading,
    proxyStream: proxyStream,
    refetch: refetch,
    startPolling: startPolling,
    stopPolling: stopPolling
  };
}

function useProxyStreamPolling(_ref) {
  var _ref$condition = _ref.condition,
      condition = _ref$condition === void 0 ? true : _ref$condition,
      _ref$interval = _ref.interval,
      interval = _ref$interval === void 0 ? 1000 : _ref$interval;

  var _useProxyStream = useProxyStream(),
      proxyStream = _useProxyStream.proxyStream,
      startPolling = _useProxyStream.startPolling,
      stopPolling = _useProxyStream.stopPolling;
  /** Side Effects **/


  (0, _react.useEffect)(function () {
    var status = (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
    /* We only start polling when the status of the stream is "created" or "streaming" */

    var isStatusEligible = status === _devConstants.STREAM_STATUS.created || status === _devConstants.STREAM_STATUS.streaming;

    if (isStatusEligible && (0, _typy["default"])(startPolling).isTruthy && condition) {
      startPolling(interval);
      return function () {
        stopPolling();
      };
    }

    return undefined;
  }, [condition, interval, proxyStream, startPolling, stopPolling]);
}
/**
 * Retrieves the latest 100 streams for the currently logged in Ethereum account
 * @param query The GraphQL query itself
 * @param options The query options, such as variables
 */


function useProxyStreams(query, options) {
  var _useQuery2 = (0, _reactHooks.useQuery)(query, options),
      data = _useQuery2.data,
      error = _useQuery2.error,
      loading = _useQuery2.loading,
      _useQuery2$refetch = _useQuery2.refetch,
      refetch = _useQuery2$refetch === void 0 ? function () {} : _useQuery2$refetch,
      _useQuery2$startPolli = _useQuery2.startPolling,
      startPolling = _useQuery2$startPolli === void 0 ? function () {} : _useQuery2$startPolli,
      _useQuery2$stopPollin = _useQuery2.stopPolling,
      stopPolling = _useQuery2$stopPollin === void 0 ? function () {} : _useQuery2$stopPollin;

  var proxyStreams = (0, _typy["default"])(data, "proxyStreams").safeObject;
  return {
    error: error,
    loading: loading,
    proxyStreams: proxyStreams,
    refetch: refetch,
    startPolling: startPolling,
    stopPolling: stopPolling
  };
}

function useRecipientProxyStreams() {
  var _useWeb3React = (0, _core.useWeb3React)(),
      account = _useWeb3React.account;

  return useProxyStreams(_apollo.GET_RECIPIENT_PROXY_STREAMS, {
    variables: {
      recipient: account || ""
    }
  });
}

function useSenderProxyStreams() {
  var _useWeb3React2 = (0, _core.useWeb3React)(),
      account = _useWeb3React2.account;

  return useProxyStreams(_apollo.GET_SENDER_PROXY_STREAMS, {
    variables: {
      sender: account || ""
    }
  });
}