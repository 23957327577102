"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Label = _interopRequireDefault(require("../Label"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  flex: ", ";\n  justify-content: center;\n  min-height: 4rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display:none;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border-bottom: 1px solid ", ";\n  width: 100%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var Cell = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.weight;
});

function Header(_ref) {
  var columns = _ref.columns;
  return _react["default"].createElement(Wrapper, null, columns.map(function (_ref2) {
    var help = _ref2.help,
        id = _ref2.id,
        label = _ref2.label,
        _ref2$weight = _ref2.weight,
        weight = _ref2$weight === void 0 ? 1 : _ref2$weight;
    return _react["default"].createElement(Cell, {
      key: id,
      weight: weight
    }, _react["default"].createElement(_Label["default"], {
      help: help
    }, label));
  }));
}

Header.propTypes = {
  columns: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    help: _propTypes["default"].string,
    id: _propTypes["default"].string.isRequired
    /** Unique identifier for column */
    ,
    label: _propTypes["default"].string.isRequired,
    weight: _propTypes["default"].number
    /** Weight will determine how big the column is (on large devices)  */

  }))
};
Header.defaultProps = {
  columns: []
};
var _default = Header;
exports["default"] = _default;