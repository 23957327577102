"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  user-select: none;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 1.5rem;\n    margin-bottom: 0.4375rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  font-family: ", ";\n  font-size: 2.25rem;\n  margin-bottom: 0.6125rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MantissaDigitSpan = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var MantissaDigitInactiveSpan = (0, _styledComponents["default"])(MantissaDigitSpan)(_templateObject3(), function (props) {
  return props.theme.aliceBlue;
});

function MantissaDigit(_ref) {
  var active = _ref.active,
      value = _ref.value;

  if (!active) {
    return _react["default"].createElement(MantissaDigitInactiveSpan, null, "0");
  }

  return _react["default"].createElement(MantissaDigitSpan, null, value);
}

MantissaDigit.propTypes = {
  active: _propTypes["default"].bool.isRequired,
  value: _propTypes["default"].string.isRequired
};
var _default = MantissaDigit;
exports["default"] = _default;