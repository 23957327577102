"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProviderOrSigner = getProviderOrSigner;
exports.getContract = getContract;
exports.getEtherscanLink = getEtherscanLink;
exports.getEvenFasterGasPriceInGwei = getEvenFasterGasPriceInGwei;
exports.getGasLimitForAction = getGasLimitForAction;
exports.getTokenBalance = getTokenBalance;
exports.shortenAddress = shortenAddress;
exports.shortenENS = shortenENS;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _Erc = _interopRequireDefault(require("@sablier/dev-constants/lib/abis/Erc20"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _bignumber = require("bignumber.js");

var _ethers = require("ethers");

var _signer = require("./signer");
/* Account is optional */


function getProviderOrSigner(_ref) {
  var library = _ref.library,
      account = _ref.account;
  return account ? new _signer.UncheckedJsonRpcSigner(library.getSigner(account)) : library;
}
/* Account is optional */


function getContract(_ref2) {
  var abi = _ref2.abi,
      account = _ref2.account,
      address = _ref2.address,
      library = _ref2.library;

  if (!(0, _typy["default"])(address).isEthereumAddress || address === _ethers.ethers.constants.AddressZero) {
    throw new Error("Invalid 'address' parameter '".concat(address, "'."));
  }

  return new _ethers.ethers.Contract(address, abi, getProviderOrSigner({
    library: library,
    account: account
  }));
}

function getEtherscanLink(_ref3) {
  var chainId = _ref3.chainId,
      data = _ref3.data,
      type = _ref3.type;
  var prefix;

  if (chainId === _devConstants.MAINNET_ID) {
    prefix = "https://etherscan.io";
  } else {
    prefix = "https://" + (0, _typy["default"])(_devConstants.CHAIN_IDS_TO_NAMES, chainId ? chainId.toString() : "").safeString + ".etherscan.io";
  }

  switch (type) {
    case "transaction":
      {
        return "".concat(prefix, "/tx/").concat(data);
      }

    case "address":
    default:
      {
        return "".concat(prefix, "/address/").concat(data);
      }
  }
}

function getEvenFasterGasPriceInGwei() {
  var gasPrices, fastGasPrice, fastGasPriceGwei, evenFasterGasPriceInGwei;
  return _regenerator["default"].async(function getEvenFasterGasPriceInGwei$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          _context.next = 2;
          return _regenerator["default"].awrap(fetch(_devConstants.ETHERCHAIN_GAS_PRICE_ORACLE).then(function (response) {
            return response.json();
          }));

        case 2:
          gasPrices = _context.sent;
          fastGasPrice = (0, _typy["default"])(gasPrices, "fast").safeString || (0, _typy["default"])(gasPrices, "fast").safeNumber;
          fastGasPriceGwei = new _bignumber.BigNumber(fastGasPrice).multipliedBy(1e9);
          /* Hehe, a bit of front running */

          evenFasterGasPriceInGwei = fastGasPriceGwei.plus(1e6);
          return _context.abrupt("return", evenFasterGasPriceInGwei);

        case 7:
        case "end":
          return _context.stop();
      }
    }
  });
}
/**
 * For most tokens, creating a stream does not cost more than a few hundred thousand gas. However,
 * a few of them like SNX, require north to one million.
 *
 * @param tokenAddress the string value of the token address
 * @see https://github.com/sablierbot/sablier-frontend/issues/109
 */


function getGasLimitForAction() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    action: "createSalary"
  },
      action = _ref4.action,
      tokenAddress = _ref4.tokenAddress;

  if ((0, _typy["default"])(tokenAddress).isFalsy) {
    return _devConstants.GAS_LIMITS[action];
  }

  if ((0, _typy["default"])(_devConstants.SPECIAL_GAS_LIMITS, "[".concat(tokenAddress.toLowerCase(), "].").concat(action)).isTruthy) {
    return _devConstants.SPECIAL_GAS_LIMITS[tokenAddress.toLowerCase()][action];
  } else {
    return _devConstants.GAS_LIMITS[action];
  }
}
/**
 * Why we convert the BigNumber below
 * https://github.com/ethers-io/ethers.js/issues/669
 */


function getTokenBalance(_ref5) {
  var account, library, tokenAddress, tokenBalanceEthersBn, tokenBalance;
  return _regenerator["default"].async(function getTokenBalance$(_context2) {
    while (1) {
      switch (_context2.prev = _context2.next) {
        case 0:
          account = _ref5.account, library = _ref5.library, tokenAddress = _ref5.tokenAddress;

          if (!(!(0, _typy["default"])(tokenAddress).isEthereumAddress || !(0, _typy["default"])(account).isEthereumAddress)) {
            _context2.next = 3;
            break;
          }

          throw Error("Invalid 'tokenAddress' or 'account' parameter '".concat(tokenAddress, "' or '").concat(account, "'."));

        case 3:
          _context2.next = 5;
          return _regenerator["default"].awrap(getContract({
            abi: _Erc["default"],
            address: tokenAddress,
            library: library
          }).balanceOf(account));

        case 5:
          tokenBalanceEthersBn = _context2.sent;
          tokenBalance = new _bignumber.BigNumber(tokenBalanceEthersBn.toString());
          return _context2.abrupt("return", tokenBalance);

        case 8:
        case "end":
          return _context2.stop();
      }
    }
  });
}

function shortenAddress(_ref6) {
  var address = _ref6.address,
      _ref6$digits = _ref6.digits,
      digits = _ref6$digits === void 0 ? 4 : _ref6$digits;

  if (!(0, _typy["default"])(address).isEthereumAddress) {
    throw new Error("Invalid 'address' parameter '".concat(address, "'."));
  }

  return "".concat(address.substring(0, digits + 2), "...").concat(address.substring(42 - digits));
}

function shortenENS(_ref7) {
  var ens = _ref7.ens,
      _ref7$letters = _ref7.letters,
      letters = _ref7$letters === void 0 ? 16 : _ref7$letters;

  if (!(0, _typy["default"])(ens).isTruthy) {
    return "";
  }

  var limit = (0, _typy["default"])(ens).safeString.length > letters ? letters - 3 : letters;
  return (0, _typy["default"])(ens).safeString.substr(0, limit) + (limit !== letters ? "..." : "");
}