"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

var _Focusable = _interopRequireDefault(require("../../Focusable"));

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        margin-bottom: 0rem !important;\n        margin-top: 0rem  !important;\n      "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-bottom: 0;\n      margin-left: 0;\n      margin-top: 0;\n      ", "\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      ", " {\n        font-size: 0.85rem;\n        padding: 0.5rem;\n      }\n      ", " {\n        font-size: 0.85rem;\n        right: 0.5rem;\n      }\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  background-color: ", ";\n  border-radius: 0.25rem;\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n  height: 100%;\n  max-width: 100%;\n  padding: 0.5rem 1rem;\n  width: 100%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n      transition: color 100ms;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  bottom: 1rem;\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n  margin: 0;\n  position: absolute;\n  right: 1rem;\n  transition: color 200ms;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0.5rem 0rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  height: 3.25rem;\n  position: relative;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapperTemp = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var ValueTip = _styledComponents["default"].p(_templateObject3(), function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject4(), props.theme.darkGunmetalBlack);
});

var StyledFocusableInput = (0, _styledComponents["default"])(_Focusable["default"].Input)(_templateObject5(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.robotoMonoFont;
});
var OuterWrapper = (0, _styledComponents["default"])(OuterWrapperTemp)(_templateObject6(), function (props) {
  return props.isSmall && (0, _styledComponents.css)(_templateObject7(), StyledFocusableInput, ValueTip);
}, function (props) {
  return props.isCleanStyle && (0, _styledComponents.css)(_templateObject8(), props.theme.mediaWidth.upToMedium(_templateObject9()));
});

function RateInput(_ref) {
  var rate = _ref.rate,
      isCleanStyle = _ref.isCleanStyle,
      isSmall = _ref.isSmall,
      onUpdateRate = _ref.onUpdateRate,
      readOnly = _ref.readOnly;
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OuterWrapper, {
    isCleanStyle: isCleanStyle,
    isSmall: isSmall
  }, _react["default"].createElement(StyledFocusableInput, {
    autoComplete: "off",
    defaultValue: (0, _typy["default"])(rate, "label").safeString || "",
    id: "rate",
    isInvalid: (0, _typy["default"])(rate, "error").isTruthy,
    name: "rate",
    onBlur: function onBlur(e) {
      e.preventDefault();
      onUpdateRate({
        amount: e.target.value,
        label: e.target.value
      });
    },
    placeholder: "0",
    readOnly: readOnly,
    type: "text"
  }), _react["default"].createElement(ValueTip, {
    isActive: (0, _typy["default"])(rate, "label").isTruthy
  }, "/hour")), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(rate, "error").safeString
  }));
}

RateInput.propTypes = {
  isCleanStyle: _propTypes["default"].bool,
  isSmall: _propTypes["default"].bool,
  rate: _propTypes["default"].shape({
    label: _propTypes["default"].string
  }),
  onUpdateRate: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool
};
RateInput.defaultProps = {
  isCleanStyle: false,
  isSmall: false,
  readOnly: false
};
var _default = RateInput;
exports["default"] = _default;