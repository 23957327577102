"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWeb3React = useWeb3React;

var _devConstants = require("@sablier/dev-constants");

var _core = require("@web3-react/core");

function useWeb3React() {
  var context = (0, _core.useWeb3React)();
  var contextNetwork = (0, _core.useWeb3React)(_devConstants.NETWORK_CONTEXT_NAME);
  return context.active ? context : contextNetwork;
}