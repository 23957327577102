"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

var _reactI18next = require("react-i18next");

var _ProgressBar = _interopRequireDefault(require("../../../../ProgressBar"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-shrink: 0;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  display: none;\n  flex: 1;\n  font-family: ", ";\n  font-size: 0.9rem;\n  font-weight: 600;\n  margin: 0;\n  text-align: left;\n  text-transform: uppercase;\n\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  justify-content: center;\n  margin-top: 0.5rem;\n  width: 100%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: none;\n  margin-bottom: 0.5rem;\n  width: 100%;\n\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  justify-content: center;\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var InnerWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Top = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3(), props.theme.flexRowNoWrap);
});

var ProgressWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Label = _styledComponents["default"].p(_templateObject5(), function (props) {
  return props.theme.lavenderGray;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});

function ItemProgress(_ref) {
  var label = _ref.label,
      value = _ref.value,
      cancelled = _ref.cancelled;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  return _react["default"].createElement(InnerWrapper, null, _react["default"].createElement(Top, null, _react["default"].createElement(Label, null, label)), _react["default"].createElement(ProgressWrapper, null, _react["default"].createElement(_ProgressBar["default"], {
    backgroundColor: _theme.colors.aliceBlue,
    isGrayscale: cancelled,
    label: translation("words.streamed"),
    streamed: Math.max((0, _typy["default"])(value).safeNumber, 2)
  })));
}

ItemProgress.propTypes = {
  cancelled: _propTypes["default"].bool,
  label: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string]),
  value: _propTypes["default"].number.isRequired
};
ItemProgress.defaultProps = {
  cancelled: false,
  label: null
};
var _default = ItemProgress;
exports["default"] = _default;