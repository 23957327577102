"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOKEN_ADDRESSES = exports.SABLIER_ADDRESSES = exports.PAYROLL_ADDRESSES = exports.CTOKEN_MANAGER_ADDRESSES = void 0;
var CTOKEN_MANAGER_ADDRESSES = {
  1: "0x342a6596f50b4db7c3246c0f4efb1f06843d7405",
  3: "0xEE5dfDf2e98FdC572786b9E5649cB8Cc93D47a19",
  4: "0xEE5dfDf2e98FdC572786b9E5649cB8Cc93D47a19",
  5: "0x3b7b1eB1B9C65Db580f673d7599da69Bc1A26338",
  42: "0xEE5dfDf2e98FdC572786b9E5649cB8Cc93D47a19"
};
exports.CTOKEN_MANAGER_ADDRESSES = CTOKEN_MANAGER_ADDRESSES;
var PAYROLL_ADDRESSES = {
  1: "0xbd6a40Bb904aEa5a49c59050B5395f7484A4203d",
  3: "0x7ee114C3628Ca90119fC699f03665bF9dB8f5faF",
  4: "0x7ee114C3628Ca90119fC699f03665bF9dB8f5faF",
  5: "0x8eB93647490DF9989295461AB2AcdEDdCCA84781",
  42: "0x7ee114C3628Ca90119fC699f03665bF9dB8f5faF"
};
exports.PAYROLL_ADDRESSES = PAYROLL_ADDRESSES;
var SABLIER_ADDRESSES = {
  1: "0xA4fc358455Febe425536fd1878bE67FfDBDEC59a",
  3: "0xc04Ad234E01327b24a831e3718DBFcbE245904CC",
  4: "0xc04Ad234E01327b24a831e3718DBFcbE245904CC",
  5: "0x590b3974533141a44a89033deEcf932F52fcFDea",
  42: "0xc04Ad234E01327b24a831e3718DBFcbE245904CC"
};
/* These are checksummed */

exports.SABLIER_ADDRESSES = SABLIER_ADDRESSES;
var TOKEN_ADDRESSES = {
  BEN: "0x3112E6EEb095791CDa8271c25a02A1E66018ade7",
  cDAI: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
  CNSL: "0xeA0bea4d852687c45fdC57F6B06A8a92302baaBc",
  cUSDC: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
  GDAO: "0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc",
  GHST: "0x3F382DbD960E3a9bbCeaE22651E88158d2791550",
  GSWAP: "0xaac41EC512808d64625576EDdd580e7Ea40ef8B2",
  kMPL: "0xc463f34040ad6222C1fFB03ACEbDFAAC032202d6",
  SHARE: "0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d",
  SNT: "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E",
  SNX: "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F",
  wETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
};
exports.TOKEN_ADDRESSES = TOKEN_ADDRESSES;