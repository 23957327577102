"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUPPORTED_WALLETS = exports.walletlink = exports.walletconnect = exports.portis = exports.network = exports.injected = exports.fortmatic = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _devConstants = require("@sablier/dev-constants");

var _injectedConnector = require("@web3-react/injected-connector");

var _portisConnector = require("@web3-react/portis-connector");

var _walletconnectConnector = require("@web3-react/walletconnect-connector");

var _walletlinkConnector = require("@web3-react/walletlink-connector");

var _theme = require("@sablier/theme");

var _Fortmatic = require("./Fortmatic");

var _Network = require("./Network");

var POLLING_INTERVAL = 10000;
var fortmatic = new _Fortmatic.FortmaticConnector({
  apiKey: process.env.REACT_APP_FORTMATIC_KEY,
  chainId: process.env.REACT_APP_NODE_ENV === "production" ? _devConstants.MAINNET_ID : _devConstants.RINKEBY_ID
});
exports.fortmatic = fortmatic;
var injected = new _injectedConnector.InjectedConnector({
  supportedChainIds: [_devConstants.GOERLI_ID, _devConstants.KOVAN_ID, _devConstants.MAINNET_ID, _devConstants.RINKEBY_ID, _devConstants.ROPSTEN_ID]
});
/**
 * We always connect to mainnet since we rely on the subgraph for testnet data. In fact, we kept this
 * connector only because we followed the Uniswap codebase and removing may have 2nd order effects.
 * See https://github.com/NoahZinsmeister/web3-react/issues/35#issuecomment-557791111
 */

exports.injected = injected;
var network = new _Network.NetworkConnector({
  pollingInterval: POLLING_INTERVAL,
  urls: (0, _defineProperty2["default"])({}, _devConstants.MAINNET_ID, process.env.REACT_APP_MAINNET_URL)
});
exports.network = network;
var portis = new _portisConnector.PortisConnector({
  dAppId: process.env.REACT_APP_PORTIS_KEY,
  networks: [_devConstants.MAINNET_ID]
});
exports.portis = portis;
var walletconnect = new _walletconnectConnector.WalletConnectConnector({
  bridge: "https://bridge.walletconnect.org",
  qrcode: false,
  pollingInterval: POLLING_INTERVAL,
  rpc: (0, _defineProperty2["default"])({}, _devConstants.MAINNET_ID, process.env.REACT_APP_MAINNET_URL)
});
exports.walletconnect = walletconnect;
var walletlink = new _walletlinkConnector.WalletLinkConnector({
  appLogoUrl: _devConstants.DOMAINS.finance + "/icon.png",
  appName: "Sablier",
  url: process.env.REACT_APP_MAINNET_URL
});
/* Heads up: don't order alphabetically here, because this is the order in which the wallets are shown to the user */

exports.walletlink = walletlink;
var SUPPORTED_WALLETS = {
  INJECTED: {
    color: _theme.colors.registrationBlack,
    connector: injected,
    description: "modals.wallet.injectedWeb3Provider",
    href: null,
    icon: require("@sablier/assets/svg/arrow-right.svg"),
    id: "Injected",
    name: "words.injected",
    primary: true
  },
  METAMASK: {
    color: _theme.colors.carrotOrange,
    connector: injected,
    description: "modals.wallet.metamask.description",
    href: null,
    id: "MetaMask",
    icon: require("@sablier/assets/images/icon-metamask.png"),
    name: "words.metamask"
  },
  WALLET_CONNECT: {
    color: "#4196fc",
    connector: walletconnect,
    description: "modals.wallet.walletConnect.description",
    href: null,
    icon: require("@sablier/assets/svg/wallet-connect-icon.svg"),
    id: "WalletConnect",
    name: "structs.walletConnect"
  },
  WALLET_LINK: {
    color: "#315cf5",
    connector: walletlink,
    description: "modals.wallet.coinbaseWallet.description",
    href: null,
    icon: require("@sablier/assets/svg/coinbase-wallet-icon.svg"),
    id: "WalletLink",
    name: "structs.coinbaseWallet"
  },
  FORTMATIC: {
    connector: fortmatic,
    description: "modals.wallet.fortmatic.description",
    href: null,
    icon: require("@sablier/assets/images/fortmatic-icon.png"),
    color: _theme.colors.veryLightBlue,
    mobile: true,
    name: "words.fortmatic"
  },
  PORTIS: {
    color: _theme.colors.queenBlue,
    connector: portis,
    description: "modals.wallet.portis.description",
    href: null,
    icon: require("@sablier/assets/images/portis-icon.png"),
    mobile: true,
    name: "words.portis"
  },

  /** Mobile only **/
  COINBASE_WALLET: {
    color: "#315cf5",
    description: "modals.wallet.coinbaseWallet.description",
    href: "https://go.cb-w.com/l6TB3irDk2",
    icon: require("@sablier/assets/svg/coinbase-wallet-icon.svg"),
    mobile: true,
    mobileOnly: true,
    name: "modals.wallet.coinbaseWallet.title"
  },
  TRUST_WALLET: {
    color: "#1c74cc",
    description: "modals.wallet.trustWallet.description",
    href: "https://link.trustwallet.com/open_url?coin_id=60&url=" + _devConstants.DOMAINS.finance,
    icon: require("@sablier/assets/images/trust-wallet-icon.png"),
    mobile: true,
    mobileOnly: true,
    name: "modals.wallet.trustWallet.title"
  }
};
exports.SUPPORTED_WALLETS = SUPPORTED_WALLETS;