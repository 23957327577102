"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     display: flex !important;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: none;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     display: none;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     display: flex;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: none;\n\n  ", "\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n    margin: 0rem 0.3125rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 0.875rem;\n  font-weight: 600;\n  margin: 0rem 0.1875rem;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  z-index: 2;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var TitleWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return (0, _typy["default"])(props, "design.color").safeString || props.theme.white;
});

var TitleLabel = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var MediumTitleLabel = (0, _styledComponents["default"])(TitleLabel)(_templateObject4(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject6());
});
var ShortTitleLabel = (0, _styledComponents["default"])(TitleLabel)(_templateObject7(), function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject8());
});

function Title(_ref) {
  var design = _ref.design,
      title = _ref.title,
      titleMedium = _ref.titleMedium,
      titleShort = _ref.titleShort;
  var renderTitleSizes = (0, _react.useCallback)(function () {
    var valueM = !(0, _typy["default"])(titleMedium).isNullOrUndefined ? titleMedium : title;
    var medium = !(0, _typy["default"])(valueM).isNullOrUndefined ? _react["default"].createElement(MediumTitleLabel, null, valueM) : null;
    var valueS = !(0, _typy["default"])(titleShort).isNullOrUndefined ? titleShort : valueM;

    var _short = !(0, _typy["default"])(valueS).isNullOrUndefined ? _react["default"].createElement(ShortTitleLabel, null, valueS) : null;

    return _react["default"].createElement(_react["default"].Fragment, null, medium, _short);
  }, [title, titleMedium, titleShort]);
  return _react["default"].createElement(TitleWrapper, {
    design: design,
    "data-component": "title"
  }, _react["default"].createElement(TitleLabel, null, title), renderTitleSizes());
}

Title.propTypes = {
  design: _propTypes["default"].shape({}),
  title: _propTypes["default"].string.isRequired,
  titleShort: _propTypes["default"].string,
  // empty string will hide the title on small screens
  titleMedium: _propTypes["default"].string // empty string will hide the title on medium screens

};
Title.defaultProps = {
  titleShort: null,
  titleMedium: null
};
var _default = Title;
exports["default"] = _default;