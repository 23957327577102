"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SPECIAL_GAS_LIMITS = exports.GAS_LIMITS = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _addresses = require("./addresses");

var _SPECIAL_GAS_LIMITS;
/* https://github.com/ethers-io/ethers.js/issues/469 */


var GAS_LIMITS = {
  cancelSalary: 200000,
  createSalary: 475000,
  tokenApproval: 150000,
  withdrawFromSalary: 225000
};
exports.GAS_LIMITS = GAS_LIMITS;
var SPECIAL_GAS_LIMITS = (_SPECIAL_GAS_LIMITS = {}, (0, _defineProperty2["default"])(_SPECIAL_GAS_LIMITS, _addresses.TOKEN_ADDRESSES.SHARE.toLowerCase(), {
  cancelSalary: 500000,
  createSalary: 1200000,
  withdrawFromSalary: 500000
}), (0, _defineProperty2["default"])(_SPECIAL_GAS_LIMITS, _addresses.TOKEN_ADDRESSES.SNT.toLowerCase(), {
  withdrawFromSalary: 250000
}), (0, _defineProperty2["default"])(_SPECIAL_GAS_LIMITS, _addresses.TOKEN_ADDRESSES.SNX.toLowerCase(), {
  createSalary: 700000,
  withdrawFromSalary: 250000
}), (0, _defineProperty2["default"])(_SPECIAL_GAS_LIMITS, _addresses.TOKEN_ADDRESSES.cDAI.toLowerCase(), {
  createSalary: 700000,
  withdrawFromSalary: 250000
}), (0, _defineProperty2["default"])(_SPECIAL_GAS_LIMITS, _addresses.TOKEN_ADDRESSES.cUSDC.toLowerCase(), {
  createSalary: 700000,
  withdrawFromSalary: 250000
}), _SPECIAL_GAS_LIMITS);
exports.SPECIAL_GAS_LIMITS = SPECIAL_GAS_LIMITS;