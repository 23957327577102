"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _rollbar = _interopRequireDefault(require("rollbar"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var rollbar = new _rollbar["default"]({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostWhiteList: ["127.0.0.1", "127.0.0.1:3000", "127.0.0.1:3001", "127.0.0.1:3002", "127.0.0.1:3003", _devConstants.DOMAINS.finance.replace("https://", ""), _devConstants.DOMAINS.me.replace("https://", ""), _devConstants.SUBDOMAINS.pay.replace("https://", ""), _devConstants.SUBDOMAINS.app.replace("https://", ""), _devConstants.SUBDOMAINS.STAGING.pay.replace("https://", ""), _devConstants.SUBDOMAINS.STAGING.app.replace("https://", ""), _devConstants.SUBDOMAINS.DEV.pay.replace("https://", ""), _devConstants.SUBDOMAINS.DEV.app.replace("https://", "")],
  payload: {
    environment: process.env.REACT_APP_NODE_ENV
  }
});
var actions = {
  configure: function configure(options) {
    if ((0, _typy["default"])(options).isTruthy) {
      rollbar.configure(options);
    }
  },
  critical: function critical(criticalError) {
    if ((0, _typy["default"])(criticalError).isTruthy) {
      rollbar.critical(criticalError);
    }
  },
  debug: function debug(message) {
    if ((0, _typy["default"])(message).isTruthy) {
      rollbar.debug(message);
    }
  },
  error: function error(_error) {
    if ((0, _typy["default"])(_error).isTruthy) {
      rollbar.error(_error);
    }
  },
  info: function info(infoMessage) {
    if ((0, _typy["default"])(infoMessage).isTruthy) {
      rollbar.info(infoMessage);
    }
  },
  warning: function warning(_warning) {
    if ((0, _typy["default"])(_warning).isTruthy) {
      rollbar.error(_warning);
    }
  }
};
var _default = actions;
exports["default"] = _default;