import { Machine, assign } from "xstate";
import Box from "3box";
import typy from "typy";

function fetchFrom3Box({ identifier }) {
  return Box.getProfile(identifier).then(profile =>
    Box.getVerifiedAccounts(profile).then(accounts => Promise.resolve({ ...profile, accounts })),
  );
}

function isIdentifierENS(context, event) {
  return typy(event, "identifier").isENSAddress;
}

function isIdentifierValid(context, event) {
  return typy(event, "identifier").isEthereumAddress;
}

function isProfileFound(context, event) {
  return typy(event, "data").isTruthy && typy(event, "data.name").isTruthy;
}

const INVALIDATE = {
  target: "failure",
  actions: assign({
    error: () => "The specified address or ens domain is not valid.",
  }),
};

const profile3BoxMachine = Machine(
  {
    id: "profile3BoxMachine",
    initial: "idle",
    context: {
      identifier: null,
      data: null,
      error: null,
    },
    states: {
      idle: {
        on: {
          FETCH: [
            /** If identifier is ENS, go back to 'idle' and wait for resolver */
            {
              target: "idle",
              cond: "isIdentifierENS",
            },
            /** If identifier is not ens, check if it is valid and go to loading */
            {
              target: "loading",
              cond: "isIdentifierValid",
              actions: assign({
                identifier: (context, event) => event.identifier,
                error: null,
              }),
            },
            /** All identifier gates failed */
            { ...INVALIDATE },
          ],
          INVALIDATE,
        },
      },
      loading: {
        invoke: {
          src: context => fetchFrom3Box(context),
          onDone: [
            {
              target: "success",
              cond: "isProfileFound",
              actions: assign({
                data: (context, event) => event.data,
              }),
            },
            /** If 3Box cannot find a valid user profile */
            { target: "unknown" },
          ],
          /** If the 3Box utility or the network are down */
          onError: {
            target: "failure",
            actions: assign({
              error: () => "Network or 3Box error.",
            }),
          },
        },
      },
      /** _Awesome */
      success: {
        on: { FETCH: "idle", REFRESH: "idle", INVALIDATE },
      },
      /** _The identifier is valid, but there's no 3Box profile */
      unknown: {
        on: { FETCH: "idle", REFRESH: "idle", INVALIDATE },
      },
      /** _Something went wrong */
      failure: {
        on: { FETCH: "idle", REFRESH: "idle" },
      },
    },
  },
  {
    guards: {
      isProfileFound,
      isIdentifierENS,
      isIdentifierValid,
    },
  },
);

export default profile3BoxMachine;
