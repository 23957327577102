"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _bignumber = require("bignumber.js");

var _utils = require("@sablier/utils");

function _default(_ref, _args, context) {
  var cancellation = _ref.cancellation,
      deposit = _ref.deposit,
      ratePerSecond = _ref.ratePerSecond,
      startTime = _ref.startTime,
      stopTime = _ref.stopTime,
      _ref$token = _ref.token,
      token = _ref$token === void 0 ? _devConstants.DEFAULT_TOKEN_MAINNET : _ref$token,
      withdrawals = _ref.withdrawals;
  var now = new _bignumber.BigNumber((0, _dayjs["default"])().unix());
  var depositAtomicUnitsBn = new _bignumber.BigNumber(deposit);

  function getStreamedBundle() {
    if (now.isGreaterThanOrEqualTo(stopTime)) {
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: deposit,
        token: token
      });
    } else if (now.isGreaterThanOrEqualTo(startTime) && now.isLessThanOrEqualTo(stopTime)) {
      var atomicUnitsStringified = now.minus(startTime).multipliedBy(ratePerSecond).toString(10);
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: atomicUnitsStringified,
        token: token
      });
    } else {
      return _devConstants.ZERO_BUNDLE;
    }
  }

  function getWithdrawnBundle() {
    if (now.isGreaterThanOrEqualTo(startTime)) {
      var atomicUnits = new _bignumber.BigNumber(0);
      withdrawals.forEach(function (withdrawal) {
        atomicUnits = atomicUnits.plus(new _bignumber.BigNumber((0, _typy["default"])(withdrawal, "amount").safeString));
      });
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: atomicUnits.toString(10),
        token: token
      });
    } else {
      return _devConstants.ZERO_BUNDLE;
    }
  }

  function getOutstandingBundle(streamedBundle) {
    if (streamedBundle.atomicUnits.isZero()) {
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: deposit,
        token: token
      });
    } else {
      var outstandingAtomicUnits = depositAtomicUnitsBn.minus(streamedBundle.atomicUnits);
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: outstandingAtomicUnits.toString(10),
        token: token
      });
    }
  }

  function getWithdrawableBundle(streamedBundle, withdrawnBundle) {
    if (streamedBundle.atomicUnits.isZero()) {
      return _devConstants.ZERO_BUNDLE;
    } else {
      var withdrawableAtomicUnits = streamedBundle.atomicUnits.minus(withdrawnBundle.atomicUnits).toString(10);
      return (0, _utils.bundlify)({
        depositInAtomicUnits: deposit,
        numberInAtomicUnits: withdrawableAtomicUnits,
        token: token
      });
    }
  }

  var bundles = {
    __typename: "StreamBundles",
    deposit: (0, _utils.bundlify)({
      numberInAtomicUnits: deposit,
      token: token
    }),
    ratePerSecond: (0, _utils.bundlify)({
      numberInAtomicUnits: ratePerSecond,
      token: token
    })
  };

  switch (context.status) {
    /* When the stream hadn't started just yet */
    case _devConstants.STREAM_STATUS.created:
      {
        bundles.outstanding = bundles.deposit;
        bundles.streamed = _devConstants.ZERO_BUNDLE;
        bundles.withdrawable = _devConstants.ZERO_BUNDLE;
        bundles.withdrawn = _devConstants.ZERO_BUNDLE;
        break;
      }

    case _devConstants.STREAM_STATUS.streaming:
      {
        var streamedBundle = getStreamedBundle();
        var withdrawnBundle = getWithdrawnBundle();
        bundles.outstanding = getOutstandingBundle(streamedBundle);
        bundles.streamed = streamedBundle;
        bundles.withdrawable = getWithdrawableBundle(streamedBundle, withdrawnBundle);
        bundles.withdrawn = withdrawnBundle;
        break;
      }

    case _devConstants.STREAM_STATUS.cancelled:
      {
        var _streamedBundle = (0, _utils.bundlify)({
          depositInAtomicUnits: deposit,
          numberInAtomicUnits: (0, _typy["default"])(cancellation, "recipientBalance").safeString,
          token: token
        });

        bundles.outstanding = _devConstants.ZERO_BUNDLE;
        bundles.streamed = _streamedBundle;
        bundles.withdrawable = _devConstants.ZERO_BUNDLE;
        bundles.withdrawn = _streamedBundle;
        break;
      }

    case _devConstants.STREAM_STATUS.ended:
      {
        /* We don't use the deposit bundle for the streamed bundle here because we need the percentages */
        var _streamedBundle2 = (0, _utils.bundlify)({
          depositInAtomicUnits: deposit,
          numberInAtomicUnits: deposit,
          token: token
        });

        var _withdrawnBundle = getWithdrawnBundle();

        bundles.outstanding = _devConstants.ZERO_BUNDLE;
        bundles.streamed = _streamedBundle2;
        bundles.withdrawable = getWithdrawableBundle(_streamedBundle2, _withdrawnBundle);
        bundles.withdrawn = _withdrawnBundle;
        break;
      }

    default:
      {
        bundles.outstanding = _devConstants.ZERO_BUNDLE;
        bundles.streamed = _devConstants.ZERO_BUNDLE;
        bundles.withdrawable = _devConstants.ZERO_BUNDLE;
        bundles.withdrawn = _devConstants.ZERO_BUNDLE;
      }
  }

  return bundles;
}