"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBlockNumber = useBlockNumber;
exports.EthereumContextProvider = EthereumContextProvider;
exports.EthereumContextUpdater = EthereumContextUpdater;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _typy = _interopRequireDefault(require("typy"));

var _utils = require("@sablier/utils");

var _reactHooks = require("@sablier/react-hooks");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var BLOCK_NUMBER = "BLOCK_NUMBER";
var UPDATE_BLOCK_NUMBER = "UPDATE_BLOCK_NUMBER";
var INITIAL_ETHEREUM_CONTEXT = (0, _defineProperty2["default"])({}, BLOCK_NUMBER, {});
/* TODO: maybe rename this? we already have Web3Provider */

var EthereumContext = (0, _react.createContext)();

function useEthereumContext() {
  return (0, _react.useContext)(EthereumContext);
}

function useBlockNumber() {
  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React.chainId;

  var _useEthereumContext = useEthereumContext(),
      _useEthereumContext2 = (0, _slicedToArray2["default"])(_useEthereumContext, 1),
      state = _useEthereumContext2[0];

  return (0, _utils.safeAccess)(state, [BLOCK_NUMBER, chainId]);
}

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case UPDATE_BLOCK_NUMBER:
      {
        var chainId = payload.chainId,
            blockNumber = payload.blockNumber;
        return _objectSpread({}, state, (0, _defineProperty2["default"])({}, BLOCK_NUMBER, _objectSpread({}, (0, _typy["default"])(state, BLOCK_NUMBER).safeObjectOrEmpty, (0, _defineProperty2["default"])({}, chainId, blockNumber))));
      }

    default:
      {
        throw new Error("Unexpected action type in EthereumContext reducer: '".concat(type, "'."));
      }
  }
}

function EthereumContextProvider(_ref2) {
  var children = _ref2.children;

  var _useReducer = (0, _react.useReducer)(reducer, INITIAL_ETHEREUM_CONTEXT),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var updateBlockNumber = (0, _react.useCallback)(function (chainId, blockNumber) {
    dispatch({
      type: UPDATE_BLOCK_NUMBER,
      payload: {
        chainId: chainId,
        blockNumber: blockNumber
      }
    });
  }, []);
  return _react["default"].createElement(EthereumContext.Provider, {
    value: (0, _react.useMemo)(function () {
      return [state, {
        updateBlockNumber: updateBlockNumber
      }];
    }, [state, updateBlockNumber])
  }, children);
}

function EthereumContextUpdater() {
  var _useWeb3React2 = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React2.chainId,
      library = _useWeb3React2.library;

  var _useEthereumContext3 = useEthereumContext(),
      _useEthereumContext4 = (0, _slicedToArray2["default"])(_useEthereumContext3, 2),
      updateBlockNumber = _useEthereumContext4[1].updateBlockNumber;
  /* Update block number */


  (0, _react.useEffect)(function () {
    var stale = false;

    function update() {
      var blockNumber;
      return _regenerator["default"].async(function update$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return _regenerator["default"].awrap(library.getBlockNumber());

            case 3:
              blockNumber = _context.sent;

              if (!stale) {
                updateBlockNumber(chainId, blockNumber);
              }

              _context.next = 10;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context["catch"](0);

              if (!stale) {
                updateBlockNumber(chainId, null);
              }

            case 10:
            case "end":
              return _context.stop();
          }
        }
      }, null, null, [[0, 7]]);
    }

    if ((0, _typy["default"])(library).isTruthy) {
      update();
      library.on("block", update);
      return function () {
        stale = true;
        library.removeListener("block", update);
      };
    }

    return undefined;
  }, [chainId, library, updateBlockNumber]);
  return null;
}