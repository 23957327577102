"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ETHEREUM_NUMBER_REGEX = exports.ETHEREUM_ADDRESS_REGEX = void 0;
/* See https://github.com/k4m4/ethereum-regex */

var ETHEREUM_ADDRESS_REGEX = new RegExp("^0x[a-fA-F0-9]{40}$");
/* See https://stackoverflow.com/questions/9799505/allow-only-numbers-and-dot-in-script */

exports.ETHEREUM_ADDRESS_REGEX = ETHEREUM_ADDRESS_REGEX;
var ETHEREUM_NUMBER_REGEX = new RegExp("^[0-9]+.?[0-9]{0,18}$");
exports.ETHEREUM_NUMBER_REGEX = ETHEREUM_NUMBER_REGEX;