"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useCopyClipboard: true,
  useDebounce: true,
  useEffectWithDelay: true,
  useEffectWithDefaultDelay: true,
  useMountEffect: true,
  usePrevious: true,
  useShowSpinner: true
};
exports.useCopyClipboard = useCopyClipboard;
exports.useDebounce = useDebounce;
exports.useEffectWithDelay = useEffectWithDelay;
exports.useEffectWithDefaultDelay = useEffectWithDefaultDelay;
exports.useMountEffect = useMountEffect;
exports.usePrevious = usePrevious;
exports.useShowSpinner = useShowSpinner;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _copyToClipboard = _interopRequireDefault(require("copy-to-clipboard"));

var _devConstants = require("@sablier/dev-constants");

var _ethereum = require("./ethereum");

Object.keys(_ethereum).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ethereum[key];
    }
  });
});

var _history = require("./history");

Object.keys(_history).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _history[key];
    }
  });
});

var _mixpanel = require("./mixpanel");

Object.keys(_mixpanel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _mixpanel[key];
    }
  });
});

var _proxyStreams = require("./proxyStreams");

Object.keys(_proxyStreams).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _proxyStreams[key];
    }
  });
});

var _streams = require("./streams");

Object.keys(_streams).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _streams[key];
    }
  });
});

var _vendors = require("./vendors");

Object.keys(_vendors).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _vendors[key];
    }
  });
});

function useCopyClipboard() {
  var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 750;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      isCopied = _useState2[0],
      setIsCopied = _useState2[1];

  var staticCopy = (0, _react.useCallback)(function (text) {
    var didCopy = (0, _copyToClipboard["default"])(text);
    setIsCopied(didCopy);
  }, []);
  (0, _react.useEffect)(function () {
    if (isCopied) {
      var hide = setTimeout(function () {
        setIsCopied(false);
      }, timeout);
      return function () {
        clearTimeout(hide);
      };
    }

    return undefined;
  }, [isCopied, setIsCopied, timeout]);
  return [isCopied, staticCopy];
}
/* Modified from https://usehooks.com/useDebounce/ */


function useDebounce(value, delay) {
  var _useState3 = (0, _react.useState)(value),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      debouncedValue = _useState4[0],
      setDebouncedValue = _useState4[1];

  (0, _react.useEffect)(function () {
    /* Update debounced value after delay */
    var handler = setTimeout(function () {
      setDebouncedValue(value);
    }, delay);
    /**
     * Cancel the timeout if value changes (also on delay change or unmount)
     * This is how we prevent debounced value from updating if value is changed
     * within the delay period. Timeout gets cleared and restarted.
     */

    return function () {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
/* Makes using timeouts in useEffect much, much more readable */


function useEffectWithDelay(_ref) {
  var _ref$condition = _ref.condition,
      condition = _ref$condition === void 0 ? false : _ref$condition,
      _ref$func = _ref.func,
      func = _ref$func === void 0 ? function () {} : _ref$func,
      _ref$delay = _ref.delay,
      delay = _ref$delay === void 0 ? 1000 : _ref$delay;
  (0, _react.useEffect)(function () {
    if (condition) {
      var timeout = setTimeout(func, delay);
      return function () {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [condition, delay, func]);
}

function useEffectWithDefaultDelay(_ref2) {
  var _ref2$condition = _ref2.condition,
      condition = _ref2$condition === void 0 ? false : _ref2$condition,
      _ref2$func = _ref2.func,
      func = _ref2$func === void 0 ? function () {} : _ref2$func;
  return useEffectWithDelay({
    condition: condition,
    func: func,
    delay: _devConstants.SLIDE_DURATION
  });
}
/* See https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once */


function useMountEffect(func) {
  (0, _react.useEffect)(func, []);
}
/* Modified from https://usehooks.com/usePrevious/ */


function usePrevious(value) {
  /**
   * The ref object is a generic container whose current property is mutable ...
   * and can hold any value, similar to an instance property on a class.
   */
  var ref = (0, _react.useRef)();
  /* Store current value in ref */

  (0, _react.useEffect)(function () {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  /* Return previous value (happens before update in useEffect above) */

  return ref.current;
}

function useShowSpinner() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 750;

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      showSpinner = _useState6[0],
      setShowSpinner = _useState6[1];

  (0, _react.useEffect)(function () {
    var timeout = setTimeout(function () {
      setShowSpinner(true);
    }, duration);
    return function () {
      clearTimeout(timeout);
    };
  }, [duration]);
  return [showSpinner, setShowSpinner];
}