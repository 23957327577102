"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _utils = require("@sablier/utils");

var _contexts = require("@sablier/contexts");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _LinkCard = _interopRequireDefault(require("./LinkCard"));

var _Modal = _interopRequireDefault(require("../Modal"));

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    grid-template-columns: 1fr 1fr;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: grid;\n  grid-gap: 1rem;\n  grid-template-columns: 1fr 1fr 1fr;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 0.8rem;\n  object-fit: contain;\n  width: 0.8rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.6rem;\n  object-fit: contain;\n  width: 1.6rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 2rem;\n\n  & h4 {\n    font-size: 1.1875rem;\n    font-weight: 600;\n    margin-top: 0rem;\n  }\n\n  & h5 {\n    font-size: 1rem;\n    font-weight: 400;\n    margin: 0rem;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  margin: 0rem;\n  padding: 0rem;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Twitter = function Twitter(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("circle", {
    cx: "101",
    cy: "100",
    r: "81",
    fill: "#fff"
  }), _react["default"].createElement("path", {
    d: "M100 0C44.775 0 0 44.775 0 100s44.775 100 100 100 100-44.775 100-100S155.225 0 100 0zm50.55 80.375c1.525 33.667-23.583 71.2-68.033 71.2-13.517 0-26.092-3.967-36.684-10.758 12.7 1.5 25.375-2.034 35.434-9.909-10.467-.191-19.309-7.116-22.367-16.625 3.758.717 7.458.509 10.817-.408-11.509-2.317-19.459-12.683-19.2-23.775a23.877 23.877 0 0 0 10.841 2.992c-10.658-7.125-13.675-21.2-7.408-31.959 11.8 14.484 29.442 24.009 49.333 25.009-3.491-14.967 7.867-29.392 23.325-29.392a23.92 23.92 0 0 1 17.467 7.558 47.744 47.744 0 0 0 15.2-5.808c-1.792 5.592-5.583 10.275-10.525 13.242a47.757 47.757 0 0 0 13.742-3.775 48.04 48.04 0 0 1-11.942 12.408z",
    fill: "#11C1FF"
  }));
};

Twitter.defaultProps = {
  width: "200",
  height: "200",
  viewBox: "0 0 200 200",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var UpperWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var defaultIconCenterCss = (0, _styledComponents.css)(_templateObject4());
var defaultIconActionCss = (0, _styledComponents.css)(_templateObject5());
var IconLink = (0, _styledComponents["default"])(_reactFeather.Paperclip)(_templateObject6(), defaultIconCenterCss);
var IconVisit = (0, _styledComponents["default"])(_reactFeather.ExternalLink)(_templateObject7(), defaultIconCenterCss);
var IconCopy = (0, _styledComponents["default"])(_reactFeather.Copy)(_templateObject8(), defaultIconActionCss);
var IconShare = (0, _styledComponents["default"])(_reactFeather.Share2)(_templateObject9(), defaultIconActionCss);
var IconTwitter = (0, _styledComponents["default"])(Twitter)(_templateObject10(), defaultIconCenterCss);

var Grid = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});

function LinksModal(_ref) {
  var children = _ref.children,
      forRecipient = _ref.forRecipient,
      proxyStream = _ref.proxyStream;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      account = _useWeb3React.account,
      chainId = _useWeb3React.chainId;

  var _useLinksModalManager = (0, _contexts.useLinksModalManager)(),
      isOpen = _useLinksModalManager.isOpen,
      toggle = _useLinksModalManager.toggle;
  /** Memoized values **/


  var streamUrls = (0, _react.useMemo)(function () {
    var id = (0, _typy["default"])(proxyStream, "id").safeString;

    if ((0, _typy["default"])(id).isFalsy) {
      return {
        sender: "",
        recipient: ""
      };
    } else {
      return {
        sender: _devConstants.SUBDOMAINS.pay + "/stream/" + (0, _typy["default"])(proxyStream, "id").safeString,
        recipient: _devConstants.SUBDOMAINS.app + "/stream/" + (0, _typy["default"])(proxyStream, "id").safeString
      };
    }
  }, [proxyStream]);
  var isForOwner = (0, _react.useMemo)(function () {
    var owner = forRecipient ? (0, _typy["default"])(proxyStream, "recipient").safeString : (0, _typy["default"])(proxyStream, "sender").safeString;
    return (0, _typy["default"])(account).safeString.toLowerCase() === owner.toLowerCase();
  }, [account, forRecipient, proxyStream]);
  var items = (0, _react.useMemo)(function () {
    var firstTxHash = (0, _typy["default"])(proxyStream, "stream.txs[0].txhash").safeString;
    var etherscanUri = (0, _utils.getEtherscanLink)({
      chainId: chainId,
      data: firstTxHash,
      type: "transaction"
    });
    var twitterUri = encodeURI(_devConstants.SHARER.twitter + "?text=" + translation("modals.links.twitterShare", {
      link: streamUrls.sender
    }));
    return [{
      action: "copy",
      iconCenter: _react["default"].createElement(IconLink, {
        color: _theme.colors.secondaryColor
      }),
      iconAction: _react["default"].createElement(IconCopy, {
        color: _theme.colors.darkGunmetalBlack
      }),
      location: streamUrls.sender,
      title: translation("modals.links.action.sender") + (forRecipient || !isForOwner ? "" : " (You)"),
      trackingMessage: 'Click Copy Sender Link in "Links" Modal'
    }, {
      action: "copy",
      iconCenter: _react["default"].createElement(IconLink, {
        color: _theme.colors.primaryColor
      }),
      iconAction: _react["default"].createElement(IconCopy, {
        color: _theme.colors.darkGunmetalBlack
      }),
      location: streamUrls.recipient,
      title: translation("modals.links.action.recipient") + (!forRecipient || !isForOwner ? "" : " (You)"),
      trackingMessage: 'Click Copy Recipient Link in "Links" Modal'
    }, {
      action: "visit",
      iconCenter: _react["default"].createElement(IconVisit, {
        color: _theme.colors.darkGunmetalBlack
      }),
      iconAction: null,
      location: etherscanUri,
      title: translation("modals.links.action.etherscan"),
      trackingMessage: 'Click Etherscan in "Links" Modal'
    }, {
      action: "visit",
      iconCenter: _react["default"].createElement(IconTwitter, null),
      iconAction: _react["default"].createElement(IconShare, {
        color: _theme.colors.darkGunmetalBlack
      }),
      location: twitterUri,
      title: translation("modals.links.action.twitter"),
      trackingMessage: 'Click Twitter in "Links" Modal'
    }];
  }, [chainId, forRecipient, isForOwner, proxyStream, translation, streamUrls.recipient, streamUrls.sender]);
  return _react["default"].createElement(_Modal["default"], {
    dialogLabelId: "links-modal-label",
    isOpen: isOpen,
    onDismiss: function onDismiss() {
      return toggle();
    },
    minHeight: null
  }, _react["default"].createElement(Wrapper, null, _react["default"].createElement(UpperWrapper, null, _react["default"].createElement("h4", {
    id: "links-modal-label"
  }, translation("words.links")), _react["default"].createElement(_theme.TopRightCloseIcon, {
    onClick: toggle
  }), (0, _typy["default"])(children).isTruthy ? children : _react["default"].createElement(Grid, null, items.map(function (item) {
    return _react["default"].createElement(_LinkCard["default"], (0, _extends2["default"])({
      key: item.title
    }, item));
  })))));
}

LinksModal.propTypes = {
  forRecipient: _propTypes["default"].bool,
  proxyStream: _propTypes["default"].shape({})
};
LinksModal.defaultProps = {
  forRecipient: false,
  proxyStream: null
};
var _default = LinksModal;
exports["default"] = _default;