"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _xstate = require("xstate");

var _default = (0, _xstate.Machine)({
  id: "durationPicker",
  initial: "idle",
  states: {
    idle: {
      on: {
        COLLAPSE: "collapsed"
      }
    },
    collapsed: {
      on: {
        IDLE: "idle"
      }
    }
  }
});

exports["default"] = _default;