"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _reactRouterDom = require("react-router-dom");

var _vendors = require("@sablier/vendors");

var _reactI18next = require("react-i18next");

var _Status = _interopRequireDefault(require("../../Web3/Status"));

function _templateObject24() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    background-color: ", ";\n    width: auto;\n  "]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  align-items: stretch;\n  border-radius: 0rem ", " ", " 0rem;\n\n  ", ";\n"]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 0.8125rem;\n  width: 0.8125rem;\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: none;\n  "]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", "\n  "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n\n  ", ";\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 0.9375rem;\n  object-fit: contain;\n  width: 0.9375rem;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", "\n\n    &[data-isdashboard=\"true\"] {\n      display: none;\n    }\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  border-radius: ", " 0rem 0rem ", ";\n\n  &[data-isdashboard=\"true\"]{\n    background-color: ", ";\n  }\n\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-weight: 500;\n  margin-left: 0.25rem;\n\n  ", "\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        display: none;\n      "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: flex;\n      ", "\n    "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        display: none;\n      "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: flex;\n      ", "\n    "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        display: flex;\n      "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: none;\n      ", "\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        display: flex;\n      "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: none;\n      ", "\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  height: 1.75rem;\n  width: 1px;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: none;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border-radius: ", ";\n  font-size: 0.875rem;\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  margin: 0rem 0.375rem;\n  min-width: 0;\n  padding: 0rem;\n  width: 2.5rem;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  cursor: pointer;\n  height: 2.5rem;\n  justify-content: center;\n  min-width: 8.5rem;\n  transition: background-color 200ms ease;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MiWebAsset = function MiWebAsset(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    fill: "#1F2133",
    d: "M19 4H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6a2 2 0 0 0-2-2zm0 14H5V8h14v10z"
  }), _react["default"].createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0z"
  }));
};

MiWebAsset.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
};
var borderRadiusSize = "1.25rem";
var navigationTabCss = (0, _styledComponents.css)(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.ghostWhite;
});
var navigationTabCssUpToMedium = (0, _styledComponents.css)(_templateObject2(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, borderRadiusSize, function (props) {
  return props.theme.ghostWhite;
});

var NavigationWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, borderRadiusSize, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4());
});

var Separator = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.upToMedium && (0, _styledComponents.css)(_templateObject6(), props.theme.mediaWidth.upToMedium(_templateObject7()));
}, function (props) {
  return props.upToSmall && (0, _styledComponents.css)(_templateObject8(), props.theme.mediaWidth.upToSmall(_templateObject9()));
}, function (props) {
  return props.downToMedium && (0, _styledComponents.css)(_templateObject10(), props.theme.mediaWidth.upToMedium(_templateObject11()));
}, function (props) {
  return props.downToSmall && (0, _styledComponents.css)(_templateObject12(), props.theme.mediaWidth.upToSmall(_templateObject13()));
});

var NavigationSpan = _styledComponents["default"].span(_templateObject14(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject15());
});

var DashboardTab = (0, _styledComponents["default"])(_reactRouterDom.NavLink)(_templateObject16(), navigationTabCss, borderRadiusSize, borderRadiusSize, function (props) {
  return props.theme.ghostWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject17(), navigationTabCssUpToMedium);
});
var DashboardIcon = (0, _styledComponents["default"])(MiWebAsset)(_templateObject18());
var AboutTab = (0, _styledComponents["default"])(_theme.Link)(_templateObject19(), navigationTabCss, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject20(), navigationTabCssUpToMedium);
}, function (props) {
  return props.downToSmall && props.theme.mediaWidth.upToSmall(_templateObject21());
});
var AboutIcon = (0, _styledComponents["default"])(_reactFeather.FileText)(_templateObject22());

var Web3StatusTab = _styledComponents["default"].div(_templateObject23(), navigationTabCss, borderRadiusSize, borderRadiusSize, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject24(), navigationTabCssUpToMedium, props.theme.ghostWhite);
});

function NavigationTabs() {
  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var dashboardMatch = (0, _reactRouterDom.useRouteMatch)({
    path: "/",
    exact: true
  });
  var streamMatch = (0, _reactRouterDom.useRouteMatch)({
    path: "/stream/",
    exact: false
  });

  function onClickDashboardTab() {
    _vendors.mixpanel.track('Click "Dashboard" Tab');
  }

  function onClickAboutTab() {
    _vendors.mixpanel.track('Click "About" Tab');
  }

  function onClickWeb3StatusTab() {
    _vendors.mixpanel.track('Click "Web3" Tab');
  }

  return _react["default"].createElement(NavigationWrapper, null, (0, _typy["default"])(streamMatch).isTruthy && _react["default"].createElement(Separator, {
    upToMedium: true
  }), _react["default"].createElement(DashboardTab, {
    "data-isdashboard": (0, _typy["default"])(dashboardMatch).isTruthy,
    onClick: onClickDashboardTab,
    title: translation("words.dashboard"),
    to: "/"
  }, _react["default"].createElement(DashboardIcon, null), _react["default"].createElement(NavigationSpan, null, translation("words.dashboard"))), _react["default"].createElement(Separator, {
    downToMedium: (0, _typy["default"])(dashboardMatch).isTruthy,
    downToSmall: (0, _typy["default"])(streamMatch).isTruthy
  }), _react["default"].createElement(AboutTab, {
    downToSmall: (0, _typy["default"])(streamMatch).isTruthy,
    href: _devConstants.SUBDOMAINS.faq,
    onClick: onClickAboutTab,
    title: translation("words.about")
  }, _react["default"].createElement(AboutIcon, {
    color: _theme.colors.darkGunmetalBlack
  }), _react["default"].createElement(NavigationSpan, null, translation("words.about"))), _react["default"].createElement(Separator, null), _react["default"].createElement(Web3StatusTab, {
    onClick: onClickWeb3StatusTab,
    title: translation("words.account")
  }, _react["default"].createElement(_Status["default"], null)));
}

var _default = NavigationTabs;
exports["default"] = _default;