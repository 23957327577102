"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = require("styled-components");

var _polished = require("polished");

var _colors = _interopRequireDefault(require("./colors"));

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    left: 50%;\n    position: absolute;\n    transform: translateX(-50%);\n  "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    box-shadow: 0 1rem 3rem -0.5rem ", ";\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    ", ";\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    ", ";\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    ", ";\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    ", ";\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: 1px solid ", ";\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: 1px solid ", ";\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px solid ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var borderAliceBlue = (0, _styledComponents.css)(_templateObject(), _colors["default"].aliceBlue);
var flexColumnNoWrap = (0, _styledComponents.css)(_templateObject2());
var flexColumnWrap = (0, _styledComponents.css)(_templateObject3());
var flexRowNoWrap = (0, _styledComponents.css)(_templateObject4());
var flexRowWrap = (0, _styledComponents.css)(_templateObject5());
var _default = {
  borderAliceBlue: borderAliceBlue,
  borderAzureishWhite: (0, _styledComponents.css)(_templateObject6(), _colors["default"].azureishWhite),
  borderGhostWhite: (0, _styledComponents.css)(_templateObject7(), _colors["default"].ghostWhite),
  borderedFlexColumnNoWrap: (0, _styledComponents.css)(_templateObject8(), borderAliceBlue, flexColumnNoWrap),
  borderedFlexColumnWrap: (0, _styledComponents.css)(_templateObject9(), borderAliceBlue, flexColumnWrap),
  borderedFlexRowNoWrap: (0, _styledComponents.css)(_templateObject10(), borderAliceBlue, flexRowNoWrap),
  borderedFlexRowWrap: (0, _styledComponents.css)(_templateObject11(), borderAliceBlue, flexRowWrap),
  boxShadowDarkGunmetalBlack: (0, _styledComponents.css)(_templateObject12(), (0, _polished.rgba)(_colors["default"].darkGunmetalBlack, 0.1)),
  flexColumnNoWrap: flexColumnNoWrap,
  flexColumnWrap: flexColumnWrap,
  flexRowNoWrap: flexRowNoWrap,
  flexRowWrap: flexRowWrap,
  posAbsoluteCenterX: (0, _styledComponents.css)(_templateObject13())
};
exports["default"] = _default;