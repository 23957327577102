"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _ActionIcon = _interopRequireDefault(require("./ActionIcon"));

var _CircularProgressBar = _interopRequireDefault(require("./CircularProgressBar"));

var _Balances = _interopRequireDefault(require("./Balances"));

var _DetailsPage = _interopRequireDefault(require("./DetailsPage"));

var _HistoryPage = _interopRequireDefault(require("./HistoryPage"));

var _ProgressBarSvg = _interopRequireDefault(require("./ProgressBarSvg"));

var _Spacer = _interopRequireDefault(require("../Spacer"));

var _Spinner = _interopRequireDefault(require("../Spinner"));

var _Status = _interopRequireDefault(require("./Status"));

var _Table = _interopRequireDefault(require("./Table"));

var _Tabs = _interopRequireDefault(require("./Tabs"));

var _TimeBox = _interopRequireDefault(require("./TimeBox"));

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 2rem;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  width: 3rem;\n\n  ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  justify-content: center;\n  margin: 1.75rem 1rem 0rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem 0.5rem 7rem 0.5rem;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem 2.5rem 7rem 2.5rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center; /* [19Q4Stream] The old stretch value will make the TimeBox too big when not needed */\n  padding-top: 2rem;\n\n  /* Caveat: if twice the padding ends up being more than 100-85(set in CircularProgressBar)=15vw the page overflows */\n  ", "\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  width: 5rem;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    align-items: stretch;\n    padding: 1.25rem 0rem 0rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  flex-grow: 1;\n  justify-content: space-between;\n  max-width: 1248px;\n  padding: 2rem 2.75rem 3rem;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  justify-content: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
/**
 * Why we need both an outer and an inner wrapper:
 * https://stackoverflow.com/questions/19063770/how-to-vertically-align-and-stretch-content-using-css-flexbox
 */


var OuterWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
});

var InnerWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3(), props.theme.flexColumnNoWrap);
});

var LeftSpacer = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
});

var StyledFlexColumnNoWrap = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject8());
});

var CircularProgressBarWrapper = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var ProgressBarsWrapper = _styledComponents["default"].div(_templateObject10(), function (props) {
  return props.theme.flexRowNoWrap;
});

var StyledSpacer = (0, _styledComponents["default"])(_Spacer["default"])(_templateObject11(), function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject12());
});

function Stream(_ref) {
  var action = _ref.action,
      children = _ref.children,
      onClickHandlers = _ref.onClickHandlers,
      showLinksTab = _ref.showLinksTab,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["action", "children", "onClickHandlers", "showLinksTab"]);

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      error = _useProxyStream.error,
      loading = _useProxyStream.loading,
      proxyStream = _useProxyStream.proxyStream;

  var render = (0, _react.useCallback)(function () {
    if ((0, _typy["default"])(error).isTruthy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_theme.UnknownErrorLabel, null, translation("structs.unknownError")));
    }

    if (loading) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_Spinner["default"], null));
    }
    /* Extraordinarily important check. Without this, the app would crash in many places ! */


    if ((0, _typy["default"])(proxyStream).isFalsy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, translation("structs.noData"));
    }

    return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(LeftSpacer, null), _react["default"].createElement(StyledFlexColumnNoWrap, null, _react["default"].createElement(CircularProgressBarWrapper, null, _react["default"].createElement(_CircularProgressBar["default"], null), _react["default"].createElement(_Balances["default"], null)), _react["default"].createElement(ProgressBarsWrapper, null, _react["default"].createElement(_ProgressBarSvg["default"], {
      label: translation("words.streamed"),
      value: (0, _typy["default"])(proxyStream, "stream.bundles.streamed.percentage").safeNumber
    }), _react["default"].createElement(StyledSpacer, null), _react["default"].createElement(_ProgressBarSvg["default"], {
      label: translation("words.withdrawn"),
      value: (0, _typy["default"])(proxyStream, "stream.bundles.withdrawn.percentage").safeNumber
    })), _react["default"].createElement(_TimeBox["default"], {
      action: action,
      onClickHandlers: onClickHandlers
    })), _react["default"].createElement(_Tabs["default"], {
      action: action,
      onClickHandlers: onClickHandlers,
      showLinksTab: showLinksTab
    }), children);
  }, [action, children, error, loading, onClickHandlers, proxyStream, showLinksTab, translation]);
  return _react["default"].createElement(OuterWrapper, otherProps, _react["default"].createElement(InnerWrapper, null, render()));
}

Stream.propTypes = {
  action: _propTypes["default"].number,
  onClickHandlers: _propTypes["default"].shape({
    action: _propTypes["default"].func,
    details: _propTypes["default"].func,
    history: _propTypes["default"].func,
    options: _propTypes["default"].func
  }),
  showLinksTab: _propTypes["default"].bool
};
Stream.defaultProps = {
  action: 0,
  onClickHandlers: {
    action: null,
    details: null,
    history: null,
    links: null
  },
  showLinksTab: true
};
var _default = {
  ActionIcon: _ActionIcon["default"],
  Balances: _Balances["default"],
  CircularProgressBar: _CircularProgressBar["default"],
  DetailsPage: _DetailsPage["default"],
  HistoryPage: _HistoryPage["default"],
  Status: _Status["default"],
  Table: _Table["default"],
  Tabs: _Tabs["default"],
  TimeBox: _TimeBox["default"],
  UI: Stream
};
exports["default"] = _default;