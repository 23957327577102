"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _Emoji = _interopRequireDefault(require("./Emoji"));

var _Image = _interopRequireDefault(require("./Image"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject());

var Icon = function Icon(props) {
  var icon = props.icon,
      emoji = props.emoji,
      image = props.image,
      source = props.source,
      alt = props.alt,
      label = props.label,
      onClick = props.onClick;

  var content = _react["default"].createElement(_react["default"].Fragment, null);

  if ((0, _typy["default"])(icon).isTruthy) {
    var family = props.family;

    if (family === "round") {
      family = "material-icons-round";
    }

    if (family === "outlined") {
      family = "material-icons-outlined";
    }

    content = _react["default"].createElement("i", {
      className: family
    }, source);
  } else if ((0, _typy["default"])(emoji).isTruthy) {
    content = _react["default"].createElement(_Emoji["default"], {
      symbol: source,
      label: label
    });
  } else if ((0, _typy["default"])(image).isTruthy) {
    var imageHasLoader = props.imageHasLoader;
    content = _react["default"].createElement(_Image["default"], {
      hasLoader: imageHasLoader,
      source: source,
      alt: alt
    });
  }

  return _react["default"].createElement(Wrapper, {
    onClick: onClick
  }, content);
};

Icon.propTypes = {
  alt: _propTypes["default"].string,
  emoji: _propTypes["default"].bool,
  family: _propTypes["default"].oneOf(["material-icons", "material-icons-round", "material-icons-outlined", "outlined", "round"]),
  icon: _propTypes["default"].bool,
  image: _propTypes["default"].bool,
  imageHasLoader: _propTypes["default"].bool,
  label: _propTypes["default"].string,
  onClick: _propTypes["default"].func,
  source: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].number, _propTypes["default"].string, _propTypes["default"].shape({})]).isRequired,
  style: _propTypes["default"].shape({})
};
Icon.defaultProps = {
  alt: "",
  emoji: false,
  icon: false,
  image: false,
  imageHasLoader: true,
  label: "",
  family: "material-icons",
  onClick: function onClick() {},
  style: {}
};
var _default = Icon;
exports["default"] = _default;