"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PROXY_STREAM = exports.GET_RECIPIENT_PROXY_STREAMS = exports.GET_SENDER_PROXY_STREAMS = exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _stream = _interopRequireDefault(require("./stream"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query ProxyStream($id: ID!) {\n    proxyStream(id: $id) {\n      ...ProxyStream\n    }\n  }\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query ProxyStreams($recipient: String!) {\n    proxyStreams(first: 200, orderBy: timestamp, orderDirection: desc, where: { recipient: $recipient }) {\n      ...ProxyStream\n    }\n  }\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query ProxyStreams($sender: String!) {\n    proxyStreams(first: 200, orderBy: timestamp, orderDirection: desc, where: { sender: $sender }) {\n      ...ProxyStream\n    }\n  }\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment ProxyStream on ProxyStream {\n    id\n    sender\n    recipient\n    stream {\n      ...Stream\n    }\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var PROXY_STREAM = {
  fragments: {}
};
PROXY_STREAM.fragments.proxyStream = (0, _graphqlTag["default"])(_templateObject(), _stream["default"].fragments.stream);
var _default = PROXY_STREAM;
exports["default"] = _default;
var GET_SENDER_PROXY_STREAMS = (0, _graphqlTag["default"])(_templateObject2(), PROXY_STREAM.fragments.proxyStream);
exports.GET_SENDER_PROXY_STREAMS = GET_SENDER_PROXY_STREAMS;
var GET_RECIPIENT_PROXY_STREAMS = (0, _graphqlTag["default"])(_templateObject3(), PROXY_STREAM.fragments.proxyStream);
exports.GET_RECIPIENT_PROXY_STREAMS = GET_RECIPIENT_PROXY_STREAMS;
var GET_PROXY_STREAM = (0, _graphqlTag["default"])(_templateObject4(), PROXY_STREAM.fragments.proxyStream);
exports.GET_PROXY_STREAM = GET_PROXY_STREAM;