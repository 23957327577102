"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Error;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0.5rem 0rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n\n  & span {\n    font-size: 0.875rem;\n  }\n\n  & .icon-circle-wrapper {\n    ", ";\n    align-items: center;\n    background-color: ", ";\n    border-radius: 0.875rem;\n    flex-shrink: 0;\n    height: 1.75rem;\n    justify-content: center;\n    width: 1.75rem;\n  }\n\n  & .label {\n    flex-grow: 1;\n    font-size: 0.9375rem;\n    margin-left: 0.5rem;\n    margin-right: 0.5rem;\n    text-align: center;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 0.875rem;\n  object-fit: contain;\n  width: 0.875rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var FaExclamationTriangle = (0, _styledComponents["default"])(_reactFeather.AlertTriangle)(_templateObject());

var Wrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.rustyRed;
}, function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.rustyRed;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

function Error(_ref) {
  var children = _ref.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children"]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement("div", {
    className: "icon-circle-wrapper"
  }, _react["default"].createElement(FaExclamationTriangle, {
    color: _theme.colors.white
  })), children);
}