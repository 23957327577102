"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: ", "rem;\n  width: ", "rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.height;
}, function (props) {
  return props.width;
});

function Spacer(_ref) {
  var height = _ref.height,
      width = _ref.width,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["height", "width"]);
  return _react["default"].createElement(Wrapper, (0, _extends2["default"])({
    height: height,
    width: width
  }, otherProps));
}

Spacer.propTypes = {
  height: _propTypes["default"].number,
  width: _propTypes["default"].number
};
Spacer.defaultProps = {
  height: 0,
  width: 0
};
var _default = Spacer;
exports["default"] = _default;