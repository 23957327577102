"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAddTransaction = useAddTransaction;
exports.useAllTransactions = useAllTransactions;
exports.usePendingApproval = usePendingApproval;
exports.useHasPendingTransactions = useHasPendingTransactions;
exports.TransactionsContextProvider = TransactionsContextProvider;
exports.TransactionsContextUpdater = TransactionsContextUpdater;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _typy = _interopRequireDefault(require("typy"));

var _utils = require("@sablier/utils");

var _reactHooks = require("@sablier/react-hooks");

var _Ethereum = require("./Ethereum");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var RESPONSE = "response";
var CUSTOM_DATA = "CUSTOM_DATA";
var BLOCK_NUMBER_CHECKED = "BLOCK_NUMBER_CHECKED";
var RECEIPT = "receipt";
var ADD_TRANSACTION = "ADD_TRANSACTION";
var CHECK_TRANSACTION = "CHECK_TRANSACTION";
var FINALIZE_TRANSACTION = "FINALIZE_TRANSACTION";
var TransactionsContext = (0, _react.createContext)();

function useTransactionsContext() {
  return (0, _react.useContext)(TransactionsContext);
}

function useAddTransaction() {
  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React.chainId;

  var _useTransactionsConte = useTransactionsContext(),
      _useTransactionsConte2 = (0, _slicedToArray2["default"])(_useTransactionsConte, 2),
      addTransaction = _useTransactionsConte2[1].addTransaction;

  return (0, _react.useCallback)(function (response) {
    var customData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if ((0, _typy["default"])(chainId).isFalsy && chainId !== 0) {
      throw Error("Invalid chainId '".concat(chainId));
    }

    if ((0, _typy["default"])(response, "hash").isFalsy) {
      throw Error("No transaction hash found.");
    }

    addTransaction(chainId, (0, _typy["default"])(response, "hash").safeString, _objectSpread({}, response, (0, _defineProperty2["default"])({}, CUSTOM_DATA, customData)));
  }, [addTransaction, chainId]);
}

function useAllTransactions() {
  var _useWeb3React2 = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React2.chainId;

  var _useTransactionsConte3 = useTransactionsContext(),
      _useTransactionsConte4 = (0, _slicedToArray2["default"])(_useTransactionsConte3, 1),
      state = _useTransactionsConte4[0];

  return (0, _typy["default"])(state, chainId ? chainId.toString() : "").safeObjectOrEmpty;
}

function usePendingApproval(tokenAddress) {
  var allTransactions = useAllTransactions();
  return Object.keys(allTransactions).filter(function (hash) {
    if (allTransactions[hash][RECEIPT]) {
      return false;
    } else if (!allTransactions[hash][RESPONSE]) {
      return false;
    } else if (allTransactions[hash][RESPONSE][CUSTOM_DATA].approval !== tokenAddress) {
      return false;
    } else {
      return true;
    }
  }).length >= 1;
}

function useHasPendingTransactions() {
  var transactions = useAllTransactions();
  var hasPendingTxs = (0, _react.useMemo)(function () {
    var pendingTxs = Object.keys(transactions).filter(function (hash) {
      return !transactions[hash].receipt;
    });
    return pendingTxs.length > 0;
  }, [transactions]);
  return hasPendingTxs;
}

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case ADD_TRANSACTION:
      {
        var chainId = payload.chainId,
            hash = payload.hash,
            response = payload.response;

        if ((0, _utils.safeAccess)(state, [chainId, hash]) !== null) {
          throw Error("Attempted to add existing transaction.");
        }

        return _objectSpread({}, state, (0, _defineProperty2["default"])({}, chainId, _objectSpread({}, (0, _utils.safeAccess)(state, [chainId]) || {}, (0, _defineProperty2["default"])({}, hash, (0, _defineProperty2["default"])({}, RESPONSE, response)))));
      }

    case CHECK_TRANSACTION:
      {
        var _chainId = payload.chainId,
            _hash2 = payload.hash,
            blockNumber = payload.blockNumber;

        if ((0, _utils.safeAccess)(state, [_chainId, _hash2]) === null) {
          throw Error("Attempted to check non-existent transaction.");
        }

        return _objectSpread({}, state, (0, _defineProperty2["default"])({}, _chainId, _objectSpread({}, (0, _utils.safeAccess)(state, [_chainId]) || {}, (0, _defineProperty2["default"])({}, _hash2, _objectSpread({}, (0, _utils.safeAccess)(state, [_chainId, _hash2]) || {}, (0, _defineProperty2["default"])({}, BLOCK_NUMBER_CHECKED, blockNumber))))));
      }

    case FINALIZE_TRANSACTION:
      {
        var _chainId2 = payload.chainId,
            _hash3 = payload.hash,
            receipt = payload.receipt;

        if ((0, _utils.safeAccess)(state, [_chainId2, _hash3]) === null) {
          throw Error("Attempted to finalize non-existent transaction.");
        }

        return _objectSpread({}, state, (0, _defineProperty2["default"])({}, _chainId2, _objectSpread({}, (0, _utils.safeAccess)(state, [_chainId2]) || {}, (0, _defineProperty2["default"])({}, _hash3, _objectSpread({}, (0, _utils.safeAccess)(state, [_chainId2, _hash3]) || {}, (0, _defineProperty2["default"])({}, RECEIPT, receipt))))));
      }

    default:
      {
        throw Error("Unexpected action type in TransactionsContext reducer: '".concat(type, "'."));
      }
  }
}

function TransactionsContextProvider(_ref2) {
  var children = _ref2.children;

  var _useReducer = (0, _react.useReducer)(reducer, {}),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var addTransaction = (0, _react.useCallback)(function (chainId, hash, response) {
    dispatch({
      type: ADD_TRANSACTION,
      payload: {
        chainId: chainId,
        hash: hash,
        response: response
      }
    });
  }, []);
  var checkTransaction = (0, _react.useCallback)(function (chainId, hash, blockNumber) {
    dispatch({
      type: CHECK_TRANSACTION,
      payload: {
        chainId: chainId,
        hash: hash,
        blockNumber: blockNumber
      }
    });
  }, []);
  var finalizeTransaction = (0, _react.useCallback)(function (chainId, hash, receipt) {
    dispatch({
      type: FINALIZE_TRANSACTION,
      payload: {
        chainId: chainId,
        hash: hash,
        receipt: receipt
      }
    });
  }, []);
  return _react["default"].createElement(TransactionsContext.Provider, {
    value: (0, _react.useMemo)(function () {
      return [state, {
        addTransaction: addTransaction,
        checkTransaction: checkTransaction,
        finalizeTransaction: finalizeTransaction
      }];
    }, [addTransaction, checkTransaction, finalizeTransaction, state])
  }, children);
}

function TransactionsContextUpdater() {
  var _useWeb3React3 = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React3.chainId,
      library = _useWeb3React3.library;

  var globalBlockNumber = (0, _Ethereum.useBlockNumber)();

  var _useTransactionsConte5 = useTransactionsContext(),
      _useTransactionsConte6 = (0, _slicedToArray2["default"])(_useTransactionsConte5, 2),
      state = _useTransactionsConte6[0],
      _useTransactionsConte7 = _useTransactionsConte6[1],
      checkTransaction = _useTransactionsConte7.checkTransaction,
      finalizeTransaction = _useTransactionsConte7.finalizeTransaction;

  var allTransactions = (0, _utils.safeAccess)(state, [chainId]) || {};
  (0, _react.useEffect)(function () {
    if ((0, _typy["default"])(chainId).isFalsy || chainId === 0 || (0, _typy["default"])(library).isFalsy) {
      return undefined;
    }

    var stale = false;
    Object.keys(allTransactions).filter(function (hash) {
      return !allTransactions[hash][RECEIPT] && allTransactions[hash][BLOCK_NUMBER_CHECKED] !== globalBlockNumber;
    }).forEach(function _callee(hash) {
      var receipt;
      return _regenerator["default"].async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;
              _context.next = 3;
              return _regenerator["default"].awrap(library.getTransactionReceipt(hash));

            case 3:
              receipt = _context.sent;

              if (!stale) {
                if (!receipt) {
                  checkTransaction(chainId, hash, globalBlockNumber);
                } else {
                  finalizeTransaction(chainId, hash, receipt);
                }
              }

              _context.next = 10;
              break;

            case 7:
              _context.prev = 7;
              _context.t0 = _context["catch"](0);
              checkTransaction(chainId, hash, globalBlockNumber);

            case 10:
            case "end":
              return _context.stop();
          }
        }
      }, null, null, [[0, 7]]);
    });
    return function () {
      stale = true;
    };
  }, [allTransactions, chainId, checkTransaction, finalizeTransaction, library, globalBlockNumber]);
  return null;
}