"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _polished = require("polished");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  margin-left: 0.25rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  flex-shrink: 0;\n  margin-left: 0.5rem;\n  margin-right: 1rem;\n  text-decoration: none;\n\n  &:active,\n  &:hover {\n    color: ", ";\n    text-decoration: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = (0, _styledComponents["default"])(_theme.Link)(_templateObject(), function (props) {
  return props.color;
}, function (props) {
  return (0, _polished.darken)(0.2, props.color);
});

var Label = _styledComponents["default"].span(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
});

function CopyHelper(_ref) {
  var color = _ref.color,
      size = _ref.size,
      toCopy = _ref.toCopy;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useCopyClipboard = (0, _reactHooks.useCopyClipboard)(),
      _useCopyClipboard2 = (0, _slicedToArray2["default"])(_useCopyClipboard, 2),
      isCopied = _useCopyClipboard2[0],
      setCopied = _useCopyClipboard2[1];

  return _react["default"].createElement(Wrapper, {
    color: color,
    onClick: function onClick() {
      return setCopied(toCopy);
    }
  }, isCopied ? _react["default"].createElement(Label, null, _react["default"].createElement(_reactFeather.CheckCircle, {
    size: size
  }), _react["default"].createElement(Label, null, translation("words.copied"))) : _react["default"].createElement(Label, null, _react["default"].createElement(_reactFeather.Copy, {
    size: size
  })));
}

CopyHelper.propTypes = {
  color: _propTypes["default"].string,
  size: _propTypes["default"].number,
  toCopy: _propTypes["default"].string.isRequired
};
CopyHelper.defaultProps = {
  color: _theme.colors.silverSandGray,
  size: 16
};
var _default = CopyHelper;
exports["default"] = _default;