"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGoToModalManager = useGoToModalManager;
exports.useLinksModalManager = useLinksModalManager;
exports.useProcessingModalManager = useProcessingModalManager;
exports.useWalletModalManager = useWalletModalManager;
exports.useModalManager = useModalManager;
exports.ModalsContextProvider = ModalsContextProvider;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _vendors = require("@sablier/vendors");

var _INITIAL_MODALS_CONTE;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var GO_TO_MODAL = "GO_TO_MODAL";
var OPTIONS_MODAL = "OPTIONS_MODAL";
var PROCESSING_MODAL = "PROCESSING_MODAL";
var WALLET_MODAL = "WALLET_MODAL";
var WALLET_MODAL_ERROR = "WALLET_MODAL_ERROR";
var UPDATE_KEY = "UPDATE_KEY";
var UPDATABLE_KEYS = [GO_TO_MODAL, OPTIONS_MODAL, PROCESSING_MODAL, WALLET_MODAL, WALLET_MODAL_ERROR];
var INITIAL_MODALS_CONTEXT = (_INITIAL_MODALS_CONTE = {}, (0, _defineProperty2["default"])(_INITIAL_MODALS_CONTE, GO_TO_MODAL, false), (0, _defineProperty2["default"])(_INITIAL_MODALS_CONTE, OPTIONS_MODAL, false), (0, _defineProperty2["default"])(_INITIAL_MODALS_CONTE, PROCESSING_MODAL, false), (0, _defineProperty2["default"])(_INITIAL_MODALS_CONTE, WALLET_MODAL, false), (0, _defineProperty2["default"])(_INITIAL_MODALS_CONTE, WALLET_MODAL_ERROR, null), _INITIAL_MODALS_CONTE);
var ModalsContext = (0, _react.createContext)();

function useModalsContext() {
  return (0, _react.useContext)(ModalsContext);
}

function useGoToModalManager() {
  var _useModalsContext = useModalsContext(),
      _useModalsContext2 = (0, _slicedToArray2["default"])(_useModalsContext, 2),
      state = _useModalsContext2[0],
      updateKey = _useModalsContext2[1].updateKey;

  var isOpen = state[GO_TO_MODAL];
  var toggle = (0, _react.useCallback)(function () {
    updateKey(GO_TO_MODAL, !isOpen);
  }, [isOpen, updateKey]);
  return {
    isOpen: isOpen,
    toggle: toggle
  };
}

function useLinksModalManager() {
  var _useModalsContext3 = useModalsContext(),
      _useModalsContext4 = (0, _slicedToArray2["default"])(_useModalsContext3, 2),
      state = _useModalsContext4[0],
      updateKey = _useModalsContext4[1].updateKey;

  var isOpen = state[OPTIONS_MODAL];
  var toggle = (0, _react.useCallback)(function () {
    updateKey(OPTIONS_MODAL, !isOpen);
  }, [isOpen, updateKey]);
  return {
    isOpen: isOpen,
    toggle: toggle
  };
}

function useProcessingModalManager() {
  var _useModalsContext5 = useModalsContext(),
      _useModalsContext6 = (0, _slicedToArray2["default"])(_useModalsContext5, 2),
      state = _useModalsContext6[0],
      updateKey = _useModalsContext6[1].updateKey;

  var isOpen = state[PROCESSING_MODAL];
  var toggle = (0, _react.useCallback)(function () {
    updateKey(PROCESSING_MODAL, !isOpen);
  }, [isOpen, updateKey]);
  return {
    isOpen: isOpen,
    toggle: toggle
  };
}

function useWalletModalManager() {
  var _useModalsContext7 = useModalsContext(),
      _useModalsContext8 = (0, _slicedToArray2["default"])(_useModalsContext7, 2),
      state = _useModalsContext8[0],
      updateKey = _useModalsContext8[1].updateKey;

  var isOpen = state[WALLET_MODAL];
  var error = state[WALLET_MODAL_ERROR];
  /* "newError" must be a string */

  var setError = (0, _react.useCallback)(function (newError) {
    updateKey(WALLET_MODAL_ERROR, newError);
  }, [updateKey]);
  var toggle = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track(isOpen ? 'Close "Wallet" Modal' : 'Open "Wallet" Modal');

    updateKey(WALLET_MODAL, !isOpen);
  }, [isOpen, updateKey]);
  return {
    error: error,
    isOpen: isOpen,
    setError: setError,
    toggle: toggle
  };
}

function useModalManager() {
  var _useGoToModalManager = useGoToModalManager(),
      isGoToModalOpen = _useGoToModalManager.isOpen;

  var _useProcessingModalMa = useProcessingModalManager(),
      isProcessingModalOpen = _useProcessingModalMa.isOpen;

  var _useLinksModalManager = useLinksModalManager(),
      isLinksModalOpen = _useLinksModalManager.isOpen;

  var _useWalletModalManage = useWalletModalManager(),
      isWalletModalOpen = _useWalletModalManage.isOpen;

  var isAnyModalOpen = isGoToModalOpen || isLinksModalOpen || isProcessingModalOpen || isWalletModalOpen;
  return {
    isAnyModalOpen: isAnyModalOpen
  };
}

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case UPDATE_KEY:
      {
        var key = payload.key,
            value = payload.value;

        if (!UPDATABLE_KEYS.some(function (k) {
          return k === key;
        })) {
          throw new Error("Unexpected key in ModalsContext reducer: '".concat(key, "'."));
        } else {
          return _objectSpread({}, state, (0, _defineProperty2["default"])({}, key, value));
        }
      }

    default:
      {
        throw new Error("Unexpected action type in ModalsContext reducer: '".concat(type, "'."));
      }
  }
}
/**
 * TODO: figure out a better naming. The context providers that handle the unique modals for each app
 * will have overlapping names with this.
 */


function ModalsContextProvider(_ref2) {
  var children = _ref2.children;

  var _useReducer = (0, _react.useReducer)(reducer, INITIAL_MODALS_CONTEXT),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var updateKey = (0, _react.useCallback)(function (key, value) {
    dispatch({
      type: UPDATE_KEY,
      payload: {
        key: key,
        value: value
      }
    });
  }, []);
  return _react["default"].createElement(ModalsContext.Provider, {
    value: (0, _react.useMemo)(function () {
      return [state, {
        updateKey: updateKey
      }];
    }, [state, updateKey])
  }, children);
}