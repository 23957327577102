"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAllTokens = useAllTokens;
exports.TokensContextProvider = TokensContextProvider;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _utils = require("@sablier/utils");

var _xB6eD7644C69416d67B, _x3F382DbD960E3a9bbC, _x27702a26126e0B, _xF5D669627376EBd, _x3472A5A71965499acd, _x0D8775F648430679A, _x3112E6EEb095791CDa, _xCF3C8Be2e2C42331Da, _x06AF07097C9Eeb7fD, _x514910771AF9Ca656a, _x5d3a536E4D6DbD, _x39AA39c021dfbaE8fa, _xB4272071eCAdd69d, _x6B175474E89094C44D, _x537A9095b78517597b, _x5F64Ab1544D28732F, _xca1207647Ff, _x4f3AfEC4E5a3F2A6a, _xC0F9bD5Fa5698B, _xaac41EC512808d, _x515d7E9D75E2b76DB, _xc944E90C64B2c, _x8A9C67fee641579dEb, _xe8D17542dfe79Ff4FB, _xdd974D5C2e2928deA, _x58b6A8A3302369DAEc, _xDF2C7238198Ad8B, _x9f8F72aA9304c8B, _xa3BeD4E1c75D00fa6f, _xa3d58c4E56fedCae3a, _xe2f2a5C287993345a, _x1776e1F26f98b1A5dF, _x7240aC91f01233BaAf, _x4575f41308EC1483f, _x0b11D5d078955C6E, _x8E870D67F660D95d5b, _xbC396689893D065F, _x83e6f1E41cdd28eAcE, _xf1f955016EcbCd, _x408e41876cCCDC0F, _x1985365e9f78359a9B, _x1a2d7C2b22a0b6Ce, _x89d24A6b4CcB1B6fAA, _x2F6081E3552b1c86cE, _x39795344cbcc76cc3f, _x67ab11058eF23D0a, _xEd0439EACf4c4965AE, _x23B608675a2B2fB, _xA4Bdb11dc0a2bEC88d, _x744d70FDBE2Ba4CF, _x6b3595068778dd592e, _xa19A40FbD7375431fA, _x57ab1e02fee, _xC011a73ee8576Fb46F, _x8dAEBADE922dF735c, _x0000000000085d, _x1f9840a85d5aF5bf1D, _xA0b86991c6218b36c, _x2260FAC5E5542a773A, _xC02aaA39b223FE8D0A, _x8BA6DcC667d3FF64C, _xdc8092AaF83e00Ebf, _xA809CeDeE9B61956c, _xeA0bea4d852687c45f, _xE98b5F11897d42Afd, _x56687cf29Ac9751Ce, _xC1fB6C015fC535aBD, _x3C35132D0b129F068F, _x8f464bfe2292748A0F, _xDB7eB3edE973665b1B, _x5A844590c5b8f40ae, _x27fD686Db10E0aE, _xbdBF67D9bcb35e2bb, _xD0Bf154768b0f1f8Cb, _x2D69aD895797C880ab, _xc3dbf84Abb494ce, _xF2D1F94310823FE26c, _x7d669A64deb8a4A51e;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var NAME = "name";
var SYMBOL = "symbol";
var DECIMALS = "decimals";
var UPDATE = "UPDATE";
var INITIAL_TOKENS_CONTEXT = {
  1: {
    "0xB6eD7644C69416d67B522e20bC294A9a9B405B31": (_xB6eD7644C69416d67B = {}, (0, _defineProperty2["default"])(_xB6eD7644C69416d67B, NAME, "0xBitcoin Token"), (0, _defineProperty2["default"])(_xB6eD7644C69416d67B, SYMBOL, "0xBTC"), (0, _defineProperty2["default"])(_xB6eD7644C69416d67B, DECIMALS, 8), _xB6eD7644C69416d67B),
    "0x3F382DbD960E3a9bbCeaE22651E88158d2791550": (_x3F382DbD960E3a9bbC = {}, (0, _defineProperty2["default"])(_x3F382DbD960E3a9bbC, NAME, "Aavegotchi Token"), (0, _defineProperty2["default"])(_x3F382DbD960E3a9bbC, SYMBOL, "GHST"), (0, _defineProperty2["default"])(_x3F382DbD960E3a9bbC, DECIMALS, 18), _x3F382DbD960E3a9bbC),
    "0x27702a26126e0B3702af63Ee09aC4d1A084EF628": (_x27702a26126e0B = {}, (0, _defineProperty2["default"])(_x27702a26126e0B, NAME, "Aleph"), (0, _defineProperty2["default"])(_x27702a26126e0B, SYMBOL, "ALEPH"), (0, _defineProperty2["default"])(_x27702a26126e0B, DECIMALS, 18), _x27702a26126e0B),
    "0xF5D669627376EBd411E34b98F19C868c8ABA5ADA": (_xF5D669627376EBd = {}, (0, _defineProperty2["default"])(_xF5D669627376EBd, NAME, "Axie Infinity Shard"), (0, _defineProperty2["default"])(_xF5D669627376EBd, SYMBOL, "AXS"), (0, _defineProperty2["default"])(_xF5D669627376EBd, DECIMALS, 18), _xF5D669627376EBd),
    "0x3472A5A71965499acd81997a54BBA8D852C6E53d": (_x3472A5A71965499acd = {}, (0, _defineProperty2["default"])(_x3472A5A71965499acd, NAME, "Badger"), (0, _defineProperty2["default"])(_x3472A5A71965499acd, SYMBOL, "BADGER"), (0, _defineProperty2["default"])(_x3472A5A71965499acd, DECIMALS, 18), _x3472A5A71965499acd),
    "0x0D8775F648430679A709E98d2b0Cb6250d2887EF": (_x0D8775F648430679A = {}, (0, _defineProperty2["default"])(_x0D8775F648430679A, NAME, "Basic Attention Token"), (0, _defineProperty2["default"])(_x0D8775F648430679A, SYMBOL, "BAT"), (0, _defineProperty2["default"])(_x0D8775F648430679A, DECIMALS, 18), _x0D8775F648430679A),
    "0x3112E6EEb095791CDa8271c25a02A1E66018ade7": (_x3112E6EEb095791CDa = {}, (0, _defineProperty2["default"])(_x3112E6EEb095791CDa, NAME, "BENCoin"), (0, _defineProperty2["default"])(_x3112E6EEb095791CDa, SYMBOL, "BEN"), (0, _defineProperty2["default"])(_x3112E6EEb095791CDa, DECIMALS, 18), _x3112E6EEb095791CDa),
    "0xCF3C8Be2e2C42331Da80EF210e9B1b307C03d36A": (_xCF3C8Be2e2C42331Da = {}, (0, _defineProperty2["default"])(_xCF3C8Be2e2C42331Da, NAME, "Bet Protocol Token"), (0, _defineProperty2["default"])(_xCF3C8Be2e2C42331Da, SYMBOL, "BEPRO"), (0, _defineProperty2["default"])(_xCF3C8Be2e2C42331Da, DECIMALS, 18), _xCF3C8Be2e2C42331Da),
    "0x06AF07097C9Eeb7fD685c692751D5C66dB49c215": (_x06AF07097C9Eeb7fD = {}, (0, _defineProperty2["default"])(_x06AF07097C9Eeb7fD, NAME, "Chai"), (0, _defineProperty2["default"])(_x06AF07097C9Eeb7fD, SYMBOL, "CHAI"), (0, _defineProperty2["default"])(_x06AF07097C9Eeb7fD, DECIMALS, 18), _x06AF07097C9Eeb7fD),
    "0x514910771AF9Ca656af840dff83E8264EcF986CA": (_x514910771AF9Ca656a = {}, (0, _defineProperty2["default"])(_x514910771AF9Ca656a, NAME, "ChainLink Token"), (0, _defineProperty2["default"])(_x514910771AF9Ca656a, SYMBOL, "LINK"), (0, _defineProperty2["default"])(_x514910771AF9Ca656a, DECIMALS, 18), _x514910771AF9Ca656a),
    "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643": (_x5d3a536E4D6DbD = {}, (0, _defineProperty2["default"])(_x5d3a536E4D6DbD, NAME, "Compound Dai"), (0, _defineProperty2["default"])(_x5d3a536E4D6DbD, SYMBOL, "cDAI"), (0, _defineProperty2["default"])(_x5d3a536E4D6DbD, DECIMALS, 8), _x5d3a536E4D6DbD),
    "0x39AA39c021dfbaE8faC545936693aC917d5E7563": (_x39AA39c021dfbaE8fa = {}, (0, _defineProperty2["default"])(_x39AA39c021dfbaE8fa, NAME, "Compound USD Coin"), (0, _defineProperty2["default"])(_x39AA39c021dfbaE8fa, SYMBOL, "cUSDC"), (0, _defineProperty2["default"])(_x39AA39c021dfbaE8fa, DECIMALS, 8), _x39AA39c021dfbaE8fa),
    "0xB4272071eCAdd69d933AdcD19cA99fe80664fc08": (_xB4272071eCAdd69d = {}, (0, _defineProperty2["default"])(_xB4272071eCAdd69d, NAME, "Crypto Franc"), (0, _defineProperty2["default"])(_xB4272071eCAdd69d, SYMBOL, "XCHF"), (0, _defineProperty2["default"])(_xB4272071eCAdd69d, DECIMALS, 18), _xB4272071eCAdd69d),
    "0x6B175474E89094C44Da98b954EedeAC495271d0F": (_x6B175474E89094C44D = {}, (0, _defineProperty2["default"])(_x6B175474E89094C44D, NAME, "Dai Stablecoin"), (0, _defineProperty2["default"])(_x6B175474E89094C44D, SYMBOL, "DAI"), (0, _defineProperty2["default"])(_x6B175474E89094C44D, DECIMALS, 18), _x6B175474E89094C44D),
    "0x537A9095b78517597b5f2058EDcd6E1978095909": (_x537A9095b78517597b = {}, (0, _defineProperty2["default"])(_x537A9095b78517597b, NAME, "Design"), (0, _defineProperty2["default"])(_x537A9095b78517597b, SYMBOL, "DSGN"), (0, _defineProperty2["default"])(_x537A9095b78517597b, DECIMALS, 4), _x537A9095b78517597b),
    "0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0": (_x5F64Ab1544D28732F = {}, (0, _defineProperty2["default"])(_x5F64Ab1544D28732F, NAME, "DEXTF Token (DEXTF)"), (0, _defineProperty2["default"])(_x5F64Ab1544D28732F, SYMBOL, "DEXTF"), (0, _defineProperty2["default"])(_x5F64Ab1544D28732F, DECIMALS, 18), _x5F64Ab1544D28732F),
    "0xca1207647Ff814039530D7d35df0e1Dd2e91Fa84": (_xca1207647Ff = {}, (0, _defineProperty2["default"])(_xca1207647Ff, NAME, "dHedge"), (0, _defineProperty2["default"])(_xca1207647Ff, SYMBOL, "DHT"), (0, _defineProperty2["default"])(_xca1207647Ff, DECIMALS, 18), _xca1207647Ff),
    "0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF": (_x4f3AfEC4E5a3F2A6a = {}, (0, _defineProperty2["default"])(_x4f3AfEC4E5a3F2A6a, NAME, "Digix Gold Token"), (0, _defineProperty2["default"])(_x4f3AfEC4E5a3F2A6a, SYMBOL, "DGX"), (0, _defineProperty2["default"])(_x4f3AfEC4E5a3F2A6a, DECIMALS, 9), _x4f3AfEC4E5a3F2A6a),
    "0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9": (_xC0F9bD5Fa5698B = {}, (0, _defineProperty2["default"])(_xC0F9bD5Fa5698B, NAME, "Donut"), (0, _defineProperty2["default"])(_xC0F9bD5Fa5698B, SYMBOL, "DONUT"), (0, _defineProperty2["default"])(_xC0F9bD5Fa5698B, DECIMALS, 18), _xC0F9bD5Fa5698B),
    "0xaac41EC512808d64625576EDdd580e7Ea40ef8B2": (_xaac41EC512808d = {}, (0, _defineProperty2["default"])(_xaac41EC512808d, NAME, "GameSwap"), (0, _defineProperty2["default"])(_xaac41EC512808d, SYMBOL, "GSWAP"), (0, _defineProperty2["default"])(_xaac41EC512808d, DECIMALS, 18), _xaac41EC512808d),
    "0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc": (_x515d7E9D75E2b76DB = {}, (0, _defineProperty2["default"])(_x515d7E9D75E2b76DB, NAME, "GDAO"), (0, _defineProperty2["default"])(_x515d7E9D75E2b76DB, SYMBOL, "GDAO"), (0, _defineProperty2["default"])(_x515d7E9D75E2b76DB, DECIMALS, 18), _x515d7E9D75E2b76DB),
    "0xc944E90C64B2c07662A292be6244BDf05Cda44a7": (_xc944E90C64B2c = {}, (0, _defineProperty2["default"])(_xc944E90C64B2c, NAME, "Graph Token"), (0, _defineProperty2["default"])(_xc944E90C64B2c, SYMBOL, "GRT"), (0, _defineProperty2["default"])(_xc944E90C64B2c, DECIMALS, 18), _xc944E90C64B2c),
    "0x8A9C67fee641579dEbA04928c4BC45F66e26343A": (_x8A9C67fee641579dEb = {}, (0, _defineProperty2["default"])(_x8A9C67fee641579dEb, NAME, "Jarvis Reward Token"), (0, _defineProperty2["default"])(_x8A9C67fee641579dEb, SYMBOL, "JRT"), (0, _defineProperty2["default"])(_x8A9C67fee641579dEb, DECIMALS, 18), _x8A9C67fee641579dEb),
    "0xe8D17542dfe79Ff4FBd4b850f2d39DC69c4489a2": (_xe8D17542dfe79Ff4FB = {}, (0, _defineProperty2["default"])(_xe8D17542dfe79Ff4FB, NAME, "KiloAmple"), (0, _defineProperty2["default"])(_xe8D17542dfe79Ff4FB, SYMBOL, "kMPL"), (0, _defineProperty2["default"])(_xe8D17542dfe79Ff4FB, DECIMALS, 9), _xe8D17542dfe79Ff4FB),
    "0xdd974D5C2e2928deA5F71b9825b8b646686BD200": (_xdd974D5C2e2928deA = {}, (0, _defineProperty2["default"])(_xdd974D5C2e2928deA, NAME, "Kyber Network Crystal"), (0, _defineProperty2["default"])(_xdd974D5C2e2928deA, SYMBOL, "KNC"), (0, _defineProperty2["default"])(_xdd974D5C2e2928deA, DECIMALS, 18), _xdd974D5C2e2928deA),
    "0x58b6A8A3302369DAEc383334672404Ee733aB239": (_x58b6A8A3302369DAEc = {}, (0, _defineProperty2["default"])(_x58b6A8A3302369DAEc, NAME, "Livepeer Token"), (0, _defineProperty2["default"])(_x58b6A8A3302369DAEc, SYMBOL, "LPT"), (0, _defineProperty2["default"])(_x58b6A8A3302369DAEc, DECIMALS, 18), _x58b6A8A3302369DAEc),
    "0xDF2C7238198Ad8B389666574f2d8bc411A4b7428": (_xDF2C7238198Ad8B = {}, (0, _defineProperty2["default"])(_xDF2C7238198Ad8B, NAME, "Mainframe Token"), (0, _defineProperty2["default"])(_xDF2C7238198Ad8B, SYMBOL, "MFT"), (0, _defineProperty2["default"])(_xDF2C7238198Ad8B, DECIMALS, 18), _xDF2C7238198Ad8B),
    "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2": (_x9f8F72aA9304c8B = {}, (0, _defineProperty2["default"])(_x9f8F72aA9304c8B, NAME, "Maker"), (0, _defineProperty2["default"])(_x9f8F72aA9304c8B, SYMBOL, "MKR"), (0, _defineProperty2["default"])(_x9f8F72aA9304c8B, DECIMALS, 18), _x9f8F72aA9304c8B),
    "0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2": (_xa3BeD4E1c75D00fa6f = {}, (0, _defineProperty2["default"])(_xa3BeD4E1c75D00fa6f, NAME, "Meta"), (0, _defineProperty2["default"])(_xa3BeD4E1c75D00fa6f, SYMBOL, "MTA"), (0, _defineProperty2["default"])(_xa3BeD4E1c75D00fa6f, DECIMALS, 18), _xa3BeD4E1c75D00fa6f),
    "0xa3d58c4E56fedCae3a7c43A725aeE9A71F0ece4e": (_xa3d58c4E56fedCae3a = {}, (0, _defineProperty2["default"])(_xa3d58c4E56fedCae3a, NAME, "Metronome"), (0, _defineProperty2["default"])(_xa3d58c4E56fedCae3a, SYMBOL, "MET"), (0, _defineProperty2["default"])(_xa3d58c4E56fedCae3a, DECIMALS, 18), _xa3d58c4E56fedCae3a),
    "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5": (_xe2f2a5C287993345a = {}, (0, _defineProperty2["default"])(_xe2f2a5C287993345a, NAME, "mStable USD"), (0, _defineProperty2["default"])(_xe2f2a5C287993345a, SYMBOL, "mUSD"), (0, _defineProperty2["default"])(_xe2f2a5C287993345a, DECIMALS, 18), _xe2f2a5C287993345a),
    "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671": (_x1776e1F26f98b1A5dF = {}, (0, _defineProperty2["default"])(_x1776e1F26f98b1A5dF, NAME, "Numeraire"), (0, _defineProperty2["default"])(_x1776e1F26f98b1A5dF, SYMBOL, "NMR"), (0, _defineProperty2["default"])(_x1776e1F26f98b1A5dF, DECIMALS, 18), _x1776e1F26f98b1A5dF),
    "0x7240aC91f01233BaAf8b064248E80feaA5912BA3": (_x7240aC91f01233BaAf = {}, (0, _defineProperty2["default"])(_x7240aC91f01233BaAf, NAME, "Octo.fi"), (0, _defineProperty2["default"])(_x7240aC91f01233BaAf, SYMBOL, "OCTO"), (0, _defineProperty2["default"])(_x7240aC91f01233BaAf, DECIMALS, 18), _x7240aC91f01233BaAf),
    "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb": (_x4575f41308EC1483f = {}, (0, _defineProperty2["default"])(_x4575f41308EC1483f, NAME, "Orchid"), (0, _defineProperty2["default"])(_x4575f41308EC1483f, SYMBOL, "OXT"), (0, _defineProperty2["default"])(_x4575f41308EC1483f, DECIMALS, 18), _x4575f41308EC1483f),
    "0x0b11D5d078955C6E5913beEaba85660202A4FC55": (_x0b11D5d078955C6E = {}, (0, _defineProperty2["default"])(_x0b11D5d078955C6E, NAME, "Ortier"), (0, _defineProperty2["default"])(_x0b11D5d078955C6E, SYMBOL, "ORTC"), (0, _defineProperty2["default"])(_x0b11D5d078955C6E, DECIMALS, 4), _x0b11D5d078955C6E),
    "0x8E870D67F660D95d5be530380D0eC0bd388289E1": (_x8E870D67F660D95d5b = {}, (0, _defineProperty2["default"])(_x8E870D67F660D95d5b, NAME, "Paxos"), (0, _defineProperty2["default"])(_x8E870D67F660D95d5b, SYMBOL, "PXT"), (0, _defineProperty2["default"])(_x8E870D67F660D95d5b, DECIMALS, 18), _x8E870D67F660D95d5b),
    "0xbC396689893D065F41bc2C6EcbeE5e0085233447": (_xbC396689893D065F = {}, (0, _defineProperty2["default"])(_xbC396689893D065F, NAME, "Perpetual"), (0, _defineProperty2["default"])(_xbC396689893D065F, SYMBOL, "PERP"), (0, _defineProperty2["default"])(_xbC396689893D065F, DECIMALS, 18), _xbC396689893D065F),
    "0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa": (_x83e6f1E41cdd28eAcE = {}, (0, _defineProperty2["default"])(_x83e6f1E41cdd28eAcE, NAME, "Polkastarter Token"), (0, _defineProperty2["default"])(_x83e6f1E41cdd28eAcE, SYMBOL, "POLS"), (0, _defineProperty2["default"])(_x83e6f1E41cdd28eAcE, DECIMALS, 18), _x83e6f1E41cdd28eAcE),
    "0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b": (_xf1f955016EcbCd = {}, (0, _defineProperty2["default"])(_xf1f955016EcbCd, NAME, "Rally"), (0, _defineProperty2["default"])(_xf1f955016EcbCd, SYMBOL, "RLY"), (0, _defineProperty2["default"])(_xf1f955016EcbCd, DECIMALS, 18), _xf1f955016EcbCd),
    "0x408e41876cCCDC0F92210600ef50372656052a38": (_x408e41876cCCDC0F = {}, (0, _defineProperty2["default"])(_x408e41876cCCDC0F, NAME, "Republic Token"), (0, _defineProperty2["default"])(_x408e41876cCCDC0F, SYMBOL, "REN"), (0, _defineProperty2["default"])(_x408e41876cCCDC0F, DECIMALS, 18), _x408e41876cCCDC0F),
    "0x1985365e9f78359a9B6AD760e32412f4a445E862": (_x1985365e9f78359a9B = {}, (0, _defineProperty2["default"])(_x1985365e9f78359a9B, NAME, "Reputation"), (0, _defineProperty2["default"])(_x1985365e9f78359a9B, SYMBOL, "REP"), (0, _defineProperty2["default"])(_x1985365e9f78359a9B, DECIMALS, 18), _x1985365e9f78359a9B),
    "0x1a2d7C2b22a0b6Ce314dCbC30aC236303303defA": (_x1a2d7C2b22a0b6Ce = {}, (0, _defineProperty2["default"])(_x1a2d7C2b22a0b6Ce, NAME, "Rupiah Token"), (0, _defineProperty2["default"])(_x1a2d7C2b22a0b6Ce, SYMBOL, "IDRTW"), (0, _defineProperty2["default"])(_x1a2d7C2b22a0b6Ce, DECIMALS, 18), _x1a2d7C2b22a0b6Ce),
    "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359": (_x89d24A6b4CcB1B6fAA = {}, (0, _defineProperty2["default"])(_x89d24A6b4CcB1B6fAA, NAME, "Sai Stablecoin v1.0"), (0, _defineProperty2["default"])(_x89d24A6b4CcB1B6fAA, SYMBOL, "SAI"), (0, _defineProperty2["default"])(_x89d24A6b4CcB1B6fAA, DECIMALS, 18), _x89d24A6b4CcB1B6fAA),
    "0x2F6081E3552b1c86cE4479B80062A1ddA8EF23E3": (_x2F6081E3552b1c86cE = {}, (0, _defineProperty2["default"])(_x2F6081E3552b1c86cE, NAME, "Seigniorage Dollars"), (0, _defineProperty2["default"])(_x2F6081E3552b1c86cE, SYMBOL, "SeignUSD"), (0, _defineProperty2["default"])(_x2F6081E3552b1c86cE, DECIMALS, 9), _x2F6081E3552b1c86cE),
    "0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d": (_x39795344cbcc76cc3f = {}, (0, _defineProperty2["default"])(_x39795344cbcc76cc3f, NAME, "Seigniorage Shares"), (0, _defineProperty2["default"])(_x39795344cbcc76cc3f, SYMBOL, "SHARE"), (0, _defineProperty2["default"])(_x39795344cbcc76cc3f, DECIMALS, 9), _x39795344cbcc76cc3f),
    "0x67ab11058eF23D0a19178f61A050D3c38F81Ae21": (_x67ab11058eF23D0a = {}, (0, _defineProperty2["default"])(_x67ab11058eF23D0a, NAME, "Self Token"), (0, _defineProperty2["default"])(_x67ab11058eF23D0a, SYMBOL, "SELF"), (0, _defineProperty2["default"])(_x67ab11058eF23D0a, DECIMALS, 18), _x67ab11058eF23D0a),
    "0xEd0439EACf4c4965AE4613D77a5C2Efe10e5f183": (_xEd0439EACf4c4965AE = {}, (0, _defineProperty2["default"])(_xEd0439EACf4c4965AE, NAME, "Shroom.Finance"), (0, _defineProperty2["default"])(_xEd0439EACf4c4965AE, SYMBOL, "SHROOM"), (0, _defineProperty2["default"])(_xEd0439EACf4c4965AE, DECIMALS, 18), _xEd0439EACf4c4965AE),
    "0x23B608675a2B2fB1890d3ABBd85c5775c51691d5": (_x23B608675a2B2fB = {}, (0, _defineProperty2["default"])(_x23B608675a2B2fB, NAME, "Unisocks v0"), (0, _defineProperty2["default"])(_x23B608675a2B2fB, SYMBOL, "SOCKS"), (0, _defineProperty2["default"])(_x23B608675a2B2fB, DECIMALS, 18), _x23B608675a2B2fB),
    "0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe": (_xA4Bdb11dc0a2bEC88d = {}, (0, _defineProperty2["default"])(_xA4Bdb11dc0a2bEC88d, NAME, "Stable USD"), (0, _defineProperty2["default"])(_xA4Bdb11dc0a2bEC88d, SYMBOL, "USDS"), (0, _defineProperty2["default"])(_xA4Bdb11dc0a2bEC88d, DECIMALS, 6), _xA4Bdb11dc0a2bEC88d),
    "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E": (_x744d70FDBE2Ba4CF = {}, (0, _defineProperty2["default"])(_x744d70FDBE2Ba4CF, NAME, "Status Network Token"), (0, _defineProperty2["default"])(_x744d70FDBE2Ba4CF, SYMBOL, "SNT"), (0, _defineProperty2["default"])(_x744d70FDBE2Ba4CF, DECIMALS, 18), _x744d70FDBE2Ba4CF),
    "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2": (_x6b3595068778dd592e = {}, (0, _defineProperty2["default"])(_x6b3595068778dd592e, NAME, "SushiToken"), (0, _defineProperty2["default"])(_x6b3595068778dd592e, SYMBOL, "SUSHI"), (0, _defineProperty2["default"])(_x6b3595068778dd592e, DECIMALS, 18), _x6b3595068778dd592e),
    "0xa19A40FbD7375431fAB013a4B08F00871B9a2791": (_xa19A40FbD7375431fA = {}, (0, _defineProperty2["default"])(_xa19A40FbD7375431fA, NAME, "Swagg"), (0, _defineProperty2["default"])(_xa19A40FbD7375431fA, SYMBOL, "SWAGG"), (0, _defineProperty2["default"])(_xa19A40FbD7375431fA, DECIMALS, 4), _xa19A40FbD7375431fA),
    "0x57ab1e02fee23774580c119740129eac7081e9d3": (_x57ab1e02fee = {}, (0, _defineProperty2["default"])(_x57ab1e02fee, NAME, "Synth sUSD"), (0, _defineProperty2["default"])(_x57ab1e02fee, SYMBOL, "sUSD"), (0, _defineProperty2["default"])(_x57ab1e02fee, DECIMALS, 18), _x57ab1e02fee),
    "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F": (_xC011a73ee8576Fb46F = {}, (0, _defineProperty2["default"])(_xC011a73ee8576Fb46F, NAME, "Synthetix Network Token"), (0, _defineProperty2["default"])(_xC011a73ee8576Fb46F, SYMBOL, "SNX"), (0, _defineProperty2["default"])(_xC011a73ee8576Fb46F, DECIMALS, 18), _xC011a73ee8576Fb46F),
    "0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa": (_x8dAEBADE922dF735c = {}, (0, _defineProperty2["default"])(_x8dAEBADE922dF735c, NAME, "tBTC"), (0, _defineProperty2["default"])(_x8dAEBADE922dF735c, SYMBOL, "TBTC"), (0, _defineProperty2["default"])(_x8dAEBADE922dF735c, DECIMALS, 18), _x8dAEBADE922dF735c),
    "0x0000000000085d4780B73119b644AE5ecd22b376": (_x0000000000085d = {}, (0, _defineProperty2["default"])(_x0000000000085d, NAME, "TrueUSD"), (0, _defineProperty2["default"])(_x0000000000085d, SYMBOL, "TUSD"), (0, _defineProperty2["default"])(_x0000000000085d, DECIMALS, 18), _x0000000000085d),
    "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984": (_x1f9840a85d5aF5bf1D = {}, (0, _defineProperty2["default"])(_x1f9840a85d5aF5bf1D, NAME, "Uniswap"), (0, _defineProperty2["default"])(_x1f9840a85d5aF5bf1D, SYMBOL, "UNI"), (0, _defineProperty2["default"])(_x1f9840a85d5aF5bf1D, DECIMALS, 18), _x1f9840a85d5aF5bf1D),
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": (_xA0b86991c6218b36c = {}, (0, _defineProperty2["default"])(_xA0b86991c6218b36c, NAME, "USD Coin"), (0, _defineProperty2["default"])(_xA0b86991c6218b36c, SYMBOL, "USDC"), (0, _defineProperty2["default"])(_xA0b86991c6218b36c, DECIMALS, 6), _xA0b86991c6218b36c),
    "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": (_x2260FAC5E5542a773A = {}, (0, _defineProperty2["default"])(_x2260FAC5E5542a773A, NAME, "Wrapped Bitcoin"), (0, _defineProperty2["default"])(_x2260FAC5E5542a773A, SYMBOL, "wBTC"), (0, _defineProperty2["default"])(_x2260FAC5E5542a773A, DECIMALS, 8), _x2260FAC5E5542a773A),
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": (_xC02aaA39b223FE8D0A = {}, (0, _defineProperty2["default"])(_xC02aaA39b223FE8D0A, NAME, "Wrapped Ether"), (0, _defineProperty2["default"])(_xC02aaA39b223FE8D0A, SYMBOL, "wETH"), (0, _defineProperty2["default"])(_xC02aaA39b223FE8D0A, DECIMALS, 18), _xC02aaA39b223FE8D0A),

    /** Social Money **/
    "0x8BA6DcC667d3FF64C1A2123cE72FF5F0199E5315": (_x8BA6DcC667d3FF64C = {}, (0, _defineProperty2["default"])(_x8BA6DcC667d3FF64C, NAME, "Alex Masmej"), (0, _defineProperty2["default"])(_x8BA6DcC667d3FF64C, SYMBOL, "ALEX"), (0, _defineProperty2["default"])(_x8BA6DcC667d3FF64C, DECIMALS, 4), _x8BA6DcC667d3FF64C),
    "0xdc8092AaF83e00Ebf9B01A2e90b7B7eF867ba503": (_xdc8092AaF83e00Ebf = {}, (0, _defineProperty2["default"])(_xdc8092AaF83e00Ebf, NAME, "Calvin"), (0, _defineProperty2["default"])(_xdc8092AaF83e00Ebf, SYMBOL, "CALVIN"), (0, _defineProperty2["default"])(_xdc8092AaF83e00Ebf, DECIMALS, 4), _xdc8092AaF83e00Ebf),
    "0xA809CeDeE9B61956c768EAa10272dd5E0FD1A985": (_xA809CeDeE9B61956c = {}, (0, _defineProperty2["default"])(_xA809CeDeE9B61956c, NAME, "Cami"), (0, _defineProperty2["default"])(_xA809CeDeE9B61956c, SYMBOL, "CAMI"), (0, _defineProperty2["default"])(_xA809CeDeE9B61956c, DECIMALS, 4), _xA809CeDeE9B61956c),
    "0xeA0bea4d852687c45fdC57F6B06A8a92302baaBc": (_xeA0bea4d852687c45f = {}, (0, _defineProperty2["default"])(_xeA0bea4d852687c45f, NAME, "Counsel"), (0, _defineProperty2["default"])(_xeA0bea4d852687c45f, SYMBOL, "CNSL"), (0, _defineProperty2["default"])(_xeA0bea4d852687c45f, DECIMALS, 18), _xeA0bea4d852687c45f),
    "0xE98b5F11897d42Afd17185dd301Bcb2d46389bF1": (_xE98b5F11897d42Afd = {}, (0, _defineProperty2["default"])(_xE98b5F11897d42Afd, NAME, "Dude"), (0, _defineProperty2["default"])(_xE98b5F11897d42Afd, SYMBOL, "DUDE"), (0, _defineProperty2["default"])(_xE98b5F11897d42Afd, DECIMALS, 4), _xE98b5F11897d42Afd),
    "0x56687cf29Ac9751Ce2a4E764680B6aD7E668942e": (_x56687cf29Ac9751Ce = {}, (0, _defineProperty2["default"])(_x56687cf29Ac9751Ce, NAME, "FlynnJamm"), (0, _defineProperty2["default"])(_x56687cf29Ac9751Ce, SYMBOL, "JAMM"), (0, _defineProperty2["default"])(_x56687cf29Ac9751Ce, DECIMALS, 4), _x56687cf29Ac9751Ce),
    "0xC1fB6C015fC535aBD331D3029De76a62e412Fb23": (_xC1fB6C015fC535aBD = {}, (0, _defineProperty2["default"])(_xC1fB6C015fC535aBD, NAME, "Forcer"), (0, _defineProperty2["default"])(_xC1fB6C015fC535aBD, SYMBOL, "FORCER"), (0, _defineProperty2["default"])(_xC1fB6C015fC535aBD, DECIMALS, 4), _xC1fB6C015fC535aBD),
    "0x3C35132D0b129F068Fdfa65b2c4D265Fa5d8CEa6": (_x3C35132D0b129F068F = {}, (0, _defineProperty2["default"])(_x3C35132D0b129F068F, NAME, "Jonathan Joseph"), (0, _defineProperty2["default"])(_x3C35132D0b129F068F, SYMBOL, "JJJ"), (0, _defineProperty2["default"])(_x3C35132D0b129F068F, DECIMALS, 4), _x3C35132D0b129F068F),
    "0x8f464bfe2292748A0FED58Bb53D3838B4CE018b0": (_x8f464bfe2292748A0F = {}, (0, _defineProperty2["default"])(_x8f464bfe2292748A0F, NAME, "Kevin"), (0, _defineProperty2["default"])(_x8f464bfe2292748A0F, SYMBOL, "KEVIN"), (0, _defineProperty2["default"])(_x8f464bfe2292748A0F, DECIMALS, 4), _x8f464bfe2292748A0F),
    "0xDB7eB3edE973665b1BB9F3016861E3255062E4ED": (_xDB7eB3edE973665b1B = {}, (0, _defineProperty2["default"])(_xDB7eB3edE973665b1B, NAME, "MNFT"), (0, _defineProperty2["default"])(_xDB7eB3edE973665b1B, SYMBOL, "MNFT"), (0, _defineProperty2["default"])(_xDB7eB3edE973665b1B, DECIMALS, 4), _xDB7eB3edE973665b1B),
    "0x5A844590c5b8f40ae56190771d06c60b9ab1Da1C": (_x5A844590c5b8f40ae = {}, (0, _defineProperty2["default"])(_x5A844590c5b8f40ae, NAME, "Ross Campbell Legal Engineering"), (0, _defineProperty2["default"])(_x5A844590c5b8f40ae, SYMBOL, "RCLE"), (0, _defineProperty2["default"])(_x5A844590c5b8f40ae, DECIMALS, 18), _x5A844590c5b8f40ae),
    "0x27fD686Db10E0aE047fe8FE1DE9830C0e0dC3CFA": (_x27fD686Db10E0aE = {}, (0, _defineProperty2["default"])(_x27fD686Db10E0aE, NAME, "Scott"), (0, _defineProperty2["default"])(_x27fD686Db10E0aE, SYMBOL, "SCOTT"), (0, _defineProperty2["default"])(_x27fD686Db10E0aE, DECIMALS, 4), _x27fD686Db10E0aE),
    "0xbdBF67D9bcb35e2bb7e4951002c6D70013196972": (_xbdBF67D9bcb35e2bb = {}, (0, _defineProperty2["default"])(_xbdBF67D9bcb35e2bb, NAME, "Scott Stevenson Legal Engineering"), (0, _defineProperty2["default"])(_xbdBF67D9bcb35e2bb, SYMBOL, "SSLE"), (0, _defineProperty2["default"])(_xbdBF67D9bcb35e2bb, DECIMALS, 18), _xbdBF67D9bcb35e2bb),
    "0xD0Bf154768b0f1f8Cba50daA8960C285CE4c67f2": (_xD0Bf154768b0f1f8Cb = {}, (0, _defineProperty2["default"])(_xD0Bf154768b0f1f8Cb, NAME, "UrBen"), (0, _defineProperty2["default"])(_xD0Bf154768b0f1f8Cb, SYMBOL, "URBEN"), (0, _defineProperty2["default"])(_xD0Bf154768b0f1f8Cb, DECIMALS, 4), _xD0Bf154768b0f1f8Cb)
  },
  3: {
    "0x2D69aD895797C880abce92437788047BA0Eb7fF6": (_x2D69aD895797C880ab = {}, (0, _defineProperty2["default"])(_x2D69aD895797C880ab, NAME, "Testnet Dai"), (0, _defineProperty2["default"])(_x2D69aD895797C880ab, SYMBOL, "DAI"), (0, _defineProperty2["default"])(_x2D69aD895797C880ab, DECIMALS, 18), _x2D69aD895797C880ab)
  },
  4: {
    "0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8": (_xc3dbf84Abb494ce = {}, (0, _defineProperty2["default"])(_xc3dbf84Abb494ce, NAME, "Testnet Dai"), (0, _defineProperty2["default"])(_xc3dbf84Abb494ce, SYMBOL, "DAI"), (0, _defineProperty2["default"])(_xc3dbf84Abb494ce, DECIMALS, 18), _xc3dbf84Abb494ce)
  },
  5: {
    "0xF2D1F94310823FE26cFa9c9B6fD152834b8E7849": (_xF2D1F94310823FE26c = {}, (0, _defineProperty2["default"])(_xF2D1F94310823FE26c, NAME, "Testnet Dai"), (0, _defineProperty2["default"])(_xF2D1F94310823FE26c, SYMBOL, "DAI"), (0, _defineProperty2["default"])(_xF2D1F94310823FE26c, DECIMALS, 18), _xF2D1F94310823FE26c)
  },
  42: {
    "0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9": (_x7d669A64deb8a4A51e = {}, (0, _defineProperty2["default"])(_x7d669A64deb8a4A51e, NAME, "Testnet Dai"), (0, _defineProperty2["default"])(_x7d669A64deb8a4A51e, SYMBOL, "DAI"), (0, _defineProperty2["default"])(_x7d669A64deb8a4A51e, DECIMALS, 18), _x7d669A64deb8a4A51e)
  }
};
var TokensContext = (0, _react.createContext)();

function useTokensContext() {
  return (0, _react.useContext)(TokensContext);
}

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case UPDATE:
      {
        var _tokenAddress;

        var chainId = payload.chainId,
            tokenAddress = payload.tokenAddress,
            name = payload.name,
            symbol = payload.symbol,
            decimals = payload.decimals;
        return _objectSpread({}, state, (0, _defineProperty2["default"])({}, chainId, _objectSpread({}, (0, _utils.safeAccess)(state, [chainId]) || {}, (0, _defineProperty2["default"])({}, tokenAddress, (_tokenAddress = {}, (0, _defineProperty2["default"])(_tokenAddress, NAME, name), (0, _defineProperty2["default"])(_tokenAddress, SYMBOL, symbol), (0, _defineProperty2["default"])(_tokenAddress, DECIMALS, decimals), _tokenAddress)))));
      }

    default:
      {
        throw new Error("Unexpected action type in TokensContext reducer: '".concat(type, "'."));
      }
  }
}

function useAllTokens() {
  var chainId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _devConstants.MAINNET_ID;

  var _useTokensContext = useTokensContext(),
      _useTokensContext2 = (0, _slicedToArray2["default"])(_useTokensContext, 1),
      state = _useTokensContext2[0];

  var tokens = _objectSpread({}, (0, _typy["default"])(state, chainId.toString()).safeObjectOrEmpty);

  return Object.keys(tokens).map(function (tokenAddress) {
    return _objectSpread({}, tokens[tokenAddress], {
      address: tokenAddress
    });
  }) || [];
}

function TokensContextProvider(_ref2) {
  var children = _ref2.children;

  var _useReducer = (0, _react.useReducer)(reducer, INITIAL_TOKENS_CONTEXT),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var updateToken = (0, _react.useCallback)(function (chainId, tokenAddress, name, symbol, decimals) {
    dispatch({
      type: UPDATE,
      payload: {
        chainId: chainId,
        tokenAddress: tokenAddress,
        name: name,
        symbol: symbol,
        decimals: decimals
      }
    });
  }, []);
  return _react["default"].createElement(TokensContext.Provider, {
    value: (0, _react.useMemo)(function () {
      return [state, {
        updateToken: updateToken
      }];
    }, [state, updateToken])
  }, children);
}