"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = CircularProgressBar;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _reactHooks = require("@sablier/react-hooks");

var _Gradient = _interopRequireDefault(require("../../Gradient"));

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 9000ms ease-in-out infinite;\n  stroke-dasharray: 0, 100;\n  stroke-width: ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  stroke-width: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 7500ms ease-in-out infinite;\n  stroke-dasharray: 0, 100;\n  stroke-opacity: 0.5;\n  stroke-width: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      filter: grayscale(100%);\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  stroke-width: ", ";\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 2000ms ease-out;\n  fill: none;\n  stroke-linecap: round;\n  transition: stroke-dasharray 400ms ease;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  stroke: ", ";\n  stroke-width: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  max-width: 31rem;\n  width: 85vw;\n  z-index: 100;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
/**
 * To keep the value proportional to 100%, we choose a CIRCUMFERENCE of 100.
 */


var CIRCUMFERENCE = 100;
var RADIUS = CIRCUMFERENCE / (2 * Math.PI);
var DIAMETER = RADIUS * 2;
var DIVIDER_STROKE_WIDTH = 0.05;
var SPACER = 3;
var STROKE_WIDTH = 1;
/**
 * The size of the container will be a bit bigger than the actual circle, in order to make room for the stroke
 * (will be centered-to-path).
 */

var CONTAINER_WIDTH = DIAMETER + SPACER;
var CONTAINER_HEIGHT = CONTAINER_WIDTH;
var POINT_CENTER_X = CONTAINER_WIDTH / 2;
var POINT_CENTER_Y = CONTAINER_HEIGHT / 2;

var Wrapper = _styledComponents["default"].div(_templateObject());

var WhiteCircle = _styledComponents["default"].circle.attrs({
  cx: POINT_CENTER_X,
  cy: POINT_CENTER_Y
})(_templateObject2(), function (props) {
  return props.theme.aliceBlue;
}, DIVIDER_STROKE_WIDTH);
/* See https://stackoverflow.com/questions/29068088/svg-circle-starting-point */


var ProgressCircle = _styledComponents["default"].circle.attrs({
  cx: POINT_CENTER_X,
  cy: POINT_CENTER_Y,
  r: RADIUS,
  transform: "rotate(-90 ".concat(POINT_CENTER_Y, " ").concat(POINT_CENTER_Y, ")")
})(_templateObject3(), _theme.progress);

var StreamedCircle = (0, _styledComponents["default"])(ProgressCircle)(_templateObject4(), STROKE_WIDTH, function (props) {
  return props.isGrayscale && (0, _styledComponents.css)(_templateObject5());
});
var StreamedAnimatedCircle = (0, _styledComponents["default"])(ProgressCircle)(_templateObject6(), _theme.progress0To100Stream, STROKE_WIDTH);
var WithdrawnCircle = (0, _styledComponents["default"])(ProgressCircle)(_templateObject7(), STROKE_WIDTH + 0.35);
var WithdrawnAnimatedCircle = (0, _styledComponents["default"])(ProgressCircle)(_templateObject8(), _theme.progress0To100Withdrawn, STROKE_WIDTH + 0.35);

function CircularProgressBar(_ref) {
  var _children = _ref.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children"]);

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;
  /** Memoized values */


  var isCancelled = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(proxyStream, "stream.status").safeNumber === _devConstants.STREAM_STATUS.cancelled;
  }, [proxyStream]);
  /* TODO: write less code by applying this: https://twitter.com/anatudor/status/1195235554792792065 */

  /**
   * Comment by Dizz: The tweet wasn't referring to less code. It was placing the center of the circle
   * in the middle of the XY plane, in order to simplify the math.
   */

  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement("svg", {
    viewBox: "0,0 ".concat(CONTAINER_WIDTH, ",").concat(CONTAINER_HEIGHT, " ")
  }, _react["default"].createElement("defs", null, _react["default"].createElement(_Gradient["default"].Blue, null), _react["default"].createElement(_Gradient["default"].Orange, null)), _react["default"].createElement(WhiteCircle, {
    fill: _theme.colors.white,
    r: RADIUS + STROKE_WIDTH / 2
  }), _react["default"].createElement(WhiteCircle, {
    fill: _theme.colors.white,
    r: RADIUS - STROKE_WIDTH / 2
  }), !isCancelled && _react["default"].createElement(StreamedAnimatedCircle, {
    stroke: _theme.colors.backgroundColor
  }), _react["default"].createElement(StreamedCircle, {
    isGrayscale: isCancelled,
    stroke: "url(#blueGradient)",
    strokeDasharray: Math.max((0, _typy["default"])(proxyStream, "stream.bundles.streamed.percentage").safeNumber, 1.25) + ", 100"
  }), _react["default"].createElement("svg", {
    viewBox: "\n              -".concat(SPACER, "\n              -").concat(SPACER, "\n              ").concat(CONTAINER_WIDTH + SPACER * 2, "\n              ").concat(CONTAINER_HEIGHT + SPACER * 2, "\n          ")
  }, !isCancelled && _react["default"].createElement(WithdrawnAnimatedCircle, {
    stroke: _theme.colors.backgroundColor
  }), _react["default"].createElement(WithdrawnCircle, {
    stroke: "url(#orangeGradient)",
    strokeDasharray: Math.max((0, _typy["default"])(proxyStream, "stream.bundles.withdrawn.percentage").safeNumber, 1.25) + ", 100"
  }))));
}