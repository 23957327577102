"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var Image = function Image(props) {
  var source = props.source,
      alt = props.alt,
      label = props.label;
  return _react["default"].createElement("div", {
    title: label
  }, _react["default"].createElement("img", {
    alt: alt,
    nopin: "nopin",
    src: source
  }));
};

Image.propTypes = {
  alt: _propTypes["default"].string,
  label: _propTypes["default"].string,
  source: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string, _propTypes["default"].shape({})])
};
Image.defaultProps = {
  source: "",
  alt: "",
  label: ""
};
var _default = Image;
exports["default"] = _default;