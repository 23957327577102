"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactFeather = require("react-feather");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _Web = _interopRequireDefault(require("../../../Web3"));

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display:none;\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-right: 0.2rem;\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", ";\n    font-size: 0.8rem;\n    justify-content:flex-start;\n    padding: 0.5rem;\n    padding-right: 1.5rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.2rem;\n  color: ", ";\n  cursor: pointer;\n  flex: 1;\n  font-size: 0.9rem;\n  font-weight: 600;\n  justify-content: center;\n  overflow-x: hidden;\n  padding: 0.3rem 0.4rem;\n  position: relative;\n  transition: background-color 200ms;\n\n  &:hover,\n  &:active {\n    background-color: ", ";\n    transition: background-color 200ms;\n    & > ", " {\n      transform: translate(0);\n      transition: transform 200ms;\n    }\n  }\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 0.7rem;\n  object-fit: contain;\n  right: 0rem;\n  transition: color 200ms;\n  width: 0.7rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      transform: translate(0);\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.75rem;\n  height: 1.5rem;\n  justify-content: center;\n  position: absolute;\n  right: 0.2rem;\n  transform: translate(1.75rem);\n  transition: transform 200ms;\n  width: 1.5rem;\n\n  &[data-forrecipient=\"true\"] {\n    &:after {\n      ", ";\n    }\n  }\n\n  &:after {\n    ", ";\n    border-radius: 0.45rem;\n    content: \"\";\n    height: 0.9rem;\n    opacity: 0;\n    position: absolute;\n    transition: opacity 200ms;\n    width: 0.9rem;\n    z-index: 100;\n  }\n\n  &[data-highlight=\"true\"] {\n    &:after {\n      opacity: 1;\n      transition: opacity 200ms;\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ClipboardIconWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.primaryGradient;
}, function (props) {
  return props.theme.secondaryGradient;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var ClipboardIcon = (0, _styledComponents["default"])(_reactFeather.Copy)(_templateObject3(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var Wrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.aliceBlue;
}, ClipboardIconWrapper, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5(), props.theme.white);
});

var Indicator = _styledComponents["default"].span(_templateObject6(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
});

function Address(_ref) {
  var value = _ref.value,
      forRecipient = _ref.forRecipient;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useCopyClipboard = (0, _reactHooks.useCopyClipboard)(),
      _useCopyClipboard2 = (0, _slicedToArray2["default"])(_useCopyClipboard, 2),
      isCopied = _useCopyClipboard2[0],
      setCopied = _useCopyClipboard2[1];

  return _react["default"].createElement(Wrapper, {
    title: "Copy ".concat(value),
    onClick: function onClick() {
      return setCopied(value);
    }
  }, _react["default"].createElement(Indicator, null, (forRecipient ? translation("words.to") : translation("words.from")) + ":"), _react["default"].createElement(_Web["default"].AccountLabel, {
    account: value
  }), _react["default"].createElement(ClipboardIconWrapper, {
    "data-highlight": isCopied,
    "data-forrecipient": forRecipient
  }, _react["default"].createElement(ClipboardIcon, null)));
}

Address.propTypes = {
  value: _propTypes["default"].string.isRequired,
  forRecipient: _propTypes["default"].bool
};
Address.defaultProps = {
  forRecipient: false
};
var _default = Address;
exports["default"] = _default;