"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  GlobalStyle: true,
  colors: true,
  mediaQueries: true
};
exports["default"] = ThemeProvider;
Object.defineProperty(exports, "colors", {
  enumerable: true,
  get: function get() {
    return _colors["default"];
  }
});
Object.defineProperty(exports, "mediaQueries", {
  enumerable: true,
  get: function get() {
    return _mediaQueries["default"];
  }
});
exports.GlobalStyle = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _sanitize = _interopRequireDefault(require("sanitize.css"));

var _styledComponents = require("styled-components");

var _colors = _interopRequireDefault(require("./colors"));

var _mediaQueries = _interopRequireDefault(require("./mediaQueries"));

var _snippets = _interopRequireDefault(require("./snippets"));

var _animations = require("./animations");

Object.keys(_animations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _animations[key];
    }
  });
});

var _components = require("./components");

Object.keys(_components).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _components[key];
    }
  });
});

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  html,\n  body {\n    height: 100%;\n    margin: 0rem;\n    overflow-x: hidden;\n    overflow-y: auto;\n    padding: 0rem;\n    width: 100%;\n  }\n\n  html {\n    color: ", ";\n    cursor: auto;\n    font-family: ", ";\n    font-size: 1rem;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    text-rendering: optimizeLegibility;\n  }\n\n  body {\n    background-color: ", ";\n    -webkit-overflow-scrolling: touch;\n  }\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n\n  button,\n  input,\n  select,\n  textarea {\n    appearance: none;\n    border: none;\n    font-family: inherit;\n    outline: none;\n  }\n\n  input::placeholder {\n    color: ", ";\n    font-weight: 500;\n    opacity: 1;\n  }\n\n  input[type=\"number\"]:-webkit-inner-spin-button,\n  input[type=\"number\"]:-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    appearance: none;\n  }\n\n  /* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */\n  @keyframes autofill {\n    to {\n      background-color: ", ";\n    }\n  }\n\n  input:-webkit-autofill {\n    animation-fill-mode: both;\n    animation-name: autofill;\n  }\n\n  /* Vendor overrides */\n  #crisp-chatbox {\n    z-index: 200 !important;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}
/* Fonts */


var fallbackFont = '-apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, Arial, sans-serif';
var robotoFont = "Roboto Mono, ".concat(fallbackFont);
var robotoMonoFont = "Roboto Mono, ".concat(fallbackFont);
var catamaranFont = "Catamaran, ".concat(fallbackFont);

var theme = _objectSpread({}, _colors["default"], {}, _snippets["default"], {
  /* Fonts */
  fallbackFont: fallbackFont,
  robotoFont: robotoFont,
  robotoMonoFont: robotoMonoFont,
  catamaranFont: catamaranFont,

  /* Media Queries */
  mediaWidth: _objectSpread({}, _mediaQueries["default"].mediaWidth)
});

var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject(), _sanitize["default"], _colors["default"].darkGunmetalBlack, catamaranFont, function (props) {
  return props.theme.backgroundColor;
}, _colors["default"].aliceBlue, _colors["default"].white);
exports.GlobalStyle = GlobalStyle;

function ThemeProvider(_ref) {
  var children = _ref.children;
  return _react["default"].createElement(_styledComponents.ThemeProvider, {
    theme: theme
  }, children);
}