"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = require("styled-components");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background: ", ";\n    background: linear-gradient(45deg, ", ", ", ");\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background: ", ";\n    background: linear-gradient(45deg, ", ", ", ");\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var rawColors = {
  // Black
  black: "#000000",
  darkGunmetalBlack: "#1f2133",
  dimBlack: "rgba(0,0,0,0.45)",
  licoriceBlack: "#12100e",
  onyxBlack: "#343c47",
  registrationBlack: "#010101",
  // Blue
  aliceBlue: "#ebf0ff",
  azureBlue: "#0099ff",
  bleuDeFranceBlue: "#2f80ed",
  blueJeansBlue: "#5ca2ff",
  brilliantAzureBlue: "#4196fc",
  littleBoyBlue: "#7289da",
  queenBlue: "#4a6c9b",
  secondaryColor: "#1469ff",
  vividCeruleanBlue: "#039be5",
  zumthorBlue: "#ebf4ff",
  periwinkleBlue: "#bdcdff",
  // Gray
  backgroundColor: "#f8faff",
  chaliceGray: "#7b7b7b",
  glitterGray: "#e1e8ff",
  glitterGrayLight: "#eaedf6",
  lavenderGray: "#bcc1d5",
  nickelGray: "#737373",
  placeholderGray: "#5f5f5f",
  platinumGray: "#e1e1e1",
  silverSandGray: "#c4c4c4",
  // Green
  crayolaGreen: "#27ae60",
  middleGreen: "#4caf50",
  rampGreen: "#56be89",
  spiroDiscBallGreen: "#12c1ff",
  // Orange
  carrotOrange: "#e8831d",
  coralOrange: "#fc8b56",
  primaryColor: "#f77423",
  // Purple
  veryLightBlue: "#6748ff",
  // Red
  pastelRed: "#ff6871",
  red: "#ff0000",
  rustyRed: "#da223d",
  // White
  almostWhite: "#fcfdff",
  azureishWhite: "#dce6ff",
  ghostWhite: "#f5f7fc",
  snowWhite: "#fafafa",
  transparent: "transparent",
  white: "#ffffff",
  // Yellow
  stilDeGrainYellow: "#fbce5b"
};
var gradients = {
  primaryGradient: (0, _styledComponents.css)(_templateObject(), rawColors.primaryColor, rawColors.primaryColor, rawColors.stilDeGrainYellow),
  secondaryGradient: (0, _styledComponents.css)(_templateObject2(), rawColors.secondaryColor, rawColors.secondaryColor, rawColors.azureBlue)
};

var _default = _objectSpread({}, rawColors, {}, gradients);

exports["default"] = _default;