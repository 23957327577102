"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _eur = _interopRequireDefault(require("@sablier/assets/images/eur.png"));

var _gbp = _interopRequireDefault(require("@sablier/assets/images/gbp.png"));

var _x3112E6EEb095791CDa8271c25a02A1E66018ade = _interopRequireDefault(require("@sablier/assets/tokens/0x3112E6EEb095791CDa8271c25a02A1E66018ade7.png"));

var _xeA0bea4d852687c45fdC57F6B06A8a92302baaBc = _interopRequireDefault(require("@sablier/assets/tokens/0xeA0bea4d852687c45fdC57F6B06A8a92302baaBc.png"));

var _x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc = _interopRequireDefault(require("@sablier/assets/tokens/0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc.png"));

var _x3F382DbD960E3a9bbCeaE22651E88158d = _interopRequireDefault(require("@sablier/assets/tokens/0x3F382DbD960E3a9bbCeaE22651E88158d2791550.png"));

var _xaac41EC512808d64625576EDdd580e7Ea40ef8B = _interopRequireDefault(require("@sablier/assets/tokens/0xaac41EC512808d64625576EDdd580e7Ea40ef8B2.png"));

var _xc463f34040ad6222C1fFB03ACEbDFAAC032202d = _interopRequireDefault(require("@sablier/assets/tokens/0xc463f34040ad6222C1fFB03ACEbDFAAC032202d6.png"));

var _xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc = _interopRequireDefault(require("@sablier/assets/tokens/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2.png"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _CUSTOM_ICON_TOKENS, _CUSTOM_ICON_FIAT;

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: ", "rem;\n  width: ", "rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  align-items: center;\n  display: flex;\n  height: ", "rem;\n  justify-content: center;\n  user-select: none;\n  width: ", "rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: white;\n  border-radius: 1rem;\n  height: ", "rem;\n  object-fit: contain;\n  user-select: none;\n  width: ", "rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var EthereumLogo = function EthereumLogo(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    fill: "#343434",
    d: "M127.961 0l-2.795 9.5v275.668l2.795 2.79 127.962-75.638z"
  }), _react["default"].createElement("path", {
    fill: "#8C8C8C",
    d: "M127.962 0L0 212.32l127.962 75.639V154.158z"
  }), _react["default"].createElement("path", {
    fill: "#3C3C3B",
    d: "M127.961 312.187l-1.575 1.92v98.199l1.575 4.6L256 236.587z"
  }), _react["default"].createElement("path", {
    fill: "#8C8C8C",
    d: "M127.962 416.905v-104.72L0 236.585z"
  }), _react["default"].createElement("path", {
    fill: "#141414",
    d: "M127.961 287.958l127.96-75.637-127.96-58.162z"
  }), _react["default"].createElement("path", {
    fill: "#393939",
    d: "M0 212.32l127.96 75.638v-133.8z"
  }));
};

EthereumLogo.defaultProps = {
  width: "256",
  height: "417",
  viewBox: "0 0 256 417",
  xmlns: "http://www.w3.org/2000/svg",
  preserveAspectRatio: "xMidYMid"
};
var CUSTOM_ICON_TOKENS = (_CUSTOM_ICON_TOKENS = {}, (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.BEN, _x3112E6EEb095791CDa8271c25a02A1E66018ade["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.CNSL, _xeA0bea4d852687c45fdC57F6B06A8a92302baaBc["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.GDAO, _x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.GHST, _x3F382DbD960E3a9bbCeaE22651E88158d["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.GSWAP, _xaac41EC512808d64625576EDdd580e7Ea40ef8B["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.kMPL, _xc463f34040ad6222C1fFB03ACEbDFAAC032202d["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_TOKENS, _devConstants.TOKEN_ADDRESSES.wETH, _xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc["default"]), _CUSTOM_ICON_TOKENS);
var CUSTOM_ICON_FIAT = (_CUSTOM_ICON_FIAT = {}, (0, _defineProperty2["default"])(_CUSTOM_ICON_FIAT, _devConstants.FIAT_TOKENS.gbp.symbol, _gbp["default"]), (0, _defineProperty2["default"])(_CUSTOM_ICON_FIAT, _devConstants.FIAT_TOKENS.eur.symbol, _eur["default"]), _CUSTOM_ICON_FIAT);
var BAD_IMAGES = {};

var TOKEN_ICON_API = function TOKEN_ICON_API(address, symbol) {
  if ((0, _typy["default"])(symbol).isTruthy && (0, _typy["default"])(CUSTOM_ICON_FIAT, (0, _typy["default"])(symbol).safeString).isTruthy) {
    return CUSTOM_ICON_FIAT[symbol];
  }

  if ((0, _typy["default"])(CUSTOM_ICON_TOKENS, (0, _typy["default"])(address).safeString).isTruthy) {
    return CUSTOM_ICON_TOKENS[address];
  }

  if ([_devConstants.DEFAULT_TOKEN[_devConstants.KOVAN_ID].address, _devConstants.DEFAULT_TOKEN[_devConstants.GOERLI_ID].address, _devConstants.DEFAULT_TOKEN[_devConstants.RINKEBY_ID].address, _devConstants.DEFAULT_TOKEN[_devConstants.ROPSTEN_ID].address].indexOf(address) >= 0) {
    address = "0x6B175474E89094C44Da98b954EedeAC495271d0F"; // DAI
  }

  return "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/".concat(address, "/logo.png");
};

var Image = _styledComponents["default"].img(_templateObject(), function (props) {
  return props.size;
}, function (props) {
  return props.size;
});

var Emoji = _styledComponents["default"].span(_templateObject2(), function (props) {
  return props.size;
}, function (props) {
  return props.size;
});

var StyledEthereumLogo = (0, _styledComponents["default"])(EthereumLogo)(_templateObject3(), function (props) {
  return props.size;
}, function (props) {
  return props.size;
});

function TokenLogo(_ref) {
  var address = _ref.address,
      size = _ref.size,
      symbol = _ref.symbol,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["address", "size", "symbol"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      error = _useState2[0],
      setError = _useState2[1];

  if ((0, _typy["default"])(address).isFalsy && (0, _typy["default"])(CUSTOM_ICON_FIAT, (0, _typy["default"])(symbol).safeString).isFalsy) {
    return null;
  } else if (address === "ETH") {
    return _react["default"].createElement(StyledEthereumLogo, {
      size: size
    });
  }

  if ((0, _typy["default"])(error).isTruthy || BAD_IMAGES[address]) {
    return _react["default"].createElement(Emoji, {
      size: size
    }, _react["default"].createElement("span", {
      role: "img",
      "aria-label": "Star"
    }, "\u2B50\uFE0F"));
  }

  return _react["default"].createElement(Image, (0, _extends2["default"])({
    alt: address,
    onError: function onError() {
      BAD_IMAGES[address] = true;
      setError(true);
    },
    src: TOKEN_ICON_API(address, symbol),
    size: size
  }, otherProps));
}

TokenLogo.propTypes = {
  address: _propTypes["default"].string,
  size: _propTypes["default"].number,
  symbol: _propTypes["default"].string
};
TokenLogo.defaultProps = {
  address: "",
  size: 1.5,
  symbol: null
};
var _default = TokenLogo;
exports["default"] = _default;