"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DASHBOARD_COLUMN_IDS: true,
  DEFAULT_TOKEN_MAINNET: true,
  DEFAULT_TOKEN: true,
  EMAILS: true,
  ERROR_CODES: true,
  FIAT_TOKENS: true,
  NEAR_ZERO: true,
  NETWORK_CONTEXT_NAME: true,
  NULL_BUNDLE: true,
  NULL_TOKEN: true,
  PUBLIC_WALLETS: true,
  RAMP_TOKENS: true,
  STATIC_ASSETS: true,
  STREAM_ACTION_TYPE: true,
  STREAM_START_DELAY: true,
  STREAM_STATUS: true,
  TABLE_ITEM_TYPES: true,
  ZERO_BUNDLE: true,
  labels: true
};
Object.defineProperty(exports, "labels", {
  enumerable: true,
  get: function get() {
    return _labels["default"];
  }
});
exports.ZERO_BUNDLE = exports.TABLE_ITEM_TYPES = exports.STREAM_STATUS = exports.STREAM_START_DELAY = exports.STREAM_ACTION_TYPE = exports.STATIC_ASSETS = exports.RAMP_TOKENS = exports.PUBLIC_WALLETS = exports.NULL_TOKEN = exports.NULL_BUNDLE = exports.NETWORK_CONTEXT_NAME = exports.NEAR_ZERO = exports.FIAT_TOKENS = exports.ERROR_CODES = exports.EMAILS = exports.DEFAULT_TOKEN = exports.DEFAULT_TOKEN_MAINNET = exports.DASHBOARD_COLUMN_IDS = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _bignumber = require("bignumber.js");

var _chains = require("./chains");

Object.keys(_chains).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _chains[key];
    }
  });
});

var _labels = _interopRequireDefault(require("./labels"));

var _addresses = require("./addresses");

Object.keys(_addresses).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _addresses[key];
    }
  });
});

var _gasLimits = require("./gasLimits");

Object.keys(_gasLimits).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _gasLimits[key];
    }
  });
});

var _links = require("./links");

Object.keys(_links).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _links[key];
    }
  });
});

var _regex = require("./regex");

Object.keys(_regex).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _regex[key];
    }
  });
});

var _shapes = require("./shapes");

Object.keys(_shapes).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _shapes[key];
    }
  });
});

var _time = require("./time");

Object.keys(_time).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _time[key];
    }
  });
});

var _DEFAULT_TOKEN;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}
/* See https://twitter.com/PaulRBerg/status/1198276650884124674 */


var BASE_TESTNET_DAI = {
  __typename: "Token",
  decimals: 18,
  name: "Testnet Dai",
  symbol: "DAI"
};
var DASHBOARD_COLUMN_IDS = Object.freeze({
  0: "status",
  1: "who",
  2: "value",
  3: "progress",
  4: "startTime",
  5: "stopTime",
  6: "link",
  status: "status",
  who: "who",
  value: "value",
  progress: "progress",
  startTime: "startTime",
  stopTime: "stopTime",
  link: "link"
});
exports.DASHBOARD_COLUMN_IDS = DASHBOARD_COLUMN_IDS;
var DEFAULT_TOKEN_MAINNET = {
  __typename: "Token",
  address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  decimals: 18,
  name: "Dai Stablecoin",
  symbol: "DAI"
};
exports.DEFAULT_TOKEN_MAINNET = DEFAULT_TOKEN_MAINNET;
var DEFAULT_TOKEN = (_DEFAULT_TOKEN = {}, (0, _defineProperty2["default"])(_DEFAULT_TOKEN, _chains.GOERLI_ID, _objectSpread({}, BASE_TESTNET_DAI, {
  address: "0xF2D1F94310823FE26cFa9c9B6fD152834b8E7849"
})), (0, _defineProperty2["default"])(_DEFAULT_TOKEN, _chains.KOVAN_ID, _objectSpread({}, BASE_TESTNET_DAI, {
  address: "0x7d669A64deb8a4A51eEa755bb0E19FD39CE25Ae9"
})), (0, _defineProperty2["default"])(_DEFAULT_TOKEN, _chains.MAINNET_ID, DEFAULT_TOKEN_MAINNET), (0, _defineProperty2["default"])(_DEFAULT_TOKEN, _chains.RINKEBY_ID, _objectSpread({}, BASE_TESTNET_DAI, {
  address: "0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8"
})), (0, _defineProperty2["default"])(_DEFAULT_TOKEN, _chains.ROPSTEN_ID, _objectSpread({}, BASE_TESTNET_DAI, {
  address: "0x2D69aD895797C880abce92437788047BA0Eb7fF6"
})), _DEFAULT_TOKEN);
exports.DEFAULT_TOKEN = DEFAULT_TOKEN;
var EMAILS = {
  contact: "hello@sablier.finance"
};
exports.EMAILS = EMAILS;
var ERROR_CODES = ["TOKEN_NAME", "TOKEN_SYMBOL", "TOKEN_DECIMALS"].reduce(function (accumulator, currentValue, currentIndex) {
  accumulator[currentValue] = currentIndex;
  return accumulator;
}, {});
exports.ERROR_CODES = ERROR_CODES;
var FIAT_TOKENS = {
  eur: {
    address: null,
    name: "EUR (Fiat)",
    symbol: "EUR"
  },
  gbp: {
    address: null,
    name: "GBP (Fiat)",
    symbol: "GBP"
  }
};
exports.FIAT_TOKENS = FIAT_TOKENS;
var NEAR_ZERO = "<" + 1e-3.toLocaleString();
exports.NEAR_ZERO = NEAR_ZERO;
var NETWORK_CONTEXT_NAME = "NETWORK";
/* See the definition of a `Bundle` in @sablier/apollo */

exports.NETWORK_CONTEXT_NAME = NETWORK_CONTEXT_NAME;
var NULL_BUNDLE = {
  __typename: "Bundle",
  atomicUnits: null,
  cash: null,
  percentage: null,
  units: null
};
exports.NULL_BUNDLE = NULL_BUNDLE;
var NULL_TOKEN = {
  __typename: "Token",
  address: null,
  decimals: null,
  name: null,
  symbol: null
};
exports.NULL_TOKEN = NULL_TOKEN;
var PUBLIC_WALLETS = {
  multisig: "0x217A46Aa92aFd9f4F4E170Bc85AEe6f36E14eddF"
};
exports.PUBLIC_WALLETS = PUBLIC_WALLETS;
var RAMP_TOKENS = ["DAI", "ETH", "USDC"];
exports.RAMP_TOKENS = RAMP_TOKENS;
var STATIC_ASSETS = {
  logoPNG: "https://www.vansoftware.ro/sablier/logo.png",
  logoSVG: "https://www.vansoftware.ro/sablier/logo.svg"
};
exports.STATIC_ASSETS = STATIC_ASSETS;
var STREAM_ACTION_TYPE = Object.freeze({
  deposit: 1,
  withdrawal: 2,
  cancellation: 3
});
/**
 * There's a good chance that the transaction won't be processed immediately, so we delay the start of the stream by
 * 315 seconds, or 5 minutes and 15 seconds;
 */

exports.STREAM_ACTION_TYPE = STREAM_ACTION_TYPE;
var STREAM_START_DELAY = 315;
exports.STREAM_START_DELAY = STREAM_START_DELAY;
var STREAM_STATUS = Object.freeze({
  created: 1,
  streaming: 2,
  cancelled: 3,
  ended: 4
});
exports.STREAM_STATUS = STREAM_STATUS;
var TABLE_ITEM_TYPES = Object.freeze({
  account: "account",
  actions: "actions",
  amount: "amount",
  custom: "custom",
  progress: "progress",
  text: "text",
  status: "status"
});
/* See the definition of a `Bundle` in @sablier/apollo */

exports.TABLE_ITEM_TYPES = TABLE_ITEM_TYPES;
var ZERO_BUNDLE = {
  __typename: "Bundle",
  atomicUnits: new _bignumber.BigNumber(0),
  cash: "0",
  percentage: 0,
  units: new _bignumber.BigNumber(0)
};
exports.ZERO_BUNDLE = ZERO_BUNDLE;