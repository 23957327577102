"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Balances;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _CharacteristicDigit = _interopRequireDefault(require("./CharacteristicDigit"));

var _MantissaDigit = _interopRequireDefault(require("./MantissaDigit"));

var _Spacer = _interopRequireDefault(require("../../Spacer"));

var _TokenLogo = _interopRequireDefault(require("../../TokenLogo"));

var _useDigits = _interopRequireWildcard(require("./useDigits"));

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.9375rem;\n    margin-top: 1rem;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: ", ";\n  font-size: 1rem;\n  letter-spacing: 0.0625rem;\n  margin-top: 2.375rem;\n\n  ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-bottom: 0;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  justify-content: flex-end;\n  margin: 0rem 0.3125rem 0.375rem;\n\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      ", ";\n      ", ";\n      background-color: rgba(255, 255, 255, 0.75);\n      width: auto;\n      padding: 0.5rem 1rem;\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        min-width: 0;\n        width: calc(100vw - 2 * 0.5rem);\n      "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        min-width: 31.2rem;\n      "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 1.25rem;\n\n    ", ";\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-end;\n  border-radius: 0.375rem;\n  font-size: 2.125rem;\n  justify-content: center;\n  margin-top: 2.125rem;\n\n  ", "\n\n  @media all and (max-width: 32rem) {\n    ", ";\n  }\n\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 2.25rem;\n    width: 2.25rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 2.5rem;\n  user-select: none;\n  width: 2.5rem;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", "\n  align-items: center;\n  font-weight: 600;\n  width: 100%;\n  z-index: 200;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OverlayWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.posAbsoluteCenterX;
});

var StyledTokenLogo = (0, _styledComponents["default"])(_TokenLogo["default"])(_templateObject2(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var CurrentBalanceWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5(), props.isExpanded && (0, _styledComponents.css)(_templateObject6()));
}, function (props) {
  return props.isExpanded && (0, _styledComponents.css)(_templateObject7());
}, function (props) {
  return props.isExpanded && (0, _styledComponents.css)(_templateObject8(), props.theme.borderAliceBlue, props.theme.boxShadowDarkGunmetalBlack);
});

var Dot = _styledComponents["default"].span(_templateObject9(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject10());
});

var TotalBalanceLabel = _styledComponents["default"].span(_templateObject11(), function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});

function Balances(_ref) {
  var _children = _ref.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children"]);

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;

  var digits = (0, _useDigits["default"])({
    status: (0, _typy["default"])(proxyStream, "stream.status").safeNumber,
    streamed: (0, _typy["default"])(proxyStream, "stream.bundles.streamed.units").safeObject
  });
  /** Side Effects **/

  (0, _react.useEffect)(function () {
    var defaultTitle = "Sablier | " + translation("structs.realTimeFinance");
    var streamedCash = (0, _typy["default"])(proxyStream, "stream.bundles.streamed.cash").safeString.replace("<", "");
    var tokenSymbol = (0, _typy["default"])(proxyStream, "stream.token.symbol").safeTokenSymbol;
    document.title = streamedCash + " " + tokenSymbol + " | " + defaultTitle;
    return function () {
      document.title = defaultTitle;
    };
  }, [proxyStream, translation]);
  return _react["default"].createElement(OverlayWrapper, otherProps, _react["default"].createElement(StyledTokenLogo, {
    address: (0, _typy["default"])(proxyStream, "stream.token.address").safeEthereumAddress
  }), _react["default"].createElement(CurrentBalanceWrapper, {
    isExpanded: digits.characteristicsMinLength > 5 || digits.mantissasMinLength > 7
  }, digits.characteristics.map(function (characteristic, index) {
    return _react["default"].createElement(_CharacteristicDigit["default"], {
      active: characteristic.value !== _useDigits.GRAYED_OUT_ZERO,
      key: index + "/" + characteristic.value,
      value: characteristic.value
    });
  }), _react["default"].createElement(Dot, null, "."), digits.mantissas.slice(0, 15).map(function (mantissa, index) {
    return _react["default"].createElement(_MantissaDigit["default"], {
      active: mantissa.value !== _useDigits.GRAYED_OUT_ZERO,
      key: index + "/" + mantissa.value,
      value: mantissa.value
    });
  })), _react["default"].createElement(TotalBalanceLabel, null, "/" + (0, _typy["default"])(proxyStream, "stream.bundles.deposit.cash").safeString, "\xA0", (0, _typy["default"])(proxyStream, "stream.token.symbol").safeTokenSymbol, "\xA0", translation("words.total")), _react["default"].createElement(_Spacer["default"], {
    height: 0.75
  }));
}