"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _reactHooks = require("@sablier/react-hooks");

var _reactFeather = require("react-feather");

var _reactI18next = require("react-i18next");

var _Web = _interopRequireDefault(require("../../../Web3"));

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      font-size: 0.95rem;\n\n      & > span {\n        font-size: 0.8rem;\n      }\n    "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-end;\n  max-width: 500px;\n\n  & > * {\n    color: ", ";\n    font-size: 1rem;\n    line-height: 1.6rem;\n    margin: 0;\n\n    div {\n      display: inline-flex;\n      font-weight: bold;\n    }\n\n    & > span {\n      font-family: ", ";\n      font-size: 0.95rem;\n      font-weight: 600;\n      padding-left: 0.2rem;\n      padding-right: 0.2rem;\n      word-spacing: -0.2rem;\n    }\n\n    ", "\n  }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 1.3rem;\n    width: 1.3rem;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 200ms ease-in;\n  height: 1.7rem;\n  margin-right: 0.6rem;\n  object-fit: contain;\n  width: 1.7rem;\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 1.4rem;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1.7rem;\n  font-weight: 600;\n  margin: 0;\n  word-spacing: -0.2rem;\n\n  & > span {\n    font-size: 1rem;\n    text-transform: lowercase;\n  }\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 1rem;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: 1.1rem;\n  font-weight: 600;\n  margin: 0rem;\n  margin-bottom: 0.7rem;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  margin-bottom: 1rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem 0.7rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.2rem;\n  color: ", ";\n  grid-column: span 1;\n  justify-content: flex-start;\n  padding: 1rem;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    opacity: 0;\n  }\n  100%{\n    opacity: 1;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var AnimationFadeIn = (0, _styledComponents.keyframes)(_templateObject());

var Wrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var Top = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Title = _styledComponents["default"].p(_templateObject5(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});

var TimeBox = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.flexRowNoWrap;
});

var TimeLabelNow = _styledComponents["default"].p(_templateObject8(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject9());
});

var TimeLabelIcon = (0, _styledComponents["default"])(_reactFeather.Watch)(_templateObject10(), AnimationFadeIn, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject11());
});

var BottomWrapper = _styledComponents["default"].div(_templateObject12(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject13());
});

function DurationCard(_ref) {
  var forRecipient = _ref.forRecipient;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      account = _useWeb3React.account;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;

  var status = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
  }, [proxyStream]);
  var cancellationTimeLabel = (0, _react.useMemo)(function () {
    return status === _devConstants.STREAM_STATUS.cancelled ? (0, _typy["default"])(proxyStream, "stream.cancellation.timeLabel").safeString : null;
  }, [proxyStream, status]);
  var timeLabels = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(proxyStream, "stream.timeLabels").safeObject;
  }, [proxyStream]);
  var isForOwner = (0, _react.useMemo)(function () {
    var owner = forRecipient ? (0, _typy["default"])(proxyStream, "recipient").safeString : (0, _typy["default"])(proxyStream, "sender").safeString;
    return (0, _typy["default"])(account).safeString.toLowerCase() === owner.toLowerCase();
  }, [account, forRecipient, proxyStream]);
  /** Memoized values **/

  var parts = (0, _react.useMemo)(function () {
    switch (status) {
      case _devConstants.STREAM_STATUS.created:
        return {
          title: translation("words.starting") + " " + translation("words.on").toLowerCase(),
          time: (0, _typy["default"])(timeLabels, "startTime").safeString,
          description: _react["default"].createElement("div", null, _react["default"].createElement(_reactI18next.Trans, {
            i18nKey: "pages.details.duration.created",
            values: {
              recipient: isForOwner && forRecipient ? translation("words.you").toLowerCase() : "",
              depositTimeLabel: (0, _typy["default"])(timeLabels, "depositTime").safeString,
              startTimeLabel: (0, _typy["default"])(timeLabels, "startTime").safeString,
              stopTimeLabel: (0, _typy["default"])(timeLabels, "stopTime").safeString
            },
            components: [_react["default"].createElement("span", null, "0"), _react["default"].createElement("span", null, "1"), isForOwner ? _react["default"].createElement("b", null, "2") : _react["default"].createElement(_Web["default"].AccountLabel, {
              account: (0, _typy["default"])(proxyStream, forRecipient ? "recipient" : "sender").safeString,
              shortenENS: false
            }), _react["default"].createElement("span", null, "3")]
          }))
        };

      case _devConstants.STREAM_STATUS.streaming:
        return {
          title: translation("words.remaining"),
          time: (0, _typy["default"])(timeLabels, "remaining").safeString,
          description: _react["default"].createElement("div", null, _react["default"].createElement(_reactI18next.Trans, {
            i18nKey: "pages.details.duration.streaming",
            values: {
              passedLabel: (0, _typy["default"])(timeLabels, "passed").safeString,
              recipient: isForOwner && forRecipient ? translation("words.you").toLowerCase() : "",
              startTimeLabel: (0, _typy["default"])(timeLabels, "startTime").safeString,
              stopTimeLabel: (0, _typy["default"])(timeLabels, "stopTime").safeString
            },
            components: [_react["default"].createElement("span", null, "0"), _react["default"].createElement("span", null, "1"), isForOwner && forRecipient ? _react["default"].createElement("b", null, "2") : _react["default"].createElement(_Web["default"].AccountLabel, {
              account: (0, _typy["default"])(proxyStream, "recipient").safeString,
              shortenENS: false
            }), _react["default"].createElement("span", null, "3")]
          }))
        };

      case _devConstants.STREAM_STATUS.ended:
        return {
          title: translation("words.ended") + " " + translation("words.on").toLowerCase(),
          time: (0, _typy["default"])(timeLabels, "stopTime").safeString,
          description: _react["default"].createElement("div", null, _react["default"].createElement(_reactI18next.Trans, {
            i18nKey: "pages.details.duration.ended",
            values: {
              startTimeLabel: (0, _typy["default"])(timeLabels, "startTime").safeString,
              stopTimeLabel: (0, _typy["default"])(timeLabels, "stopTime").safeString
            },
            components: [_react["default"].createElement("span", null, "0"), _react["default"].createElement("span", null, "1")]
          }))
        };

      case _devConstants.STREAM_STATUS.cancelled:
        return {
          title: translation("words.cancelled") + " " + translation("words.on").toLowerCase(),
          time: (0, _typy["default"])(cancellationTimeLabel).safeString,
          description: _react["default"].createElement("div", null, _react["default"].createElement(_reactI18next.Trans, {
            i18nKey: "pages.details.duration.cancelled",
            values: {
              cancellationTimeLabel: (0, _typy["default"])(cancellationTimeLabel).safeString,
              sender: isForOwner && !forRecipient ? translation("words.you").toLowerCase() : "",
              startTimeLabel: (0, _typy["default"])(timeLabels, "startTime").safeString,
              stopTimeLabel: (0, _typy["default"])(timeLabels, "stopTime").safeString
            },
            components: [_react["default"].createElement("span", null, "0"), _react["default"].createElement("span", null, "1"), isForOwner && !forRecipient ? _react["default"].createElement("b", null, "2") : _react["default"].createElement(_Web["default"].AccountLabel, {
              account: (0, _typy["default"])(proxyStream, "sender").safeString,
              shortenENS: false
            })]
          }))
        };

      default:
        return {
          title: "",
          time: "",
          description: _react["default"].createElement(_react["default"].Fragment, null)
        };
    }
  }, [cancellationTimeLabel, forRecipient, isForOwner, proxyStream, status, timeLabels, translation]);
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Top, null, _react["default"].createElement(Title, null, parts.title), _react["default"].createElement(TimeBox, null, _react["default"].createElement(TimeLabelIcon, null), _react["default"].createElement(TimeLabelNow, null, parts.time))), _react["default"].createElement(BottomWrapper, null, parts.description));
}

DurationCard.propTypes = {
  forRecipient: _propTypes["default"].bool
};
DurationCard.defaultProps = {
  forRecipient: false
};
var _default = DurationCard;
exports["default"] = _default;