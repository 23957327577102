"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("@sablier/theme");

var _reactI18next = require("react-i18next");

var _Gradient = _interopRequireDefault(require("../../Gradient"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 0.625rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: ", ";\n  font-weight: 700;\n  margin-left: 0.5rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 0.09375rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  flex-grow: 1;\n  font-size: 0.9375rem;\n  max-width: 14rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Label = _styledComponents["default"].span(_templateObject2());

var ValueLabel = _styledComponents["default"].span(_templateObject3(), function (props) {
  return props.theme.robotoMonoFont;
});

var Svg = _styledComponents["default"].svg.attrs({
  viewBox: "0 0 100 6"
})(_templateObject4());

function ProgressBarSvg(_ref) {
  var label = _ref.label,
      value = _ref.value,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["label", "value"]);

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;
  /* See the `BlueGradient` and `OrangeGradient` for more details */


  var getGradientId = (0, _react.useCallback)(function () {
    switch (label) {
      case translation("words.streamed"):
        return "blueGradient";

      case translation("words.withdrawn"):
        return "orangeGradient";

      default:
        return "orangeGradient";
    }
  }, [label, translation]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement(_theme.FlexRowNoWrap, null, _react["default"].createElement(Label, null, label + ":"), _react["default"].createElement(ValueLabel, null, value + "%")), _react["default"].createElement(Svg, null, _react["default"].createElement("defs", null, _react["default"].createElement(_Gradient["default"].Blue, null), _react["default"].createElement(_Gradient["default"].Orange, null)), _react["default"].createElement("path", {
    d: "M 3 3 L 97 3",
    stroke: _theme.colors.aliceBlue,
    strokeLinecap: "round",
    strokeWidth: "4.6"
  }), _react["default"].createElement("path", {
    d: "M 3 3 L 97 3",
    stroke: "white",
    strokeLinecap: "round",
    strokeWidth: "4"
  }), _react["default"].createElement("path", {
    d: "M 3 3 L 97 3",
    fill: "none",
    stroke: "url(#".concat(getGradientId(), ")"),
    strokeDasharray: Math.max(value, 1.25) + ", 100",
    strokeLinecap: "round",
    strokeWidth: "4"
  })));
}

ProgressBarSvg.propTypes = {
  label: _propTypes["default"].string.isRequired,
  value: _propTypes["default"].number
};
ProgressBarSvg.defaultProps = {
  value: 100
};
var _default = ProgressBarSvg;
exports["default"] = _default;