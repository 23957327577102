"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useDigits;
exports.GRAYED_OUT_ZERO = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _typy = _interopRequireDefault(require("typy"));

var _lib = require("@sablier/dev-constants/lib");

var _bignumber = require("bignumber.js");

var _reactHooks = require("@sablier/react-hooks");

var CHARACTERISTICS_MIN_LENGTH = 3;
var MANTISSAS_MIN_LENGTH = 5;
var GRAYED_OUT_ZERO = "-1";
/**
 * Splits the big number in two parts:
 *
 * 1. Characteristics (the digits before the dot)
 * 2. Mantissas (the digits after the dot - the decimals)
 *
 * You may also wonder why we don't use the cash notation (K, M, B):
 *
 * 1. Brings complexity
 * 2. Harder to understand for users, because it's a dynamic component.
 * 3. While we're optimistic with regards to the amount of money people will lock up into Sablier, it's going
 * to be below $1B for a while.
 */

exports.GRAYED_OUT_ZERO = GRAYED_OUT_ZERO;

function useDigits(_ref) {
  var _ref$status = _ref.status,
      status = _ref$status === void 0 ? _lib.STREAM_STATUS.created : _ref$status,
      _ref$streamed = _ref.streamed,
      streamed = _ref$streamed === void 0 ? new _bignumber.BigNumber(0) : _ref$streamed;
  var previousStatus = (0, _reactHooks.usePrevious)(status);
  /**
   * There's an exception to the cash notation rule: if the stream ended, then we do show the cash notation,
   * to keep it consistent across all other places where it's used, such as the total deposit label underneath
   * the real-time balance.
   */

  if (status === _lib.STREAM_STATUS.ended) {
    /* https://mikemcl.github.io/bignumber.js/#dp */
    streamed = streamed.decimalPlaces(2);
  }
  /* Without useState, the min lengths would update on every render, but these numbers should only be defined once */

  /* https://mikemcl.github.io/bignumber.js/#sd */


  var computedCharacteristicsMinLength = Math.max(streamed.precision() - streamed.decimalPlaces(), CHARACTERISTICS_MIN_LENGTH);

  var _useState = (0, _react.useState)(computedCharacteristicsMinLength),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      characteristicsMinLength = _useState2[0],
      setCharacteristicsMinLength = _useState2[1];
  /* https://mikemcl.github.io/bignumber.js/#dp */


  var computedMantissasMinLength = Math.max(streamed.decimalPlaces(), MANTISSAS_MIN_LENGTH);

  var _useState3 = (0, _react.useState)(computedMantissasMinLength),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      mantissasMinLength = _useState4[0],
      setMantissasMinLength = _useState4[1];

  var digits = (0, _react.useMemo)(function () {
    if (status === _lib.STREAM_STATUS.streaming && previousStatus === _lib.STREAM_STATUS.created) {
      setCharacteristicsMinLength(computedCharacteristicsMinLength);
      setMantissasMinLength(computedMantissasMinLength);
    }

    var characteristics = new Array(characteristicsMinLength).fill({
      value: GRAYED_OUT_ZERO
    });
    var mantissas = new Array(mantissasMinLength).fill({
      value: GRAYED_OUT_ZERO
    });
    /* Handle the default case by returning a bunch of zeroes */

    if ((0, _typy["default"])(streamed).isFalsy) {
      return {
        characteristics: characteristics,
        characteristicsMinLength: characteristicsMinLength,
        mantissas: mantissas,
        mantissasMinLength: mantissasMinLength
      };
    }
    /* When the "streamed" big number has a value of zero, return "0.00" */


    if (streamed.isZero()) {
      characteristics[2] = {
        value: "0"
      };
      mantissas[0] = {
        value: "0"
      };
      mantissas[1] = {
        value: "0"
      };
      return {
        characteristics: characteristics,
        characteristicsMinLength: characteristicsMinLength,
        mantissas: mantissas,
        mantissasMinLength: mantissasMinLength
      };
    }

    var parts;

    if (streamed.decimalPlaces() > 1) {
      parts = streamed.toString().split(".");
    } else {
      /* Add a trailing "0" to multiples of 10 */
      parts = streamed.toFixed(2).split(".");
    }
    /* Set the decimals on the first positions of the mantissas and extend them w/ grayed-out zeroes */


    var paidMantissas = [];
    (0, _toConsumableArray2["default"])((0, _typy["default"])(parts[1]).safeString).forEach(function (digit) {
      paidMantissas.push({
        value: digit
      });
    });

    for (var i = paidMantissas.length; i < mantissasMinLength; i += 1) {
      paidMantissas.push({
        value: GRAYED_OUT_ZERO
      });
    }

    mantissas = paidMantissas;
    /* Set the significant digits on the last positions of the characteristics and prepend them w/ grayed-out zeroes */

    var paidCharacteristics = [];
    (0, _toConsumableArray2["default"])(parts[0]).forEach(function (digit) {
      paidCharacteristics.push({
        value: digit
      });
    });

    for (var _i = 0; _i < characteristicsMinLength - paidCharacteristics.length + 1; _i += 1) {
      paidCharacteristics.unshift({
        value: GRAYED_OUT_ZERO
      });
    }

    characteristics = paidCharacteristics;
    /* We need the min lengths outside of this Hook because we change the width of the Balances component after the
    initial render */

    return {
      characteristics: characteristics,
      characteristicsMinLength: characteristicsMinLength,
      mantissas: mantissas,
      mantissasMinLength: mantissasMinLength
    };
  }, [characteristicsMinLength, computedCharacteristicsMinLength, computedMantissasMinLength, mantissasMinLength, previousStatus, status, streamed]);
  return digits;
}