"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _reactFeather = require("react-feather");

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _contexts = require("@sablier/contexts");

var _reactI18next = require("react-i18next");

var _v = _interopRequireDefault(require("../Button/v2"));

var _Modal = _interopRequireDefault(require("../Modal"));

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  justify-content: flex-end;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: inline-block;\n  height: 1rem;\n  margin-top: -1px;\n  object-fit: contain;\n  width: 1rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  &[data-type=\"recipient\"] {\n    color: ", ";\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: 1rem;\n  font-weight: 500;\n  margin: 1rem 0rem 2rem 0rem;\n\n  span {\n    color: ", ";\n    font-weight: 500;\n\n    &[data-type=\"recipient\"] {\n      color: ", ";\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 2rem;\n\n  & h4 {\n    font-size: 1.1875rem;\n    font-weight: 600;\n    margin-top: 0rem;\n  }\n\n  & h5 {\n    font-size: 1rem;\n    font-weight: 400;\n    margin: 0rem;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  margin: 0rem;\n  padding: 0rem;\n  position: relative;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var UpperWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var ContentWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Description = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.secondaryColor;
}, function (props) {
  return props.theme.primaryColor;
});

var StyledLink = (0, _styledComponents["default"])(_theme.Link)(_templateObject6(), function (props) {
  return props.theme.secondaryColor;
}, function (props) {
  return props.theme.primaryColor;
});
var arrowIconCss = (0, _styledComponents.css)(_templateObject7());
var StyledArrowUpRight = (0, _styledComponents["default"])(_reactFeather.ArrowUpRight)(_templateObject8(), arrowIconCss);
var StyledArrowDownRight = (0, _styledComponents["default"])(_reactFeather.ArrowUpRight)(_templateObject9(), arrowIconCss);

var ButtonWrapper = _styledComponents["default"].div(_templateObject10(), function (props) {
  return props.theme.flexRowNoWrap;
});

var links = [];
Object.keys(_devConstants.SUBDOMAINS).filter(function (key) {
  return typeof _devConstants.SUBDOMAINS[key] === "string";
}).forEach(function (key) {
  links[key] = _devConstants.SUBDOMAINS[key].split("https://").pop();
});

function GoToModal(_ref) {
  var forRecipient = _ref.forRecipient;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useGoToModalManager = (0, _contexts.useGoToModalManager)(),
      isOpen = _useGoToModalManager.isOpen,
      toggle = _useGoToModalManager.toggle;
  /** Memoized values **/


  var title = (0, _react.useMemo)(function () {
    return translation(forRecipient ? "structs.payWithSablier" : "structs.getPaidWithSablier");
  }, [translation, forRecipient]);
  var subdomain = (0, _react.useMemo)(function () {
    return forRecipient ? "pay" : "app";
  }, [forRecipient]);
  /** Callbacks **/

  var renderDescription = (0, _react.useCallback)(function () {
    return forRecipient ? _react["default"].createElement(Description, null, _react["default"].createElement(_reactI18next.Trans, {
      i18nKey: "modals.goTo.recipient",
      values: {
        app: links.app,
        pay: links.pay
      },
      components: [_react["default"].createElement("span", {
        "data-type": "recipient"
      }, "0"), _react["default"].createElement(StyledArrowUpRight, null), _react["default"].createElement(StyledLink, {
        "data-type": "sender",
        href: _devConstants.SUBDOMAINS.pay
      }, "2")]
    })) : _react["default"].createElement(Description, null, _react["default"].createElement(_reactI18next.Trans, {
      i18nKey: "modals.goTo.sender",
      values: {
        app: links.app,
        pay: links.pay
      },
      components: [_react["default"].createElement("span", {
        "data-type": "sender"
      }, "0"), _react["default"].createElement(StyledArrowDownRight, null), _react["default"].createElement(StyledLink, {
        "data-type": "recipient",
        href: _devConstants.SUBDOMAINS.app
      }, "2")]
    }));
  }, [forRecipient]);
  return _react["default"].createElement(_Modal["default"], {
    dialogLabelId: "goto-modal-label",
    isOpen: isOpen,
    onDismiss: function onDismiss() {
      return toggle();
    },
    minHeight: null
  }, _react["default"].createElement(Wrapper, null, _react["default"].createElement(UpperWrapper, null, _react["default"].createElement("h4", {
    id: "goto-modal-label"
  }, title), _react["default"].createElement(_theme.TopRightCloseIcon, {
    onClick: toggle
  }), _react["default"].createElement(ContentWrapper, null, renderDescription(), _react["default"].createElement(ButtonWrapper, null, _react["default"].createElement(_v["default"], {
    type: "link",
    href: (0, _typy["default"])(_devConstants.SUBDOMAINS, subdomain).safeString,
    title: translation("structs.goTo") + " " + (0, _typy["default"])(links, subdomain).safeString,
    theme: "outline"
  }))))));
}

GoToModal.propTypes = {
  forRecipient: _propTypes["default"].bool
};
GoToModal.defaultProps = {
  forRecipient: false
};
var _default = GoToModal;
exports["default"] = _default;