"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));

var _typy = _interopRequireDefault(require("typy"));

var _reactFeather = require("react-feather");

var _bignumber = require("bignumber.js");

var _polished = require("polished");

var _devConstants = require("@sablier/dev-constants");

var _react2 = require("@xstate/react");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _machine = _interopRequireDefault(require("./machine"));

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

var _state = require("../state");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject22() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        margin-bottom: 0rem !important;\n        margin-top: 0rem  !important;\n      "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-bottom: 0;\n      margin-left: 0;\n      margin-top: 0;\n      ", "\n    "]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      padding: 0.5rem;\n      ", " {\n        font-size: 0.85rem;\n      }\n    "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  height: 10rem;\n  overflow-y: auto;\n  width: 100%;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      & > p {\n        color: ", ";\n      }\n      background-color: ", ";\n\n      &:hover,\n      &:active {\n        background-color: ", ";\n        transition: background-color 200ms;\n      }\n    "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  cursor: pointer;\n  padding: 0.6rem;\n  text-align: center;\n  transition: background-color 200ms;\n  width: 100%;\n  & > p {\n    color: ", ";\n    font-size: 0.8rem;\n    font-weight: 500;\n    margin: 0;\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n  &:hover,\n  &:active {\n    background-color: ", ";\n    transition: background-color 200ms;\n  }\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0);\n  border-radius: 0.1rem;\n  cursor: pointer;\n  padding: 0.25rem 0.2rem 0.2rem 0.2rem;\n  transition: background-color 200ms;\n  z-index: 100;\n\n  & > p {\n    color: ", ";\n    font-size: 0.9rem;\n    font-weight: 600;\n    margin: 0rem 0.3rem 0rem 0rem;\n    text-align: center;\n    user-select: none;\n  }\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  flex: 1;\n  justify-content: center;\n  position: relative;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      opacity: 0.4;\n      pointer-events: none;\n    "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0);\n  border-radius: 0.8rem;\n  cursor: pointer;\n  height: 1.6rem;\n  justify-content: center;\n  transition: background-color 200ms;\n  width: 1.6rem;\n  &:hover,\n  &:active {\n    background-color: rgba(255, 255, 255, 0.3);\n    transition: background-color 200ms;\n  }\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 0rem;\n  width: 100%;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.2rem 0.2rem 0rem 0rem;\n  height: 3rem;\n  justify-content: center;\n  padding: 0.2rem 0.4rem;\n  width: 100%;\n  z-index: 100;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    left: auto;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  border-radius: 0.25rem;\n  box-shadow: 0rem 1.25rem 2.5rem 0.5rem ", ";\n  display: ", ";\n  left: 0rem;\n  max-height: 15rem;\n  min-width: 8rem;\n  position: absolute;\n  right: 0rem;\n  top: 3.5rem;\n  z-index: 100;\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  cursor: ", ";\n  flex-grow: 1;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0.5rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  height: 3.25rem;\n  padding: 0.5rem 1rem;\n  position: relative;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapperTemp = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var StyledFlexRowNoWrap = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.isDisabled ? "not-allowed" : "pointer";
});

var Label = _styledComponents["default"].span(_templateObject4(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.isPlaceholder && (0, _styledComponents.css)(_templateObject5(), props.theme.aliceBlue);
});

var DropdownWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return (0, _polished.rgba)(props.theme.darkGunmetalBlack, 0.15);
}, function (props) {
  return props.isOpen ? "flex" : "none";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
});

var DropdownHeader = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
});

var DropdownContent = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.theme.white;
});

var ArrowButton = _styledComponents["default"].div(_templateObject10(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return !props.isEnabled && (0, _styledComponents.css)(_templateObject11());
});

var StyledArrowRight = (0, _styledComponents["default"])(_reactFeather.ArrowRight)(_templateObject12(), function (props) {
  return props.theme.white;
});
var StyledArrowLeft = (0, _styledComponents["default"])(_reactFeather.ArrowLeft)(_templateObject13(), function (props) {
  return props.theme.white;
});

var TimeTitleWrapper = _styledComponents["default"].div(_templateObject14(), function (props) {
  return props.theme.flexRowNoWrap;
});

var TimeTitle = _styledComponents["default"].div(_templateObject15(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
});

var TimeRow = _styledComponents["default"].div(_templateObject16(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject17(), props.theme.white, props.theme.darkGunmetalBlack, props.theme.darkGunmetalBlack);
});

var TimePicker = _styledComponents["default"].div(_templateObject18(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var OuterWrapper = (0, _styledComponents["default"])(OuterWrapperTemp)(_templateObject19(), function (props) {
  return props.isSmall && (0, _styledComponents.css)(_templateObject20(), Label);
}, function (props) {
  return props.isCleanStyle && (0, _styledComponents.css)(_templateObject21(), props.theme.mediaWidth.upToMedium(_templateObject22()));
});

function TimeInput(_ref) {
  var isCleanStyle = _ref.isCleanStyle,
      isSmall = _ref.isSmall,
      onUpdateTime = _ref.onUpdateTime,
      readOnly = _ref.readOnly,
      shouldGoIdle = _ref.shouldGoIdle,
      time = _ref.time;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var wrapperRef = (0, _react.useRef)(null);

  var _useMachine = (0, _react2.useMachine)(_machine["default"]),
      _useMachine2 = (0, _slicedToArray2["default"])(_useMachine, 2),
      currentMachine = _useMachine2[0],
      sendToMachine = _useMachine2[1];

  var timeSource = (0, _react.useMemo)(function () {
    var result = [];
    (0, _toConsumableArray2["default"])(Array(24).keys()).forEach(function (h) {
      (0, _toConsumableArray2["default"])(Array(12).keys()).forEach(function (f) {
        result.push({
          hours: h,
          minutes: f * 5,
          label: (0, _dayjs["default"])().hour(h).minute(f * 5).format(_devConstants.TIME_FORMAT)
        });
      });
    });
    return result;
  }, []);
  var viewedTimeIndex = (0, _react.useMemo)(function () {
    return timeSource.findIndex(function (search) {
      return (0, _typy["default"])(search, "label").safeString === (0, _typy["default"])(time, "label").safeString;
    });
  }, [timeSource, time]);
  /** Special Events **/

  (0, _useOnclickoutside["default"])(wrapperRef, function () {
    sendToMachine("IDLE");
  });
  /** Callbacks **/

  var onClickWrapper = (0, _react.useCallback)(function () {
    if (!readOnly) {
      if (currentMachine.matches("idle")) {
        sendToMachine("COLLAPSE");
      } else if (currentMachine.matches("collapsed")) {
        sendToMachine("IDLE");
      }
    }
  }, [currentMachine, readOnly, sendToMachine]);
  var onClickTimeRow = (0, _react.useCallback)(function (source) {
    if ((0, _typy["default"])(onUpdateTime).isTruthy && (0, _typy["default"])(time, "label").safeString !== (0, _typy["default"])(source, "label").safeString) {
      var label = (0, _typy["default"])(source, "label").safeString;
      var totalSeconds = new _bignumber.BigNumber((0, _typy["default"])(source, "hours").safeNumber * 60 * 60 + (0, _typy["default"])(source, "minutes").safeNumber * 60);
      var payload = {
        hours: (0, _typy["default"])(source, "hours").safeNumber,
        minutes: (0, _typy["default"])(source, "minutes").safeNumber,
        label: label,
        totalSeconds: totalSeconds
      };
      onUpdateTime(_objectSpread({}, payload));
    }

    sendToMachine("IDLE");
  }, [sendToMachine, onUpdateTime, time]);
  var onClickArrow = (0, _react.useCallback)(function () {
    var left = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (!readOnly && !currentMachine.matches("idle")) {
      var move = null;

      if (left && viewedTimeIndex > 0) {
        move = viewedTimeIndex - 1;
      }

      if (!left && viewedTimeIndex < timeSource.length - 1) {
        move = viewedTimeIndex + 1;
      }

      if (move !== null) {
        onClickTimeRow(_objectSpread({}, timeSource[move]));
      }
    }
  }, [currentMachine, timeSource, readOnly, viewedTimeIndex, onClickTimeRow]);
  var onClickArrowLeft = (0, _react.useCallback)(function () {
    onClickArrow(true);
  }, [onClickArrow]);
  var onClickArrowRight = (0, _react.useCallback)(function () {
    onClickArrow(false);
  }, [onClickArrow]);
  /** Effects **/

  (0, _reactHooks.useEffectWithDefaultDelay)({
    condition: shouldGoIdle,
    func: function func() {
      sendToMachine("IDLE");
    }
  });
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OuterWrapper, {
    ref: wrapperRef,
    isCleanStyle: isCleanStyle,
    isSmall: isSmall
  }, _react["default"].createElement(StyledFlexRowNoWrap, {
    onClick: onClickWrapper
  }, _react["default"].createElement(Label, {
    isPlaceholder: (0, _typy["default"])(time, "label").isFalsy
  }, (0, _typy["default"])(time, "label").safeString || translation("structs.timePlaceholder"))), _react["default"].createElement(DropdownWrapper, {
    isOpen: currentMachine.matches("collapsed")
  }, _react["default"].createElement(DropdownHeader, null, _react["default"].createElement(ArrowButton, {
    onClick: onClickArrowLeft,
    isEnabled: viewedTimeIndex > 0
  }, _react["default"].createElement(StyledArrowLeft, null)), _react["default"].createElement(TimeTitleWrapper, null, _react["default"].createElement(TimeTitle, null, _react["default"].createElement("p", null, translation("words.time")))), _react["default"].createElement(ArrowButton, {
    onClick: onClickArrowRight,
    isEnabled: viewedTimeIndex < timeSource.length - 1
  }, _react["default"].createElement(StyledArrowRight, null))), _react["default"].createElement(DropdownContent, null, _react["default"].createElement(TimePicker, null, _react["default"].createElement(TimeRow, {
    onClick: function onClick() {
      onClickTimeRow(_objectSpread({}, _state.time));
    }
  }, _react["default"].createElement("p", null, translation("words.clear"))), timeSource.map(function (row) {
    return _react["default"].createElement(TimeRow, {
      key: (0, _typy["default"])(row, "label").safeString,
      isActive: (0, _typy["default"])(row, "label").safeString === (0, _typy["default"])(time, "label").safeString,
      onClick: function onClick() {
        onClickTimeRow(row);
      }
    }, _react["default"].createElement("p", null, (0, _typy["default"])(row, "label").safeString));
  }))))), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(time, "error").safeString
  }));
}

TimeInput.propTypes = {
  isCleanStyle: _propTypes["default"].bool,
  isSmall: _propTypes["default"].bool,
  onUpdateTime: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool,
  shouldGoIdle: _propTypes["default"].bool,
  time: _propTypes["default"].shape({
    label: _propTypes["default"].string
  })
};
TimeInput.defaultProps = {
  isCleanStyle: false,
  isSmall: false,
  readOnly: false,
  shouldGoIdle: false
};
var _default = TimeInput;
exports["default"] = _default;