"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fadeOutAnimation = exports.fadeInAnimation = exports.progress0To100Withdrawn = exports.progress0To100Stream = exports.progress = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = require("styled-components");

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    opacity: 1;\n  },\n  100%{\n    opacity: 0;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    opacity: 0;\n  },\n  100%{\n    opacity: 1;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0%,\n  40% {\n    opacity: 0.8;\n    stroke-dasharray: 0 100;\n  }\n  90% {\n    opacity: 0.8;\n    stroke-dasharray: 100 100;\n  }\n  100% {\n    opacity: 0;\n    stroke-dasharray: 100 100;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    opacity: 0.9;\n    stroke-dasharray: 0 100;\n  }\n  40% {\n    opacity: 0.9;\n    stroke-dasharray: 100 100;\n  }\n  50% {\n    opacity: 0;\n    stroke-dasharray: 100 100;\n  }\n  100% {\n    opacity: 0;\n    stroke-dasharray: 100 100;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  0% {\n    stroke-dasharray: 0 100;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var progress = (0, _styledComponents.keyframes)(_templateObject());
exports.progress = progress;
var progress0To100Stream = (0, _styledComponents.keyframes)(_templateObject2());
exports.progress0To100Stream = progress0To100Stream;
var progress0To100Withdrawn = (0, _styledComponents.keyframes)(_templateObject3());
exports.progress0To100Withdrawn = progress0To100Withdrawn;
var fadeInAnimation = (0, _styledComponents.keyframes)(_templateObject4());
exports.fadeInAnimation = fadeInAnimation;
var fadeOutAnimation = (0, _styledComponents.keyframes)(_templateObject5());
exports.fadeOutAnimation = fadeOutAnimation;