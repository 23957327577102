"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

function Footer() {
  return _react["default"].createElement(_react["default"].Fragment, null);
}

var _default = Footer;
exports["default"] = _default;