"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _factory = _interopRequireDefault(require("./factory"));

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        border-bottom: none;\n        padding: 0;\n        position: absolute;\n        right: 0.8rem;\n        top: 1rem;\n        width: auto;\n      "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        border-bottom: none;\n        bottom: 1rem;\n        position: absolute;\n        right: 1.5rem;\n        padding: 0;\n        width: auto;\n      "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        border-bottom: none;\n        padding: 1.2rem 0.5rem;\n        border-right: 1px solid ", ";\n        max-width: calc(100% - 6rem);\n      "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        min-height: 4rem;\n        padding-left: 0.8rem;\n        & > * {\n          max-width: calc(100% - 7rem);\n        }\n      "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        background-color: ", ";\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        display: none;\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     justify-content: flex-start;\n     min-height: 1rem;\n     border-bottom: 1px solid ", ";\n     flex: auto;\n     padding: 0.8rem 0.6rem;\n     order: ", ";\n     width: 100%;\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n\n    ", "\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  flex: ", ";\n  flex-wrap: wrap;\n  justify-content: center;\n  padding: 0rem 0.4rem;\n  text-align: center;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.weight;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.aliceBlue, props.reorder, props.reorder < 0 && (0, _styledComponents.css)(_templateObject3()), [_devConstants.DASHBOARD_COLUMN_IDS.who, _devConstants.DASHBOARD_COLUMN_IDS.stopTime].includes(props.id) && (0, _styledComponents.css)(_templateObject4(), props.theme.backgroundColor), [_devConstants.DASHBOARD_COLUMN_IDS.value].includes(props.id) && (0, _styledComponents.css)(_templateObject5()), [_devConstants.DASHBOARD_COLUMN_IDS.progress].includes(props.id) && (0, _styledComponents.css)(_templateObject6(), props.theme.aliceBlue), [_devConstants.DASHBOARD_COLUMN_IDS.link].includes(props.id) && (0, _styledComponents.css)(_templateObject7()), [_devConstants.DASHBOARD_COLUMN_IDS.status].includes(props.id) && (0, _styledComponents.css)(_templateObject8()));
});

function Item(_ref) {
  var actions = _ref.actions,
      component = _ref.component,
      id = _ref.id,
      label = _ref.label,
      reorder = _ref.reorder,
      type = _ref.type,
      value = _ref.value,
      weight = _ref.weight;
  var render = (0, _react.useCallback)(function () {
    var itemProps = _objectSpread({
      label: label
    }, value);

    switch ((0, _typy["default"])(type).safeString) {
      case _devConstants.TABLE_ITEM_TYPES.actions:
        return _react["default"].createElement(_factory["default"].ItemActions, (0, _extends2["default"])({
          actions: actions
        }, itemProps));

      case _devConstants.TABLE_ITEM_TYPES.amount:
        return _react["default"].createElement(_factory["default"].ItemAmount, itemProps);

      case _devConstants.TABLE_ITEM_TYPES.progress:
        return _react["default"].createElement(_factory["default"].ItemProgress, itemProps);

      case _devConstants.TABLE_ITEM_TYPES.status:
        return _react["default"].createElement(_factory["default"].ItemStatus, itemProps);

      case _devConstants.TABLE_ITEM_TYPES.custom:
        return component();

      case _devConstants.TABLE_ITEM_TYPES.text:
      default:
        return _react["default"].createElement(_factory["default"].ItemText, itemProps);
    }
  }, [actions, component, label, type, value]);
  return _react["default"].createElement(Wrapper, {
    id: id,
    reorder: reorder,
    weight: weight
  }, render());
}

Item.propTypes = {
  actions: _propTypes["default"].shape({}),
  component: _propTypes["default"].func,
  id: _propTypes["default"].string,
  label: _propTypes["default"].string,
  reorder: _propTypes["default"].number,
  type: _propTypes["default"].oneOf(["amount", "actions", "custom", "progress", "status", "text"]),
  value: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].shape({}), _propTypes["default"].number, _propTypes["default"].string]),
  weight: _propTypes["default"].number
};
Item.defaultProps = {
  actions: {},
  component: function component() {
    return _react["default"].createElement(_react["default"].Fragment, null);
  },
  label: "",
  type: "text",
  value: "",
  reorder: 1,
  weight: 1
};
var _default = Item;
exports["default"] = _default;