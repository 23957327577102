"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  type Bundle {\n    atomicUnits: BigInt!\n    cash: String!\n    percentage: Int\n    units: BigInt!\n  }\n\n  type CancellationBundles {\n    recipientBalance: Bundle!\n    senderBalance: Bundle!\n  }\n\n  type StreamBundles {\n    deposit: Bundle!\n    outstanding: Bundle!\n    streamed: Bundle!\n    ratePerSecond: Bundle!\n    withdrawable: Bundle!\n    withdrawn: Bundle!\n  }\n\n  type TimeLabels {\n    depositTime: String\n    passed: String\n    remaining: String\n    startTime: String!\n    stopTime: String!\n  }\n\n  type WithdrawalBundles {\n    amount: Bundle!\n  }\n\n  extend type Cancellation {\n    bundles: CancellationBundles!\n    timeLabel: String!\n  }\n\n  extend type Stream {\n    bundles: StreamBundles!\n    status: BigInt!\n    timeLabels: TimeLabels!\n  }\n\n  extend type Token {\n    address: String!\n  }\n\n  extend type Withdrawal {\n    bundles: WithdrawalBundles!\n    timeLabel: String!\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var _default = (0, _graphqlTag["default"])(_templateObject());

exports["default"] = _default;