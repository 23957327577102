import React, { Suspense, lazy } from "react";
import styled from "styled-components";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { CreateStreamContextProvider } from "@sablier/contexts";
import { Web3 } from "@sablier/components";
import { useCrispChat } from "@sablier/react-hooks";

import { ModalsContextProvider as LocalModalsContextProvider } from "../contexts/ModalsContext";
import { Profile3BoxContextProvider } from "../contexts/Profile3BoxContext";

const ProfilePage = lazy(() => import("./ProfilePage"));
const RequestPage = lazy(() => import("./RequestPage"));
const LandingPage = lazy(() => import("./LandingPage"));

const AppWrapper = styled.div`
  align-items: stretch;
  background-color: ${props => props.theme.backgroundColor};
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const BodyWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  html,
  body {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;

function LocalContextProviders({ children }) {
  return (
    <CreateStreamContextProvider>
      <LocalModalsContextProvider>
        <Profile3BoxContextProvider>{children}</Profile3BoxContextProvider>
      </LocalModalsContextProvider>
    </CreateStreamContextProvider>
  );
}

function Routes() {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path="/request">
          <RequestPage />
        </Route>
        <Route exact path="/:id">
          <ProfilePage />
        </Route>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}

const disableCrispOnMobile = true;

function App() {
  useCrispChat(disableCrispOnMobile);
  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <BodyWrapper>
          <BrowserRouter>
            <LocalContextProviders>
              <Web3.ReactManager>
                <Routes />
              </Web3.ReactManager>
            </LocalContextProviders>
          </BrowserRouter>
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  );
}

export default App;
