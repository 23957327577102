"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _i18next = _interopRequireDefault(require("i18next"));

var _i18nextBrowserLanguagedetector = _interopRequireDefault(require("i18next-browser-languagedetector"));

var _i18nextXhrBackend = _interopRequireDefault(require("i18next-xhr-backend"));

var _reactI18next = require("react-i18next");
/* This file was thought to be initialized from within a create-react-app project */


_i18next["default"].use(_i18nextXhrBackend["default"]).use(_i18nextBrowserLanguagedetector["default"]).use(_reactI18next.initReactI18next).init({
  backend: {
    loadPath: "/locales/{{lng}}.json"
  },
  debug: process.env.NODE_ENV !== "production",
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true
  }
});

var _default = _i18next["default"];
exports["default"] = _default;