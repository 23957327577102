"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Withdrawal on Withdrawal {\n    id\n    amount\n    timestamp\n    token {\n      address: id\n      decimals\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var WITHDRAWAL = {
  fragments: {}
};
WITHDRAWAL.fragments.withdrawal = (0, _graphqlTag["default"])(_templateObject());
var _default = WITHDRAWAL;
exports["default"] = _default;