"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));

var _reactFeather = require("react-feather");

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _vendors = require("@sablier/vendors");

var _polished = require("polished");

var _reactHooks = require("@sablier/react-hooks");

var _react2 = require("@xstate/react");

var _reactI18next = require("react-i18next");

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

var _TokenLogo = _interopRequireDefault(require("../../TokenLogo"));

var _machine = _interopRequireDefault(require("./machine"));

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject22() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      height: 100%;\n      max-width: 100%;\n      width: 100%;\n\n      ", " {\n        max-height: 15rem;\n        top: 5rem;\n      }\n\n      ", " {\n        font-size: 1.5rem;\n        font-weight: 500;\n        margin-left: 0.7rem;\n        max-width: 10rem;\n      }\n\n      ", " {\n        font-size: 1rem;\n      }\n\n      ", " {\n        height: 6rem;\n        width: 100%;\n      }\n    "]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      max-width: 100%;\n      width: 100%;\n\n      ", " {\n        font-weight: 500;\n      }\n    "]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n"]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.935rem;\n  "]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 0.935rem;\n\n  ", "\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.9375rem;\n    margin-left: 0.5rem;\n    max-width: auto;\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex-grow: 1;\n  font-family: ", ";\n  font-size: 0.9375rem;\n  margin-left: 0.5rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  user-select: none;\n  white-space: nowrap;\n\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    transition: none;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  cursor: pointer;\n  height: 3.25rem;\n  padding: 0rem 0.8125rem;\n  transition: background-color 200ms ease-in-out;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  &:last-child {\n    border-bottom: 1px solid transparent;\n    border-bottom-left-radius: 0.25rem;\n    border-bottom-right-radius: 0.25rem;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.1875rem;\n  width: 1.1875rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 0.9rem;\n  object-fit: contain;\n  transition: color 200ms;\n  width: 0.8rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      opacity: 0.5;\n      pointer-events: none;\n      &:hover,\n      &:active {\n        background-color: ", ";\n      }\n    "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.8rem;\n  height: 1.6rem;\n  justify-content: center;\n  margin-left: 0.6125rem;\n  transition: background-color 200ms;\n  width: 1.6rem;\n\n  &:hover,\n  &:active {\n    background-color: ", ";\n    transition: background-color 200ms;\n\n    & > svg {\n      color: ", ";\n      transition: color 200ms;\n    }\n  }\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: transparent;\n  border-bottom: 1px solid ", ";\n  flex: 1;\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n  height: 100%;\n  min-width: 0;\n  outline: none;\n  transition: border-bottom 200ms;\n\n  &:focus {\n    border-bottom: 1px solid ", ";\n    transition: border-bottom 200ms;\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.125rem;\n  height: 100%;\n  padding: 0.6125rem;\n  position: relative;\n  width: 100%;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  border-top-left-radius: 0.25rem;\n  border-top-right-radius: 0.25rem;\n  cursor: pointer;\n  height: 3.25rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    max-height: 12rem;\n    top: 3.5rem;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", ";\n  align-items: flex-start;\n  border-radius: 0.25rem;\n  box-shadow: 0rem 1.25rem 2.5rem 0.5rem ", ";\n  display: ", ";\n  left: 0rem;\n  max-height: 17.875rem;\n  overflow-y: auto;\n  position: absolute;\n  right: 0rem;\n  top: 3.5rem;\n  z-index: 100;\n\n  & > * {\n    flex-shrink: 0;\n    width: 100%;\n  }\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 3.25rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  cursor: ", ";\n  height: 3.25rem;\n  justify-content: space-between;\n  padding: 0rem 0.8125rem;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    max-width: 29rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: auto;\n  position: relative;\n  width: 100%;\n  z-index: 100;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapperTemp = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.mediaWidth.mediumAndUp(_templateObject2());
});

var StyledFlexRowNoWrap = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.isDisabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4());
});

var DropdownWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.boxShadowDarkGunmetalBlack;
}, (0, _polished.rgba)(_theme.colors.darkGunmetalBlack, 0.15), function (props) {
  return props.isOpen ? "flex" : "none";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});

var SearchWrapper = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
});

var SearchOuterWrapper = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
});

var SearchInput = _styledComponents["default"].input(_templateObject9(), function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.glitterGray;
}, function (props) {
  return props.theme.glitterGray;
});

var SearchInnerWrapper = _styledComponents["default"].div(_templateObject10(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.isDisabled && (0, _styledComponents.css)(_templateObject11(), props.theme.white);
});

var StyledIconClose = (0, _styledComponents["default"])(_reactFeather.X)(_templateObject12(), function (props) {
  return props.theme.darkGunmetalBlack;
});
var StyledChevronDown = (0, _styledComponents["default"])(_reactFeather.ChevronDown)(_templateObject13());

var Token = _styledComponents["default"].div(_templateObject14(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject15());
});

var NameLabel = _styledComponents["default"].span(_templateObject16(), function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject17());
});

var SearchNameLabel = (0, _styledComponents["default"])(NameLabel)(_templateObject18(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject19());
});
var OuterWrapper = (0, _styledComponents["default"])(OuterWrapperTemp)(_templateObject20(), function (props) {
  return props.isBig && (0, _styledComponents.css)(_templateObject21(), NameLabel);
}, function (props) {
  return props.isBig && props.theme.mediaWidth.mediumAndUp(_templateObject22(), DropdownWrapper, NameLabel, SearchNameLabel, StyledFlexRowNoWrap);
});

function Dropdown(_ref) {
  var className = _ref.className,
      isErrorPlaceholder = _ref.isErrorPlaceholder,
      isBig = _ref.isBig,
      onUpdateToken = _ref.onUpdateToken,
      readOnly = _ref.readOnly,
      selectedToken = _ref.selectedToken,
      sourceTokens = _ref.sourceTokens;

  var _useMachine = (0, _react2.useMachine)(_machine["default"]),
      _useMachine2 = (0, _slicedToArray2["default"])(_useMachine, 2),
      currentMachine = _useMachine2[0],
      sendToMachine = _useMachine2[1];

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var wrapperRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(""),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      selectedSearchTerm = _useState2[0],
      updateSelectedSearchTerm = _useState2[1];

  var debouncedSearchTerm = (0, _reactHooks.useDebounce)((0, _typy["default"])(selectedSearchTerm).safeString, 100);
  (0, _useOnclickoutside["default"])(wrapperRef, function () {
    sendToMachine("IDLE");
  });
  /** Memoized values **/

  var usedTokens = (0, _react.useMemo)(function () {
    if (debouncedSearchTerm === "<empty string>") {
      return sourceTokens;
    }

    var cleanSearchTerm = (0, _typy["default"])(debouncedSearchTerm).safeString.toLowerCase();
    return sourceTokens.map(function (token) {
      return _objectSpread({}, token, {
        weight: Math.max((0, _typy["default"])(token.name).safeString.toLowerCase().indexOf(cleanSearchTerm), (0, _typy["default"])(token.symbol).safeString.toLowerCase().indexOf(cleanSearchTerm))
      });
    }).filter(function (token) {
      return token.weight > -1;
    }).sort(function (a, b) {
      return a.weight > b.weight;
    });
  }, [sourceTokens, debouncedSearchTerm]);
  /** Callbacks **/

  var onUpdateSelectedSearchTerm = (0, _react.useCallback)(function (e) {
    updateSelectedSearchTerm((0, _typy["default"])(e, "target.value").safeString);
  }, []);
  var onClickToken = (0, _react.useCallback)(function (token) {
    _vendors.mixpanel.track('Click Token in "Create Stream" Sidebar', {
      token: token
    });

    var payload = {
      address: token.address,
      decimals: token.decimals,
      name: token.name,
      symbol: token.symbol
    };
    onUpdateToken(payload);
  }, [onUpdateToken]);
  /**
   * When the transactions are being submitted, the token input turns into read-only mode, which means the user can't
   * select another token.
   */

  var onClickWrapper = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track('Click "Token Dropdown" in "Create Stream" Sidebar');

    if (!readOnly) {
      if (currentMachine.value === "idle") {
        sendToMachine("COLLAPSE");
      } else if (currentMachine.value === "collapsed") {
        sendToMachine("IDLE");
      }
    }
  }, [currentMachine.value, readOnly, sendToMachine]);
  var renderToken = (0, _react.useCallback)(function (token) {
    var address = (0, _typy["default"])(token, "address").safeEthereumAddress;
    var symbol = (0, _typy["default"])(token, "symbol").safeString || translation("words.error");
    return _react["default"].createElement(Token, {
      className: "token",
      key: symbol,
      onClick: function onClick(e) {
        e.stopPropagation();
        sendToMachine("IDLE");
        onClickToken(token);
      }
    }, _react["default"].createElement(_TokenLogo["default"], {
      address: address,
      size: 1.5,
      symbol: symbol
    }), _react["default"].createElement(SearchNameLabel, null, symbol));
  }, [onClickToken, sendToMachine, translation]);
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OuterWrapper, {
    className: className,
    isBig: isBig,
    onClick: onClickWrapper,
    ref: wrapperRef
  }, _react["default"].createElement(StyledFlexRowNoWrap, null, _react["default"].createElement(_TokenLogo["default"], {
    address: (0, _typy["default"])(selectedToken, "address").safeEthereumAddress,
    size: 2,
    symbol: (0, _typy["default"])(selectedToken, "symbol").safeString
  }), _react["default"].createElement(NameLabel, null, (0, _typy["default"])(selectedToken, "symbol").safeTokenName), _react["default"].createElement(StyledChevronDown, {
    color: _theme.colors.darkGunmetalBlack
  })), _react["default"].createElement(DropdownWrapper, {
    isOpen: (0, _typy["default"])(currentMachine, "value").safeString === "collapsed"
  }, _react["default"].createElement(SearchWrapper, {
    onClick: function onClick(e) {
      e.stopPropagation();
      sendToMachine("COLLAPSE");
    }
  }, _react["default"].createElement(SearchOuterWrapper, null, _react["default"].createElement(SearchInput, {
    placeholder: "".concat(translation("words.search"), "..."),
    value: selectedSearchTerm,
    onChange: onUpdateSelectedSearchTerm
  }), _react["default"].createElement(SearchInnerWrapper, {
    title: translation("words.clear") + " " + translation("words.input"),
    isDisabled: (0, _typy["default"])(selectedSearchTerm).isEmptyString,
    onClick: onUpdateSelectedSearchTerm
  }, _react["default"].createElement(StyledIconClose, null)))), usedTokens.map(function (token, index) {
    return renderToken(token, index);
  }))), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(selectedToken, "error").safeString,
    isPlaceholder: isErrorPlaceholder
  }));
}

Dropdown.propTypes = {
  className: _propTypes["default"].string,
  isErrorPlaceholder: _propTypes["default"].bool,
  isBig: _propTypes["default"].bool,
  onUpdateToken: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool,
  selectedToken: _devConstants.TOKEN_SHAPE,
  sourceTokens: _propTypes["default"].arrayOf(_propTypes["default"].shape({}))
};
Dropdown.defaultProps = {
  isErrorPlaceholder: false,
  isBig: false,
  readOnly: false,
  selectedToken: null,
  sourceTokens: []
};
var _default = Dropdown;
exports["default"] = _default;