"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCrispChat = useCrispChat;
exports.useHubspot = useHubspot;

var _react = require("react");

var _reactDeviceDetect = require("react-device-detect");

function useCrispChat() {
  var disableOnMobile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  (0, _react.useEffect)(function () {
    if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
      if (!disableOnMobile || !_reactDeviceDetect.isMobile) {
        window.$crisp = [];
        window.$crisp.push(["safe", true]);
        window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        d.getElementsByTagName("head")[0].appendChild(s);
      }
    }
  }, [disableOnMobile]);
}

function useHubspot() {
  (0, _react.useEffect)(function () {
    if (process.env.REACT_APP_HUBSPOT_URL) {
      var d = document;
      var s = d.createElement("script");
      s.src = process.env.REACT_APP_HUBSPOT_URL;
      s.async = true;
      s.defer = true;
      d.getElementsByTagName("body")[0].appendChild(s);
    }
  }, []);
}