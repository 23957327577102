"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createClient;

var _typy = _interopRequireDefault(require("typy"));

var _apolloClient = require("apollo-client");

var _apolloLinkHttp = require("apollo-link-http");

var _apolloCacheInmemory = require("apollo-cache-inmemory");

var _cacheRedirects = _interopRequireDefault(require("./cacheRedirects"));

var _resolvers = _interopRequireDefault(require("./resolvers"));

var _typeDefs = _interopRequireDefault(require("./typeDefs"));

function createClient() {
  var chainName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  /* By default, we display the data on mainnet */

  var uri;

  if ((0, _typy["default"])(chainName).isFalsy || chainName === "mainnet") {
    uri = process.env.REACT_APP_APOLLO_HTTP_URL;
  } else {
    uri = process.env.REACT_APP_APOLLO_HTTP_URL + "-" + chainName;
  }

  return new _apolloClient.ApolloClient({
    cache: new _apolloCacheInmemory.InMemoryCache({
      cacheRedirects: _cacheRedirects["default"]
    }),
    link: new _apolloLinkHttp.HttpLink({
      uri: uri
    }),
    resolvers: _resolvers["default"],
    typeDefs: _typeDefs["default"]
  });
}