"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Cancellation on Cancellation {\n    id\n    recipientBalance\n    senderBalance\n    timestamp\n    token {\n      address: id\n      decimals\n    }\n    txhash\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var CANCELLATION = {
  fragments: {}
};
CANCELLATION.fragments.cancellation = (0, _graphqlTag["default"])(_templateObject());
var _default = CANCELLATION;
exports["default"] = _default;