import React, { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const GO_TO_PROFILE_MODAL = "GO_TO_PROFILE_MODAL";
const ON_RAMP_MODAL = "ON_RAMP_MODAL";
const REQUEST_URL_MODAL = "REQUEST_URL_MODAL";
const STREAM_BROADCAST_MODAL = "STREAM_BROADCAST_MODAL";

const UPDATE_KEY = "UPDATE_KEY";
const UPDATABLE_KEYS = [GO_TO_PROFILE_MODAL, ON_RAMP_MODAL, REQUEST_URL_MODAL, STREAM_BROADCAST_MODAL];

const INITIAL_MODALS_CONTEXT = {
  [GO_TO_PROFILE_MODAL]: false,
  [ON_RAMP_MODAL]: false,
  [REQUEST_URL_MODAL]: false,
  [STREAM_BROADCAST_MODAL]: false,
};

const ModalsContext = createContext();

function useModalsContext() {
  return useContext(ModalsContext);
}

export function useRequestUrlModalManager() {
  const [state, { updateKey }] = useModalsContext();

  const isOpen = state[REQUEST_URL_MODAL];
  const toggle = useCallback(() => {
    updateKey(REQUEST_URL_MODAL, !isOpen);
  }, [isOpen, updateKey]);

  return {
    isOpen,
    toggle,
  };
}

export function useGoToProfileModalManager() {
  const [state, { updateKey }] = useModalsContext();

  const isOpen = state[GO_TO_PROFILE_MODAL];
  const toggle = useCallback(() => {
    updateKey(GO_TO_PROFILE_MODAL, !isOpen);
  }, [isOpen, updateKey]);

  return {
    isOpen,
    toggle,
  };
}

export function useStreamBroadcastModalManager() {
  const [state, { updateKey }] = useModalsContext();

  const isOpen = state[STREAM_BROADCAST_MODAL];
  const toggle = useCallback(() => {
    updateKey(STREAM_BROADCAST_MODAL, !isOpen);
  }, [isOpen, updateKey]);

  return {
    isOpen,
    toggle,
  };
}

export function useOnRampModalManager() {
  const [state, { updateKey }] = useModalsContext();

  const isOpen = state[ON_RAMP_MODAL];
  const toggle = useCallback(() => {
    updateKey(ON_RAMP_MODAL, !isOpen);
  }, [isOpen, updateKey]);

  return {
    isOpen,
    toggle,
  };
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_KEY: {
      const { key, value } = payload;
      if (!UPDATABLE_KEYS.some(k => k === key)) {
        throw new Error(`Unexpected key in ModalsContext reducer: '${key}'.`);
      } else {
        return {
          ...state,
          [key]: value,
        };
      }
    }
    default: {
      throw new Error(`Unexpected action type in ModalsContext reducer: '${type}'.`);
    }
  }
}

export function ModalsContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_MODALS_CONTEXT);

  const updateKey = useCallback((key, value) => {
    dispatch({ type: UPDATE_KEY, payload: { key, value } });
  }, []);

  return (
    <ModalsContext.Provider value={useMemo(() => [state, { updateKey }], [state, updateKey])}>
      {children}
    </ModalsContext.Provider>
  );
}
