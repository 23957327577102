"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSecondsForDays = getSecondsForDays;
exports.getSecondsForHours = getSecondsForHours;
exports.getSecondsForMinutes = getSecondsForMinutes;
exports.formatDuration = formatDuration;

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _bignumber = require("bignumber.js");

function getSecondsForDays(days) {
  if (typeof days !== "number" || days === 0) {
    return 0;
  }

  var daysBn = new _bignumber.BigNumber(days);
  var secondsBn = daysBn.multipliedBy(_devConstants.SECONDS_IN_DAY);
  return secondsBn.toNumber();
}

function getSecondsForHours(hours) {
  if (typeof hours !== "number" || hours === 0) {
    return 0;
  }

  var hoursBn = new _bignumber.BigNumber(hours);
  var secondsBn = hoursBn.multipliedBy(_devConstants.SECONDS_IN_HOUR);
  return secondsBn.toNumber();
}

function getSecondsForMinutes(minutes) {
  if (typeof minutes !== "number" || minutes === 0) {
    return 0;
  }

  var minutesBn = new _bignumber.BigNumber(minutes);
  var secondsBn = minutesBn.multipliedBy(_devConstants.SECONDS_IN_MINUTE);
  return secondsBn.toNumber();
}
/**
 * TODO: internationalise this function. Not currently possible because the resolvers are called before rendering time.
 * Convert seconds to months, days, hours and minutes.
 * @param durationInSeconds durationInSeconds in seconds, most likely a delta between two unix timestamps
 * @see https://github.com/i18next/react-i18next/issues/788
 */


function formatDuration(_ref) {
  var durationInSeconds = _ref.durationInSeconds;

  if ((0, _typy["default"])(durationInSeconds).isFalsy) {
    return "0";
  }

  var formattedDuration = [];
  var days = Math.floor(durationInSeconds / _devConstants.SECONDS_IN_DAY);

  if (days) {
    formattedDuration.push(days === 1 ? "1 day" : "".concat(days, " days"));
  }

  durationInSeconds %= _devConstants.SECONDS_IN_DAY;
  var hours = Math.floor(durationInSeconds / _devConstants.SECONDS_IN_HOUR);

  if (hours) {
    formattedDuration.push(hours === 1 ? "1 hour" : "".concat(hours, " hours"));
  }

  durationInSeconds %= _devConstants.SECONDS_IN_HOUR; // Purposefully skipping the quarter hour ...

  var mins = Math.floor(durationInSeconds / _devConstants.SECONDS_IN_MINUTE);

  if (mins) {
    formattedDuration.push(mins === 1 ? "1 min" : "".concat(mins, " mins"));
  }

  durationInSeconds %= _devConstants.SECONDS_IN_MINUTE;
  return formattedDuration.join(" ") || "0";
}