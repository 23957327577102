"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _bundles = _interopRequireDefault(require("./bundles"));

var _timeLabel = _interopRequireDefault(require("./timeLabel"));

var _default = {
  bundles: _bundles["default"],
  timeLabel: _timeLabel["default"]
};
exports["default"] = _default;