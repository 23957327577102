"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

var _vendors = require("@sablier/vendors");

var _polished = require("polished");

var _reactRouterDom = require("react-router-dom");

var _reactI18next = require("react-i18next");

var _ActionTab = _interopRequireDefault(require("./ActionTab"));

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 0.5rem;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  height: 1.1875rem;\n  width: 1.1875rem;\n\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 0.375rem;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.375rem;\n  object-fit: contain;\n  width: 1.375rem;\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 0.75rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 1rem;\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex-grow: 1;\n    font-size: 0.75rem;\n    height: 4.5rem;\n    margin: 0rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    border-radius: 0.75rem;\n    box-shadow: 0 0.25rem 1rem -0.25rem ", ";\n    font-size: 0.8125rem;\n    height: 5.5rem;\n    margin: 0.1875rem;\n    transition: background-color 200ms, border 200ms, box-shadow 200ms;\n\n    &:active,\n    &:hover {\n      background-color: ", ";\n    }\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  font-weight: 600;\n  justify-content: center;\n\n  ", "\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    border: none;\n    border-top: 1px solid ", ";\n    border-radius: 0rem;\n    bottom: 0rem;\n    left: 0rem;\n    position: fixed;\n    width: 100%;\n    z-index: 300;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  align-self: center;\n  border-radius: 1rem;\n  width: 5rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var FaHistory = function FaHistory(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    d: "M62 31c0-5.625-1.5-10.75-4.25-15.5S51.25 7 46.5 4.25 36.5 0 31 0a30.5 30.5 0 0 0-12.125 2.5C15.125 4.125 11.75 6.375 9 9.125l-5.625-5.5c-.5-.5-1.125-.625-1.75-.375S.75 4 .75 4.625V20.5c0 .5.125.875.375 1.125S1.75 22 2.25 22h16.125c.625 0 1.125-.25 1.375-.875s.125-1.25-.375-1.75l-6.125-6c2.25-2.25 5-4.125 8-5.375S27.5 6 31 6c4.5 0 8.625 1.125 12.5 3.375a26.566 26.566 0 0 1 9.125 9.125C54.875 22.375 56 26.5 56 31s-1.125 8.75-3.375 12.5c-2.25 3.875-5.375 6.875-9.125 9.125C39.625 54.875 35.5 56 31 56c-3.25 0-6.25-.5-9-1.625-2.875-1.125-5.375-2.75-7.625-4.75-.375-.25-.75-.375-1.125-.375s-.75.25-1 .5l-2.125 2.125c-.25.25-.375.625-.375 1.125s.125.75.5 1c2.75 2.5 5.875 4.5 9.5 5.875C23.25 61.375 27 62 31 62c5.5 0 10.75-1.375 15.5-4.125S55 51.25 57.75 46.5 62 36.625 62 31zM41.125 42.25c-.25.375-.625.625-1 .625-.5 0-.875-.125-1.125-.375l-11-8v-21c0-.375.125-.75.375-1S29 12 29.5 12h3c.375 0 .75.25 1 .5s.5.625.5 1v18l8.5 6.25c.25.25.5.5.625.875 0 .5 0 .875-.25 1.125l-1.75 2.5z",
    fill: "#1F2133"
  }));
};

FaHistory.defaultProps = {
  width: "62",
  height: "62",
  viewBox: "0 0 62 62",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};

var MiMultilineChart = function MiMultilineChart(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    fill: "#1F2133",
    d: "M22 6.92l-1.41-1.41-2.85 3.21C15.68 6.4 12.83 5 9.61 5 6.72 5 4.07 6.16 2 8l1.42 1.42C5.12 7.93 7.27 7 9.61 7c2.74 0 5.09 1.26 6.77 3.24l-2.88 3.24-4-4L2 16.99l1.5 1.5 6-6.01 4 4 4.05-4.55c.75 1.35 1.25 2.9 1.44 4.55H21c-.22-2.3-.95-4.39-2.04-6.14L22 6.92z"
  }), _react["default"].createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0z"
  }));
};

MiMultilineChart.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
};

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderedFlexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.flexRowNoWrap, props.theme.aliceBlue);
});

var Tab = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.mediaWidth.mediumAndUp(_templateObject4(), props.theme.borderAliceBlue, (0, _polished.rgba)(props.theme.darkGunmetalBlack, 0.1), props.theme.backgroundColor);
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
});

var TabLabel = _styledComponents["default"].span(_templateObject6(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
});

var iconCss = (0, _styledComponents.css)(_templateObject8(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject9());
});
var DetailsIcon = (0, _styledComponents["default"])(MiMultilineChart)(_templateObject10(), iconCss);
var HistoryIcon = (0, _styledComponents["default"])(FaHistory)(_templateObject11(), iconCss, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});
var LinksIcon = (0, _styledComponents["default"])(_reactFeather.Paperclip)(_templateObject13(), iconCss);

function Tabs(_ref) {
  var action = _ref.action,
      onClickHandlers = _ref.onClickHandlers,
      showLinksTab = _ref.showLinksTab,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["action", "onClickHandlers", "showLinksTab"]);
  var history = (0, _reactRouterDom.useHistory)();
  var params = (0, _reactRouterDom.useParams)();

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var onClickDetailsTab = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track('Click "Details" Tab');

    var proxyStreamId = (0, _typy["default"])(params, "id").safeString;
    history.push({
      pathname: "/stream/" + proxyStreamId + "/details",
      state: {
        from: "stream/" + proxyStreamId
      }
    });
  }, [history, params]);
  var onClickHistoryTab = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track('Click "History" Tab');

    var proxyStreamId = (0, _typy["default"])(params, "id").safeString;
    history.push({
      pathname: "/stream/" + proxyStreamId + "/history",
      state: {
        from: "stream/" + proxyStreamId
      }
    });
  }, [history, params]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement(_ActionTab["default"], {
    action: action,
    onClick: onClickHandlers.action
  }), showLinksTab && _react["default"].createElement(Tab, {
    onClick: onClickHandlers.links
  }, _react["default"].createElement(LinksIcon, {
    color: _theme.colors.darkGunmetalBlack
  }), _react["default"].createElement(TabLabel, null, translation("words.links"))), _react["default"].createElement(Tab, {
    onClick: onClickHandlers.details || onClickDetailsTab
  }, _react["default"].createElement(DetailsIcon, null), _react["default"].createElement(TabLabel, null, translation("words.details"))), _react["default"].createElement(Tab, {
    onClick: onClickHandlers.history || onClickHistoryTab
  }, _react["default"].createElement(HistoryIcon, null), _react["default"].createElement(TabLabel, null, translation("words.history"))));
}

Tabs.propTypes = {
  action: _propTypes["default"].number,
  onClickHandlers: _propTypes["default"].shape({
    action: _propTypes["default"].func,
    details: _propTypes["default"].func,
    history: _propTypes["default"].func,
    links: _propTypes["default"].func
  }),
  showLinksTab: _propTypes["default"].bool
};
Tabs.defaultProps = {
  action: 0,
  onClickHandlers: {
    action: null,
    details: null,
    history: null,
    links: null
  },
  showLinksTab: true
};
var _default = Tabs;
exports["default"] = _default;