"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = TimeBox;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _reactFeather = require("react-feather");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _Asset = _interopRequireDefault(require("../../Asset"));

var _v = _interopRequireDefault(require("../../Button/v2"));

var _Status = _interopRequireDefault(require("../Status"));

function _templateObject21() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1rem;\n  object-fit: contain;\n  width: 1rem;\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     margin: 0;\n  "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.15);\n  border-radius: 2rem;\n  display: flex;\n  height: 2rem;\n  justify-content: center;\n  margin-left: -0.2rem;\n  margin-right: 0.2rem;\n  position: relative;\n  width: 2rem;\n\n  ", ";\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin: 0rem 0.3rem 0rem 0.2rem;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n     font-size: 0.95rem;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 1rem;\n  font-weight: 600;\n  text-align: center;\n\n  ", ";\n\n  ", "\n\n  ", "\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-weight: 400;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex: 1;\n  padding: 0.5rem 0rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-right: 0.5rem;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 3.25rem;\n    margin-left: 0;\n    width: 3.25rem;\n    margin: auto 0;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 4.5rem;\n  margin-left: -3.5rem;\n  width: 4.5rem;\n\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n       margin-left: 0rem;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  justify-content: center;\n  margin-left: 0.5rem;\n\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      width: auto;\n      padding: 0 0.5rem;\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      width: 1rem;\n\n      & > * {\n        display: none;\n      }\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  align-items: center;\n  display: flex;\n  flex-shrink: 0;\n  justify-content: center;\n  width: 3rem;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      width: 100%;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  justify-content: center;\n  margin-top: 3.5rem;\n  transition: max-width 300ms ease;\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var IndicatorCancelled = function IndicatorCancelled(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("rect", {
    x: "26",
    y: ".707",
    width: "35.77",
    height: "35.77",
    rx: "14.06",
    transform: "rotate(45 26 .707)",
    fill: "#F5F7FC",
    stroke: "#EBF0FF"
  }), _react["default"].createElement("circle", {
    cx: "26.024",
    cy: "26.023",
    r: "10.652",
    fill: "#fff",
    stroke: "#EBF0FF"
  }), _react["default"].createElement("path", {
    stroke: "#FF0F00",
    strokeWidth: "1.6",
    strokeLinecap: "round",
    d: "M23.172 29.111l5.939-5.939M22.889 23.172l5.939 5.939"
  }));
};

IndicatorCancelled.defaultProps = {
  width: "52",
  height: "52",
  viewBox: "0 0 52 52",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderedFlexRowWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject2());
});

var Side = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.status === _devConstants.STREAM_STATUS.created && (0, _styledComponents.css)(_templateObject4());
});

var SideLeft = (0, _styledComponents["default"])(Side)(_templateObject5(), function (props) {
  return props.status === _devConstants.STREAM_STATUS.ended && (0, _styledComponents.css)(_templateObject6());
});
var SideRight = (0, _styledComponents["default"])(Side)(_templateObject7(), function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject8());
});
var StyledIcon = (0, _styledComponents["default"])(IndicatorCancelled)(_templateObject9(), function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject10());
});
var StyledHelper = (0, _styledComponents["default"])(_Asset["default"].InfoBox)(_templateObject11());

var Content = _styledComponents["default"].div(_templateObject12());

var Timestamp = _styledComponents["default"].span(_templateObject13(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
});

var Label = _styledComponents["default"].p(_templateObject14(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject15());
}, function (props) {
  return props.status === _devConstants.STREAM_STATUS.cancelled && (0, _styledComponents.css)(_templateObject16(), props.theme.red);
}, function (props) {
  return [_devConstants.STREAM_STATUS.created, _devConstants.STREAM_STATUS.streaming].includes(props.status) && (0, _styledComponents.css)(_templateObject17(), props.theme.darkGunmetalBlack);
});

var StyledWithdrawButton = (0, _styledComponents["default"])(_v["default"])(_templateObject18());

var WithdrawButtonIconWrapper = _styledComponents["default"].div(_templateObject19(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject20());
});

var WithdrawButtonIcon = (0, _styledComponents["default"])(_reactFeather.Download)(_templateObject21());

function TimeBox(_ref) {
  var _children = _ref.children,
      action = _ref.action,
      onClickHandlers = _ref.onClickHandlers,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "action", "onClickHandlers"]);

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var forRecipient = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(action).safeNumber === _devConstants.STREAM_ACTION_TYPE.withdrawal;
  }, [action]);
  var status = (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
  var shouldWithdraw = (0, _typy["default"])(proxyStream, "stream.bundles.withdrawable.cash").safeString !== "0" && forRecipient ? translation("pages.stream.status.recipient.fundsAvailable") : "";

  var content = _react["default"].createElement(_react["default"].Fragment, null);

  var help = _react["default"].createElement(_Status["default"], {
    forRecipient: forRecipient,
    forSender: !forRecipient
  });

  switch (status) {
    case _devConstants.STREAM_STATUS.created:
      content = _react["default"].createElement(Label, {
        status: status
      }, translation("words.starting"), " on ", _react["default"].createElement(Timestamp, null, (0, _typy["default"])(proxyStream, "stream.timeLabels.startTime").safeString));
      break;

    case _devConstants.STREAM_STATUS.streaming:
      content = _react["default"].createElement(Label, {
        status: status
      }, _react["default"].createElement(Timestamp, null, (0, _typy["default"])(proxyStream, "stream.timeLabels.remaining").safeString), "\xA0", translation("words.remaining").toLowerCase());
      help = _react["default"].createElement("span", null, help, "\xA0", shouldWithdraw);
      break;

    case _devConstants.STREAM_STATUS.cancelled:
      content = _react["default"].createElement(Label, {
        status: status
      }, translation("words.cancelled"), " on ", _react["default"].createElement(Timestamp, null, (0, _typy["default"])(proxyStream, "stream.cancellation.timeLabel").safeString));
      help = _react["default"].createElement("span", null, help, "\xA0", shouldWithdraw);
      break;

    case _devConstants.STREAM_STATUS.ended:
      content = _react["default"].createElement(Label, {
        status: status
      }, translation("words.ended"), " on ", _react["default"].createElement(Timestamp, null, (0, _typy["default"])(proxyStream, "stream.timeLabels.stopTime").safeString));
      help = _react["default"].createElement("span", null, help, "\xA0", shouldWithdraw);
      break;

    default:
      content = _react["default"].createElement(_react["default"].Fragment, null);
  }

  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement(SideLeft, {
    status: status
  }, status === _devConstants.STREAM_STATUS.cancelled && _react["default"].createElement(StyledIcon, null), status === _devConstants.STREAM_STATUS.ended && (0, _typy["default"])(shouldWithdraw).safeString.length > 0 && _react["default"].createElement(StyledWithdrawButton, {
    isMini: true,
    title: "Withdraw",
    titleShort: "",
    theme: "gradient",
    type: "button",
    childrenLeft: _react["default"].createElement(WithdrawButtonIconWrapper, null, _react["default"].createElement(WithdrawButtonIcon, {
      color: "white"
    })),
    onClick: (0, _typy["default"])(onClickHandlers, "action").safeFunction
  })), _react["default"].createElement(Content, null, content), _react["default"].createElement(SideRight, {
    status: status
  }, _react["default"].createElement(StyledHelper, {
    useDefault: true,
    help: help
  })));
}

TimeBox.propTypes = {
  action: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number]),
  onClickHandlers: _propTypes["default"].shape({
    onClickWithdrawActionTab: _propTypes["default"].func
  })
};