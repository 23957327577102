"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIME_FORMAT_DEFAULT = exports.TIME_FORMAT = exports.DATE_FORMAT = exports.SLIDE_DURATION = exports.SECONDS_IN_MINUTE = exports.SECONDS_IN_HOUR = exports.SECONDS_IN_DAY = exports.SECONDS_IN_WEEK = exports.ISO_8601_FORMAT_HOUR = exports.ISO_8601_FORMAT_MONTH = exports.ISO_8601_FORMAT_DAY = exports.ISO_8601_FORMAT = void 0;
var ISO_8601_FORMAT = "DD MMM YYYY @ h:mma";
exports.ISO_8601_FORMAT = ISO_8601_FORMAT;
var ISO_8601_FORMAT_DAY = "DD MMM YYYY";
exports.ISO_8601_FORMAT_DAY = ISO_8601_FORMAT_DAY;
var ISO_8601_FORMAT_MONTH = "MMM YYYY";
exports.ISO_8601_FORMAT_MONTH = ISO_8601_FORMAT_MONTH;
var ISO_8601_FORMAT_HOUR = "DD MMM YYYY @ h:00a";
exports.ISO_8601_FORMAT_HOUR = ISO_8601_FORMAT_HOUR;
var SECONDS_IN_WEEK = 604800;
exports.SECONDS_IN_WEEK = SECONDS_IN_WEEK;
var SECONDS_IN_DAY = 86400;
exports.SECONDS_IN_DAY = SECONDS_IN_DAY;
var SECONDS_IN_HOUR = 3600;
exports.SECONDS_IN_HOUR = SECONDS_IN_HOUR;
var SECONDS_IN_MINUTE = 60;
/* Measured in milliseconds */

exports.SECONDS_IN_MINUTE = SECONDS_IN_MINUTE;
var SLIDE_DURATION = 250;
exports.SLIDE_DURATION = SLIDE_DURATION;
var DATE_FORMAT = "MMMM DD, YYYY";
exports.DATE_FORMAT = DATE_FORMAT;
var TIME_FORMAT = "hh:mmA";
exports.TIME_FORMAT = TIME_FORMAT;
var TIME_FORMAT_DEFAULT = "12:00AM";
exports.TIME_FORMAT_DEFAULT = TIME_FORMAT_DEFAULT;