"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStreamHistory = useStreamHistory;

var _typy = _interopRequireDefault(require("typy"));

var _apollo = require("@sablier/apollo");

var _reactRouterDom = require("react-router-dom");

var _reactHooks = require("@apollo/react-hooks");

function useStreamHistory() {
  var params = (0, _reactRouterDom.useParams)();

  var _useQuery = (0, _reactHooks.useQuery)(_apollo.GET_HISTORY, {
    variables: {
      proxyStreamId: (0, _typy["default"])(params, "id").safeString
    }
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      loading = _useQuery.loading,
      _useQuery$refetch = _useQuery.refetch,
      refetch = _useQuery$refetch === void 0 ? function () {} : _useQuery$refetch,
      _useQuery$startPollin = _useQuery.startPolling,
      startPolling = _useQuery$startPollin === void 0 ? function () {} : _useQuery$startPollin,
      _useQuery$stopPolling = _useQuery.stopPolling,
      stopPolling = _useQuery$stopPolling === void 0 ? function () {} : _useQuery$stopPolling;

  var history = (0, _typy["default"])(data, "history").safeObject;
  return {
    data: data,
    error: error,
    loading: loading,
    refetch: refetch,
    startPolling: startPolling,
    stopPolling: stopPolling,
    history: history
  };
}