"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _xstate = require("xstate");

var _default = (0, _xstate.Machine)({
  id: "datePicker",
  initial: "idle",
  states: {
    idle: {
      on: {
        COLLAPSE: "collapsed"
      }
    },
    collapsed: {
      on: {
        IDLE: "idle",
        MONTH_IDLE: "collapsed.idle",
        MONTH_COLLAPSE: "collapsed.collapsed"
      },
      initial: "idle",
      states: {
        idle: {
          on: {
            MONTH_COLLAPSE: "collapsed"
          }
        },
        collapsed: {
          on: {
            MONTH_IDLE: "idle"
          }
        }
      }
    }
  }
});

exports["default"] = _default;