"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMixpanel = useMixpanel;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _typy = _interopRequireDefault(require("typy"));

var _vendors = require("@sablier/vendors");

var _reactRouterDom = require("react-router-dom");

var _core = require("@web3-react/core");
/**
 * We can't put move this Hook to vendors.js because it relies on web3-react and not all apps
 * are ask the user to sign in with a crypto wallet (such as @sablier/landing).
 */


function useMixpanel() {
  var location = (0, _reactRouterDom.useLocation)();
  /* Can't use "useParams" here because we are not in a Route */

  var streamMatch = (0, _reactRouterDom.useRouteMatch)({
    path: "/stream/:id?",
    exact: true
  });

  var _useWeb3React = (0, _core.useWeb3React)(),
      account = _useWeb3React.account,
      chainId = _useWeb3React.chainId;
  /* Re-identify the user on mixpanel every time the account changes  */


  (0, _react.useEffect)(function () {
    if ((0, _typy["default"])(account).isTruthy) {
      _vendors.mixpanel.identify(account);
    }
  }, [account]);
  /* Register the network id the user is on */

  (0, _react.useEffect)(function () {
    /* If the user doesn't have a web3 wallet, don't track the network only connector */
    if ((0, _typy["default"])(account).isTruthy && (0, _typy["default"])(chainId).isTruthy) {
      _vendors.mixpanel.people.set({
        "Chain Ids": (0, _defineProperty2["default"])({}, chainId, true)
      });
      /* TODO: remove this in a few months, after all data has been unset. We used to not have the "Chain Ids" key */


      _vendors.mixpanel.people.unset(chainId.toString());

      _vendors.mixpanel.register({
        "Chain Id": chainId
      });

      return function () {
        _vendors.mixpanel.unregister("Chain Id");
      };
    }

    return undefined;
  }, [account, chainId]);
  /* Register the id of the 'proxyStream' object the user opened, so that this will get sent alongside all tracks */

  (0, _react.useEffect)(function () {
    if ((0, _typy["default"])(streamMatch).isTruthy) {
      _vendors.mixpanel.register({
        "Proxy Stream Id": (0, _typy["default"])(streamMatch, "params.id").safeString
      });

      return function () {
        _vendors.mixpanel.unregister("Proxy Stream Id");
      };
    }

    return undefined;
  }, [location, streamMatch]);
}