"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStream = useStream;
exports.useRecipientStreams = useRecipientStreams;
exports.useSenderStreams = useSenderStreams;

var _typy = _interopRequireDefault(require("typy"));

var _apollo = require("@sablier/apollo");

var _reactRouterDom = require("react-router-dom");

var _reactHooks = require("@apollo/react-hooks");

var _core = require("@web3-react/core");
/**
 * Uses the id from the url, e.g. for id = 20: https://app.sablier.finance/stream/20/
 * This is the app intended to be used by employees, who are obviously the recipient of streams
 */


function useStream() {
  var params = (0, _reactRouterDom.useParams)();

  var _useQuery = (0, _reactHooks.useQuery)(_apollo.GET_STREAM, {
    variables: {
      id: (0, _typy["default"])(params, "id").safeString
    }
  }),
      data = _useQuery.data,
      error = _useQuery.error,
      loading = _useQuery.loading,
      _useQuery$refetch = _useQuery.refetch,
      refetch = _useQuery$refetch === void 0 ? function () {} : _useQuery$refetch,
      _useQuery$startPollin = _useQuery.startPolling,
      startPolling = _useQuery$startPollin === void 0 ? function () {} : _useQuery$startPollin,
      _useQuery$stopPolling = _useQuery.stopPolling,
      stopPolling = _useQuery$stopPolling === void 0 ? function () {} : _useQuery$stopPolling;

  var stream = (0, _typy["default"])(data, "stream").safeObject;
  return {
    error: error,
    loading: loading,
    refetch: refetch,
    startPolling: startPolling,
    stopPolling: stopPolling,
    stream: stream
  };
}
/**
 * Retrieves the latest 100 streams for the currently logged in Ethereum account.
 * @param query The GraphQL query itself
 * @param options The query options, such as variables
 */


function useStreams(query, options) {
  var _useQuery2 = (0, _reactHooks.useQuery)(query, options),
      data = _useQuery2.data,
      error = _useQuery2.error,
      loading = _useQuery2.loading,
      _useQuery2$refetch = _useQuery2.refetch,
      refetch = _useQuery2$refetch === void 0 ? function () {} : _useQuery2$refetch,
      _useQuery2$startPolli = _useQuery2.startPolling,
      startPolling = _useQuery2$startPolli === void 0 ? function () {} : _useQuery2$startPolli,
      _useQuery2$stopPollin = _useQuery2.stopPolling,
      stopPolling = _useQuery2$stopPollin === void 0 ? function () {} : _useQuery2$stopPollin;

  var streams = (0, _typy["default"])(data, "streams").safeObject;
  return {
    error: error,
    loading: loading,
    refetch: refetch,
    startPolling: startPolling,
    stopPolling: stopPolling,
    streams: streams
  };
}

function useRecipientStreams() {
  var _useWeb3React = (0, _core.useWeb3React)(),
      account = _useWeb3React.account;

  return useStreams(_apollo.GET_RECIPIENT_STREAMS, {
    variables: {
      recipient: account || ""
    }
  });
}

function useSenderStreams() {
  var _useWeb3React2 = (0, _core.useWeb3React)(),
      account = _useWeb3React2.account;

  return useStreams(_apollo.GET_SENDER_STREAMS, {
    variables: {
      sender: account || ""
    }
  });
}