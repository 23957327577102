"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _rampInstantSdk = require("@ramp-network/ramp-instant-sdk");

var _devConstants = require("@sablier/dev-constants");

var _vendors = require("@sablier/vendors");

var _contexts = require("@sablier/contexts");

var _reactI18next = require("react-i18next");

var _reactHooks = require("@sablier/react-hooks");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.1875rem;\n  cursor: pointer;\n  justify-content: flex-start;\n  padding: 0.4rem 0.4rem;\n  position: relative;\n  transition: background-color 200ms, border 200ms;\n\n  &:active,\n  &:hover {\n    border: 1px solid ", ";\n    transition: background-color 200ms, border 200ms;\n\n    ", " {\n      & > * {\n        fill: ", ";\n      }\n    }\n\n    ", " {\n      color: ", ";\n      transition: color 200ms;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1rem;\n  margin-right: 0.3125rem;\n  object-fit: contain;\n  width: 1rem;\n\n  & > * {\n    fill: ", ";\n    transition: fill 200ms;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: 0.8rem;\n  font-weight: 600;\n  margin: 0;\n  transition: color 200ms;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var RampIcon = function RampIcon(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("g", {
    clipPath: "url(#a)"
  }, _react["default"].createElement("path", {
    d: "M17.895 20.267l7.82 7.53-6.245 6.014a2.304 2.304 0 0 1-3.164 0L.653 18.737a2.104 2.104 0 0 1 0-3.047L16.306.629a2.305 2.305 0 0 1 3.164 0l6.244 6.014-7.82 7.53a4.208 4.208 0 0 0 0 6.094z"
  }), _react["default"].createElement("path", {
    d: "M35.12 18.733l-7.82 7.531-7.819-7.53a2.104 2.104 0 0 1 0-3.047l7.82-7.531 7.82 7.53c.87.852.87 2.21 0 3.047z"
  }), _react["default"].createElement("path", {
    d: "M53.937 18.737L38.284 33.81a2.304 2.304 0 0 1-3.164 0l-6.245-6.014 7.82-7.53c1.74-1.677 1.74-4.404 0-6.094l-7.82-7.53L35.12.629a2.304 2.304 0 0 1 3.164 0l15.653 15.074c.87.838.87 2.196 0 3.034z"
  })), _react["default"].createElement("defs", null, _react["default"].createElement("clipPath", {
    id: "a"
  }, _react["default"].createElement("path", {
    d: "M0 0h54.59v34.44H0z"
  }))));
};

RampIcon.defaultProps = {
  width: "55",
  height: "35",
  viewBox: "0 0 55 35",
  xmlns: "http://www.w3.org/2000/svg"
};

var OuterWrapper = _styledComponents["default"].div(_templateObject());

var Text = _styledComponents["default"].span(_templateObject2(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var StyledRampIcon = (0, _styledComponents["default"])(RampIcon)(_templateObject3(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var InnerWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.rampGreen;
}, StyledRampIcon, function (props) {
  return props.theme.rampGreen;
}, Text, function (props) {
  return props.theme.rampGreen;
});

function RampButton(_ref) {
  var children = _ref.children,
      className = _ref.className,
      onOpenCallback = _ref.onOpenCallback,
      tokenSymbol = _ref.tokenSymbol;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      account = _useWeb3React.account;

  var _useProcessingModalMa = (0, _contexts.useProcessingModalManager)(),
      toggleProcessingModal = _useProcessingModalMa.toggle;

  var onClickRampButton = (0, _react.useCallback)(function () {
    /* From their docs: If a user closes the widget, you need to create a new instance of the RampInstantSDK class. */
    _vendors.mixpanel.track('Click "Ramp" Button in "Create Stream" Sidebar');

    try {
      var widget = new _rampInstantSdk.RampInstantSDK({
        hostAppName: translation("structs.appName"),
        hostApiKey: process.env.REACT_APP_RAMP_KEY,
        hostLogoUrl: _devConstants.STATIC_ASSETS.logoSVG,
        swapAsset: tokenSymbol,
        userAddress: account,
        variant: "auto"
      }).on("PURCHASE_CREATED", function () {
        _vendors.mixpanel.track('Purchase created in "Ramp" widget');

        toggleProcessingModal(true);
      }).on("PURCHASE_SUCCESSFUL", function () {
        _vendors.mixpanel.track('Purchase fulfilled in "Ramp" widget');
      });
      widget.domNodes.overlay.style.zIndex = 20000;
      widget.show();

      if ((0, _typy["default"])(onOpenCallback).isFunction) {
        onOpenCallback();
      }
    } catch (e) {
      console.error(e);
    }
  }, [account, onOpenCallback, toggleProcessingModal, tokenSymbol, translation]);
  return children ? _react["default"].createElement(OuterWrapper, {
    className: className,
    onClick: onClickRampButton
  }, children) : _react["default"].createElement(OuterWrapper, {
    className: className
  }, _react["default"].createElement(InnerWrapper, {
    onClick: onClickRampButton
  }, _react["default"].createElement(StyledRampIcon, null), _react["default"].createElement(Text, null, translation("sidebars.createStream.ramp.rampButton", {
    tokenSymbol: tokenSymbol
  }))));
}

RampButton.propTypes = {
  className: _propTypes["default"].string,
  onOpenCallback: _propTypes["default"].func,
  tokenSymbol: _propTypes["default"].string
};
RampButton.defaultProps = {
  className: "",
  onOpenCallback: function onOpenCallback() {},
  tokenSymbol: null
};
var _default = RampButton;
exports["default"] = _default;