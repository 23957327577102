"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Gradient = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("@sablier/theme");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Gradient = _styledComponents["default"].linearGradient.attrs({
  gradientUnits: "userSpaceOnUse"
})(_templateObject());

exports.Gradient = Gradient;

function BlueGradient() {
  return _react["default"].createElement(Gradient, {
    id: "blueGradient"
  }, _react["default"].createElement("stop", {
    offset: "0%",
    stopColor: _theme.colors.azureBlue
  }), _react["default"].createElement("stop", {
    offset: "100%",
    stopColor: _theme.colors.secondaryColor
  }));
}

function OrangeGradient() {
  return _react["default"].createElement(Gradient, {
    id: "orangeGradient"
  }, _react["default"].createElement("stop", {
    offset: "0%",
    stopColor: _theme.colors.stilDeGrainYellow
  }), _react["default"].createElement("stop", {
    offset: "100%",
    stopColor: _theme.colors.primaryColor
  }));
}

var _default = {
  Blue: BlueGradient,
  Orange: OrangeGradient
};
exports["default"] = _default;