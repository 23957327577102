"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

var _polished = require("polished");

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    align-items: flex-end;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n\n  & > img,\n  span {\n    height: ", ";\n    width: ", ";\n  }\n\n  ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.675rem;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.75rem;\n  margin-top: 0.375rem;\n\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-left: 0.4375rem;\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.9375rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 1.0625rem;\n  font-weight: 500;\n  margin-left: 0rem;\n\n  ", ";\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  margin-left: 0.125rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  height: 100%;\n  justify-content: center;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      &:active,\n      &:hover {\n        border-color: ", ";\n        cursor: pointer;\n      }\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      background-color: ", ";\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid;\n  border-color: ", ";\n  border-radius: 0.75rem;\n  box-shadow: 0rem 0.25rem 0.5rem 0rem ", ";\n  justify-content: space-between;\n  margin-top: 0rem;\n  opacity: ", ";\n  outline: none;\n  padding: 1rem;\n  transition: border-color 150ms ease-in-out;\n  width: 100% !important;\n\n  &:focus {\n    box-shadow: 0rem 0rem 0rem 1px ", ";\n  }\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.platinumGray;
}, function (props) {
  return (0, _polished.transparentize)(0.95, props.theme.bleuDeFranceBlue);
}, function (props) {
  return props.isDisabled ? "0.5" : "1";
}, function (props) {
  return props.theme.bleuDeFranceBlue;
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject2(), props.theme.backgroundColor);
}, function (props) {
  return props.isClickable && (0, _styledComponents.css)(_templateObject3(), props.theme.blueJeansBlue);
});

var LabelWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var TitleWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.flexRowNoWrap;
});

var TitleLabel = _styledComponents["default"].span(_templateObject6(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7());
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject8());
});

var SubtitleLabel = _styledComponents["default"].span(_templateObject9(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.fallbackFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject10());
});

var IconWrapper = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.size + "rem";
}, function (props) {
  return props.size + "rem";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});

function Option(_ref) {
  var color = _ref.color,
      icon = _ref.icon,
      iconSize = _ref.iconSize,
      isActive = _ref.isActive,
      isClickable = _ref.isClickable,
      link = _ref.link,
      onClick = _ref.onClick,
      subtitle = _ref.subtitle,
      title = _ref.title;

  var content = _react["default"].createElement(Wrapper, {
    isActive: isActive,
    onClick: onClick,
    isClickable: isClickable && !isActive
  }, _react["default"].createElement(LabelWrapper, null, _react["default"].createElement(TitleWrapper, null, isActive ? _react["default"].createElement(_theme.GreenCircle, null) : "", _react["default"].createElement(TitleLabel, {
    color: color,
    isActive: isActive
  }, title)), (0, _typy["default"])(subtitle).isTruthy && _react["default"].createElement(SubtitleLabel, null, subtitle)), _react["default"].createElement(IconWrapper, {
    isActive: isActive,
    size: iconSize
  }, _react["default"].createElement("img", {
    src: icon,
    alt: "Icon"
  })));

  if ((0, _typy["default"])(link).isTruthy) {
    return _react["default"].createElement(_theme.Link, {
      href: link
    }, content);
  } else {
    return content;
  }
}

Option.propTypes = {
  color: _propTypes["default"].string,
  icon: _propTypes["default"].string.isRequired,
  isActive: _propTypes["default"].bool,
  isClickable: _propTypes["default"].bool,
  link: _propTypes["default"].string,
  onClick: _propTypes["default"].func,
  size: _propTypes["default"].number,
  subtitle: _propTypes["default"].string,
  title: _propTypes["default"].string
};
Option.defaultProps = {
  color: _theme.colors.bleuDeFranceBlue,
  link: null,
  iconSize: 1.75,
  isActive: false,
  isClickable: true,
  onClick: function onClick() {},
  subtitle: null,
  title: null
};
var _default = Option;
exports["default"] = _default;