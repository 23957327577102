"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));

var _bignumber = require("bignumber.js");

var _utils = require("@sablier/utils");

var _vendors = require("@sablier/vendors");

var _polished = require("polished");

var _reactHooks = require("@sablier/react-hooks");

var _react2 = require("@xstate/react");

var _reactI18next = require("react-i18next");

var _machine = _interopRequireDefault(require("./machine"));

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        margin-bottom: 0rem !important;\n        margin-top: 0rem  !important;\n      "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-bottom: 0;\n      margin-left: 0;\n      margin-top: 0;\n\n      ", "\n    "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      padding: 0.5rem;\n      ", " {\n        font-size: 0.85rem;\n      }\n      ", " {\n        font-size: 0.75rem;\n        padding: 0.6125rem 0.4rem;\n      }\n    "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      background-color: ", ";\n      font-weight: 600;\n    "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    transition: none;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  cursor: pointer;\n  font-family: ", ";\n  font-size: 0.8125rem;\n  font-weight: 400;\n  padding: 0.6125rem 0.875rem;\n  transition: background-color 200ms ease-in-out;\n  user-select: none;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  width: 1px;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  flex-grow: 1;\n  overflow-y: auto;\n  /* https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll */\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar,\n  &::-moz-scrollbars,\n  &::-o-scrollbar {\n    display: none;\n    height: 0rem;\n    width: 0rem;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  border-radius: 0.25rem;\n  box-shadow: 0rem 1.25rem 2.5rem 0.5rem ", ";\n  display: ", ";\n  left: 0rem;\n  max-height: 15rem;\n  min-width: 17rem;\n  position: absolute;\n  top: 3.5rem;\n  z-index: 100;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  cursor: ", ";\n  flex-grow: 1;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0.5rem 0rem;\n    padding: 0.5rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  height: 3.25rem;\n  margin: 0.75rem 0rem 0.75rem 0.5rem;\n  max-width: 29rem;\n  padding: 0.5rem 1rem;\n  position: relative;\n  width: 100%;\n  z-index: 100;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapperTemp = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var StyledFlexRowNoWrap = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.isDisabled ? "not-allowed" : "pointer";
});

var Label = _styledComponents["default"].span(_templateObject4(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.isPlaceholder && (0, _styledComponents.css)(_templateObject5(), props.theme.aliceBlue);
});

var DropdownWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return (0, _polished.rgba)(props.theme.darkGunmetalBlack, 0.15);
}, function (props) {
  return props.isOpen ? "flex" : "none";
});

var StyledFlexColumnNoWrap = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var Separator = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.aliceBlue;
});

var Row = _styledComponents["default"].span(_templateObject9(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.ghostWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject10());
}, function (props) {
  return props.isSelected && (0, _styledComponents.css)(_templateObject11(), props.theme.ghostWhite);
});

var OuterWrapper = (0, _styledComponents["default"])(OuterWrapperTemp)(_templateObject12(), function (props) {
  return props.isSmall && (0, _styledComponents.css)(_templateObject13(), Label, Row);
}, function (props) {
  return props.isCleanStyle && (0, _styledComponents.css)(_templateObject14(), props.theme.mediaWidth.upToMedium(_templateObject15()));
});
/**
 * Allows the user to select between:
 * + 0 and 365 days
 * + 0 and 24 hours
 * + 0 and 60 minutes
 */

var daysArray = (0, _toConsumableArray2["default"])(Array(365).keys());
var hoursArray = (0, _toConsumableArray2["default"])(Array(24).keys());
var minutesArray = (0, _toConsumableArray2["default"])(Array(60).keys());

function formatLabel(translation, days, hours, minutes) {
  var formattedDuration = [];

  if (days > 0) {
    formattedDuration.push(days.toString() + " " + translation("words.day", {
      count: days
    }));
  }

  if (hours > 0) {
    formattedDuration.push(hours.toString() + " " + translation("words.hour", {
      count: hours
    }));
  }

  if (minutes > 0) {
    formattedDuration.push(minutes.toString() + " " + translation("words.minute", {
      count: minutes
    }));
  }

  return formattedDuration.join(" ") || null;
}

function DurationInput(_ref) {
  var duration = _ref.duration,
      isCleanStyle = _ref.isCleanStyle,
      isSmall = _ref.isSmall,
      onUpdateDuration = _ref.onUpdateDuration,
      readOnly = _ref.readOnly,
      shouldGoIdle = _ref.shouldGoIdle;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var wrapperRef = (0, _react.useRef)(null);

  var _useMachine = (0, _react2.useMachine)(_machine["default"]),
      _useMachine2 = (0, _slicedToArray2["default"])(_useMachine, 2),
      currentMachine = _useMachine2[0],
      sendToMachine = _useMachine2[1];

  (0, _useOnclickoutside["default"])(wrapperRef, function () {
    sendToMachine("IDLE");
  });
  /** Callbacks **/

  /**
   * When the transactions are being submitted, the duration input turns into read-only mode, which means the user can't
   * select another duration.
   */

  var onClickWrapper = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track('Click "DurationInput" in "Create Stream" Sidebar');

    if (!readOnly) {
      if (currentMachine.value === "idle") {
        sendToMachine("COLLAPSE");
      } else if (currentMachine.value === "collapsed") {
        sendToMachine("IDLE");
      }
    }
  }, [currentMachine.value, readOnly, sendToMachine]);
  var onChange = (0, _react.useCallback)(function (_ref2) {
    var days = _ref2.days,
        hours = _ref2.hours,
        minutes = _ref2.minutes;

    if (!readOnly && (0, _typy["default"])(onUpdateDuration).isTruthy) {
      /** Keep null or undefined, as Falsy will fail at values equal to 0 */
      var payload = {
        days: (0, _typy["default"])(days).isNullOrUndefined ? (0, _typy["default"])(duration, "days").safeNumber : days,
        hours: (0, _typy["default"])(hours).isNullOrUndefined ? (0, _typy["default"])(duration, "hours").safeNumber : hours,
        minutes: (0, _typy["default"])(minutes).isNullOrUndefined ? (0, _typy["default"])(duration, "minutes").safeNumber : minutes
      };
      payload.label = formatLabel(translation, payload.days, payload.hours, payload.minutes);
      payload.totalSeconds = new _bignumber.BigNumber((0, _utils.getSecondsForDays)(payload.days) + (0, _utils.getSecondsForHours)(payload.hours) + (0, _utils.getSecondsForMinutes)(payload.minutes));
      onUpdateDuration(payload);
    }
  }, [duration, onUpdateDuration, readOnly, translation]);
  /* When the sidebar/container closes, hide the dropdown and reset the state */

  (0, _reactHooks.useEffectWithDefaultDelay)({
    condition: shouldGoIdle,
    func: function func() {
      sendToMachine("IDLE");
    }
  });
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OuterWrapper, {
    isCleanStyle: isCleanStyle,
    isSmall: isSmall,
    onClick: onClickWrapper,
    ref: wrapperRef
  }, _react["default"].createElement(StyledFlexRowNoWrap, null, _react["default"].createElement(Label, {
    isPlaceholder: (0, _typy["default"])(duration, "label").isFalsy
  }, (0, _typy["default"])(duration, "label").safeString || translation("sidebars.createStream.durationPlaceholder"))), _react["default"].createElement(DropdownWrapper, {
    isOpen: (0, _typy["default"])(currentMachine, "value").safeString === "collapsed"
  }, _react["default"].createElement(StyledFlexColumnNoWrap, null, daysArray.map(function (days) {
    return _react["default"].createElement(Row, {
      isSelected: days === (0, _typy["default"])(duration, "days").safeNumber,
      key: days,
      onClick: function onClick(e) {
        e.preventDefault();

        if (days !== (0, _typy["default"])(duration, "days").safeNumber) {
          onChange({
            days: days
          });
        }
      }
    }, days, "\xA0", translation("words.day", {
      count: days
    }).toLowerCase());
  })), _react["default"].createElement(Separator, null), _react["default"].createElement(StyledFlexColumnNoWrap, null, hoursArray.map(function (hours) {
    return _react["default"].createElement(Row, {
      isSelected: hours === (0, _typy["default"])(duration, "hours").safeNumber,
      key: hours,
      onClick: function onClick(e) {
        e.preventDefault();

        if (hours !== (0, _typy["default"])(duration, "hours").safeNumber) {
          onChange({
            hours: hours
          });
        }
      }
    }, hours, "\xA0", translation("words.hour", {
      count: hours
    }).toLowerCase());
  })), _react["default"].createElement(Separator, null), _react["default"].createElement(StyledFlexColumnNoWrap, null, minutesArray.map(function (minutes) {
    return _react["default"].createElement(Row, {
      isSelected: minutes === (0, _typy["default"])(duration, "minutes").safeNumber,
      key: minutes,
      onClick: function onClick(e) {
        e.preventDefault();

        if (minutes !== (0, _typy["default"])(duration, "minutes").safeNumber) {
          onChange({
            minutes: minutes
          });
        }
      }
    }, minutes, "\xA0", translation("words.minute", {
      count: minutes
    }).toLowerCase());
  })))), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(duration, "error").safeString
  }));
}

DurationInput.propTypes = {
  duration: _propTypes["default"].shape({
    label: _propTypes["default"].string,
    days: _propTypes["default"].number,
    hours: _propTypes["default"].number,
    minutes: _propTypes["default"].number,
    totalSeconds: _propTypes["default"].shape({})
  }),
  isCleanStyle: _propTypes["default"].bool,
  isSmall: _propTypes["default"].bool,
  onUpdateDuration: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool,
  shouldGoIdle: _propTypes["default"].bool
};
DurationInput.defaultProps = {
  duration: null,
  isCleanStyle: false,
  isSmall: false,
  readOnly: false,
  shouldGoIdle: false
};
var _default = DurationInput;
exports["default"] = _default;