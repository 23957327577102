"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _devConstants = require("@sablier/dev-constants");

var _reactFeather = require("react-feather");

var _theme = require("@sablier/theme");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.25rem;\n  width: 1.25rem;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.5rem;\n  object-fit: contain;\n  width: 1.5rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MiShowChartDown = function MiShowChartDown(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    d: "M20.5 18.49l-6-6.01-4 4L2 6.92l1.41-1.41 7.09 7.97 4-4 7.5 7.51-1.5 1.5z",
    fill: "#F77423"
  }));
};

MiShowChartDown.defaultProps = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};

var MiShowChartUp = function MiShowChartUp(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    fill: "#4CAF50",
    d: "M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"
  }), _react["default"].createElement("path", {
    fill: "none",
    d: "M0 0h24v24H0z"
  }));
};

MiShowChartUp.defaultProps = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
};
var iconCss = (0, _styledComponents.css)(_templateObject());
var DepositIcon = (0, _styledComponents["default"])(MiShowChartUp)(_templateObject2(), iconCss);
var WithdrawalIcon = (0, _styledComponents["default"])(MiShowChartDown)(_templateObject3(), iconCss);
var CancellationIcon = (0, _styledComponents["default"])(_reactFeather.X)(_templateObject4());

function ActionIcon(_ref) {
  var type = _ref.type,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["type"]);

  switch (type) {
    case _devConstants.STREAM_ACTION_TYPE.deposit:
      {
        return _react["default"].createElement(DepositIcon, otherProps);
      }

    case _devConstants.STREAM_ACTION_TYPE.withdrawal:
      {
        return _react["default"].createElement(WithdrawalIcon, (0, _extends2["default"])({
          color: _theme.colors.primaryColor
        }, otherProps));
      }

    case _devConstants.STREAM_ACTION_TYPE.cancellation:
      {
        return _react["default"].createElement(CancellationIcon, (0, _extends2["default"])({
          color: _theme.colors.rustyRed
        }, otherProps));
      }

    default:
      return null;
  }
}

ActionIcon.propTypes = {
  type: _propTypes["default"].number.isRequired
};
var _default = ActionIcon;
exports["default"] = _default;