"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _reactRouterDom = require("react-router-dom");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _reactFeather = require("react-feather");

var _Address = _interopRequireDefault(require("./Address"));

var _ActivityCard = _interopRequireDefault(require("./ActivityCard"));

var _DurationCard = _interopRequireDefault(require("./DurationCard"));

var _Item = _interopRequireDefault(require("../Table/Item"));

var _Spinner = _interopRequireDefault(require("../../Spinner"));

var _TitleAndBorderedCross = _interopRequireDefault(require("../../TitleAndBorderedCross"));

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n  "]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  grid-column: span 1;\n\n  ", "\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    grid-gap: 0.5rem;\n    grid-template-columns: 1fr;\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: grid;\n  grid-gap: 0.7rem;\n  grid-template-columns: 1fr 1fr;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: none;\n    padding: 0rem;\n    margin: 0rem;\n\n    & > div {\n      border: none;\n      margin: 0rem;\n      padding: 0rem;\n    }\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px solid ", ";\n  border-radius: 0.2rem;\n  margin-left: 0.2rem;\n  padding: 0.3rem 0.2rem;\n\n  ", "\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  flex: 1;\n  padding-left: 1rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: 1.1rem;\n  font-weight: 600;\n  margin-bottom: 1rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0rem 0.6rem;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  margin: 0 1rem;\n  object-fit: contain;\n  width: 1rem;\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n    padding-top: 0rem;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  justify-content: flex-start;\n  padding: 1.2rem;\n  padding-top: 0rem;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    min-width: 0;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  justify-content: center;\n  padding: 1rem 0rem;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", ";\n    border: none;\n    min-width: 0;\n    width: 100%;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", "\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  min-height: 40rem;\n  min-width: 35rem;\n\n  /* See this for why we need 100% instead of 100vw: https://bit.ly/34xeInG */\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  justify-content: center;\n  padding: 3rem 0rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var InnerWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.borderedFlexColumnNoWrap;
}, function (props) {
  return props.theme.boxShadowDarkGunmetalBlack;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4(), props.theme.backgroundColor);
});

var ListWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});

var SectionWrapper = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject8());
});

var TransactionWrapper = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.theme.flexRowNoWrap;
});

var TransactionIcon = (0, _styledComponents["default"])(_reactFeather.Shuffle)(_templateObject10(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject11());
});

var SectionTitle = _styledComponents["default"].div(_templateObject12(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var HeaderStatusWrapper = _styledComponents["default"].div(_templateObject13(), function (props) {
  return props.theme.flexRowNoWrap;
});

var HeaderStatus = _styledComponents["default"].div(_templateObject14(), function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject15());
});

var CardsWrapper = _styledComponents["default"].div(_templateObject16(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject17());
});

var EmptyCard = _styledComponents["default"].div(_templateObject18(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject19());
});
/* TODO: figure out a better way to handle the dichotomy between the recipient and sender app here */


function DetailsPage(_ref) {
  var _children = _ref.children,
      forRecipient = _ref.forRecipient,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "forRecipient"]);
  var location = (0, _reactRouterDom.useLocation)();
  var params = (0, _reactRouterDom.useParams)();
  var routerHistory = (0, _reactRouterDom.useHistory)();

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      error = _useProxyStream.error,
      loading = _useProxyStream.loading,
      proxyStream = _useProxyStream.proxyStream;
  /** Side Effects **/


  (0, _reactHooks.useProxyStreamPolling)({
    interval: 5000
  });
  /** Memoized Values **/

  var status = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(proxyStream, "stream.status").safeNumber;
  }, [proxyStream]);
  /** Callbacks **/

  var goBack = (0, _react.useCallback)(function () {
    var proxyStreamId = (0, _typy["default"])(params, "id").safeString;

    if ((0, _typy["default"])(proxyStreamId).isTruthy) {
      /* When the user opened the Stream page first, pop the history stack */
      if ((0, _typy["default"])(location, "state.from").safeString === "stream/" + proxyStreamId) {
        routerHistory.goBack();
      } else {
        /* When the user opened the History page first, push the Stream page into the stack */
        routerHistory.push({
          pathname: "/stream/" + proxyStreamId,
          state: {
            from: "/stream/" + proxyStreamId + "/details"
          }
        });
      }
    } else {
      /* When the id is falsy, go back home to the dashboard */
      routerHistory.push({
        pathname: "/",
        state: {
          from: "details"
        }
      });
    }
  }, [location, params, routerHistory]);
  var render = (0, _react.useCallback)(function () {
    if ((0, _typy["default"])(error).isTruthy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_theme.UnknownErrorLabel, null, translation("structs.unknownError")));
    }

    if (loading) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_Spinner["default"], null));
    }

    if ((0, _typy["default"])(proxyStream).isFalsy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, translation("structs.noData"));
    }

    return _react["default"].createElement(ListWrapper, null, _react["default"].createElement(SectionWrapper, null, _react["default"].createElement(TransactionWrapper, null, _react["default"].createElement(_Address["default"], {
      value: (0, _typy["default"])(proxyStream, "sender").safeString
    }), _react["default"].createElement(TransactionIcon, null), _react["default"].createElement(_Address["default"], {
      value: (0, _typy["default"])(proxyStream, "recipient").safeString,
      forRecipient: true
    }))), _react["default"].createElement(SectionWrapper, null, _react["default"].createElement(SectionTitle, null, translation("words.activity")), _react["default"].createElement(CardsWrapper, null, _react["default"].createElement(_ActivityCard["default"], {
      type: "light",
      title: translation("words.total"),
      subtitle: translation("structs.valueOfStream").toLowerCase(),
      value: (0, _typy["default"])(proxyStream, "stream.bundles.deposit.cash").safeString,
      token: (0, _typy["default"])(proxyStream, "stream.token").safeObject
    }), _react["default"].createElement(EmptyCard, null), _react["default"].createElement(_ActivityCard["default"], {
      type: "secondary",
      title: translation("words.streamed"),
      subtitle: (0, _typy["default"])(proxyStream, "stream.bundles.streamed.percentage").safeNumber + "% " + translation("structs.outOfTotal").toLowerCase(),
      value: (0, _typy["default"])(proxyStream, "stream.bundles.streamed.cash").safeString,
      token: (0, _typy["default"])(proxyStream, "stream.token").safeObject
    }), _react["default"].createElement(_ActivityCard["default"], {
      type: "primary",
      title: translation("words.withdrawn"),
      subtitle: (0, _typy["default"])(proxyStream, "stream.bundles.withdrawn.percentage").safeNumber + "% " + translation("structs.outOfTotal").toLowerCase(),
      value: (0, _typy["default"])(proxyStream, "stream.bundles.withdrawn.cash").safeString,
      token: (0, _typy["default"])(proxyStream, "stream.token").safeObject
    }))), _react["default"].createElement(SectionWrapper, null, _react["default"].createElement(SectionTitle, null, translation("words.duration")), _react["default"].createElement(_DurationCard["default"], {
      forRecipient: forRecipient
    })));
  }, [error, forRecipient, loading, proxyStream, translation]);
  return _react["default"].createElement(OuterWrapper, otherProps, _react["default"].createElement(InnerWrapper, null, _react["default"].createElement(_TitleAndBorderedCross["default"], {
    onClickBorderedCross: goBack,
    title: translation("words.details")
  }, _react["default"].createElement(HeaderStatusWrapper, null, _react["default"].createElement(HeaderStatus, null, _react["default"].createElement(_Item["default"], {
    type: _devConstants.TABLE_ITEM_TYPES.status,
    value: {
      forRecipient: forRecipient,
      isCell: false,
      status: status,
      withdrawable: true
    }
  })))), render()));
}

DetailsPage.propTypes = {
  forRecipient: _propTypes["default"].bool
};
DetailsPage.defaultProps = {
  forRecipient: false
};
var _default = DetailsPage;
exports["default"] = _default;