"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Info;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n\n  & span {\n    font-size: 0.875rem;\n  }\n\n  & .icon-circle-wrapper {\n    ", ";\n    align-items: center;\n    background-color: ", ";\n    border-radius: 0.875rem;\n    flex-shrink: 0;\n    height: 1.75rem;\n    justify-content: center;\n    width: 1.75rem;\n  }\n\n  & .label {\n    font-size: 0.90625rem;\n    margin-left: 0.5rem;\n    margin-right: 0.5rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.ghostWhite;
});

function Info(_ref) {
  var children = _ref.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children"]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement("div", {
    className: "icon-circle-wrapper"
  }, _react["default"].createElement("span", null, "i")), children);
}