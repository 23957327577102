"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactLoadScript = _interopRequireDefault(require("react-load-script"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _vendors = require("@sablier/vendors");

var _reactI18next = require("react-i18next");

var _Spinner = _interopRequireDefault(require("../../Spinner"));

require("./style.css");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-left: 0.4rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.1875rem;\n  cursor: pointer;\n  justify-content: flex-start;\n  padding: 0.4rem 0.4rem;\n  position: relative;\n  transition: background-color 200ms, border 200ms;\n\n  &[data-loading=\"true\"],\n  &:active,\n  &:hover {\n    border: 1px solid ", ";\n    transition: background-color 200ms, border 200ms;\n\n    ", " {\n      & > path {\n        fill: ", ";\n      }\n    }\n\n    ", " {\n      color: ", ";\n      transition: color 200ms;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 0.875rem;\n  margin-right: 0.3125rem;\n  object-fit: contain;\n  width: 0.875rem;\n\n  & > path {\n    fill: ", ";\n    transition: fill 200ms;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-size: 0.8rem;\n  font-weight: 600;\n  margin: 0;\n  transition: color 200ms;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var KyberIcon = function KyberIcon(props) {
  return _react["default"].createElement("svg", props, _react["default"].createElement("path", {
    d: "M29.698 50.005l37.033 21.418c1.3.747 2.916-.203 2.916-1.696V30.26a1.943 1.943 0 0 0-2.916-1.696L29.698 50.005z",
    fill: "url(#a)"
  }), _react["default"].createElement("path", {
    d: "M64.6 19.359L39.97.406c-1.143-.882-2.78-.249-3.072 1.153L27.903 43.74l36.472-21.124a1.968 1.968 0 0 0 .225-3.257z",
    fill: "url(#b)"
  }), _react["default"].createElement("path", {
    d: "M39.97 99.604l24.652-18.953c1.1-.86.987-2.555-.202-3.257L27.926 56.27l8.994 42.18c.27 1.403 1.93 2.014 3.05 1.154z",
    fill: "url(#c)"
  }), _react["default"].createElement("path", {
    d: "M20.21 50.005l9.69-45.46c.381-1.787-1.638-3.076-3.073-1.968L2.019 21.666C.74 22.639 0 24.176 0 25.782v48.424c0 1.605.74 3.143 2.019 4.116L26.827 97.41c1.435 1.108 3.454-.181 3.073-1.968l-9.69-45.438z",
    fill: "url(#d)"
  }), _react["default"].createElement("defs", null, _react["default"].createElement("linearGradient", {
    id: "a",
    x1: "14.288",
    y1: "28.301",
    x2: "69.725",
    y2: "138.142",
    gradientUnits: "userSpaceOnUse"
  }, _react["default"].createElement("stop", {
    stopColor: "#FFB046"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#F52880"
  })), _react["default"].createElement("linearGradient", {
    id: "b",
    x1: "13.455",
    x2: "71.712",
    y2: "107.338",
    gradientUnits: "userSpaceOnUse"
  }, _react["default"].createElement("stop", {
    stopColor: "#FFB046"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#F52880"
  })), _react["default"].createElement("linearGradient", {
    id: "c",
    x1: "13.476",
    y1: "56.27",
    x2: "71.711",
    y2: "163.597",
    gradientUnits: "userSpaceOnUse"
  }, _react["default"].createElement("stop", {
    stopColor: "#FFB046"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#F52880"
  })), _react["default"].createElement("linearGradient", {
    id: "d",
    x1: "-11.552",
    y1: "2.166",
    x2: "129.258",
    y2: "97.017",
    gradientUnits: "userSpaceOnUse"
  }, _react["default"].createElement("stop", {
    stopColor: "#FFB046"
  }), _react["default"].createElement("stop", {
    offset: "1",
    stopColor: "#F52880"
  }))));
};

KyberIcon.defaultProps = {
  width: "70",
  height: "100",
  viewBox: "0 0 70 100",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};
var KYBER_API_VERSION = "0.7.5";

var OuterWrapper = _styledComponents["default"].div(_templateObject());

var Text = _styledComponents["default"].span(_templateObject2(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var Icon = (0, _styledComponents["default"])(KyberIcon)(_templateObject3(), function (props) {
  return props.theme.darkGunmetalBlack;
});

var InnerWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.coralOrange;
}, Icon, function (props) {
  return props.theme.coralOrange;
}, Text, function (props) {
  return props.theme.coralOrange;
});

var StyledSpinner = (0, _styledComponents["default"])(_Spinner["default"])(_templateObject5());
var KyberOptions = {
  callback: "https%3A%2F%2Fkyberpay-sample.knstats.com%2Fcallback",
  commissionId: _devConstants.PUBLIC_WALLETS.multisig,
  lang: "en",
  mode: "popup",
  network: "mainnet",
  paramForwarding: "true",
  theme: "theme-dark",
  type: "swap"
};
var RESET = "RESET";
var SET_LOADING = "SET_LOADING";
var SET_LOADED = "SET_LOADED";
var initialState = {
  loading: false,
  loaded: false
};

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case RESET:
      {
        return initialState;
      }

    case SET_LOADING:
      {
        return _objectSpread({}, state, {
          loading: payload
        });
      }

    case SET_LOADED:
      {
        return _objectSpread({}, state, {
          loaded: payload
        });
      }

    default:
      throw new Error("Unexpected action type in KyberButton reducer: '".concat(type, "'."));
  }
}

function KyberButton(_ref2) {
  var children = _ref2.children,
      onOpenCallback = _ref2.onOpenCallback,
      tokenSymbol = _ref2.tokenSymbol;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var link = (0, _react.useRef)(null);

  var _useReducer = (0, _react.useReducer)(reducer, initialState),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];
  /** Memoized Values **/


  var widgetUrl = (0, _react.useMemo)(function () {
    var params = Object.keys(KyberOptions).map(function (key) {
      return "".concat(key, "=").concat(KyberOptions[key]);
    }).join("&");
    return "https://widget.kyber.network/v".concat(KYBER_API_VERSION, "/?").concat(params, "&defaultPair=ETH_").concat(tokenSymbol.toUpperCase());
  }, [tokenSymbol]);
  /** Callbacks **/

  var onClickExchangeButton = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track('Click "Kyber" Button in "Create Stream" Sidebar');

    if (!state.loaded) {
      dispatch({
        type: SET_LOADING,
        payload: true
      });
    } else if ((0, _typy["default"])(link, "current").isTruthy) {
      link.current.click();

      if ((0, _typy["default"])(onOpenCallback).isFunction) {
        onOpenCallback();
      }
    }

    return null;
  }, [link, onOpenCallback, state.loaded]);
  return _react["default"].createElement(OuterWrapper, null, children ? _react["default"].createElement("div", {
    onClick: onClickExchangeButton
  }, children) : _react["default"].createElement(InnerWrapper, {
    "data-loading": state.loading,
    onClick: onClickExchangeButton
  }, _react["default"].createElement(Icon, {
    "data-loading": state.loading
  }), _react["default"].createElement(Text, {
    "data-loading": state.loading
  }, translation("sidebars.createStream.swap.kyberButton", {
    tokenSymbol: tokenSymbol
  })), state.loading ? _react["default"].createElement(StyledSpinner, {
    color: _theme.colors.coralOrange,
    delay: 0,
    size: 1
  }) : null), state.loading || state.loaded ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactLoadScript["default"], {
    onCreate: function onCreate() {},
    onError: function onError(error) {
      dispatch({
        type: SET_LOADED,
        payload: false
      });
      dispatch({
        type: SET_LOADING,
        payload: false
      });

      _vendors.rollbar.error(error);
    },
    onLoad: function onLoad() {
      dispatch({
        type: SET_LOADED,
        payload: true
      });
      setTimeout(function () {
        dispatch({
          type: SET_LOADING,
          payload: false
        });
      }, 1000);

      if ((0, _typy["default"])(link, "current").isTruthy) {
        link.current.click();
        window.sablierFlagKyberScript = true;
      }
    },
    url: "https://widget.kyber.network/v".concat(KYBER_API_VERSION, "/widget.js?").concat(String(Date.now()))
  })) : null, _react["default"].createElement("a", {
    className: "kyber-widget-button theme-dark theme-supported",
    href: widgetUrl,
    name: "KyberWidget - Powered by KyberNetwork",
    ref: link,
    rel: "noopener noreferrer",
    style: {
      display: "none"
    },
    target: "_blank",
    title: "Pay with tokens"
  }, translation("words.swap")));
}

KyberButton.propTypes = {
  onOpenCallback: _propTypes["default"].func,
  tokenSymbol: _propTypes["default"].string
};
KyberButton.defaultProps = {
  onOpenCallback: function onOpenCallback() {},
  tokenSymbol: "DAI"
};
var _default = KyberButton;
exports["default"] = _default;