"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment StreamTransaction on StreamTransaction {\n    id\n    block\n    event\n    timestamp\n    txhash\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var STREAM_TRANSACTION = {
  fragments: {}
};
STREAM_TRANSACTION.fragments.streamTransaction = (0, _graphqlTag["default"])(_templateObject());
var _default = STREAM_TRANSACTION;
exports["default"] = _default;