"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _utils = require("@sablier/utils");

function _default(_ref) {
  var recipientBalance = _ref.recipientBalance,
      senderBalance = _ref.senderBalance,
      _ref$token = _ref.token,
      token = _ref$token === void 0 ? _devConstants.DEFAULT_TOKEN_MAINNET : _ref$token;
  return {
    __typename: "CancellationBundles",
    recipientBalance: (0, _utils.bundlify)({
      numberInAtomicUnits: (0, _typy["default"])(recipientBalance).safeString,
      token: token
    }),
    senderBalance: (0, _utils.bundlify)({
      numberInAtomicUnits: (0, _typy["default"])(senderBalance).safeString,
      token: token
    })
  };
}