"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _theme = require("@sablier/theme");

var _polished = require("polished");

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      &:focus {\n        box-shadow: ", " 0rem 0rem 0rem 0.125rem inset,\n          ", " 0rem 0rem 0rem 0.125rem;\n      }\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  &:focus {\n    box-shadow: inset 0 0 0.2rem rgba(230, 240, 255, 1);\n    transition: border-color 200ms;\n  }\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      &:focus-within {\n        box-shadow: ", " 0rem 0rem 0rem 0.125rem inset,\n          ", " 0rem 0rem 0rem 0.25rem;\n      }\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      box-shadow: ", " 0rem 0rem 0rem 0.125rem inset;\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-shadow: none;\n  outline: none;\n  transition: all 200ms ease-in-out;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var baseCss = (0, _styledComponents.css)(_templateObject(), function (props) {
  return props.isInvalid && (0, _styledComponents.css)(_templateObject2(), props.errorColor);
});

var StyledWrapper = _styledComponents["default"].div(_templateObject3(), baseCss, function (props) {
  return props.isInvalid && (0, _styledComponents.css)(_templateObject4(), props.errorColor, (0, _polished.rgba)(props.errorColor, 0.15));
});

function Wrapper(_ref) {
  var children = _ref.children,
      errorColor = _ref.errorColor,
      focusColor = _ref.focusColor,
      isInvalid = _ref.isInvalid,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "errorColor", "focusColor", "isInvalid"]);
  return _react["default"].createElement(StyledWrapper, (0, _extends2["default"])({
    errorColor: errorColor,
    focusColor: focusColor,
    isInvalid: isInvalid
  }, otherProps), children);
}

var StyledInput = _styledComponents["default"].input(_templateObject5(), baseCss, function (props) {
  return props.isInvalid && (0, _styledComponents.css)(_templateObject6(), props.errorColor, (0, _polished.rgba)(props.errorColor, 0.15));
});

function Input(_ref2) {
  var children = _ref2.children,
      errorColor = _ref2.errorColor,
      focusColor = _ref2.focusColor,
      isInvalid = _ref2.isInvalid,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref2, ["children", "errorColor", "focusColor", "isInvalid"]);
  return _react["default"].createElement(StyledInput, (0, _extends2["default"])({
    errorColor: errorColor,
    focusColor: focusColor,
    isInvalid: isInvalid
  }, otherProps), children);
}

var defaultProps = {
  errorColor: _theme.colors.rustyRed,
  focusColor: _theme.colors.primaryColor,
  isInvalid: false
};
Wrapper.defaultProps = defaultProps;
Input.defaultProps = defaultProps;
var propTypes = {
  errorColor: _propTypes["default"].string,
  focusColor: _propTypes["default"].string,
  isInvalid: _propTypes["default"].bool
};
Wrapper.propTypes = propTypes;
Input.propTypes = propTypes;
var _default = {
  Input: Input,
  Wrapper: Wrapper
};
exports["default"] = _default;