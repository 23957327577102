"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  createClient: true
};
Object.defineProperty(exports, "createClient", {
  enumerable: true,
  get: function get() {
    return _createClient["default"];
  }
});

var _createClient = _interopRequireDefault(require("./createClient"));

var _queries = require("./queries");

Object.keys(_queries).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _queries[key];
    }
  });
});