import React, { createContext, useContext, useMemo } from "react";

import { useMachine } from "@xstate/react";
import profile3BoxMachine from "../../machines/profile3BoxMachine";

const Profile3BoxContext = createContext();

function useProfile3BoxContext() {
  return useContext(Profile3BoxContext);
}

export function useProfile3BoxManager() {
  const [machine] = useProfile3BoxContext();
  const [currentMachine, sendToMachine] = machine;

  return {
    currentMachine,
    sendToMachine,
  };
}

export function Profile3BoxContextProvider({ children }) {
  const machine = useMachine(profile3BoxMachine);
  return (
    <Profile3BoxContext.Provider value={useMemo(() => [machine], [machine])}>{children}</Profile3BoxContext.Provider>
  );
}
