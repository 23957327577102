"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));

var _reactFeather = require("react-feather");

var _bignumber = require("bignumber.js");

var _devConstants = require("@sablier/dev-constants");

var _polished = require("polished");

var _reactHooks = require("@sablier/react-hooks");

var _react2 = require("@xstate/react");

var _reactI18next = require("react-i18next");

var _machine = _interopRequireDefault(require("./machine"));

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

var _state = require("../state");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject27() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        margin-bottom: 0rem !important;\n        margin-top: 0rem  !important;\n      "]);

  _templateObject27 = function _templateObject27() {
    return data;
  };

  return data;
}

function _templateObject26() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-bottom: 0;\n      margin-left: 0;\n      margin-top: 0;\n      ", "\n    "]);

  _templateObject26 = function _templateObject26() {
    return data;
  };

  return data;
}

function _templateObject25() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      padding: 0.5rem;\n\n      ", " {\n        font-size: 0.85rem;\n      }\n    "]);

  _templateObject25 = function _templateObject25() {
    return data;
  };

  return data;
}

function _templateObject24() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n\n  ", "\n"]);

  _templateObject24 = function _templateObject24() {
    return data;
  };

  return data;
}

function _templateObject23() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        color: ", ";\n        background-color: ", ";\n\n        &:hover,\n        &:active {\n          background-color: ", ";\n          transition: background-color 200ms;\n        }\n      "]);

  _templateObject23 = function _templateObject23() {
    return data;
  };

  return data;
}

function _templateObject22() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  cursor: pointer;\n  grid-column: 1fr;\n  justify-content: center;\n\n  & > p {\n    ", ";\n    align-items: center;\n    background-color: ", ";\n    border-radius: 1rem;\n    color: ", ";\n    font-family: ", ";\n    font-size: 0.7rem;\n    font-weight: 600;\n    height: 2rem;\n    justify-content: center;\n    margin: 0;\n    width: 2rem;\n    &:hover,\n    &:active {\n      background-color: ", ";\n    }\n    ", "\n  }\n"]);

  _templateObject22 = function _templateObject22() {
    return data;
  };

  return data;
}

function _templateObject21() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: grid;\n  grid-template-columns: repeat(7, 1fr);\n  height: 10rem;\n  width: 100%;\n"]);

  _templateObject21 = function _templateObject21() {
    return data;
  };

  return data;
}

function _templateObject20() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      &:after {\n        background-color: ", ";\n        border-radius: 0.2rem;\n        content: \"\";\n        height: 0.4rem;\n        position: absolute;\n        left: 0.5rem;\n        width: 0.4rem;\n      }\n    "]);

  _templateObject20 = function _templateObject20() {
    return data;
  };

  return data;
}

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-bottom: 1px solid ", ";\n  cursor: pointer;\n  justify-content: center;\n  padding: 0.6rem;\n  position: relative;\n  text-align: center;\n  transition: background-color 200ms;\n  width: 100%;\n  & > p {\n    color: ", ";\n    flex: 1;\n    font-size: 0.8rem;\n    font-weight: 600;\n    margin: 0;\n    text-align: center;\n  }\n\n  &:last-child {\n    border-bottom: none;\n  }\n  &:hover,\n  &:active {\n    background-color: ", ";\n    transition: background-color 200ms;\n  }\n\n  ", "\n"]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      display: block;\n      opacity: 1;\n      transition: opacity 200ms 2ms;\n    "]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 0.1rem;\n  box-shadow: 0 16px 30px -12px rgba(0, 0, 0, 0.3);\n  display: none;\n  height: 8rem;\n  opacity: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  position: absolute;\n  top: 0.4rem;\n  transition: opacity 200ms 2ms;\n  width: 10rem;\n  z-index: 200;\n\n  ", "\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0);\n  border-radius: 0.1rem;\n  cursor: pointer;\n  padding: 0.25rem 0.2rem 0.2rem 0.7rem;\n  transition: background-color 200ms;\n  z-index: 100;\n  &:hover,\n  &:active {\n    background-color: rgba(255, 255, 255, 0.1);\n    transition: background-color 200ms;\n  }\n\n  & > p {\n    color: ", ";\n    font-size: 0.9rem;\n    font-weight: 600;\n    margin: 0rem 0.3rem 0rem 0rem;\n    text-align: center;\n    user-select: none;\n  }\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  flex: 1;\n  justify-content: center;\n  position: relative;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 0.7rem;\n  width: 0.7rem;\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      opacity: 0.4;\n      pointer-events: none;\n    "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0);\n  border-radius: 0.8rem;\n  cursor: pointer;\n  height: 1.6rem;\n  justify-content: center;\n  transition: background-color 200ms;\n  width: 1.6rem;\n  &:hover,\n  &:active {\n    background-color: rgba(255, 255, 255, 0.3);\n    transition: background-color 200ms;\n  }\n\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 0.2rem 0rem;\n  width: 100%;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.2rem 0.2rem 0rem 0rem;\n  height: 3rem;\n  justify-content: center;\n  padding: 0.2rem 0.4rem;\n  width: 100%;\n  z-index: 100;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  border-radius: 0.25rem;\n  box-shadow: 0rem 1.25rem 2.5rem 0.5rem ", ";\n  display: ", ";\n  left: 0rem;\n  max-height: 15rem;\n  min-width: 16rem;\n  position: absolute;\n  right: 0rem;\n  top: 3.5rem;\n  z-index: 100;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  cursor: ", ";\n  flex-grow: 1;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0.5rem 0.5rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  height: 3.25rem;\n  padding: 0.5rem 1rem;\n  position: relative;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OuterWrapperTemp = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var StyledFlexRowNoWrap = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.isDisabled ? "not-allowed" : "pointer";
});

var Label = _styledComponents["default"].span(_templateObject4(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.isPlaceholder && (0, _styledComponents.css)(_templateObject5(), props.theme.aliceBlue);
});

var DropdownWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return (0, _polished.rgba)(props.theme.darkGunmetalBlack, 0.15);
}, function (props) {
  return props.isOpen ? "flex" : "none";
});

var DropdownHeader = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
});

var DropdownContent = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.white;
});

var ArrowButton = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return !props.isEnabled && (0, _styledComponents.css)(_templateObject10());
});

var DropdownArrowButton = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.flexRowNoWrap;
});

var StyledArrowRight = (0, _styledComponents["default"])(_reactFeather.ArrowRight)(_templateObject12(), function (props) {
  return props.theme.white;
});
var StyledArrowLeft = (0, _styledComponents["default"])(_reactFeather.ArrowLeft)(_templateObject13(), function (props) {
  return props.theme.white;
});
var StyledArrowDown = (0, _styledComponents["default"])(_reactFeather.ChevronDown)(_templateObject14(), function (props) {
  return props.theme.white;
});

var MonthPicker = _styledComponents["default"].div(_templateObject15(), function (props) {
  return props.theme.flexRowNoWrap;
});

var MonthTitle = _styledComponents["default"].div(_templateObject16(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
});

var MonthDropdown = _styledComponents["default"].div(_templateObject17(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.isOpen && (0, _styledComponents.css)(_templateObject18());
});

var MonthRow = _styledComponents["default"].div(_templateObject19(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject20(), props.theme.darkGunmetalBlack);
});

var DayPicker = _styledComponents["default"].div(_templateObject21());

var DayCell = _styledComponents["default"].div(_templateObject22(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.isActive && (0, _styledComponents.css)(_templateObject23(), props.theme.white, props.theme.darkGunmetalBlack, props.theme.darkGunmetalBlack);
});

var OuterWrapper = (0, _styledComponents["default"])(OuterWrapperTemp)(_templateObject24(), function (props) {
  return props.isSmall && (0, _styledComponents.css)(_templateObject25(), Label);
}, function (props) {
  return props.isCleanStyle && (0, _styledComponents.css)(_templateObject26(), props.theme.mediaWidth.upToMedium(_templateObject27()));
});

function buildMonthRowSource(month, year) {
  return {
    year: year,
    month: month,
    label: "".concat((0, _dayjs["default"])(year + "-" + month).format("MMMM"), " ").concat(year)
  };
}

function getParsedDate(date) {
  var isMin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if ((0, _typy["default"])(date).isFalsy) {
    return isMin ? (0, _dayjs["default"])().subtract(3, "year") : (0, _dayjs["default"])().add(3, "year");
  } else if (date === "today") {
    return (0, _dayjs["default"])();
  } else {
    return (0, _dayjs["default"])(date, _devConstants.DATE_FORMAT);
  }
}
/**
 * 🚨 Because `dayjs` counts month indexes from 0 to 11 (instead of 1 to 12) there will be a correction
 * every time we convert from dayjs format to readable format (or the one stored inside the reducer)
 */


function DateInput(_ref) {
  var date = _ref.date,
      isCleanStyle = _ref.isCleanStyle,
      isSmall = _ref.isSmall,
      minDate = _ref.minDate,
      maxDate = _ref.maxDate,
      onUpdateDate = _ref.onUpdateDate,
      readOnly = _ref.readOnly,
      shouldGoIdle = _ref.shouldGoIdle;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      viewedMonthIndex = _useState2[0],
      setViewedMonthIndex = _useState2[1];

  var wrapperRef = (0, _react.useRef)(null);
  var monthPickerRef = (0, _react.useRef)(null);

  var _useMachine = (0, _react2.useMachine)(_machine["default"]),
      _useMachine2 = (0, _slicedToArray2["default"])(_useMachine, 2),
      currentMachine = _useMachine2[0],
      sendToMachine = _useMachine2[1];

  var parsedMinDate = (0, _react.useMemo)(function () {
    return getParsedDate(minDate, true);
  }, [minDate]);
  var parsedMaxDate = (0, _react.useMemo)(function () {
    return getParsedDate(maxDate, false);
  }, [maxDate]);
  var monthSource = (0, _react.useMemo)(function () {
    var months = Math.abs(parsedMaxDate.diff(parsedMinDate, "month"));
    var m = parsedMinDate.month();
    var y = parsedMinDate.year();
    var result = [];
    (0, _toConsumableArray2["default"])(Array(months + 1).keys()).forEach(function () {
      if (!(y === parsedMinDate.year() && m < parsedMinDate.month())) {
        result.push(buildMonthRowSource(m + 1, y));
      }

      if (m === 11) {
        m = 0;
        y += 1;
      } else {
        m += 1;
      }
    });
    return result;
  }, [parsedMinDate, parsedMaxDate]);
  var viewedMonth = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(monthSource, "[".concat(viewedMonthIndex, "]")).safeObject;
  }, [monthSource, viewedMonthIndex]);
  var daySource = (0, _react.useMemo)(function () {
    if ((0, _typy["default"])(viewedMonth, "month").isNullOrUndefined || (0, _typy["default"])(viewedMonth, "year").isNullOrUndefined) {
      return 0;
    }

    return (0, _dayjs["default"])().month((0, _typy["default"])(viewedMonth, "month").safeNumber - 1).year((0, _typy["default"])(viewedMonth, "year").safeNumber).daysInMonth();
  }, [viewedMonth]);
  /** Special Events **/

  (0, _useOnclickoutside["default"])(wrapperRef, function () {
    sendToMachine("IDLE");
  });
  (0, _useOnclickoutside["default"])(monthPickerRef, function () {
    if (currentMachine.matches("collapsed")) {
      sendToMachine("MONTH_IDLE");
    }
  });
  /** Callbacks **/

  var onClickWrapper = (0, _react.useCallback)(function () {
    if (!readOnly) {
      if (currentMachine.matches("idle")) {
        sendToMachine("COLLAPSE");
      } else if (currentMachine.matches("collapsed")) {
        sendToMachine("IDLE");
      }
    }
  }, [currentMachine, readOnly, sendToMachine]);
  var onClickMonthPicker = (0, _react.useCallback)(function () {
    if (!readOnly) {
      if (currentMachine.matches("collapsed.idle")) {
        sendToMachine("MONTH_COLLAPSE");
      } else if (currentMachine.matches("collapsed.collapsed")) {
        sendToMachine("MONTH_IDLE");
      }
    }
  }, [currentMachine, readOnly, sendToMachine]);
  var onClickArrow = (0, _react.useCallback)(function () {
    var left = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (!readOnly && !currentMachine.matches("collapsed.collapsed")) {
      var move = null;

      if (left && viewedMonthIndex > 0) {
        move = viewedMonthIndex - 1;
      } else if (!left && viewedMonthIndex < monthSource.length - 1) {
        move = viewedMonthIndex + 1;
      }

      if (move !== null) {
        setViewedMonthIndex(move);
      }
    }
  }, [currentMachine, monthSource, readOnly, viewedMonthIndex]);
  var onClickArrowLeft = (0, _react.useCallback)(function () {
    onClickArrow(true);
  }, [onClickArrow]);
  var onClickArrowRight = (0, _react.useCallback)(function () {
    onClickArrow(false);
  }, [onClickArrow]);
  var onClickMonthRow = (0, _react.useCallback)(function (source, index) {
    if ((0, _typy["default"])(viewedMonth, "month").safeNumber !== source.month || (0, _typy["default"])(viewedMonth, "year").safeNumber !== source.year) {
      setViewedMonthIndex(index);
    }

    sendToMachine("MONTH_IDLE");
  }, [sendToMachine, viewedMonth]);
  var onDatePick = (0, _react.useCallback)(function (data) {
    if (readOnly) {
      return;
    }

    if ((0, _typy["default"])(data).isFalsy) {
      onUpdateDate(_objectSpread({}, _state.date));
      sendToMachine("IDLE");
      return;
    }

    var year = data.year,
        month = data.month,
        day = data.day;
    var picked = (0, _dayjs["default"])().year(year).month(month - 1).date(day).hour(0).minute(0).second(0).millisecond(0);
    var label = picked.format("MMMM DD, YYYY");
    var totalSeconds = new _bignumber.BigNumber(picked.unix());
    var payload = {
      day: day,
      month: month,
      year: year,
      label: label,
      totalSeconds: totalSeconds
    };
    onUpdateDate(_objectSpread({}, payload));
    sendToMachine("IDLE");
  }, [readOnly, onUpdateDate, sendToMachine]);
  var onResetViewedMonth = (0, _react.useCallback)(function () {
    var index = monthSource.findIndex(function (_ref2) {
      var month = _ref2.month,
          year = _ref2.year;
      return month === (0, _typy["default"])(date, "month").safeNumber && year === (0, _typy["default"])(date, "year").safeNumber;
    });

    if (index !== viewedMonthIndex) {
      setViewedMonthIndex(index < 1 ? 0 : index);
    }
  }, [monthSource, date, viewedMonthIndex]);
  /** Effects **/

  (0, _react.useEffect)(function () {
    if (currentMachine.matches("idle")) {
      onResetViewedMonth();
    }
  }, [currentMachine, onResetViewedMonth]);
  /** When the dropdown is closed, revert viewed month to the chosen month */

  (0, _reactHooks.useEffectWithDefaultDelay)({
    condition: shouldGoIdle,
    func: function func() {
      sendToMachine("IDLE");
    }
  });
  var monthPickerLabel = (0, _react.useMemo)(function () {
    if ((0, _typy["default"])(viewedMonth).isTruthy) {
      return (0, _dayjs["default"])().year((0, _typy["default"])(viewedMonth, "year").safeNumber).month((0, _typy["default"])(viewedMonth, "month").safeNumber - 1).format("MMMM YYYY");
    } else {
      return "";
    }
  }, [viewedMonth]);
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OuterWrapper, {
    ref: wrapperRef,
    isCleanStyle: isCleanStyle,
    isSmall: isSmall
  }, _react["default"].createElement(StyledFlexRowNoWrap, {
    onClick: onClickWrapper
  }, _react["default"].createElement(Label, {
    isPlaceholder: (0, _typy["default"])(date, "label").isFalsy
  }, (0, _typy["default"])(date, "label").safeString || translation("structs.datePlaceholder"))), _react["default"].createElement(DropdownWrapper, {
    isOpen: currentMachine.matches("collapsed")
  }, _react["default"].createElement(DropdownHeader, null, _react["default"].createElement(ArrowButton, {
    onClick: onClickArrowLeft,
    isEnabled: viewedMonthIndex > 0
  }, _react["default"].createElement(StyledArrowLeft, null)), _react["default"].createElement(MonthPicker, null, _react["default"].createElement(MonthTitle, {
    onClick: onClickMonthPicker
  }, _react["default"].createElement("p", null, monthPickerLabel), _react["default"].createElement(DropdownArrowButton, null, _react["default"].createElement(StyledArrowDown, null))), _react["default"].createElement(MonthDropdown, {
    ref: monthPickerRef,
    isOpen: currentMachine.matches("collapsed.collapsed")
  }, monthSource.map(function (month, index) {
    return _react["default"].createElement(MonthRow, {
      key: month.label,
      onClick: function onClick() {
        return onClickMonthRow(month, index);
      },
      isActive: (0, _typy["default"])(viewedMonthIndex).safeNumber === index
    }, _react["default"].createElement("p", null, month.label));
  }))), _react["default"].createElement(ArrowButton, {
    onClick: onClickArrowRight,
    isEnabled: viewedMonthIndex < monthSource.length - 1
  }, _react["default"].createElement(StyledArrowRight, null))), _react["default"].createElement(DropdownContent, null, _react["default"].createElement(DayPicker, null, (0, _toConsumableArray2["default"])(Array(daySource).keys()).map(function (day) {
    return _react["default"].createElement(DayCell, {
      key: day,
      isActive: (0, _typy["default"])(date, "year").safeNumber === (0, _typy["default"])(viewedMonth, "year").safeNumber && (0, _typy["default"])(date, "month").safeNumber === (0, _typy["default"])(viewedMonth, "month").safeNumber && (0, _typy["default"])(date, "day").safeNumber === day + 1,
      onClick: function onClick() {
        onDatePick({
          year: (0, _typy["default"])(viewedMonth, "year").safeNumber,
          month: (0, _typy["default"])(viewedMonth, "month").safeNumber,
          day: day + 1
        });
      }
    }, _react["default"].createElement("p", null, day + 1));
  }), _react["default"].createElement(DayCell, {
    onClick: function onClick() {
      return onDatePick(null);
    }
  }, _react["default"].createElement("p", null, "x")))))), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(date, "error").safeString
  }));
}

DateInput.propTypes = {
  isCleanStyle: _propTypes["default"].bool,
  isSmall: _propTypes["default"].bool,
  date: _propTypes["default"].shape({
    label: _propTypes["default"].string
  }),
  minDate: _propTypes["default"].string,
  maxDate: _propTypes["default"].string,
  onUpdateDate: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool,
  shouldGoIdle: _propTypes["default"].bool
};
DateInput.defaultProps = {
  isCleanStyle: false,
  isSmall: false,
  readOnly: false,
  shouldGoIdle: false
};
var _default = DateInput;
exports["default"] = _default;