"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = WalletModal;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _connectors = require("@sablier/connectors");

var _Fortmatic = require("@sablier/connectors/lib/Fortmatic");

var _walletconnectConnector = require("@web3-react/walletconnect-connector");

var _theme = require("@sablier/theme");

var _reactDeviceDetect = require("react-device-detect");

var _vendors = require("@sablier/vendors");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _contexts = require("@sablier/contexts");

var _core = require("@web3-react/core");

var _AccountDetails = _interopRequireDefault(require("./AccountDetails"));

var _Modal = _interopRequireDefault(require("../Modal"));

var _Option = _interopRequireDefault(require("./Option"));

var _PendingView = _interopRequireDefault(require("./PendingView"));

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    grid-gap: 0.6125rem;\n    grid-template-columns: 1fr;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: grid;\n  grid-gap: 0.6125rem;\n  grid-template-columns: 1fr 1fr;\n  margin-top: 0.25rem;\n\n  ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 1rem;\n    font-size: 12px;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n  margin-top: 2rem;\n\n  & a {\n    color: ", ";\n  }\n\n  ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 2rem;\n  ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  text-transform: capitalize;\n\n  &:active,\n  &:hover {\n    cursor: pointer;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  font-size: 1.1875rem;\n  font-weight: 500;\n  margin: 0rem;\n  padding: 1.5rem;\n  text-transform: capitalize;\n\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  position: relative;\n\n  h5 {\n    font-size: 1rem;\n    font-weight: 400;\n    margin: 0rem;\n    margin-bottom: 0.5rem;\n  }\n\n  h5:last-child {\n    margin-bottom: 0rem;\n  }\n\n  h4 {\n    font-weight: 500;\n    margin-top: 0rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  background-color: ", ";\n  margin: 0rem;\n  padding: 0rem;\n  width: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  user-select: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledModal = (0, _styledComponents["default"])(_Modal["default"])(_templateObject());

var OuterWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.white;
});

var InnerWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.snowWhite;
});

var TitleLabel = _styledComponents["default"].h4(_templateObject4(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
});

var HoverLabel = _styledComponents["default"].div(_templateObject6());

var ContentWrapper = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject8());
});

var Blurb = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.bleuDeFranceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject10());
});

var OptionGrid = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});

var WALLET_VIEWS = Object.freeze({
  account: "account",
  options: "options",
  optionsSecondary: "optionsSecondary",
  pending: "pending"
});
var DIALOG_LABEL_ID = "wallet-modal-label";

function WalletModal() {
  var _useWeb3React = (0, _core.useWeb3React)(),
      activate = _useWeb3React.activate,
      active = _useWeb3React.active,
      account = _useWeb3React.account,
      connector = _useWeb3React.connector,
      error = _useWeb3React.error;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var activePrevious = (0, _reactHooks.usePrevious)(active);
  var connectorPrevious = (0, _reactHooks.usePrevious)(connector);

  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      pendingError = _useState2[0],
      setPendingError = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      pendingWallet = _useState4[0],
      setPendingWallet = _useState4[1];

  var _useState5 = (0, _react.useState)(),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      uri = _useState6[0],
      setUri = _useState6[1];

  var _useState7 = (0, _react.useState)(WALLET_VIEWS.account),
      _useState8 = (0, _slicedToArray2["default"])(_useState7, 2),
      walletView = _useState8[0],
      setWalletView = _useState8[1];

  var _useWalletModalManage = (0, _contexts.useWalletModalManager)(),
      isWalletModalOpen = _useWalletModalManage.isOpen,
      toggleWalletModal = _useWalletModalManage.toggle;
  /** Callbacks **/


  var tryActivation = (0, _react.useCallback)(function _callee(connectorToActivate) {
    var name;
    return _regenerator["default"].async(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            name = "";
            Object.keys(_connectors.SUPPORTED_WALLETS).map(function (key) {
              if (connectorToActivate === _connectors.SUPPORTED_WALLETS[key].connector) {
                name = translation(_connectors.SUPPORTED_WALLETS[key].name);
              }

              return true;
            });

            _vendors.mixpanel.track("Change Wallet", {
              name: name
            });
            /* Set wallet for pending view */


            setPendingWallet(connectorToActivate);
            setWalletView(WALLET_VIEWS.pending);
            activate(connectorToActivate, undefined, true)["catch"](function (connectorError) {
              if (connectorError instanceof _core.UnsupportedChainIdError) {
                activate(connectorToActivate);
                /* a little janky...can't use setError because the connector isn't set */
              } else {
                setPendingError(true);
              }
            });

          case 6:
          case "end":
            return _context.stop();
        }
      }
    });
  }, [activate, translation]);
  /** Side Effects **/

  /* Always reset to account view */

  (0, _react.useEffect)(function () {
    if (isWalletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.account);
    }
  }, [isWalletModalOpen]);
  /* Set up uri listener for walletconnect */

  (0, _react.useEffect)(function () {
    var activateWC = function activateWC(newUri) {
      setUri(newUri);
    };

    _connectors.walletconnect.on(_walletconnectConnector.URI_AVAILABLE, activateWC);

    return function () {
      _connectors.walletconnect.off(_walletconnectConnector.URI_AVAILABLE, activateWC);
    };
  }, []);
  /**
   * Close wallet modal if fortmatic modal is active
   * Warning: without this, we get this effing bug: https://imgur.com/a/iGap2UL
   */

  (0, _react.useEffect)(function () {
    _connectors.fortmatic.on(_Fortmatic.OVERLAY_READY, function () {
      toggleWalletModal();
    });
  }, [toggleWalletModal]);
  /* TODO: Find a smarter, less janky way to do this */

  (0, _react.useEffect)(function () {
    var isFreshlyActive = active && !activePrevious;
    var isFreshlyConnected = (0, _typy["default"])(connector).isTruthy && connector !== connectorPrevious && (0, _typy["default"])(error).isFalsy;

    if (isWalletModalOpen && (isFreshlyActive || isFreshlyConnected)) {
      setWalletView(WALLET_VIEWS.account);
    }
  }, [active, activePrevious, connector, connectorPrevious, error, isWalletModalOpen, setWalletView]);
  /* Get wallets user can switch too, depending on device/browser */

  var renderOptions = (0, _react.useCallback)(function () {
    var isEthereumFalsy = (0, _typy["default"])(window, "ethereum").isFalsy;
    var isWeb3Falsy = (0, _typy["default"])(window, "web3").isFalsy;
    var isMetaMask = (0, _typy["default"])(window, "ethereum").isTruthy && (0, _typy["default"])(window, "ethereum.isMetaMask").isTruthy;
    return Object.keys(_connectors.SUPPORTED_WALLETS).map(function (key) {
      var option = _connectors.SUPPORTED_WALLETS[key];
      /* Check for mobile options */

      if (_reactDeviceDetect.isMobile) {
        /* Disable portis on mobile for now */
        if (option.connector === _connectors.portis) {
          return null;
        }

        if (isEthereumFalsy && isWeb3Falsy && (0, _typy["default"])(option, "mobile").isTrue) {
          return _react["default"].createElement(_Option["default"], {
            color: option.color,
            isActive: option.connector && option.connector === connector,
            key: key,
            link: option.href,
            icon: option.icon,
            onClick: function onClick() {
              return option.connector !== connector && (0, _typy["default"])(option, "href").isFalsy && tryActivation(option.connector);
            },
            title: translation(option.name),
            subtitle: null
          });
        } else {
          return null;
        }
      }
      /* Overwrite injected when needed */


      if (option.connector === _connectors.injected) {
        /* Don't show injected if there's no injected provider */
        if (isWeb3Falsy && isEthereumFalsy) {
          if (option.name === "words.metamask") {
            return _react["default"].createElement(_Option["default"], {
              key: key,
              color: _theme.colors.carrotOrange,
              link: "https://metamask.io",
              icon: require("@sablier/assets/images/icon-metamask.png"),
              subtitle: null,
              title: translation("modals.wallet.metamask.title")
            });
          } else {
            /* Don't want to return install twice */
            return null;
          }
        } else if (option.name === "words.metamask" && !isMetaMask) {
          /* Don't return metamask if injected provider isn't metamask */
          return null;
        } else if (option.name === "words.injected" && isMetaMask) {
          /* Likewise for generic */
          return null;
        }
      }
      /* Rest of options */


      if (_reactDeviceDetect.isMobile || (0, _typy["default"])(option, "mobileOnly").isTrue) {
        return null;
      } else {
        return _react["default"].createElement(_Option["default"], {
          color: option.color,
          key: key,
          icon: option.icon,
          isActive: option.connector === connector,
          link: option.href,
          onClick: function onClick() {
            return option.connector === connector ? setWalletView(WALLET_VIEWS.account) : (0, _typy["default"])(option, "href").isFalsy && tryActivation(option.connector);
          },
          subtitle: null
          /* Use option.description to bring back multi-line */
          ,
          title: translation(option.name)
        });
      }
    });
  }, [connector, tryActivation, translation]);
  var render = (0, _react.useCallback)(function () {
    if ((0, _typy["default"])(error).isTruthy) {
      _vendors.mixpanel.track('Error in "WalletModal"', {
        error: (0, _typy["default"])(error, "toString").isFunction ? error.toString() : error
      });

      return _react["default"].createElement(InnerWrapper, null, _react["default"].createElement(_theme.TopRightCloseIcon, {
        onClick: toggleWalletModal
      }), _react["default"].createElement(TitleLabel, null, error instanceof _core.UnsupportedChainIdError ? translation("words.wrong") + " " + translation("words.network") : translation("words.error")), _react["default"].createElement(ContentWrapper, null, error instanceof _core.UnsupportedChainIdError ? _react["default"].createElement("h5", null, translation("modals.wallet.pleaseConnect")) : translation("structs.unknownError")));
    }

    if ((0, _typy["default"])(account).isTruthy && walletView === WALLET_VIEWS.account) {
      return _react["default"].createElement(_AccountDetails["default"], {
        openOptions: function openOptions() {
          return setWalletView(WALLET_VIEWS.options);
        },
        toggleWalletModal: toggleWalletModal
      });
    }

    return _react["default"].createElement(InnerWrapper, null, walletView !== WALLET_VIEWS.account ? _react["default"].createElement(TitleLabel, {
      onClick: function onClick() {
        setPendingError(false);
        setWalletView(WALLET_VIEWS.account);
      }
    }, _react["default"].createElement(HoverLabel, null, translation("words.back"))) : _react["default"].createElement(TitleLabel, null, _react["default"].createElement(HoverLabel, null, translation("structs.signIn"))), _react["default"].createElement(_theme.TopRightCloseIcon, {
      onClick: toggleWalletModal
    }), _react["default"].createElement(ContentWrapper, null, walletView !== WALLET_VIEWS.pending ? _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(OptionGrid, null, renderOptions()), _react["default"].createElement(Blurb, null, _react["default"].createElement("span", null, translation("modals.wallet.newToEthereum") + ""), "\xA0", _react["default"].createElement(_theme.Link, {
      href: "https://ethereum.org/use/#3-what-is-a-wallet-and-which-one-should-i-use"
    }, translation("modals.wallet.learnMore")))) : _react["default"].createElement(_PendingView["default"], {
      connector: pendingWallet,
      error: pendingError,
      setPendingError: setPendingError,
      size: 220,
      tryActivation: tryActivation,
      uri: uri
    })));
  }, [account, error, pendingError, pendingWallet, renderOptions, setPendingError, toggleWalletModal, translation, tryActivation, uri, walletView]);
  return _react["default"].createElement(StyledModal, {
    dialogLabelId: DIALOG_LABEL_ID,
    isOpen: isWalletModalOpen,
    onDismiss: toggleWalletModal,
    maxHeight: 90,
    minHeight: null
  }, _react["default"].createElement(OuterWrapper, null, render()));
}