"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 1rem;\n  display: flex;\n  height: 1.5rem;\n  justify-content: center;\n  width: 1.5rem;\n\n  & > p {\n    color: ", ";\n    font-family: ", ";\n    font-size: 0.8125rem;\n    font-weight: 500;\n    text-align: center;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    &[data-mobile-force=\"top-left\"] {\n      bottom: 1.25rem;\n      left: initial;\n      right: 1.25rem;\n      top: initial;\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background: ", ";\n  border-radius: 0.25rem;\n  box-shadow: 0 1rem 2rem -0.8rem rgba(0, 0, 0, 0.1);\n  margin-left: -0.3rem;\n  max-height: 18rem;\n  opacity: 0;\n  order: 2;\n  overflow-y: auto;\n  padding: 1rem;\n  pointer-events: none;\n  position: absolute;\n  top: 2.5rem;\n  transition: opacity 0s;\n  transition: opacity 150ms;\n  width: 15rem;\n  z-index: 8;\n\n  & > p {\n    color: ", ";\n    font-family: ", ";\n    font-size: 0.8125rem;\n    font-weight: 400;\n    margin: 0;\n    text-align: left;\n  }\n\n  &[data-visible=\"true\"],\n  &:active,\n  &:focus,\n  &:hover {\n    opacity: 1;\n    pointer-events: all;\n    transition: opacity 150ms 100ms;\n  }\n\n  &[data-force=\"bottom\"] {\n    bottom: initial;\n    left: initial;\n    right: initial;\n    top: 2rem;\n  }\n\n  &[data-force=\"bottom-right\"] {\n    bottom: initial;\n    left: 2rem;\n    right: initial;\n    top: 2rem;\n  }\n\n  &[data-force=\"left\"] {\n    bottom: initial;\n    left: initial;\n    right: 2rem;\n    top: 0;\n  }\n\n  &[data-force=\"right\"] {\n    bottom: initial;\n    left: 2rem;\n    right: initial;\n    top: 0;\n  }\n\n  &[data-force=\"top\"] {\n    bottom: 2rem;\n    left: initial;\n    right: initial;\n    top: initial;\n  }\n\n  &[data-force=\"top-right\"] {\n    bottom: 2rem;\n    left: 2rem;\n    right: initial;\n    top: initial;\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  cursor: pointer;\n  position: relative;\n  z-index: 4;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject());

var Tooltip = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

var DefaultWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.catamaranFont;
});

function DefaultIcon() {
  return _react["default"].createElement(DefaultWrapper, null, _react["default"].createElement("p", null, "i"));
}

function InfoBox(_ref) {
  var title = _ref.title,
      help = _ref.help,
      force = _ref.force,
      forceMobile = _ref.forceMobile,
      children = _ref.children,
      useDefault = _ref.useDefault,
      className = _ref.className;
  var tooltipRef = (0, _react.useRef)();

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      visible = _useState2[0],
      setVisible = _useState2[1];

  (0, _react.useEffect)(function () {
    var handleTouch = function handleTouch(event) {
      try {
        if ((0, _typy["default"])(tooltipRef).isTruthy && !tooltipRef.current.contains(event.target)) {
          if (visible) {
            setVisible(false);
          }
        }
      } catch (e) {
        console.warn(e);
      }
    };

    if ((0, _typy["default"])(help).isTruthy) {
      document.addEventListener("mousedown", handleTouch);
      document.addEventListener("touchstart", handleTouch);
    }

    return function () {
      if ((0, _typy["default"])(help).isTruthy) {
        document.removeEventListener("mousedown", handleTouch);
        document.removeEventListener("touchstart", handleTouch);
      }
    };
  });
  return _react["default"].createElement(Wrapper, {
    className: className,
    onClick: function onClick() {
      setVisible(function (v) {
        return !v;
      });
    },
    title: title
  }, useDefault ? _react["default"].createElement(DefaultIcon, null) : children, (0, _typy["default"])(help).isTruthy ? _react["default"].createElement(Tooltip, {
    "data-force": force,
    "data-mobile-force": forceMobile,
    "data-visible": visible,
    ref: tooltipRef
  }, _react["default"].createElement("p", null, help)) : null);
}

InfoBox.propTypes = {
  children: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].node]),
  className: _propTypes["default"].string,
  force: _propTypes["default"].oneOf(["bottom", "bottom-left", "bottom-right", "left", "right", "top", "top-left", "top-right"]),
  forceMobile: _propTypes["default"].string,
  help: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].node, _propTypes["default"].shape({})]),
  title: _propTypes["default"].string,
  useDefault: _propTypes["default"].bool
};
InfoBox.defaultProps = {
  force: "top-right",
  help: null,
  forceMobile: "top-left",
  title: "",
  children: DefaultIcon,
  useDefault: false,
  className: null
};
var _default = InfoBox;
exports["default"] = _default;