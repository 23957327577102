"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _BorderedCross = _interopRequireDefault(require("../BorderedCross"));

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  height: 1px;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin: 0rem;\n  text-transform: capitalize;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", ";\n    padding: 1.5rem 1rem 1.25rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: space-between;\n  padding: 1.5rem 1.5rem 1.25rem;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var TopWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3(), props.theme.white);
});

var TitleLabel = _styledComponents["default"].h2(_templateObject4());

var Separator = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.aliceBlue;
});

function TitleAndBorderedCross(_ref) {
  var children = _ref.children,
      onClickBorderedCross = _ref.onClickBorderedCross,
      title = _ref.title,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "onClickBorderedCross", "title"]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement(TopWrapper, null, _react["default"].createElement(TitleLabel, null, title), children, _react["default"].createElement(_BorderedCross["default"], {
    onClick: onClickBorderedCross
  })), _react["default"].createElement(Separator, null));
}

TitleAndBorderedCross.propTypes = {
  onClickBorderedCross: _propTypes["default"].func,
  title: _propTypes["default"].string
};
TitleAndBorderedCross.defaultProps = {
  onClickBorderedCross: function onClickBorderedCross() {},
  title: "Title"
};
var _default = TitleAndBorderedCross;
exports["default"] = _default;