"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _utils = require("@sablier/utils");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

function Status(_ref) {
  var _children = _ref.children,
      forRecipient = _ref.forRecipient,
      forSender = _ref.forSender,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "forRecipient", "forSender"]);

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useProxyStream = (0, _reactHooks.useProxyStream)(),
      proxyStream = _useProxyStream.proxyStream;

  var senderENSName = (0, _reactHooks.useENSName)((0, _typy["default"])(proxyStream, "sender").safeEthereumAddress);
  var recipientENSName = (0, _reactHooks.useENSName)((0, _typy["default"])(proxyStream, "recipient").safeEthereumAddress);
  /** Memoized Values **/

  var prefix = (0, _react.useMemo)(function () {
    if (forRecipient) {
      return "pages.stream.status.recipient.";
    }

    if (forSender) {
      return "pages.stream.status.sender.";
    }

    return "";
  }, [forRecipient, forSender]);
  return _react["default"].createElement("span", otherProps, function () {
    switch ((0, _typy["default"])(proxyStream, "stream.status").safeNumber) {
      case _devConstants.STREAM_STATUS.created:
        {
          return translation(prefix + "created", {
            startTimeLabel: (0, _typy["default"])(proxyStream, "stream.timeLabels.startTime").safeString
          });
        }

      case _devConstants.STREAM_STATUS.streaming:
        {
          return translation(prefix + "streaming", {
            ratePerSecond: (0, _typy["default"])(proxyStream, "stream.bundles.ratePerSecond.cash").safeObject.toString(10),
            recipient: recipientENSName || (0, _utils.shortenAddress)({
              address: (0, _typy["default"])(proxyStream, "recipient").safeEthereumAddress
            }),
            sender: senderENSName || (0, _utils.shortenAddress)({
              address: (0, _typy["default"])(proxyStream, "sender").safeEthereumAddress
            }),
            tokenSymbol: (0, _typy["default"])(proxyStream, "stream.token.symbol").safeTokenSymbol
          });
        }

      case _devConstants.STREAM_STATUS.cancelled:
        {
          return translation(prefix + "cancelled", {
            cancellationTimeLabel: (0, _typy["default"])(proxyStream, "stream.cancellation.timeLabel").safeString,
            recipient: recipientENSName || (0, _typy["default"])(proxyStream, "recipient").safeEthereumAddress,
            recipientAmount: (0, _typy["default"])(proxyStream, "stream.cancellation.recipientAmount").safeNumber,
            sender: senderENSName || (0, _utils.shortenAddress)({
              address: (0, _typy["default"])(proxyStream, "sender").safeEthereumAddress
            }),
            senderAmount: (0, _typy["default"])(proxyStream, "stream.cancellation.senderAmount").safeNumber,
            startTimeLabel: (0, _typy["default"])(proxyStream, "stream.timeLabels.startTime").safeString
          });
        }

      case _devConstants.STREAM_STATUS.ended:
        {
          return translation(prefix + "ended", {
            amount: (0, _typy["default"])(proxyStream, "stream.bundles.deposit.cash").safeString,
            recipient: recipientENSName || (0, _utils.shortenAddress)({
              address: (0, _typy["default"])(proxyStream, "recipient").safeEthereumAddress
            }),
            stopTimeLabel: (0, _typy["default"])(proxyStream, "stream.timeLabels.stopTime").safeString,
            tokenSymbol: (0, _typy["default"])(proxyStream, "stream.token.symbol").safeTokenSymbol
          });
        }

      default:
        return null;
    }
  }(), ".");
}

Status.propTypes = {
  forRecipient: _propTypes["default"].bool,
  forSender: _propTypes["default"].bool
};
Status.defaultProps = {
  forRecipient: false,
  forSender: false
};
var _default = Status;
exports["default"] = _default;