"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  safeAccess: true,
  capitalize: true,
  i18n: true
};
exports.safeAccess = safeAccess;
exports.capitalize = capitalize;
Object.defineProperty(exports, "i18n", {
  enumerable: true,
  get: function get() {
    return _i18n["default"];
  }
});

var _errors = require("./errors");

Object.keys(_errors).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _errors[key];
    }
  });
});

var _ethereum = require("./ethereum");

Object.keys(_ethereum).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ethereum[key];
    }
  });
});

var _i18n = _interopRequireDefault(require("./i18n"));

var _logger = require("./logger");

Object.keys(_logger).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _logger[key];
    }
  });
});

var _numbers = require("./numbers");

Object.keys(_numbers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _numbers[key];
    }
  });
});

var _signer = require("./signer");

Object.keys(_signer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _signer[key];
    }
  });
});

var _time = require("./time");

Object.keys(_time).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _time[key];
    }
  });
});
/* purposely omitting the bignumber config */

/* purposely omitting google analytics */

/* purposely omitting the types */

function safeAccess(object, path) {
  if (object) {
    return path.reduce(function (accumulator, currentValue) {
      return accumulator && accumulator[currentValue] ? accumulator[currentValue] : null;
    }, object);
  } else {
    return null;
  }
}

function capitalize(source) {
  if (typeof source !== "string") {
    return "";
  }

  try {
    return source.charAt(0).toUpperCase() + source.slice(1);
  } catch (_e) {
    return "";
  }
}