"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _theme = require("@sablier/theme");

var _reactHooks = require("@sablier/react-hooks");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: ", "rem solid ", ";\n    border-top: ", "rem solid ", ";\n    height: ", "rem;\n    width: ", "rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  ", ";\n  animation: spin 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.85) infinite;\n  border-radius: 50%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return (0, _styledComponents.css)(_templateObject2(), props.size / 24, props.borderColor, props.size / 12, props.color, props.size, props.size);
});

function Spinner(_ref) {
  var borderColor = _ref.borderColor,
      color = _ref.color,
      delay = _ref.delay,
      size = _ref.size,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["borderColor", "color", "delay", "size"]);

  var _useShowSpinner = (0, _reactHooks.useShowSpinner)(delay),
      _useShowSpinner2 = (0, _slicedToArray2["default"])(_useShowSpinner, 1),
      showSpinner = _useShowSpinner2[0];

  return showSpinner ? _react["default"].createElement(Wrapper, (0, _extends2["default"])({
    borderColor: borderColor,
    color: color,
    size: size
  }, otherProps)) : null;
}

Spinner.propTypes = {
  borderColor: _propTypes["default"].string,
  color: _propTypes["default"].string,
  delay: _propTypes["default"].number,
  size: _propTypes["default"].number
};
Spinner.defaultProps = {
  borderColor: _theme.colors.aliceBlue,
  color: _theme.colors.primaryColor,
  delay: 750,
  size: 3
};
var _default = Spinner;
exports["default"] = _default;