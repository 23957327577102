"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _Label = _interopRequireDefault(require("../../Label"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    margin-right: 0.5rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: none;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      font-weight: 500;\n      text-align: left;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  flex: 1;\n  font-family: ", ";\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin: 0rem;\n  text-align: center;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Content = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var StyledLabel = (0, _styledComponents["default"])(_Label["default"])(_templateObject3(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4());
});

function ItemText(_ref) {
  var label = _ref.label,
      value = _ref.value;
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(StyledLabel, null, (0, _typy["default"])(label).safeString), _react["default"].createElement(Content, null, value));
}

ItemText.propTypes = {
  label: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string]),
  value: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string, _propTypes["default"].func, _propTypes["default"].node])
};
var _default = ItemText;
exports["default"] = _default;