"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Token on Token {\n    address: id\n    decimals\n    name\n    symbol\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var TOKEN = {
  fragments: {}
};
TOKEN.fragments.token = (0, _graphqlTag["default"])(_templateObject());
var _default = TOKEN;
exports["default"] = _default;