"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _dialog = require("@reach/dialog");

var _reactSpring = require("react-spring");

require("@reach/dialog/styles.css");

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: none;\n  height: 0rem;\n  margin: 0rem;\n  padding: 0rem;\n  width: 0rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      width:  90vw;\n      max-height: 90vh;\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin: 0rem;\n      max-height: 80vh;\n      width: 65vw;\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        min-height: ", "vh;\n      "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        max-height: ", "vh;\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  &[data-reach-dialog-content] {\n    ", ";\n    ", ";\n    background-color: ", ";\n    border: 1px solid ", ";\n    border-radius: 0.5rem;\n    margin: 0rem;\n    margin-bottom: 2rem;\n    max-height: 80vh;\n    max-width: 46rem;\n    min-height: ", ";\n    overflow-x: hidden;\n    padding: 0rem;\n    width: 60vw;\n\n    ", ";\n\n    ", ";\n\n    ", ";\n\n    ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  /**\n   * TODO: Implement a proprietary Modal system and ditch the @reach/dialog\n   * The \"hack\" below aims to make every dialog box background lighter (white)\n   */\n  & > div > div,\n  & > div > div > div,\n  & > div > div > div > div {\n    background-color: ", ";\n  }\n\n  &[data-reach-dialog-overlay] {\n    align-items: center;\n    background-color: ", ";\n    display: flex;\n    justify-content: center;\n    z-index: 1000;\n\n    &::after {\n      background-color: ", ";\n      bottom: 0rem;\n      content: \"\";\n      left: 0rem;\n      opacity: 0.5;\n      position: fixed;\n      right: 0rem;\n      top: 0rem;\n      z-index: -1;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var AnimatedDialogOverlay = (0, _reactSpring.animated)(_dialog.DialogOverlay);
var StyledDialogOverlay = (0, _styledComponents["default"])(AnimatedDialogOverlay)(_templateObject(), function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.transparent;
}, function (props) {
  return props.theme.dimBlack;
});
/* Getting a "React does not recognize the `maxHeight` prop on a DOM element" error without this filter component */
// eslint-disable-next-line no-unused-vars

var FilteredDialogContent = function FilteredDialogContent(_ref) {
  var maxHeight = _ref.maxHeight,
      minHeight = _ref.minHeight,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["maxHeight", "minHeight"]);
  return _react["default"].createElement(_dialog.DialogContent, otherProps);
};

FilteredDialogContent.propTypes = {
  maxHeight: _propTypes["default"].number,
  minHeight: _propTypes["default"].number
};
FilteredDialogContent.defaultProps = {
  maxHeight: null,
  minHeight: null
};
var StyledDialogContent = (0, _styledComponents["default"])(FilteredDialogContent)(_templateObject2(), function (props) {
  return props.theme.boxShadowDarkGunmetalBlack;
}, function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.minHeight ? "".concat(props.minHeight, "vh") : "0rem";
}, function (props) {
  return props.maxHeight && (0, _styledComponents.css)(_templateObject3(), props.maxHeight);
}, function (props) {
  return props.minHeight && (0, _styledComponents.css)(_templateObject4(), props.minHeight);
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject6());
});

var HiddenCloseButton = _styledComponents["default"].button(_templateObject7());

function Modal(_ref2) {
  var children = _ref2.children,
      dialogLabelId = _ref2.dialogLabelId,
      initialFocusRef = _ref2.initialFocusRef,
      isOpen = _ref2.isOpen,
      onDismiss = _ref2.onDismiss,
      maxHeight = _ref2.maxHeight,
      minHeight = _ref2.minHeight,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref2, ["children", "dialogLabelId", "initialFocusRef", "isOpen", "onDismiss", "maxHeight", "minHeight"]);
  var transitions = (0, _reactSpring.useTransition)(isOpen, null, {
    config: {
      duration: 200
    }
    /* in milliseconds */
    ,
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    }
  });
  /* Place the modal in the middle of the screen */

  return transitions.map(function (_ref3) {
    var item = _ref3.item,
        key = _ref3.key,
        props = _ref3.props;
    return (0, _typy["default"])(item).isTruthy && _react["default"].createElement(StyledDialogOverlay, (0, _extends2["default"])({
      key: key,
      initialFocusRef: initialFocusRef,
      onDismiss: onDismiss,
      style: props
    }, otherProps), _react["default"].createElement(StyledDialogContent, {
      "aria-labelledby": dialogLabelId,
      hidden: true,
      maxHeight: maxHeight,
      minHeight: minHeight
    }, _react["default"].createElement(HiddenCloseButton, {
      onClick: onDismiss
    }), children));
  });
}

Modal.propTypes = {
  dialogLabelId: _propTypes["default"].string.isRequired,
  initialFocusRef: _propTypes["default"].element,
  isOpen: _propTypes["default"].bool.isRequired,
  maxHeight: _propTypes["default"].number,
  minHeight: _propTypes["default"].number,
  onDismiss: _propTypes["default"].func.isRequired
};
Modal.defaultProps = {
  initialFocusRef: null,
  maxHeight: null,
  minHeight: null
};
var _default = Modal;
exports["default"] = _default;