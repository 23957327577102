"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    text-align: left;\n    font-family: ", ";\n    font-size: 1.1rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  flex: 1;\n  font-family: ", ";\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin: 0rem;\n  text-align: center;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Content = _styledComponents["default"].p(_templateObject(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.robotoMonoFont);
});

function ItemAmount(_ref) {
  var value = _ref.value;
  return _react["default"].createElement(Content, null, (0, _typy["default"])(value).safeString);
}

ItemAmount.propTypes = {
  value: _propTypes["default"].oneOfType([_propTypes["default"].number, _propTypes["default"].string])
};
var _default = ItemAmount;
exports["default"] = _default;