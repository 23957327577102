"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ReactManager;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _vendors = require("@sablier/vendors");

var _connectors = require("@sablier/connectors");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _core = require("@web3-react/core");

var _Spinner = _interopRequireDefault(require("../../Spinner"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  height: 25rem;\n  justify-content: center;\n  padding: 1rem 0.5rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MessageWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
});

function ReactManager(_ref) {
  var children = _ref.children;

  var _useWeb3React = (0, _core.useWeb3React)(),
      active = _useWeb3React.active;

  var _useWeb3React2 = (0, _core.useWeb3React)(_devConstants.NETWORK_CONTEXT_NAME),
      networkActive = _useWeb3React2.active,
      networkError = _useWeb3React2.error,
      activateNetwork = _useWeb3React2.activate;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  (0, _reactHooks.useMixpanel)();
  /* Try to eagerly connect to an injected provider, if it exists and has granted access already */

  var triedEager = (0, _reactHooks.useEagerConnect)();
  /**
   * After eagerly trying injected, if the network connect ever isn't active or in an error state, activate it
   * TODO: think about not doing this at all
   */

  (0, _react.useEffect)(function () {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(_connectors.network);
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active]);
  /* "Pause" the network connector if we're ever connected to an account and it's active */

  (0, _react.useEffect)(function () {
    if (active && networkActive) {
      _connectors.network.pause();
    }
  }, [active, networkActive]);
  /* "Resume" the network connector if we're ever not connected to an account and it's active */

  (0, _react.useEffect)(function () {
    if (!active && networkActive) {
      _connectors.network.resume();
    }
  }, [active, networkActive]);
  /* When there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists */

  (0, _reactHooks.useInactiveListener)(!triedEager);
  /* On page load, do nothing until we've tried to connect to the injected connector */

  if (!triedEager) {
    return null;
  }
  /* If the account context isn't active, and there's an error on the network context, it's an irrecoverable error */


  if (!active && networkError) {
    _vendors.mixpanel.track('Error in "Web3.ReactManager"', {
      error: (0, _typy["default"])(networkError, "toString").isFunction ? networkError.toString() : networkError
    });

    return _react["default"].createElement(MessageWrapper, null, _react["default"].createElement(_theme.UnknownErrorLabel, null, translation("structs.unknownError")));
  }
  /* If neither context is active, spin */


  if (!active && !networkActive) {
    return _react["default"].createElement(MessageWrapper, null, _react["default"].createElement(_Spinner["default"], {
      delay: 600
    }));
  }

  return children;
}