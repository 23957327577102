"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _Asset = _interopRequireDefault(require("../../../Asset"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-shrink: 0;\n    min-width: 6rem;\n    text-align: left;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.9rem;\n  font-weight: 600;\n  margin: 0;\n  text-align: center;\n  text-transform: uppercase;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Content = _styledComponents["default"].p(_templateObject(), function (props) {
  return props.theme.lavenderGray;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

function Label(_ref) {
  var children = _ref.children,
      className = _ref.className,
      help = _ref.help;
  return (0, _typy["default"])(help).isTruthy ? _react["default"].createElement(_Asset["default"].Helper, {
    help: help
  }, _react["default"].createElement(Content, null, children)) : _react["default"].createElement(Content, {
    className: className
  }, children);
}

Label.propTypes = {
  className: _propTypes["default"].string,
  help: _propTypes["default"].string
};
Label.defaultProps = {
  className: "",
  help: ""
};
var _default = Label;
exports["default"] = _default;