"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _typy = _interopRequireDefault(require("typy"));

var _utils = require("@sablier/utils");

var _reactHooks = require("@sablier/react-hooks");
/**
 * Display the ENS name, if there is one, or revert to the Ethereum address otherwise. Caveat: we override the React
 * components defined in the columns.
 */


function AccountLabel(_ref) {
  var account = _ref.account,
      shouldShortenAddress = _ref.shortenAddress,
      shouldShortenENS = _ref.shortenENS,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["account", "shortenAddress", "shortenENS"]);
  var ENSName = (0, _reactHooks.useENSName)(account);

  if ((0, _typy["default"])(ENSName).isTruthy) {
    return _react["default"].createElement("div", otherProps, shouldShortenENS ? (0, _utils.shortenENS)({
      ens: ENSName
    }) : ENSName);
  } else {
    return _react["default"].createElement("div", otherProps, ENSName || shouldShortenAddress ? (0, _utils.shortenAddress)({
      address: account
    }) : account);
  }
}

AccountLabel.propTypes = {
  account: _propTypes["default"].string.isRequired,
  shortenAddress: _propTypes["default"].bool,
  shortenENS: _propTypes["default"].bool
};
AccountLabel.defaultProps = {
  shortenAddress: true,
  shortenENS: true
};
var _default = AccountLabel;
exports["default"] = _default;