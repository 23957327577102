"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _connectors = require("@sablier/connectors");

var _theme = require("@sablier/theme");

var _polished = require("polished");

var _reactI18next = require("react-i18next");

var _Option = _interopRequireDefault(require("../Option"));

var _Spinner = _interopRequireDefault(require("../../Spinner"));

var _WalletConnectData = _interopRequireDefault(require("../WalletConnectData"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-left: 0.5rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 0.5rem;\n  color: ", ";\n  font-size: 0.8125rem;\n  font-weight: 600;\n  margin-left: 1rem;\n  padding: 0.5rem;\n  user-select: none;\n\n  &:hover {\n    background-color: ", ";\n    cursor: pointer;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: flex-start;\n  padding-left: 0.5rem;\n  padding-right: 0.5rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border: 1px solid ", ";\n  border-radius: 0.75rem;\n  color: ", ";\n  justify-content: flex-start;\n  margin-bottom: 1.25rem;\n\n  & > * {\n    padding: 1rem 0.5rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n\n  & > * {\n    width: 100%;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

var LoadingMessageWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.error ? props.theme.pastelRed : props.theme.platinumGray;
}, function (props) {
  return props.error ? props.theme.pastelRed : "inherit";
});

var ErrorGroupWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
});

var ErrorButton = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.platinumGray;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return (0, _polished.darken)(0.1, props.theme.platinumGray);
});

var SpinnerWrapper = _styledComponents["default"].div(_templateObject5());

var LoadingMessageInnerWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.flexRowNoWrap;
});

function PendingView(_ref) {
  var connector = _ref.connector,
      error = _ref.error,
      setPendingError = _ref.setPendingError,
      size = _ref.size,
      tryActivation = _ref.tryActivation,
      uri = _ref.uri;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;
  /** Memoized values **/


  var isMetamask = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(window, "ethereum").isTruthy && (0, _typy["default"])(window.ethereum.isMetaMask).isTruthy;
  }, []);
  /** Callbacks **/

  var onClickErrorButton = (0, _react.useCallback)(function () {
    setPendingError(false);
    tryActivation(connector);
  }, [connector, setPendingError, tryActivation]);
  var renderLoadingMessage = (0, _react.useCallback)(function () {
    if ((0, _typy["default"])(error).isTruthy) {
      return _react["default"].createElement(ErrorGroupWrapper, null, _react["default"].createElement("div", null, translation("words.error") + " " + translation("words.connecting").toLowerCase()), _react["default"].createElement(ErrorButton, {
        onClick: onClickErrorButton
      }, translation("structs.tryAgain")));
    } else {
      if (connector === _connectors.walletconnect) {
        return translation("modals.wallet.scanQrCode") + "...";
      } else {
        return translation("words.initializing") + "...";
      }
    }
  }, [connector, error, onClickErrorButton, translation]);
  return _react["default"].createElement(Wrapper, null, (0, _typy["default"])(error).isFalsy && connector === _connectors.walletconnect && _react["default"].createElement(_WalletConnectData["default"], {
    size: size,
    uri: uri
  }), _react["default"].createElement(LoadingMessageWrapper, {
    error: error
  }, (0, _typy["default"])(error).isFalsy && _react["default"].createElement(SpinnerWrapper, null, _react["default"].createElement(_Spinner["default"], {
    color: _theme.colors.bleuDeFranceBlue,
    size: 1.25
  })), _react["default"].createElement(LoadingMessageInnerWrapper, null, renderLoadingMessage())), Object.keys(_connectors.SUPPORTED_WALLETS).map(function (key) {
    var option = _connectors.SUPPORTED_WALLETS[key];

    if (option.connector === connector) {
      if (option.connector === _connectors.injected) {
        if (isMetamask && option.name !== "words.metamask") {
          return null;
        }

        if (!isMetamask && option.name === "words.metamask") {
          return null;
        }
      }

      return _react["default"].createElement(_Option["default"], {
        key: key,
        color: option.color,
        isActive: false,
        isClickable: false,
        icon: option.icon,
        subtitle: translation(option.description),
        title: translation(option.name)
      });
    }

    return null;
  }));
}

PendingView.propTypes = {
  connector: _propTypes["default"].shape({}).isRequired,
  error: _propTypes["default"].oneOfType([_propTypes["default"].bool, _propTypes["default"].string]),
  setPendingError: _propTypes["default"].func.isRequired,
  size: _propTypes["default"].number,
  tryActivation: _propTypes["default"].func.isRequired,
  uri: _propTypes["default"].string
};
PendingView.defaultProps = {
  error: false,
  size: 220,
  uri: ""
};
var _default = PendingView;
exports["default"] = _default;