"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _reactFeather = require("react-feather");

var _devConstants = require("@sablier/dev-constants");

var _reactI18next = require("react-i18next");

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 0.15rem;\n  height: 0.3rem;\n  width: 0.3rem;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  margin-right: -0.125rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  height: 0.7rem;\n  object-fit: contain;\n  stroke-width: 2.5;\n  width: 0.7rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      color: ", ";\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.875rem;\n  font-weight: 500;\n  margin: 0rem;\n  margin-left: 0.5rem;\n\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-left: 0;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border: 1px solid ", ";\n  border-radius: 0.6rem;\n  height: 1.2rem;\n  justify-content: center;\n  width: 1.2rem;\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: 1px solid ", ";\n    border-radius: 0.4rem;\n    padding: 0.4rem 0.6rem;\n    width: auto;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.aliceBlue);
});

var IconWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4());
});

var Title = _styledComponents["default"].p(_templateObject5(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.status === _devConstants.STREAM_STATUS.cancelled && (0, _styledComponents.css)(_templateObject6(), props.theme.red);
});

var iconCss = (0, _styledComponents.css)(_templateObject7(), function (props) {
  return props.theme.darkGunmetalBlack;
});
var IconCheck = (0, _styledComponents["default"])(_reactFeather.Check)(_templateObject8(), iconCss);
var IconCancelled = (0, _styledComponents["default"])(_reactFeather.X)(_templateObject9(), iconCss, function (props) {
  return props.theme.red;
});
var IconEarning = (0, _styledComponents["default"])(_reactFeather.Play)(_templateObject10(), iconCss);

var IconDot = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.darkGunmetalBlack;
});

function ItemStatus(_ref) {
  var forRecipient = _ref.forRecipient,
      status = _ref.status,
      withdrawable = _ref.withdrawable;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var title = (0, _react.useMemo)(function () {
    switch (status) {
      case _devConstants.STREAM_STATUS.created:
        return translation("words.created");

      case _devConstants.STREAM_STATUS.streaming:
        return forRecipient ? translation("words.earning") : translation("words.streaming");

      case _devConstants.STREAM_STATUS.cancelled:
        return translation("words.cancelled");

      case _devConstants.STREAM_STATUS.ended:
        return translation("words.ended");

      default:
        return translation("words.status");
    }
  }, [forRecipient, status, translation]);
  var icon = (0, _react.useMemo)(function () {
    switch (status) {
      case _devConstants.STREAM_STATUS.created:
      case _devConstants.STREAM_STATUS.streaming:
        return _react["default"].createElement(IconEarning, null);

      case _devConstants.STREAM_STATUS.cancelled:
        return _react["default"].createElement(IconCancelled, null);

      case _devConstants.STREAM_STATUS.ended:
        return withdrawable ? _react["default"].createElement(IconDot, null) : _react["default"].createElement(IconCheck, null);

      /* TODO check withdraw */

      default:
        return _react["default"].createElement(IconDot, null);
    }
  }, [status, withdrawable]);
  return _react["default"].createElement(Wrapper, null, _react["default"].createElement(IconWrapper, null, icon), _react["default"].createElement(Title, {
    status: status
  }, title));
}

ItemStatus.propTypes = {
  forRecipient: _propTypes["default"].bool,
  status: _propTypes["default"].number.isRequired,
  withdrawable: _propTypes["default"].bool
};
ItemStatus.defaultProps = {
  forRecipient: false,
  withdrawable: false
};
var _default = ItemStatus;
exports["default"] = _default;