"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var Emoji = function Emoji(props) {
  var label = props.label,
      symbol = props.symbol;
  return _react["default"].createElement("span", {
    className: "Emoji emoji",
    role: "img",
    "aria-label": label,
    "aria-hidden": label
  }, symbol);
};

Emoji.propTypes = {
  label: _propTypes["default"].string,
  symbol: _propTypes["default"].string.isRequired
};
Emoji.defaultProps = {
  label: null
};
var _default = Emoji;
exports["default"] = _default;