"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _theme = require("@sablier/theme");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  /* background-color: ", "; */\n  ", "\n  border-radius: inherit;\n  height: 100%;\n  max-width: 100%;\n  transition: width 200ms ease-in;\n  width: ", "%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      filter: isGrayscale(100%);\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      border: 1px solid ", ";\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 0.1875rem;\n  flex-grow: 1;\n  height: 0.4rem;\n  margin: 0rem;\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.backgroundColor;
}, function (props) {
  return props.borderColor && (0, _styledComponents.css)(_templateObject2(), props.borderColor);
}, function (props) {
  return props.isGrayscale && (0, _styledComponents.css)(_templateObject3());
});

var Filler = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.color || props.theme.primaryColor;
}, function (props) {
  return props.theme.secondaryGradient;
}, function (props) {
  return props.width;
});

function ProgressBar(_ref) {
  var backgroundColor = _ref.backgroundColor,
      color = _ref.color,
      isGrayscale = _ref.isGrayscale,
      streamed = _ref.streamed,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["backgroundColor", "color", "isGrayscale", "streamed"]);
  return _react["default"].createElement(Wrapper, (0, _extends2["default"])({
    isGrayscale: isGrayscale,
    backgroundColor: backgroundColor
  }, otherProps), _react["default"].createElement(Filler, {
    color: color,
    width: streamed
  }));
}

ProgressBar.propTypes = {
  backgroundColor: _propTypes["default"].string,
  borderColor: _propTypes["default"].string,
  color: _propTypes["default"].string,
  isGrayscale: _propTypes["default"].bool,
  streamed: _propTypes["default"].number
};
ProgressBar.defaultProps = {
  backgroundColor: _theme.colors.white,
  borderColor: "",
  color: "",
  isGrayscale: false,
  streamed: 0
};
var _default = ProgressBar;
exports["default"] = _default;