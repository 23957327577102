"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _TokenLogo = _interopRequireDefault(require("../../../TokenLogo"));

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border-radius: 0rem;\n  height: 1.2rem;\n  object-fit: center;\n  user-select: none;\n  width: 1.2rem;\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: none;\n    font-size: 1.3rem;\n    margin-right: 0.4rem;\n    word-spacing: -0.2rem;\n\n    & > span {\n      font-size: 1.2rem;\n    }\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex: 1;\n  font-family: ", ";\n  font-size: 1.7rem;\n  font-weight: 600;\n  margin: 0rem;\n\n  & > span {\n    font-size: 1.2rem;\n  }\n\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    align-items:center;\n    flex: 1;\n    justify-content: flex-end;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-end;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.9rem;\n  "]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 1rem;\n  margin: 0rem;\n\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 1rem;\n  "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 1.1rem;\n  font-weight: 700;\n  margin: 0rem;\n  margin-bottom: 0.1rem;\n\n  ", "\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-bottom: 0rem;\n    width: auto;\n    padding-right: 0.6rem;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  justify-content: flex-start;\n  margin-bottom: 0.4rem;\n  width: 100%;\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n     align-items: center;\n     padding: 1rem 0.7rem;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      ", "\n      border: 1px solid ", ";\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      ", "\n      border: 1px solid ", ";\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      border: 1px solid ", ";\n      background-color: ", ";\n      color: ", ";\n\n      ", " {\n        border: 1px solid ", ";\n      }\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  border:none;\n  border-radius: 0.2rem;\n  color: ", ";\n  grid-column: span 1;\n  justify-content: flex-start;\n  padding: 1rem;\n\n  ", "\n\n  ", "\n\n  ", "\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-bottom: 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.2rem;\n  flex-shrink: 0rem;\n  height: 1.7rem;\n  justify-content: center;\n  margin-bottom: 0.2rem;\n  margin-left: 0.4rem;\n  margin-right: 0.2rem;\n  width: 1.7rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Token = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var Wrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.type === "light" && (0, _styledComponents.css)(_templateObject4(), props.theme.aliceBlue, props.theme.white, props.theme.darkGunmetalBlack, Token, props.theme.aliceBlue);
}, function (props) {
  return props.type === "primary" && (0, _styledComponents.css)(_templateObject5(), props.theme.primaryGradient, props.theme.white);
}, function (props) {
  return props.type === "secondary" && (0, _styledComponents.css)(_templateObject6(), props.theme.secondaryGradient, props.theme.white);
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject7(), props.theme.flexRowNoWrap);
});

var Header = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject9());
});

var Title = _styledComponents["default"].p(_templateObject10(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject11());
});

var Subtitle = _styledComponents["default"].p(_templateObject12(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject13());
});

var Main = _styledComponents["default"].div(_templateObject14(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject15());
});

var Value = _styledComponents["default"].p(_templateObject16(), function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject17());
});

var StyledTokenLogo = (0, _styledComponents["default"])(_TokenLogo["default"])(_templateObject18());

function ActivityCard(_ref) {
  var subtitle = _ref.subtitle,
      title = _ref.title,
      token = _ref.token,
      type = _ref.type,
      value = _ref.value;
  var source = (0, _react.useMemo)(function () {
    return (0, _typy["default"])(value).safeString.split(".");
  }, [value]);
  return _react["default"].createElement(Wrapper, {
    type: type
  }, _react["default"].createElement(Header, null, _react["default"].createElement(Title, null, title), _react["default"].createElement(Subtitle, null, subtitle)), _react["default"].createElement(Main, null, _react["default"].createElement(Value, null, (0, _typy["default"])(source, "[0]").isTruthy ? (0, _typy["default"])(source, "[0]").safeString : "0", (0, _typy["default"])(source, "[1]").isTruthy ? _react["default"].createElement("span", null, "." + (0, _typy["default"])(source, "[1]").safeString.slice(0, 10)) : null, _react["default"].createElement("span", null, " " + (0, _typy["default"])(token, "symbol").safeTokenSymbol)), _react["default"].createElement(Token, null, _react["default"].createElement(StyledTokenLogo, {
    address: (0, _typy["default"])(token, "address").safeEthereumAddress
  }))));
}

ActivityCard.propTypes = {
  subtitle: _propTypes["default"].string,
  title: _propTypes["default"].string.isRequired,
  token: _propTypes["default"].shape({}),
  type: _propTypes["default"].oneOf(["light", "primary", "secondary"]),
  value: _propTypes["default"].string
};
ActivityCard.defaultProps = {
  subtitle: "",
  type: "light"
};
var _default = ActivityCard;
exports["default"] = _default;