"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_STREAM = exports.GET_SENDER_STREAMS = exports.GET_RECIPIENT_STREAMS = exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _bundle = _interopRequireDefault(require("./bundle"));

var _cancellation = _interopRequireDefault(require("./cancellation"));

var _streamTransaction = _interopRequireDefault(require("./streamTransaction"));

var _token = _interopRequireDefault(require("./token"));

var _withdrawal = _interopRequireDefault(require("./withdrawal"));

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query Stream($id: ID!) {\n    stream(id: $id) {\n      ...Stream\n    }\n  }\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query Streams($sender: String!) {\n    streams(first: 200, orderBy: timestamp, orderDirection: desc, where: { sender: $sender }) {\n      ...Stream\n    }\n  }\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query Streams($recipient: String!) {\n    streams(first: 200, orderBy: timestamp, orderDirection: desc, where: { recipient: $recipient }) {\n      ...Stream\n    }\n  }\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Stream on Stream {\n    id\n    cancellation {\n      ...Cancellation\n    }\n    deposit\n    ratePerSecond\n    recipient\n    sender\n    startTime\n    stopTime\n    timestamp\n    token {\n      ...Token\n    }\n    ...Txs\n    ...Withdrawals\n    ...LocalStream\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment LocalStream on Stream {\n    cancellation {\n      timeLabel @client\n    }\n    timeLabels @client {\n      depositTime\n      passed\n      remaining\n      startTime\n      stopTime\n    }\n    status @client\n    bundles @client {\n      deposit {\n        ...Bundle\n      }\n      outstanding {\n        ...Bundle\n      }\n      streamed {\n        ...Bundle\n      }\n      ratePerSecond {\n        ...Bundle\n      }\n      withdrawable {\n        ...Bundle\n      }\n      withdrawn {\n        ...Bundle\n      }\n    }\n  }\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Withdrawals on Stream {\n    withdrawals(orderBy: timestamp, orderDirection: desc) {\n      ...Withdrawal\n    }\n  }\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment Txs on Stream {\n    txs(where: { event: \"CreateStream\" }) {\n      ...StreamTransaction\n    }\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var STREAM = {
  fragments: {}
};
/* Remote Properties */

STREAM.fragments.txs = (0, _graphqlTag["default"])(_templateObject(), _streamTransaction["default"].fragments.streamTransaction);
STREAM.fragments.withdrawals = (0, _graphqlTag["default"])(_templateObject2(), _withdrawal["default"].fragments.withdrawal);
/* Local Properties */

/**
 * Deconstructing this fragment doesn't work: https://github.com/apollographql/apollo-client/issues/5586
 * The `bundles` property needs to be defined first: https://bit.ly/2O2NdNC
 */

STREAM.fragments.local = (0, _graphqlTag["default"])(_templateObject3(), _bundle["default"].fragments.bundle);
/* Wrap all fragments into one big fragment */

STREAM.fragments.stream = (0, _graphqlTag["default"])(_templateObject4(), _cancellation["default"].fragments.cancellation, STREAM.fragments.txs, STREAM.fragments.withdrawals, STREAM.fragments.local, _token["default"].fragments.token);
var _default = STREAM;
exports["default"] = _default;
var GET_RECIPIENT_STREAMS = (0, _graphqlTag["default"])(_templateObject5(), STREAM.fragments.stream);
exports.GET_RECIPIENT_STREAMS = GET_RECIPIENT_STREAMS;
var GET_SENDER_STREAMS = (0, _graphqlTag["default"])(_templateObject6(), STREAM.fragments.stream);
exports.GET_SENDER_STREAMS = GET_SENDER_STREAMS;
var GET_STREAM = (0, _graphqlTag["default"])(_templateObject7(), STREAM.fragments.stream);
exports.GET_STREAM = GET_STREAM;