"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("@sablier/theme");

var _reactFeather = require("react-feather");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  ", ";\n  align-items: center;\n  border-radius: 1.25rem;\n  color: ", ";\n  cursor: pointer;\n  height: 2.5rem;\n  justify-content: center;\n  transition: border-color 200ms ease;\n  width: 2.5rem;\n\n  &:active,\n  &:hover {\n    border-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.borderAzureishWhite;
}, function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.darkGunmetalBlack;
});

var Cross = (0, _styledComponents["default"])(_reactFeather.X)(_templateObject2());

function BorderedCross(_ref) {
  var onClick = _ref.onClick,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["onClick"]);
  return _react["default"].createElement(Wrapper, (0, _extends2["default"])({
    onClick: onClick
  }, otherProps), _react["default"].createElement(Cross, {
    color: _theme.colors.darkGunmetalBlack
  }));
}

BorderedCross.propTypes = {
  onClick: _propTypes["default"].func
};
BorderedCross.defaultProps = {
  onClick: function onClick() {}
};
var _default = BorderedCross;
exports["default"] = _default;