"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

function _default(_ref) {
  var timestamp = _ref.timestamp;
  return (0, _typy["default"])(timestamp).isTruthy ? _dayjs["default"].unix(timestamp).format(_devConstants.ISO_8601_FORMAT) : undefined;
}