"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROPSTEN_ID = exports.RINKEBY_ID = exports.MAINNET_ID = exports.KOVAN_ID = exports.GOERLI_ID = exports.CHAIN_IDS_TO_NAMES = void 0;
var CHAIN_IDS_TO_NAMES = {
  1: "mainnet",
  3: "ropsten",
  4: "rinkeby",
  5: "goerli",
  42: "kovan"
};
exports.CHAIN_IDS_TO_NAMES = CHAIN_IDS_TO_NAMES;
var GOERLI_ID = 5;
exports.GOERLI_ID = GOERLI_ID;
var KOVAN_ID = 42;
exports.KOVAN_ID = KOVAN_ID;
var MAINNET_ID = 1;
exports.MAINNET_ID = MAINNET_ID;
var RINKEBY_ID = 4;
exports.RINKEBY_ID = RINKEBY_ID;
var ROPSTEN_ID = 3;
exports.ROPSTEN_ID = ROPSTEN_ID;