"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TWITTER_DOTME_TIMELINE_ID = exports.TWITTER_TIMELINE_ID = exports.DOTME = exports.SUBDOMAINS = exports.SHARER = exports.SOCIAL_MEDIA_LINKS = exports.PROFILE3BOX = exports.SITE_LINKS = exports.PRESS = exports.NOTION_LINKS = exports.QUANTSTAMP_LINK = exports.LICENSE_LINK = exports.ETHERCHAIN_GAS_PRICE_ORACLE = exports.DOMAINS = exports.BLOG_LINKS = exports.CALENDLY_LINK = void 0;
var CALENDLY_LINK = "https://calendly.com/sablier";
exports.CALENDLY_LINK = CALENDLY_LINK;
var BLOG_LINKS = {
  cartel: "https://medium.com/metacartel/metacartel-cohort-1-6336cdca05db",
  grant: "https://medium.com/sablier/big-day-for-sablier-we-raised-a-grant-bde00a9a31e3"
};
exports.BLOG_LINKS = BLOG_LINKS;
var DOMAINS = {
  finance: "https://sablier.finance",
  me: "https://sablier.me"
};
exports.DOMAINS = DOMAINS;
var ETHERCHAIN_GAS_PRICE_ORACLE = "https://www.etherchain.org/api/gasPriceOracle";
exports.ETHERCHAIN_GAS_PRICE_ORACLE = ETHERCHAIN_GAS_PRICE_ORACLE;
var LICENSE_LINK = "https://github.com/sablierhq/sablier/blob/develop/LICENSE.md";
exports.LICENSE_LINK = LICENSE_LINK;
var QUANTSTAMP_LINK = "https://certificate.quantstamp.com/view/sablier";
exports.QUANTSTAMP_LINK = QUANTSTAMP_LINK;
var NOTION_LINKS = {
  roadmap: "https://www.notion.so/sablier/e6fa6198bea149cd9551679421ae33ec?v=807f6528930e40d694af3bd7b54460fb"
};
exports.NOTION_LINKS = NOTION_LINKS;
var PRESS = {
  defi_pulse: "https://defipulse.com/blog/defi-dive-sablier-protocol/"
};
exports.PRESS = PRESS;
var SITE_LINKS = {
  about: "https://twitter.com/sablierhq"
};
exports.SITE_LINKS = SITE_LINKS;
var PROFILE3BOX = {
  base: "https://www.3box.io",
  login: "https://www.3box.io/login"
};
exports.PROFILE3BOX = PROFILE3BOX;
var SOCIAL_MEDIA_LINKS = {
  facebook: "https://facebook.com/sablierhq",
  github: "https://github.com/sablierhq",
  linkedin: "https://linkedin.com/company/sablierhq",
  medium: "https://medium.com/sablier",
  telegram: "https://t.me/sablier",
  discord: "https://discord.gg/KXajCXC",
  twitter: "https://twitter.com/sablierhq"
};
exports.SOCIAL_MEDIA_LINKS = SOCIAL_MEDIA_LINKS;
var SHARER = {
  twitter: "https://twitter.com/intent/tweet"
};
exports.SHARER = SHARER;
var SUBDOMAINS = {
  DEV: {
    app: "https://dev.app.sablier.finance",
    pay: "https://dev.pay.sablier.finance"
  },
  DOCS: {
    STREAMS: {
      depositGotcha: "https://docs.sablier.finance/streams#the-deposit-gotcha"
    }
  },
  STAGING: {
    app: "https://staging.app.sablier.finance",
    pay: "https://staging.pay.sablier.finance"
  },
  app: "https://app.sablier.finance",
  docs: "https://docs.sablier.finance",
  faq: "https://faq.sablier.finance",
  me: "https://sablier.me",
  pay: "https://pay.sablier.finance"
};
exports.SUBDOMAINS = SUBDOMAINS;
var DOTME = {
  param_amount: "a",
  param_duration: "d",
  param_end_date: "e",
  param_end_time: "et",
  param_option: "o",
  param_rate: "r",
  param_start_date: "s",
  param_start_time: "st",
  param_token: "t"
};
exports.DOTME = DOTME;
var TWITTER_TIMELINE_ID = "1205148670108274689";
exports.TWITTER_TIMELINE_ID = TWITTER_TIMELINE_ID;
var TWITTER_DOTME_TIMELINE_ID = "1233852792063844352";
exports.TWITTER_DOTME_TIMELINE_ID = TWITTER_DOTME_TIMELINE_ID;