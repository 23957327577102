"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logError = logError;

var _vendors = require("@sablier/vendors");

var _errors = require("./errors");

function logError(error, benignErrorMessage) {
  if ((0, _errors.isWalletError)(error)) {
    _vendors.mixpanel.track(benignErrorMessage);
  } else if ((0, _errors.isError)(error)) {
    _vendors.rollbar.error(error);
  }
}