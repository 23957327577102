"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var tokenInput = {
  boxy: "boxy",
  rectangular: "rectangular"
};
var timeBox = {
  timespan: "timespan",
  duration: "duration",
  rate: "rate"
};
var _default = {
  timeBox: timeBox,
  tokenInput: tokenInput
};
exports["default"] = _default;