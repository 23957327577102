"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _devConstants = require("@sablier/dev-constants");

var _reactDeviceDetect = require("react-device-detect");

var _polished = require("polished");

var _DashedTrail = _interopRequireDefault(require("./DashedTrail"));

var _TitleAndBorderedCross = _interopRequireDefault(require("../TitleAndBorderedCross"));

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: ", ";\n    flex-grow: 1;\n    transition: none;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    transform: ", ";\n    transition: transform ", "ms ease-in-out;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  max-width: 100vw;\n  -webkit-overflow-scrolling: auto;\n  overflow-x: hidden;\n  width: ", "rem;\n  z-index: 1100;\n\n  ", ";\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    transition: none;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  background-color: ", ";\n  justify-content: flex-end;\n  left: 0rem;\n  min-height: 100vh;\n  overflow: hidden;\n  pointer-events: ", ";\n  position: absolute;\n  right: 0rem;\n  top: 0rem;\n  transition: background-color ", "ms ease-in-out;\n  width: 100vw;\n  z-index: 1000;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var OverlayWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.isOpen ? (0, _polished.rgba)(0, 0, 0, 0.65) : "transparent";
}, function (props) {
  return props.isOpen ? "auto" : "none";
}, _devConstants.SLIDE_DURATION, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var VisibleWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.width;
}, function (props) {
  return props.theme.mediaWidth.mediumAndUp(_templateObject4(), !_reactDeviceDetect.isMobile && props.isOpen ? "translateX(0)" : "translateX(100%)", _devConstants.SLIDE_DURATION);
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5(), props.isOpen ? "block" : "none");
});

function Sidebar(_ref) {
  var children = _ref.children,
      closeSidebar = _ref.closeSidebar,
      isOpen = _ref.isOpen,
      title = _ref.title,
      width = _ref.width,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "closeSidebar", "isOpen", "title", "width"]);
  return _react["default"].createElement(OverlayWrapper, (0, _extends2["default"])({
    isOpen: isOpen
  }, otherProps, {
    onClick: closeSidebar
  }), _react["default"].createElement(VisibleWrapper, {
    isOpen: isOpen,
    onClick: function onClick(e) {
      return e.stopPropagation();
    },
    width: width
  }, _react["default"].createElement(_TitleAndBorderedCross["default"], {
    onClickBorderedCross: closeSidebar,
    title: title
  }), children));
}

Sidebar.propTypes = {
  closeSidebar: _propTypes["default"].func,
  isOpen: _propTypes["default"].bool,
  title: _propTypes["default"].string,
  width: _propTypes["default"].number
};
Sidebar.defaultProps = {
  closeSidebar: function closeSidebar() {},
  isOpen: false,
  title: "Sidebar",
  width: 34
};
var _default = {
  DashedTrail: _DashedTrail["default"],
  UI: Sidebar
};
exports["default"] = _default;