"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ApolloReactManager;

var _react = _interopRequireWildcard(require("react"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _apollo = require("@sablier/apollo");

var _reactHooks = require("@sablier/react-hooks");

var _MemoizedProvider = _interopRequireDefault(require("./MemoizedProvider"));

function ApolloReactManager(_ref) {
  var children = _ref.children;
  /* Note that when there's no web3 wallet, we default to the NetworkOnlyConnector, which is connected to Mainnet */

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React.chainId;

  var client = (0, _react.useMemo)(function () {
    var chainName = (0, _typy["default"])(_devConstants.CHAIN_IDS_TO_NAMES, chainId ? chainId.toString() : "").safeString;
    return (0, _apollo.createClient)(chainName);
  }, [chainId]);
  return _react["default"].createElement(_MemoizedProvider["default"], {
    client: client
  }, children);
}