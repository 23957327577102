"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.MEDIA_WIDTHS_MIN = exports.MEDIA_WIDTHS_MAX = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = require("styled-components");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    @media (min-width: ", "px) {\n      ", "\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    @media (max-width: ", "px) {\n      ", "\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MEDIA_WIDTHS_MAX = {
  upToSmall: 640,
  upToMedium: 960,
  upToLarge: 1280
};
exports.MEDIA_WIDTHS_MAX = MEDIA_WIDTHS_MAX;
var MEDIA_WIDTHS_MIN = {
  smallAndUp: 640,
  mediumAndUp: 960,
  largeAndUp: 1280
};
exports.MEDIA_WIDTHS_MIN = MEDIA_WIDTHS_MIN;
var mediaWidthMaxTemplates = Object.keys(MEDIA_WIDTHS_MAX).reduce(function (accumulator, size) {
  accumulator[size] = function () {
    return (0, _styledComponents.css)(_templateObject(), MEDIA_WIDTHS_MAX[size], _styledComponents.css.apply(void 0, arguments));
  };

  return accumulator;
}, {});
var mediaWidthMinTemplates = Object.keys(MEDIA_WIDTHS_MIN).reduce(function (accumulator, size) {
  accumulator[size] = function () {
    return (0, _styledComponents.css)(_templateObject2(), MEDIA_WIDTHS_MIN[size], _styledComponents.css.apply(void 0, arguments));
  };

  return accumulator;
}, {});
var _default = {
  mediaWidth: _objectSpread({}, mediaWidthMaxTemplates, {}, mediaWidthMinTemplates)
};
exports["default"] = _default;