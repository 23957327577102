"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-left: 0rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  animation: ", " 200ms ease-in;\n  color: ", ";\n  font-family: ", ";\n  font-size: 0.8rem;\n  font-weight: 500;\n  margin-bottom: 0rem;\n  margin-left: 0rem;\n  margin-top: 0.2rem;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject());

var Label = _styledComponents["default"].span(_templateObject2(), _theme.fadeInAnimation, function (props) {
  return props.theme.rustyRed;
}, function (props) {
  return props.theme.fallbackFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

function ErrorLabel(_ref) {
  var error = _ref.error,
      isPlaceholder = _ref.isPlaceholder;

  if ((0, _typy["default"])(error).isFalsy) {
    if ((0, _typy["default"])(isPlaceholder).safeBoolean === false) {
      return _react["default"].createElement(_react["default"].Fragment, null);
    } else {
      return _react["default"].createElement(Wrapper, null);
    }
  } else {
    return _react["default"].createElement(Wrapper, null, _react["default"].createElement(Label, null, error));
  }
}

ErrorLabel.propTypes = {
  error: _propTypes["default"].string,
  isPlaceholder: _propTypes["default"].bool
};
ErrorLabel.defaultProps = {
  isPlaceholder: false
};
var _default = ErrorLabel;
exports["default"] = _default;