"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cashify = cashify;
exports.bundlify = bundlify;
exports.roundWithPrecision = roundWithPrecision;

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _bignumber = require("bignumber.js");

var _ethers = require("ethers");
/**
 * Formats a number to a string appropriate to a human-readable currency amount.
 * @param number the number to round
 * @param decimals the rounding precision
 * @param roundSmallNumbersToZero whether to return "~0" for very small numbers (< 0.001)
 * @see https://bit.ly/2NwjlHQ
 */


function cashify(_ref) {
  var number = _ref.number,
      _ref$decimals = _ref.decimals,
      decimals = _ref$decimals === void 0 ? 2 : _ref$decimals,
      _ref$roundSmallNumber = _ref.roundSmallNumbersToZero,
      roundSmallNumbersToZero = _ref$roundSmallNumber === void 0 ? true : _ref$roundSmallNumber;
  /* Terminate early if the number doesn't exist, it's 0 or lower than 0.001 */

  if (number === 0) {
    return "0";
  }

  if ((0, _typy["default"])(number).isFalsy) {
    return null;
  }

  if (number < 1e-3) {
    if (roundSmallNumbersToZero) {
      return _devConstants.NEAR_ZERO;
    } else {
      return number.toLocaleString();
    }
  }

  if (number < 1e4) {
    return number.toLocaleString();
  }
  /* Number of decimal places to show */


  decimals = !decimals || decimals < 0 ? 0 : decimals;
  /* Get power */

  var b = number.toPrecision(2).split("e");
  /* Floor at decimals, ceiling at trillions */

  var k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
  /* Floor at decimals, ceiling at trillions */

  var c = k < 1 ? number.toFixed(0 + decimals) : (number / Math.pow(10, k * 3)).toFixed(1 + decimals);
  /* Enforce -0 is 0 */

  var d = c < 0 ? c : Math.abs(c);
  /* Append power */

  var e = d + ["", "K", "M", "B", "T"][k];
  return e;
}
/**
 * Bundlifies a number by returning all the formats needed through the codebase.
 * @param depositInAtomicUnits The stream's initial deposit. Optional, necessary only when the `percentage` is needed
 * @param numberInAtomicUnits The number to format measured in the atomic units of the token (e.g. 18 decimals)
 * @param token The number of decimals the token used as payment current of stream has
 * @returns bundle an object containing the atomic units BN, the cash value, human units BN and the percentage in
 * rapport to the deposit.
 */


function bundlify(_ref2) {
  var depositInAtomicUnits = _ref2.depositInAtomicUnits,
      _ref2$numberInAtomicU = _ref2.numberInAtomicUnits,
      numberInAtomicUnits = _ref2$numberInAtomicU === void 0 ? "" : _ref2$numberInAtomicU,
      _ref2$token = _ref2.token,
      token = _ref2$token === void 0 ? _devConstants.DEFAULT_TOKEN_MAINNET : _ref2$token;

  if ((0, _typy["default"])(numberInAtomicUnits).isFalsy) {
    return _devConstants.ZERO_BUNDLE;
  }

  if (typeof numberInAtomicUnits !== "number" && typeof numberInAtomicUnits !== "string") {
    return _devConstants.ZERO_BUNDLE;
  }

  var numberInAtomicUnitsBn = new _bignumber.BigNumber(numberInAtomicUnits);
  /* TODO: consider setting the percentage to 100 when `depositInAtomicUnits` is undefined */

  var percentage = null;

  if ((0, _typy["default"])(depositInAtomicUnits).isTruthy) {
    percentage = numberInAtomicUnitsBn.dividedBy(new _bignumber.BigNumber(depositInAtomicUnits)).multipliedBy(100).decimalPlaces(2).toNumber();
  }
  /* Caveat: ethers.js crashes when "tokenDecimals" is not a number */


  var tokenDecimals = (0, _typy["default"])(token, "decimals").safeTokenDecimals;
  var unitsBn = new _bignumber.BigNumber(_ethers.ethers.utils.formatUnits(numberInAtomicUnits, tokenDecimals));
  return {
    __typename: "Bundle",
    atomicUnits: numberInAtomicUnitsBn,

    /* TODO: what happens if the number has more than 53 bits? */
    cash: cashify({
      number: unitsBn.toNumber()
    }),
    percentage: percentage,
    units: unitsBn
  };
}
/**
 * Rounds a number to the given number of decimal points. Does not add zeros if there
 * are no decimal points.
 * Warning: for scientific values, this returns "NaN".
 * @param number the number to round
 * @param precision the rounding precision
 * @see https://stackoverflow.com/a/18358056/3873510
 */


function roundWithPrecision(num) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  return +(Math.round(num + "e+" + precision) + "e-" + precision);
}