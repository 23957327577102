"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCreateStreamManager = useCreateStreamManager;
exports.CreateStreamContextProvider = CreateStreamContextProvider;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireWildcard(require("react"));

var _typy = _interopRequireDefault(require("typy"));

var _vendors = require("@sablier/vendors");

var _reactRouterDom = require("react-router-dom");

var _INITIAL_CREATE_STREA;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var CREATE_STREAM_ERROR = "CREATE_STREAM_ERROR";
var CREATE_STREAM_LAST_STREAM_ID = "CREATE_STREAM_LAST_STREAM_ID";
var CREATE_STREAM_SIDEBAR = "CREATE_STREAM_SIDEBAR";
var UPDATE_KEY = "UPDATE_KEY";
var UPDATABLE_KEYS = [CREATE_STREAM_ERROR, CREATE_STREAM_SIDEBAR];
var INITIAL_CREATE_STREAM_CONTEXT = (_INITIAL_CREATE_STREA = {}, (0, _defineProperty2["default"])(_INITIAL_CREATE_STREA, CREATE_STREAM_ERROR, null), (0, _defineProperty2["default"])(_INITIAL_CREATE_STREA, CREATE_STREAM_LAST_STREAM_ID, null), (0, _defineProperty2["default"])(_INITIAL_CREATE_STREA, CREATE_STREAM_SIDEBAR, false), _INITIAL_CREATE_STREA);
var CreateStreamContext = (0, _react.createContext)();

function useCreateStreamContext() {
  return (0, _react.useContext)(CreateStreamContext);
}

function useCreateStreamManager() {
  var createStreamMatch = (0, _reactRouterDom.useRouteMatch)({
    path: "/stream/create",
    exact: true
  });

  var _useCreateStreamConte = useCreateStreamContext(),
      _useCreateStreamConte2 = (0, _slicedToArray2["default"])(_useCreateStreamConte, 2),
      state = _useCreateStreamConte2[0],
      updateKey = _useCreateStreamConte2[1].updateKey;

  var error = state[CREATE_STREAM_ERROR];
  var isSidebarOpen = state[CREATE_STREAM_SIDEBAR];
  var isOpen = isSidebarOpen || (0, _typy["default"])(createStreamMatch).isTruthy;
  /* "newError" must be a string */

  var setError = (0, _react.useCallback)(function (newError) {
    updateKey(CREATE_STREAM_ERROR, newError);
  }, [updateKey]);
  var toggleSidebar = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track(isOpen ? 'Close "Create Stream" Sidebar' : 'Open "Create Stream" Sidebar');

    updateKey(CREATE_STREAM_SIDEBAR, !isOpen);
  }, [isOpen, updateKey]);
  return {
    error: error,
    isOpen: isOpen,
    isSidebarOpen: isSidebarOpen,
    setError: setError,
    toggleSidebar: toggleSidebar
  };
}

function reducer(state, _ref) {
  var type = _ref.type,
      payload = _ref.payload;

  switch (type) {
    case UPDATE_KEY:
      {
        var key = payload.key,
            value = payload.value;

        if (!UPDATABLE_KEYS.some(function (k) {
          return k === key;
        })) {
          throw new Error("Unexpected key in CreateStreamContext reducer: '".concat(key, "'."));
        } else {
          return _objectSpread({}, state, (0, _defineProperty2["default"])({}, key, value));
        }
      }

    default:
      {
        throw new Error("Unexpected action type in CreateStreamContext reducer: '".concat(type, "'."));
      }
  }
}

function CreateStreamContextProvider(_ref2) {
  var children = _ref2.children;

  var _useReducer = (0, _react.useReducer)(reducer, INITIAL_CREATE_STREAM_CONTEXT),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var updateKey = (0, _react.useCallback)(function (key, value) {
    dispatch({
      type: UPDATE_KEY,
      payload: {
        key: key,
        value: value
      }
    });
  }, []);
  return _react["default"].createElement(CreateStreamContext.Provider, {
    value: (0, _react.useMemo)(function () {
      return [state, {
        updateKey: updateKey
      }];
    }, [state, updateKey])
  }, children);
}