"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _typy = _interopRequireDefault(require("typy"));
/* See https://stackoverflow.com/questions/58844886/why-do-we-need-cacheredirects-at-all */


var _default = {
  Query: {
    /* TODO: check if this truly works for history */
    stream: function stream(_, args, _ref) {
      var getCacheKey = _ref.getCacheKey;
      return getCacheKey({
        __typename: "Stream",
        id: (0, _typy["default"])(args, "id").safeString
      });
    }
  }
};
exports["default"] = _default;