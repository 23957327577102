"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Identicon;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _jazzicon = _interopRequireDefault(require("jazzicon"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _reactHooks = require("@sablier/react-hooks");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  border-radius: 1.125rem;\n  height: 1rem;\n  width: 1rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledIdenticon = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.silverSandGray;
});

function Identicon() {
  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      account = _useWeb3React.account;

  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    if ((0, _typy["default"])(account).isTruthy && (0, _typy["default"])(ref, "current").isTruthy) {
      ref.current.innerHTML = "";
      ref.current.appendChild((0, _jazzicon["default"])(16, parseInt(account.slice(2, 10), 16)));
    }
  }, [account]);
  return _react["default"].createElement(StyledIdenticon, {
    ref: ref
  });
}