"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _mixpanelBrowser = _interopRequireDefault(require("mixpanel-browser"));

var _typy = _interopRequireDefault(require("typy"));

_mixpanelBrowser["default"].init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  api_host: "https://api.mixpanel.com"
});

var actions = {
  identify: function identify(account) {
    if ((0, _typy["default"])(account).isTruthy) {
      _mixpanelBrowser["default"].identify(account);
    }
  },

  /* "People" properties are stored persistently on the server */
  people: {
    set: function set(props) {
      if ((0, _typy["default"])(props).isTruthy) {
        _mixpanelBrowser["default"].people.set(props);
      }
    },
    unset: function unset(key) {
      if ((0, _typy["default"])(key).isTruthy) {
        _mixpanelBrowser["default"].people.unset(key);
      }
    }
  },

  /* "Super" properties are stored in cookies and are sent with every event */
  register: function register(props) {
    if ((0, _typy["default"])(props).isTruthy) {
      _mixpanelBrowser["default"].register(props);
    }
  },
  track: function track(message, props) {
    if ((0, _typy["default"])(message).isTruthy) {
      if ((0, _typy["default"])(props).isTruthy) {
        _mixpanelBrowser["default"].track(message, props);
      } else {
        _mixpanelBrowser["default"].track(message);
      }
    }
  },
  unregister: function unregister(props) {
    if ((0, _typy["default"])(props).isTruthy) {
      _mixpanelBrowser["default"].unregister(props);
    }
  }
};
var _default = actions;
exports["default"] = _default;