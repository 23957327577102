"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _polished = require("polished");

var _reactRouterDom = require("react-router-dom");

var _Spinner = _interopRequireDefault(require("../Spinner"));

var _Title = _interopRequireDefault(require("./Title"));

var _designer = require("./designer");

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  right: 1rem;\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n         background-color: ", ";\n         border: 1px solid ", ";\n         transition: background-color 200ms;\n\n         &:hover {\n           background-color: ", ";\n           transition: background-color 200ms;\n         }\n       "]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n             background-size: 200% 100%;\n             transition: background-size 200ms;\n           "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n         ", ";\n         background-position-y: 0%;\n         background-position-x: 0%;\n         background-size: 100% 100%;\n         transition: background-size 200ms;\n\n         &:hover {\n           background-size: 200% 100%;\n           transition: background-size 200ms;\n         }\n         ", "\n       "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n         background-color: ", ";\n         transition: background-color 200ms;\n\n         &:hover {\n           background-color: ", ";\n           transition: background-color 200ms;\n         }\n       "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        padding: 0.5rem;\n      "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0.75rem;\n    ", ";\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      width: 100%;\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      min-height: 1rem;\n      padding: 0.5rem 0.65rem;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      cursor: not-allowed !important;\n      user-select: none;\n      &:active {\n        pointer-events: none;\n      }\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      cursor: not-allowed;\n      filter: grayscale(100%);\n      user-select: none;\n      &:active {\n        pointer-events: none;\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 3.125rem;\n  cursor: pointer;\n  justify-content: center;\n  min-height: 1.25rem;\n  padding: 0.75rem 1.25rem;\n  position: relative;\n\n  ", "\n\n  ", "\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var wrapperCss = (0, _styledComponents.css)(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.lavenderGray;
}, function (props) {
  return props.isDisabled && (0, _styledComponents.css)(_templateObject2());
}, function (props) {
  return (props.isDisabledSoft || props.isLoading) && (0, _styledComponents.css)(_templateObject3());
}, function (props) {
  return props.isMini && (0, _styledComponents.css)(_templateObject4());
}, function (props) {
  return props.isFullWidth && (0, _styledComponents.css)(_templateObject5());
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6(), props.isMini && (0, _styledComponents.css)(_templateObject7()));
}, function (props) {
  var design = props.design;

  switch (design.behaviour) {
    case _designer.behaviour.solid:
      return (0, _styledComponents.css)(_templateObject8(), design.background, design.backgroundHover || (0, _polished.lighten)(0.05, design.background));

    case _designer.behaviour.gradient:
      return (0, _styledComponents.css)(_templateObject9(), design.background, props.isLoading && (0, _styledComponents.css)(_templateObject10()));

    case _designer.behaviour.outlined:
      return (0, _styledComponents.css)(_templateObject11(), design.background, design.border, design.backgroundHover || props.theme.ghostWhite);

    default:
      return (0, _styledComponents.css)(_templateObject12());
  }
});

var LinkWrapper = _styledComponents["default"].a(_templateObject13(), wrapperCss);

var ButtonWrapper = _styledComponents["default"].div(_templateObject14(), wrapperCss);

var RouterWrapper = _styledComponents["default"].div(_templateObject15(), wrapperCss);

var LoaderWrapper = _styledComponents["default"].div(_templateObject16());

var StyledLoader = (0, _styledComponents["default"])(_Spinner["default"]).attrs(function (props) {
  switch (props.t) {
    case "gradient":
    case "gradient-primary":
    case "gradient-secondary":
      return {
        borderColor: (0, _polished.rgba)(props.theme.white, 0.4),
        color: props.theme.white,
        size: 1.2
      };

    default:
      return {
        size: 1.2
      };
  }
})(_templateObject17());

function ButtonV2(_ref) {
  var className = _ref.className,
      childrenLeft = _ref.childrenLeft,
      childrenRight = _ref.childrenRight,
      href = _ref.href,
      isDisabled = _ref.isDisabled,
      isDisabledSoft = _ref.isDisabledSoft,
      isFullWidth = _ref.isFullWidth,
      isLoading = _ref.isLoading,
      isMini = _ref.isMini,
      onClick = _ref.onClick,
      target = _ref.target,
      theme = _ref.theme,
      title = _ref.title,
      titleMedium = _ref.titleMedium,
      titleShort = _ref.titleShort,
      to = _ref.to,
      type = _ref.type,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["className", "childrenLeft", "childrenRight", "href", "isDisabled", "isDisabledSoft", "isFullWidth", "isLoading", "isMini", "onClick", "target", "theme", "title", "titleMedium", "titleShort", "to", "type"]);
  var history = (0, _reactRouterDom.useHistory)();
  var design = (0, _designer.useDesigner)(theme);
  var sharedProps = {
    className: className,
    design: design,
    isDisabled: isDisabled,
    isDisabledSoft: isDisabledSoft,
    isFullWidth: isFullWidth,
    isLoading: isLoading,
    isMini: isMini,
    t: theme
  };
  var titleProps = {
    title: title,
    design: design,
    titleMedium: titleMedium,
    titleShort: titleShort
  };
  var renderLoader = (0, _react.useCallback)(function () {
    return isLoading ? _react["default"].createElement(LoaderWrapper, (0, _extends2["default"])({}, otherProps, {
      "data-component": "loader"
    }), _react["default"].createElement(StyledLoader, {
      t: theme
    })) : _react["default"].createElement(_react["default"].Fragment, null);
  }, [isLoading, otherProps, theme]);
  var renderBody = (0, _react.useCallback)(function () {
    return _react["default"].createElement(_react["default"].Fragment, null, childrenLeft, _react["default"].createElement(_Title["default"], (0, _extends2["default"])({}, titleProps, {
      design: design
    })), childrenRight, renderLoader());
  }, [childrenLeft, childrenRight, design, titleProps, renderLoader]);
  var onFinalClick = (0, _react.useCallback)(function (e) {
    if (isDisabled || isDisabledSoft) {
      e.preventDefault();
    } else {
      onClick(e);

      if (type === "router") {
        history.push(to);
      }
    }
  }, [history, isDisabled, isDisabledSoft, onClick, to, type]);

  switch (type) {
    case "link":
      return _react["default"].createElement(LinkWrapper, (0, _extends2["default"])({}, sharedProps, {
        href: href,
        onClick: onFinalClick,
        rel: "noopener noreferrer",
        target: target
      }), renderBody());

    case "router":
      return _react["default"].createElement(RouterWrapper, (0, _extends2["default"])({}, sharedProps, {
        onClick: onFinalClick
      }), renderBody());

    default:
      return _react["default"].createElement(ButtonWrapper, (0, _extends2["default"])({}, sharedProps, {
        onClick: onFinalClick
      }), renderBody());
  }
}

ButtonV2.propTypes = {
  childrenLeft: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].node]),
  childrenRight: _propTypes["default"].oneOfType([_propTypes["default"].func, _propTypes["default"].node]),
  className: _propTypes["default"].string,
  href: _propTypes["default"].string,
  isDisabled: _propTypes["default"].bool,
  isDisabledSoft: _propTypes["default"].bool,
  isFullWidth: _propTypes["default"].bool,
  isLoading: _propTypes["default"].bool,
  isMini: _propTypes["default"].bool,
  onClick: _propTypes["default"].func,
  target: _propTypes["default"].string,
  theme: _propTypes["default"].string,
  title: _propTypes["default"].string.isRequired,
  titleShort: _propTypes["default"].string,
  // empty string will hide the title on small screens
  titleMedium: _propTypes["default"].string,
  // empty string will hide the title on medium screens
  to: _propTypes["default"].string,
  type: _propTypes["default"].oneOf(["button", "link", "router"])
};
ButtonV2.defaultProps = {
  childrenLeft: null,
  childrenRight: null,
  className: null,
  href: "",
  isDisabled: false,
  isDisabledSoft: false,
  isFullWidth: false,
  isLoading: false,
  isMini: false,
  onClick: function onClick() {},
  target: "_blank",
  theme: "outline",
  titleShort: null,
  titleMedium: null,
  to: null,
  type: "button"
};
var _default = ButtonV2;
exports["default"] = _default;