"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _fortmaticIcon = _interopRequireDefault(require("@sablier/assets/images/fortmatic-icon.png"));

var _portisIcon = _interopRequireDefault(require("@sablier/assets/images/portis-icon.png"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _connectors = require("@sablier/connectors");

var _theme = require("@sablier/theme");

var _utils = require("@sablier/utils");

var _reactDeviceDetect = require("react-device-detect");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _CopyHelper = _interopRequireDefault(require("../../CopyHelper"));

var _Identicon = _interopRequireDefault(require("../../Identicon"));

function _templateObject19() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 12px;\n  "]);

  _templateObject19 = function _templateObject19() {
    return data;
  };

  return data;
}

function _templateObject18() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border: 1px solid ", ";\n  border-radius: 1.25rem;\n  color: ", ";\n  font-family: ", ";\n  justify-content: center;\n  padding: 0.5rem 1.5rem;\n\n  &:active,\n  &:hover {\n    border: 1px solid ", ";\n    cursor: pointer;\n  }\n\n  ", ";\n"]);

  _templateObject18 = function _templateObject18() {
    return data;
  };

  return data;
}

function _templateObject17() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    align-items: flex-end;\n  "]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n\n  & > img,\n  span {\n    height: ", ";\n    width: ", ";\n  }\n\n  ", ";\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  padding-left: 0.5rem;\n  width: initial;\n"]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  justify-content: center;\n  margin: 1.875rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      margin-bottom: 0.5rem;\n    "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  font-size: ", ";\n  font-weight: ", ";\n  min-width: 0rem;\n\n  & a {\n    min-width: 0rem;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    &:active,\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-left: 0.75rem;\n  margin-right: 0.125rem;\n  margin-top: -0.375rem;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-weight: 400;\n  margin-left: 16px;\n\n  :active,\n  :hover {\n    cursor: pointer;\n    text-decoration: underline;\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  color: ", ";\n  font-weight: 500;\n  justify-content: space-between;\n\n  div {\n    ", ";\n    align-items: center;\n  }\n\n  &:first-of-type {\n    margin-bottom: 20px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n    padding-top: 0rem;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  padding: 0rem 1.5rem;\n\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 1rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  font-size: 1.1875rem;\n  font-weight: 500;\n  margin: 0rem;\n  padding: 1.5rem;\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-color: ", ";\n  position: relative;\n\n  & h4 {\n    font-weight: 500;\n    margin-top: 0rem;\n  }\n\n  & h5 {\n    font-size: 1rem;\n    font-weight: 400;\n    margin: 0rem;\n    margin-bottom: 0.5rem;\n  }\n\n  & h5:last-child {\n    margin-bottom: 0rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: 1px solid ", ";\n  border-radius: 1.25rem;\n  padding: 1rem;\n\n  & h4 {\n    font-weight: 500;\n    margin: 0rem;\n  }\n\n  & h5 {\n    font-weight: 400;\n    margin: 0rem 0rem 1rem 0rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
/**
 * This has be here because stylelint triggers a false positive linting error otherwise. It thinks that
 * `& h5` should be defined before `& h5:last-child`.
 */


var AccountWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.platinumGray;
});

var OuterWrapper = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.snowWhite;
});

var TitleLabel = _styledComponents["default"].h4(_templateObject3(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4());
});

var AccountSectionWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});

var GroupingRow = _styledComponents["default"].div(_templateObject7(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.flexRowNoWrap;
});

var WalletAction = _styledComponents["default"].div(_templateObject8(), function (props) {
  return props.theme.chaliceGray;
});

var MainWalletAction = (0, _styledComponents["default"])(WalletAction)(_templateObject9(), function (props) {
  return props.theme.bleuDeFranceBlue;
});
var StyledGreenCircle = (0, _styledComponents["default"])(_theme.GreenCircle)(_templateObject10());

var AccountControl = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.hasENS ? props.isENS ? "1rem" : "0.8rem" : "1rem";
}, function (props) {
  return props.hasENS ? props.isENS ? "500" : "400" : "500";
}, function (props) {
  return props.hasENS && props.isENS && (0, _styledComponents.css)(_templateObject12());
});

var ConnectButtonRow = _styledComponents["default"].div(_templateObject13(), function (props) {
  return props.theme.flexRowNoWrap;
});

var StyledLink = (0, _styledComponents["default"])(_theme.Link)(_templateObject14(), function (props) {
  return props.hasENS ? props.isENS ? props.theme.bleuDeFranceBlue : props.theme.nickelGray : props.theme.bleuDeFranceBlue;
});

var WalletName = _styledComponents["default"].div(_templateObject15());

var IconWrapper = _styledComponents["default"].div(_templateObject16(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.size ? props.size + "rem" : "2rem";
}, function (props) {
  return props.size ? props.size + "rem" : "2rem";
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject17());
});

var OptionButton = _styledComponents["default"].div(_templateObject18(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.bleuDeFranceBlue;
}, function (props) {
  return props.theme.bleuDeFranceBlue;
}, function (props) {
  return props.theme.fallbackFont;
}, function (props) {
  return props.theme.blueJeansBlue;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject19());
});

function AccountDetails(_ref) {
  var toggleWalletModal = _ref.toggleWalletModal,
      openOptions = _ref.openOptions;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      account = _useWeb3React.account,
      chainId = _useWeb3React.chainId,
      connector = _useWeb3React.connector;

  var ENSName = (0, _reactHooks.useENSName)(account);
  /* TODO: find an alternative, this is unnecessarily complicated */

  var formatConnectorName = (0, _react.useCallback)(function () {
    var isMetaMask = window.ethereum && (0, _typy["default"])(window.ethereum.isMetaMask).isTruthy;
    var name = Object.keys(_connectors.SUPPORTED_WALLETS).filter(function (key) {
      return _connectors.SUPPORTED_WALLETS[key].connector === connector && (connector !== _connectors.injected || isMetaMask === (key === "METAMASK"));
    }).map(function (k) {
      return translation(_connectors.SUPPORTED_WALLETS[k].name);
    })[0];
    return _react["default"].createElement(WalletName, null, name);
  }, [connector, translation]);
  var renderIcon = (0, _react.useCallback)(function () {
    if (connector === _connectors.injected) {
      return _react["default"].createElement(IconWrapper, {
        size: 1
      }, _react["default"].createElement(_Identicon["default"], null), formatConnectorName());
    } else if (connector === _connectors.walletconnect) {
      return _react["default"].createElement(IconWrapper, {
        size: 1
      }, _react["default"].createElement("img", {
        alt: "",
        src: require("@sablier/assets/svg/wallet-connect-icon.svg")
      }), formatConnectorName());
    } else if (connector === _connectors.walletlink) {
      return _react["default"].createElement(IconWrapper, {
        size: 1
      }, _react["default"].createElement("img", {
        alt: "",
        src: require("@sablier/assets/svg/coinbase-wallet-icon.svg")
      }), formatConnectorName());
    } else if (connector === _connectors.fortmatic) {
      return _react["default"].createElement(IconWrapper, {
        size: 1
      }, _react["default"].createElement("img", {
        alt: "",
        src: _fortmaticIcon["default"]
      }), formatConnectorName());
    } else if (connector === _connectors.portis) {
      return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(IconWrapper, {
        size: 1
      }, _react["default"].createElement("img", {
        alt: "",
        src: _portisIcon["default"]
      }), formatConnectorName(), _react["default"].createElement(MainWalletAction, {
        onClick: function onClick() {
          connector.portis.showPortis();
        }
      }, translation("words.show"), translation("words.portis"))));
    }

    return undefined;
  }, [connector, formatConnectorName, translation]);
  var renderFirstGroupingRow = (0, _react.useCallback)(function () {
    return _react["default"].createElement(GroupingRow, null, renderIcon(), _react["default"].createElement("div", null, connector !== _connectors.injected && connector !== _connectors.walletlink && _react["default"].createElement(WalletAction, {
      onClick: function onClick() {
        connector.close();
      }
    }, translation("words.disconnect")), _react["default"].createElement(StyledGreenCircle, null)));
  }, [connector, renderIcon, translation]);
  var renderSecondGroupingRow = (0, _react.useCallback)(function () {
    return _react["default"].createElement(GroupingRow, null, (0, _typy["default"])(ENSName).isTruthy && _react["default"].createElement(AccountControl, {
      hasENS: (0, _typy["default"])(ENSName).isTruthy,
      isENS: true
    }, _react["default"].createElement(StyledLink, {
      hasENS: (0, _typy["default"])(ENSName).isTruthy,
      href: (0, _utils.getEtherscanLink)({
        chainId: chainId,
        data: ENSName,
        type: "address"
      }),
      isENS: true
    }, ENSName, "\xA0", "↗ "), _react["default"].createElement(_CopyHelper["default"], {
      toCopy: ENSName
    })), _react["default"].createElement(AccountControl, {
      hasENS: (0, _typy["default"])(ENSName).isTruthy,
      isENS: false
    }, _react["default"].createElement(StyledLink, {
      hasENS: (0, _typy["default"])(ENSName).isTruthy,
      href: (0, _utils.getEtherscanLink)({
        chainId: chainId,
        data: account,
        type: "address"
      }),
      isENS: false
    }, account, "\xA0", "↗ "), _react["default"].createElement(_CopyHelper["default"], {
      toCopy: account
    })));
  }, [ENSName, account, chainId]);
  var renderConnectButton = (0, _react.useCallback)(function () {
    var isEthereumFalsy = (0, _typy["default"])(window, "ethereum").isFalsy;
    var isWeb3Falsy = (0, _typy["default"])(window, "web3").isFalsy;

    if (!_reactDeviceDetect.isMobile || isEthereumFalsy && isWeb3Falsy) {
      return _react["default"].createElement(ConnectButtonRow, null, _react["default"].createElement(OptionButton, {
        onClick: function onClick() {
          openOptions();
        }
      }, translation("modals.wallet.connectToADifferentWallet")));
    } else {
      return null;
    }
  }, [openOptions, translation]);
  return _react["default"].createElement(OuterWrapper, null, _react["default"].createElement(TitleLabel, null, translation("words.account")), _react["default"].createElement(_theme.TopRightCloseIcon, {
    onClick: toggleWalletModal
  }), _react["default"].createElement(AccountSectionWrapper, null, _react["default"].createElement(AccountWrapper, null, renderFirstGroupingRow(), renderSecondGroupingRow()), renderConnectButton()));
}

AccountDetails.propTypes = {
  openOptions: _propTypes["default"].func.isRequired,
  toggleWalletModal: _propTypes["default"].func.isRequired
};
var _default = AccountDetails;
exports["default"] = _default;