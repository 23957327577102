"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactCommon = require("@apollo/react-common");

function MemoizedProvider(_ref) {
  var client = _ref.client,
      children = _ref.children;
  var ApolloContext = (0, _reactCommon.getApolloContext)();
  var value = (0, _react.useMemo)(function () {
    return {
      client: client
    };
  }, [client]);
  return _react["default"].createElement(ApolloContext.Provider, {
    value: value
  }, children);
}

MemoizedProvider.propTypes = {
  client: _propTypes["default"].shape({})
};
MemoizedProvider.defaultProps = {
  client: null
};
var _default = MemoizedProvider;
exports["default"] = _default;