"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useContract = useContract;
exports.useCTokenManagerContract = useCTokenManagerContract;
exports.usePayrollContract = usePayrollContract;
exports.useSablierContract = useSablierContract;
exports.useTokenContract = useTokenContract;

var _react = require("react");

var _CTokenManager = _interopRequireDefault(require("@sablier/dev-constants/lib/abis/CTokenManager"));

var _Erc = _interopRequireDefault(require("@sablier/dev-constants/lib/abis/Erc20"));

var _Payroll = _interopRequireDefault(require("@sablier/dev-constants/lib/abis/Payroll"));

var _Sablier = _interopRequireDefault(require("@sablier/dev-constants/lib/abis/Sablier"));

var _devConstants = require("@sablier/dev-constants");

var _utils = require("@sablier/utils");

var _web3React = require("./web3React");
/* Returns null on errors */


function useContract(_ref) {
  var abi = _ref.abi,
      address = _ref.address,
      _ref$withSignerIfPoss = _ref.withSignerIfPossible,
      withSignerIfPossible = _ref$withSignerIfPoss === void 0 ? true : _ref$withSignerIfPoss;

  var _useWeb3React = (0, _web3React.useWeb3React)(),
      account = _useWeb3React.account,
      library = _useWeb3React.library;

  return (0, _react.useMemo)(function () {
    try {
      return (0, _utils.getContract)({
        abi: abi,
        account: withSignerIfPossible ? account : undefined,
        address: address,
        library: library
      });
    } catch (_unused) {
      return null;
    }
  }, [abi, account, address, library, withSignerIfPossible]);
}

function useCTokenManagerContract() {
  var withSignerIfPossible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  var _useWeb3React2 = (0, _web3React.useWeb3React)(),
      chainId = _useWeb3React2.chainId;

  return useContract({
    abi: _CTokenManager["default"],
    address: _devConstants.CTOKEN_MANAGER_ADDRESSES[chainId],
    withSignerIfPossible: withSignerIfPossible
  });
}

function usePayrollContract() {
  var withSignerIfPossible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  var _useWeb3React3 = (0, _web3React.useWeb3React)(),
      chainId = _useWeb3React3.chainId;

  return useContract({
    abi: _Payroll["default"],
    address: _devConstants.PAYROLL_ADDRESSES[chainId],
    withSignerIfPossible: withSignerIfPossible
  });
}

function useSablierContract() {
  var withSignerIfPossible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

  var _useWeb3React4 = (0, _web3React.useWeb3React)(),
      chainId = _useWeb3React4.chainId;

  return useContract({
    abi: _Sablier["default"],
    address: _devConstants.SABLIER_ADDRESSES[chainId],
    withSignerIfPossible: withSignerIfPossible
  });
}

function useTokenContract(_ref2) {
  var tokenAddress = _ref2.tokenAddress,
      _ref2$withSignerIfPos = _ref2.withSignerIfPossible,
      withSignerIfPossible = _ref2$withSignerIfPos === void 0 ? true : _ref2$withSignerIfPos;
  return useContract({
    abi: _Erc["default"],
    address: tokenAddress,
    withSignerIfPossible: withSignerIfPossible
  });
}