"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _theme = require("@sablier/theme");

var _reactRouterDom = require("react-router-dom");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

var _Item = _interopRequireDefault(require("./Item"));

var _Spinner = _interopRequireDefault(require("../../Spinner"));

var _TitleAndBorderedCross = _interopRequireDefault(require("../../TitleAndBorderedCross"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    min-width: 0;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  justify-content: center;\n  padding: 1rem 0rem;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", ";\n    border: none;\n    min-width: 0;\n    width: 100%;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", "\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.25rem;\n  min-height: 40rem;\n  min-width: 35rem;\n\n  /* See this for why we need 100% instead of 100vw: https://bit.ly/34xeInG */\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 0rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: stretch;\n  flex-grow: 1;\n  justify-content: center;\n  padding: 3rem 0rem;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var ContentWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.borderedFlexColumnNoWrap;
}, function (props) {
  return props.theme.boxShadowDarkGunmetalBlack;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject4(), props.theme.backgroundColor);
});

var ListWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject6());
});
/* TODO: figure out a better way to handle the dichotomy between the recipient and sender app here */


function HistoryPage(_ref) {
  var _children = _ref.children,
      forRecipient = _ref.forRecipient,
      forSender = _ref.forSender,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "forRecipient", "forSender"]);
  var location = (0, _reactRouterDom.useLocation)();
  var params = (0, _reactRouterDom.useParams)();
  var routerHistory = (0, _reactRouterDom.useHistory)();

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useStreamHistory = (0, _reactHooks.useStreamHistory)(),
      error = _useStreamHistory.error,
      history = _useStreamHistory.history,
      loading = _useStreamHistory.loading;
  /** Memoized Values **/

  /* A cancelled stream pays the recipient what has been streamed so far and returns the sender the remainder */


  var cancellationCash = (0, _react.useMemo)(function () {
    if (forRecipient) {
      return (0, _typy["default"])(history, "stream.cancellation.bundles.recipientBalance.cash").safeString;
    }

    if (forSender) {
      return (0, _typy["default"])(history, "stream.cancellation.bundles.senderBalance.cash").safeString;
    }

    return "";
  }, [forRecipient, forSender, history]);
  /** Callbacks **/

  var goBack = (0, _react.useCallback)(function () {
    var proxyStreamId = (0, _typy["default"])(params, "id").safeString;

    if ((0, _typy["default"])(proxyStreamId).isTruthy) {
      /* When the user opened the Stream page first, pop the history stack */
      if ((0, _typy["default"])(location, "state.from").safeString === "stream/" + proxyStreamId) {
        routerHistory.goBack();
      } else {
        /* When the user opened the History page first, push the Stream page into the stack */
        routerHistory.push({
          pathname: "/stream/" + proxyStreamId,
          state: {
            from: "/stream/" + proxyStreamId + "/history"
          }
        });
      }
    } else {
      /* When the id is falsy, go back home to the dashboard */
      routerHistory.push({
        pathname: "/",
        state: {
          from: "history"
        }
      });
    }
  }, [location, params, routerHistory]);
  var render = (0, _react.useCallback)(function () {
    if ((0, _typy["default"])(error).isTruthy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_theme.UnknownErrorLabel, null, translation("structs.unknownError")));
    }

    if (loading) {
      return _react["default"].createElement(_theme.CentringWrapper, null, _react["default"].createElement(_Spinner["default"], null));
    }

    if ((0, _typy["default"])(history).isFalsy) {
      return _react["default"].createElement(_theme.CentringWrapper, null, translation("structs.noData"));
    }

    return _react["default"].createElement(ListWrapper, null, (0, _typy["default"])(history, "stream.cancellation").isTruthy && _react["default"].createElement(_Item["default"], {
      cash: cancellationCash,
      key: (0, _typy["default"])(history, "stream.cancellation.txhash").safeString,
      label: translation("words.cancelled"),
      timeLabel: (0, _typy["default"])(history, "stream.cancellation.timeLabel").safeString,
      txhash: (0, _typy["default"])(history, "stream.cancellation.txhash").safeString,
      type: _devConstants.STREAM_ACTION_TYPE.cancellation
    }), (0, _typy["default"])(history, "stream.withdrawals").isTruthy && (0, _typy["default"])(history, "stream.withdrawals").safeObject.map(function (withdrawal) {
      return _react["default"].createElement(_Item["default"], {
        cash: (0, _typy["default"])(withdrawal, "bundles.amount.cash").safeString,
        key: (0, _typy["default"])(withdrawal, "id").safeString,
        label: translation("words.withdrawal"),
        timeLabel: (0, _typy["default"])(withdrawal, "timeLabel").safeString,
        txhash: (0, _typy["default"])(withdrawal, "id").safeString,
        type: _devConstants.STREAM_ACTION_TYPE.withdrawal
      });
    }), (0, _typy["default"])(history, "stream.bundles.deposit").isTruthy && (0, _typy["default"])(history, "stream.txs[0]").isTruthy && _react["default"].createElement(_Item["default"], {
      cash: (0, _typy["default"])(history, "stream.bundles.deposit.cash").safeString,
      key: (0, _typy["default"])(history, "stream.txs[0].txhash").safeString,
      label: translation("words.deposit"),
      timeLabel: (0, _typy["default"])(history, "stream.timeLabels.depositTime").safeString,
      txhash: (0, _typy["default"])(history, "stream.txs[0].txhash").safeString,
      type: _devConstants.STREAM_ACTION_TYPE.deposit
    }));
  }, [cancellationCash, error, history, loading, translation]);
  return _react["default"].createElement(Wrapper, otherProps, _react["default"].createElement(ContentWrapper, null, _react["default"].createElement(_TitleAndBorderedCross["default"], {
    onClickBorderedCross: goBack,
    title: translation("words.history")
  }), render()));
}

HistoryPage.propTypes = {
  forRecipient: _propTypes["default"].bool,
  forSender: _propTypes["default"].bool
};
HistoryPage.defaultProps = {
  forRecipient: false,
  forSender: false
};
var _default = HistoryPage;
exports["default"] = _default;