"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HISTORY = exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _bundle = _interopRequireDefault(require("./bundle"));

var _cancellation = _interopRequireDefault(require("./cancellation"));

var _stream = _interopRequireDefault(require("./stream"));

var _token = _interopRequireDefault(require("./token"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query History($proxyStreamId: ID!) {\n    history: proxyStream(id: $proxyStreamId) {\n      id\n      sender\n      recipient\n      stream {\n        ...History\n      }\n    }\n  }\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment History on Stream {\n    id\n    cancellation {\n      ...Cancellation\n    }\n    deposit\n    ratePerSecond\n    token {\n      address: id\n      decimals\n      symbol\n    }\n    ...LocalHistory\n    ...Txs\n    ...Withdrawals\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment LocalHistory on Stream {\n    bundles @client {\n      deposit {\n        ...Bundle\n      }\n    }\n    cancellation {\n      bundles @client {\n        recipientBalance {\n          ...Bundle\n        }\n        senderBalance {\n          ...Bundle\n        }\n      }\n      timeLabel @client\n    }\n    timeLabels @client {\n      depositTime\n    }\n    withdrawals(orderBy: timestamp, orderDirection: desc) {\n      bundles @client {\n        amount {\n          ...Bundle\n        }\n      }\n      timeLabel @client\n    }\n  }\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var HISTORY = {
  fragments: {}
};
HISTORY.fragments.local = (0, _graphqlTag["default"])(_templateObject(), _bundle["default"].fragments.bundle);
HISTORY.fragments.history = (0, _graphqlTag["default"])(_templateObject2(), _cancellation["default"].fragments.cancellation, HISTORY.fragments.local, _stream["default"].fragments.txs, _stream["default"].fragments.withdrawals, _token["default"].fragments.token);
var _default = HISTORY;
exports["default"] = _default;
var GET_HISTORY = (0, _graphqlTag["default"])(_templateObject3(), HISTORY.fragments.history);
exports.GET_HISTORY = GET_HISTORY;