"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _typy = _interopRequireDefault(require("typy"));

var _bignumber = require("bignumber.js");

var _devConstants = require("@sablier/dev-constants");

var _utils = require("@sablier/utils");

function _default(_ref) {
  var startTime = _ref.startTime,
      stopTime = _ref.stopTime,
      txs = _ref.txs;
  var depositTimeLabel = (0, _typy["default"])(txs, "[0].timestamp").isTruthy ? _dayjs["default"].unix((0, _typy["default"])(txs, "[0].timestamp").safeString).format(_devConstants.ISO_8601_FORMAT) : undefined;
  var timeLabels = {
    __typename: "TimeLabels",
    depositTime: depositTimeLabel
  };
  var now = new _bignumber.BigNumber((0, _dayjs["default"])().unix());

  if (now.isGreaterThanOrEqualTo(startTime) && now.isLessThanOrEqualTo(stopTime)) {
    timeLabels.passed = (0, _utils.formatDuration)({
      durationInSeconds: now.minus(startTime).toNumber()
    });
    timeLabels.remaining = (0, _utils.formatDuration)({
      durationInSeconds: new _bignumber.BigNumber(stopTime).minus(now).toNumber()
    });
  } else {
    timeLabels.passed = "";
    timeLabels.remaining = "";
  }

  timeLabels.startTime = _dayjs["default"].unix(startTime).format(_devConstants.ISO_8601_FORMAT);
  timeLabels.stopTime = _dayjs["default"].unix(stopTime).format(_devConstants.ISO_8601_FORMAT);
  return timeLabels;
}