"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _vendors = require("@sablier/vendors");

var _reactHooks = require("@sablier/react-hooks");

var _reactI18next = require("react-i18next");

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 10pt;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 11pt;\n  font-weight: 500;\n  margin: 0rem;\n  text-align: center;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 1rem;\n  bottom: -0.5rem;\n  height: 2rem;\n  justify-content: center;\n  position: absolute;\n  right: -0.5rem;\n  transition: border 200ms ease-in-out;\n  width: 2rem;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 2rem;\n  height: 4rem;\n  justify-content: center;\n  margin-bottom: 1rem;\n  position: relative;\n  width: 4rem;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      transform: translateY(0);\n      transition: transform 300ms;\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  border-radius: 0.4rem;\n  bottom: 0.4rem;\n  padding: 0.4rem;\n  pointer-events: none;\n  position: absolute;\n  transform: translateY(4rem);\n  transition: transform 300ms ease-in-out;\n  width: calc(100% - 0.8rem);\n  z-index: 200;\n\n  & > p {\n    color: ", ";\n    font-size: 0.8125rem;\n    font-weight: 500;\n    margin: 0rem;\n    text-align: center;\n  }\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      padding: 0.8rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 0.5rem;\n  cursor: pointer;\n  grid-column: span 1;\n  justify-content: center;\n  overflow-y: hidden;\n  padding: 1.5rem 1rem;\n  position: relative;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n    transition: background-color 200ms ease-in-out;\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2());
});

var Label = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.primaryGradient;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.isCopied && (0, _styledComponents.css)(_templateObject4());
});

var IconWrapper = _styledComponents["default"].div(_templateObject5(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
}, function (props) {
  return props.theme.aliceBlue;
});

var IconActionWrapper = _styledComponents["default"].div(_templateObject6(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.aliceBlue;
});

var Title = _styledComponents["default"].p(_templateObject7(), function (props) {
  return props.theme.darkGunmetalBlack;
}, function (props) {
  return props.theme.catamaranFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject8());
});

function LinkCard(_ref) {
  var title = _ref.title,
      iconCenter = _ref.iconCenter,
      iconAction = _ref.iconAction,
      location = _ref.location,
      action = _ref.action,
      trackingMessage = _ref.trackingMessage;

  var _useTranslation = (0, _reactI18next.useTranslation)(),
      translation = _useTranslation.t;

  var _useCopyClipboard = (0, _reactHooks.useCopyClipboard)(1500),
      _useCopyClipboard2 = (0, _slicedToArray2["default"])(_useCopyClipboard, 2),
      isCopied = _useCopyClipboard2[0],
      setCopied = _useCopyClipboard2[1];
  /** Memoized values **/


  var onAction = (0, _react.useMemo)(function () {
    if (action === "copy") {
      return function () {
        setCopied((0, _typy["default"])(location).safeString);
      };
    }

    if (action === "visit") {
      return function () {
        window.open((0, _typy["default"])(location).safeString, "_blank");
      };
    }

    if (action === "func") {
      return function () {
        var func = (0, _typy["default"])(location).safeFunction;

        if ((0, _typy["default"])(func).isTruthy) {
          func();
        }
      };
    }

    return function () {};
  }, [action, location, setCopied]);
  /** Callbacks **/

  var onClick = (0, _react.useCallback)(function () {
    _vendors.mixpanel.track(trackingMessage);

    onAction();
  }, [onAction, trackingMessage]);
  return _react["default"].createElement(Wrapper, {
    onClick: onClick
  }, _react["default"].createElement(Label, {
    isCopied: isCopied
  }, _react["default"].createElement("p", null, translation("structs.copiedToClipboard"))), _react["default"].createElement(IconWrapper, null, iconCenter, (0, _typy["default"])(iconAction).isTruthy && _react["default"].createElement(IconActionWrapper, null, iconAction)), _react["default"].createElement(Title, null, title));
}

LinkCard.propTypes = {
  action: _propTypes["default"].oneOf(["copy", "visit", "custom"]),
  location: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].func]),
  iconAction: _propTypes["default"].oneOfType([_propTypes["default"].node, _propTypes["default"].shape({}), _propTypes["default"].func]),
  iconCenter: _propTypes["default"].oneOfType([_propTypes["default"].node, _propTypes["default"].shape({}), _propTypes["default"].func]).isRequired,
  title: _propTypes["default"].string.isRequired,
  trackingMessage: _propTypes["default"].string
};
LinkCard.defaultProps = {
  iconAction: null,
  location: "",
  trackingMessage: null
};
var _default = LinkCard;
exports["default"] = _default;