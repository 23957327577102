"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

var _utils = require("@sablier/utils");

var _vendors = require("@sablier/vendors");

var _reactHooks = require("@sablier/react-hooks");

var _ActionIcon = _interopRequireDefault(require("../../ActionIcon"));

var _TokenLogo = _interopRequireDefault(require("../../../TokenLogo"));

function _templateObject14() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.9375rem;\n  "]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: ", ";\n  font-size: 0.875rem;\n  font-weight: 600;\n  margin-left: 0.5rem;\n\n  ", "\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: none;\n    padding: 0rem 0.25rem;\n  "]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  padding: 0rem 0.5rem;\n\n  ", "\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 1.25rem;\n    width: 1.25rem;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: 1.5rem;\n  width: 1.5rem;\n\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex-grow: 1;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-family: ", ";\n  font-size: 0.75rem;\n  margin-left: 0.0625rem;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: 0.9375rem;\n  font-weight: 600;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0.75rem 0.25rem;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: flex-start;\n  margin: 0rem 1rem;\n\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", "\n  ", ";\n  align-items: center;\n  background-color: ", ";\n  border-radius: 1.25rem;\n  height: 2.5rem;\n  justify-content: center;\n  width: 2.5rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    ", ";\n    border-radius: 0.25rem;\n    margin: 0.375rem 0.75rem;\n    padding: 0.75rem;\n    transition: none;\n\n    &:active,\n    &:hover {\n      background-color: ", ";\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  background-color: ", ";\n  cursor: pointer;\n  padding: 1rem 1.25rem;\n  transition: background-color 200ms ease;\n\n  &:active,\n  &:hover {\n    background-color: ", ";\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = (0, _styledComponents["default"])(_theme.Link)(_templateObject(), function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.snowWhite;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject2(), props.theme.borderedFlexColumnNoWrap, props.theme.white);
});

var ActionIndicatorWrapper = _styledComponents["default"].div(_templateObject3(), function (props) {
  return props.theme.borderAzureishWhite;
}, function (props) {
  return props.theme.flexRowNoWrap;
}, function (props) {
  return props.theme.backgroundColor;
});

var LabelWrapper = _styledComponents["default"].div(_templateObject4(), function (props) {
  return props.theme.flexColumnNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject5());
});

var Label = _styledComponents["default"].span(_templateObject6());

var TimestampLabel = _styledComponents["default"].span(_templateObject7(), function (props) {
  return props.theme.robotoMonoFont;
});

var FlexGrowDiv = _styledComponents["default"].div(_templateObject8());

var StyledTokenLogo = (0, _styledComponents["default"])(_TokenLogo["default"]).attrs({
  alt: "Token Logo"
})(_templateObject9(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject10());
});

var ValueWrapper = _styledComponents["default"].div(_templateObject11(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject12());
});

var ValueLabel = _styledComponents["default"].span(_templateObject13(), function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject14());
});

function Item(_ref) {
  var cash = _ref.cash,
      label = _ref.label,
      timeLabel = _ref.timeLabel,
      txhash = _ref.txhash,
      type = _ref.type;

  var _useWeb3React = (0, _reactHooks.useWeb3React)(),
      chainId = _useWeb3React.chainId;

  var _useStreamHistory = (0, _reactHooks.useStreamHistory)(),
      history = _useStreamHistory.history;

  function onClick() {
    _vendors.mixpanel.track("Click History Item", {
      label: label,
      txhash: txhash
    });
  }

  return _react["default"].createElement(Wrapper, {
    href: (0, _utils.getEtherscanLink)({
      chainId: chainId,
      data: txhash,
      type: "transaction"
    }),
    onClick: onClick
  }, _react["default"].createElement(ActionIndicatorWrapper, null, _react["default"].createElement(_ActionIcon["default"], {
    type: type
  })), _react["default"].createElement(LabelWrapper, null, _react["default"].createElement(Label, null, label), _react["default"].createElement(TimestampLabel, null, timeLabel)), _react["default"].createElement(FlexGrowDiv, null), _react["default"].createElement(ValueWrapper, null, _react["default"].createElement(StyledTokenLogo, {
    address: (0, _typy["default"])(history, "stream.token.address").safeEthereumAddress
  }), _react["default"].createElement(ValueLabel, null, cash, " " + (0, _typy["default"])(history, "stream.token.symbol").safeTokenSymbol)));
}

Item.propTypes = {
  cash: _propTypes["default"].string.isRequired,
  label: _propTypes["default"].string.isRequired,
  timeLabel: _propTypes["default"].string.isRequired,
  txhash: _propTypes["default"].string.isRequired,
  type: _propTypes["default"].number.isRequired
};
var _default = Item;
exports["default"] = _default;