"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _typy = _interopRequireWildcard(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _ethers = require("ethers");
/* See https://github.com/flexdinesh/typy#addcustomtypes-custom-types */


(0, _typy.addCustomTypes)({
  /* See https://github.com/flexdinesh/typy/issues/33 */
  isDeepTruthy: function isDeepTruthy(input) {
    if ((0, _typy["default"])(input).isFalsy) {
      return false;
    }

    var keys = Object.keys(input);

    for (var i = 0; i < keys.length; i += 1) {
      var ownProperty = input[keys[i]];

      if ((0, _typy["default"])(ownProperty).isFalsy) {
        return false;
      }
    }

    return true;
  },

  /*
   * We don't use ethers' `getAddress` here because that throws an error for non-checksummed inputs. Some users may
   * not like that.
   */
  isEthereumAddress: function isEthereumAddress(input) {
    if (!(0, _typy["default"])(input).isString) {
      return false;
    }

    return input.match(_devConstants.ETHEREUM_ADDRESS_REGEX);
  },
  isEthereumNumber: function isEthereumNumber(input) {
    if ((0, _typy["default"])(input).isNumber) {
      input = input.toString();
    } else if (!(0, _typy["default"])(input).isString) {
      return false;
    }

    return input.match(_devConstants.ETHEREUM_NUMBER_REGEX);
  },
  isENSAddress: function isENSAddress(input) {
    if (!(0, _typy["default"])(input).isString) {
      return false;
    }

    var found = [".art", ".eth", ".kred", ".luxe", ".xyz"].some(function (domain) {
      return input.endsWith(domain);
    });
    return (0, _typy["default"])(found).isTruthy;
  },
  safeEthereumAddress: function safeEthereumAddress(input) {
    if (!(0, _typy["default"])(input).isString) {
      return "";
    } else {
      try {
        return _ethers.ethers.utils.getAddress(input);
      } catch (_unused) {
        return "";
      }
    }
  },
  safeTokenDecimals: function safeTokenDecimals(input) {
    if (!(0, _typy["default"])(input).isNumber || input === 0) {
      return 18;
    } else {
      return input;
    }
  },
  safeTokenName: function safeTokenName(input) {
    if (!(0, _typy["default"])(input).isString || input === "") {
      return "Token";
    } else {
      return input;
    }
  },
  safeTokenSymbol: function safeTokenSymbol(input) {
    if (!(0, _typy["default"])(input).isString || input === "") {
      return "ERC20";
    } else {
      return input;
    }
  }
});