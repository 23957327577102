"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.amountIsNotZero = amountIsNotZero;
exports.amountIsAvailableInWallet = amountIsAvailableInWallet;
exports.dateIsNotAfterToday = dateIsNotAfterToday;
exports.momentIsNotAfterToday = momentIsNotAfterToday;
exports.dateIsNotBeforeDate = dateIsNotBeforeDate;
exports.dateIsNotAfterDate = dateIsNotAfterDate;
exports.momentIsNotBeforeMoment = momentIsNotBeforeMoment;
exports.momentIsNotAfterMoment = momentIsNotAfterMoment;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _typy = _interopRequireDefault(require("typy"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _isSameOrBefore = _interopRequireDefault(require("dayjs/plugin/isSameOrBefore"));

var _bignumber = require("bignumber.js");

var _ethers = require("ethers");

var _utils = require("@sablier/utils");

function result(passed) {
  var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    passed: passed,
    error: error
  };
}
/**
 *
 * Amount
 *
 */


function amountIsNotZero(_ref) {
  var translation, value;
  return _regenerator["default"].async(function amountIsNotZero$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          translation = _ref.translation, value = _ref.value;
          _context.prev = 1;

          if (!((0, _typy["default"])(value).isFalsy || !(0, _typy["default"])(value).isEthereumNumber)) {
            _context.next = 4;
            break;
          }

          throw new Error(translation("errors.amount.invalid"));

        case 4:
          if (!new _bignumber.BigNumber(value).isZero()) {
            _context.next = 6;
            break;
          }

          throw new Error(translation("errors.amount.zero"));

        case 6:
          _context.next = 11;
          break;

        case 8:
          _context.prev = 8;
          _context.t0 = _context["catch"](1);
          return _context.abrupt("return", result(false, (0, _typy["default"])(_context.t0, "message").safeString));

        case 11:
          return _context.abrupt("return", result(true));

        case 12:
        case "end":
          return _context.stop();
      }
    }
  }, null, null, [[1, 8]]);
}

function amountIsAvailableInWallet(_ref2) {
  var account, library, token, translation, value, tokenDecimals, tokenAddress, newAmountInAtomicUnits, balance, units;
  return _regenerator["default"].async(function amountIsAvailableInWallet$(_context2) {
    while (1) {
      switch (_context2.prev = _context2.next) {
        case 0:
          account = _ref2.account, library = _ref2.library, token = _ref2.token, translation = _ref2.translation, value = _ref2.value;
          _context2.prev = 1;

          if (!((0, _typy["default"])(value).isFalsy || !(0, _typy["default"])(value).isEthereumNumber)) {
            _context2.next = 4;
            break;
          }

          return _context2.abrupt("return", result(false, translation("errors.amount.invalid")));

        case 4:
          if (!((0, _typy["default"])(token).isFalsy || (0, _typy["default"])(token, "address").isFalsy)) {
            _context2.next = 6;
            break;
          }

          return _context2.abrupt("return", result(false, translation("errors.token.invalid")));

        case 6:
          tokenDecimals = (0, _typy["default"])(token, "decimals").safeTokenDecimals;
          tokenAddress = (0, _typy["default"])(token, "address").safeEthereumAddress;
          /* Caveat: ethers.js crashes when "tokenDecimals" is not a number */

          newAmountInAtomicUnits = _ethers.ethers.utils.parseUnits(value, tokenDecimals).toString();
          _context2.next = 11;
          return _regenerator["default"].awrap((0, _utils.getTokenBalance)({
            account: account,
            library: library,
            tokenAddress: tokenAddress
          }));

        case 11:
          balance = _context2.sent;

          if (!(0, _typy["default"])(balance).isFalsy) {
            _context2.next = 14;
            break;
          }

          throw new Error(translation("errors.unknown.balance"));

        case 14:
          if (balance.isGreaterThanOrEqualTo(new _bignumber.BigNumber(newAmountInAtomicUnits))) {
            _context2.next = 17;
            break;
          }
          /**
           * When the balance is not enough, let the user know.
           * Caveat: ethers.js crashes when "tokenDecimals" is not a number.
           */


          units = _ethers.ethers.utils.formatUnits(balance.toString(10), tokenDecimals);
          throw new Error(translation("errors.amount.notEnough", {
            tokenBalance: (0, _utils.cashify)({
              number: Number(units)
            }),
            tokenSymbol: (0, _typy["default"])(token, "symbol").safeTokenSymbol
          }));

        case 17:
          _context2.next = 22;
          break;

        case 19:
          _context2.prev = 19;
          _context2.t0 = _context2["catch"](1);
          return _context2.abrupt("return", result(false, (0, _typy["default"])(_context2.t0, "message").safeString || translation("errors.unknown.default")));

        case 22:
          return _context2.abrupt("return", result(true));

        case 23:
        case "end":
          return _context2.stop();
      }
    }
  }, null, null, [[1, 19]]);
}
/**
 *
 * Date
 *
 */

/** Throwables */


function validateDate(_ref3) {
  var translation = _ref3.translation,
      value = _ref3.value;

  if ((0, _typy["default"])(value).isFalsy || !(0, _typy["default"])(value).isObject) {
    throw new Error(translation("errors.date.invalid"));
  }

  var date = (0, _dayjs["default"])().year((0, _typy["default"])(value, "year").safeNumber).month((0, _typy["default"])(value, "month").safeNumber - 1).date((0, _typy["default"])(value, "day").safeNumber);

  if (!date.isValid()) {
    throw new Error(translation("errors.date.invalid"));
  }

  return date;
}

function validateMoment(_ref4) {
  var translation = _ref4.translation,
      value = _ref4.value;

  if ((0, _typy["default"])(value).isFalsy || !(0, _typy["default"])(value).isObject) {
    throw new Error(translation("errors.date.invalid"));
  }

  var date = value.date,
      time = value.time;

  if ((0, _typy["default"])(date).isFalsy || !(0, _typy["default"])(date).isObject) {
    throw new Error(translation("errors.date.invalid"));
  }

  if ((0, _typy["default"])(time).isFalsy || !(0, _typy["default"])(time).isObject) {
    throw new Error(translation("errors.date.invalid"));
  }

  var moment = (0, _dayjs["default"])().year((0, _typy["default"])(date, "year").safeNumber).month((0, _typy["default"])(date, "month").safeNumber - 1).date((0, _typy["default"])(date, "day").safeNumber).hour((0, _typy["default"])(time, "hours").safeNumber).minute((0, _typy["default"])(time, "minutes").safeNumber);

  if (!moment.isValid()) {
    throw new Error(translation("errors.date.invalid"));
  }

  return moment;
}
/** Testers */


function dateIsNotAfterToday(_ref5) {
  var translation = _ref5.translation,
      value = _ref5.value;

  try {
    var date = validateDate({
      translation: translation,
      value: value
    });

    if (date.isBefore((0, _dayjs["default"])(), "date")) {
      throw new Error(translation("errors.date.beforeToday"));
    }
  } catch (error) {
    return result(false, (0, _typy["default"])(error, "message").safeString);
  }

  return result(true);
}

function momentIsNotAfterToday(_ref6) {
  var translation = _ref6.translation,
      value = _ref6.value;

  try {
    var moment = validateMoment({
      translation: translation,
      value: value
    });

    if (moment.isBefore((0, _dayjs["default"])())) {
      throw new Error(translation("errors.date.beforeNow"));
    }
  } catch (error) {
    return result(false, (0, _typy["default"])(error, "message").safeString);
  }

  return result(true);
}

function dateIsNotBeforeDate(_ref7) {
  var translation = _ref7.translation,
      value = _ref7.value,
      milestone = _ref7.milestone;

  try {
    var date = validateDate({
      translation: translation,
      value: value
    });
    var stone = validateDate({
      translation: translation,
      value: milestone
    });

    if (date.isBefore(stone, "second")) {
      throw new Error(translation("errors.date.beforeStart"));
    }
  } catch (error) {
    return result(false, (0, _typy["default"])(error, "message").safeString);
  }

  return result(true);
}

function dateIsNotAfterDate(_ref8) {
  var translation = _ref8.translation,
      value = _ref8.value,
      milestone = _ref8.milestone;
  return dateIsNotBeforeDate({
    translation: translation,
    value: milestone,
    milestone: value
  });
}

function momentIsNotBeforeMoment(_ref9) {
  var translation = _ref9.translation,
      value = _ref9.value,
      milestone = _ref9.milestone;

  try {
    _dayjs["default"].extend(_isSameOrBefore["default"]);

    var moment = validateMoment({
      translation: translation,
      value: value
    });
    var stone = validateMoment({
      translation: translation,
      value: milestone
    });

    if (moment.isSameOrBefore(stone)) {
      throw new Error(translation("errors.date.beforeStart"));
    }
  } catch (error) {
    return result(false, (0, _typy["default"])(error, "message").safeString);
  }

  return result(true);
}

function momentIsNotAfterMoment(_ref10) {
  var translation = _ref10.translation,
      value = _ref10.value,
      milestone = _ref10.milestone;
  return momentIsNotBeforeMoment({
    translation: translation,
    value: milestone,
    milestone: value
  });
}