"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOKEN_SHAPE = exports.DEFAULT_FSM_SHAPE = exports.BUNDLE_SHAPE = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var BUNDLE_SHAPE = _propTypes["default"].shape({
  atomicUnits: _propTypes["default"].shape({}),
  cash: _propTypes["default"].string,
  percentage: _propTypes["default"].number,
  units: _propTypes["default"].shape({})
});
/* FSM = Finite-State Machine */


exports.BUNDLE_SHAPE = BUNDLE_SHAPE;

var DEFAULT_FSM_SHAPE = _propTypes["default"].shape({
  context: _propTypes["default"].shape({
    error: _propTypes["default"].string
  }).isRequired,
  matches: _propTypes["default"].func.isRequired,
  value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].shape({})]).isRequired
});

exports.DEFAULT_FSM_SHAPE = DEFAULT_FSM_SHAPE;

var TOKEN_SHAPE = _propTypes["default"].shape({
  address: _propTypes["default"].string.isRequired,
  decimals: _propTypes["default"].number.isRequired,
  name: _propTypes["default"].string,
  symbol: _propTypes["default"].string
});

exports.TOKEN_SHAPE = TOKEN_SHAPE;