"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _devConstants = require("@sablier/dev-constants");

var _ErrorLabel = _interopRequireDefault(require("../ErrorLabel"));

var _Focusable = _interopRequireDefault(require("../../Focusable"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      font-size: 0.9375rem;\n      height: 3.25rem;\n      max-width: 100%;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n      font-size: 1.5rem;\n      font-weight: 500;\n      height: 6rem;\n      margin: 0;\n      width: 100%;\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  background-color: ", ";\n  border-radius: 0.25rem;\n  font-family: ", ";\n  font-size: 0.9375rem;\n  font-weight: 500;\n  height: 3.25rem;\n  max-width: 29rem;\n  padding: 0.5rem 1rem;\n  transition: border-color 200ms;\n  width: 100%;\n\n  ", "\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledFocusableInput = (0, _styledComponents["default"])(_Focusable["default"].Input)(_templateObject(), function (props) {
  return props.theme.borderedFlexRowNoWrap;
}, function (props) {
  return props.theme.white;
}, function (props) {
  return props.theme.robotoMonoFont;
}, function (props) {
  return props.isBig && (0, _styledComponents.css)(_templateObject2());
}, function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
});

function AmountInput(_ref) {
  var amount = _ref.amount,
      className = _ref.className,
      isErrorPlaceholder = _ref.isErrorPlaceholder,
      isBig = _ref.isBig,
      onUpdateAmount = _ref.onUpdateAmount,
      readOnly = _ref.readOnly,
      token = _ref.token;
  /** Side Effects **/

  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(StyledFocusableInput, {
    autoComplete: "off",
    className: className,
    defaultValue: !(0, _typy["default"])(amount, "value").isNullOrUndefined ? amount.value : "",
    id: "amount",
    isBig: isBig,
    isInvalid: (0, _typy["default"])(amount, "error").isTruthy,
    name: "amount",
    onBlur: function onBlur(e) {
      e.preventDefault();
      onUpdateAmount({
        value: e.target.value
      });
    },
    placeholder: "0 " + (0, _typy["default"])(token, "symbol").safeTokenSymbol,
    readOnly: readOnly,
    type: "text"
  }), _react["default"].createElement(_ErrorLabel["default"], {
    error: (0, _typy["default"])(amount, "error").safeString,
    isPlaceholder: isErrorPlaceholder
  }));
}

AmountInput.propTypes = {
  amount: _propTypes["default"].shape({
    value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number])
  }),
  isErrorPlaceholder: _propTypes["default"].bool,
  isBig: _propTypes["default"].bool,
  className: _propTypes["default"].string,
  onUpdateAmount: _propTypes["default"].func.isRequired,
  readOnly: _propTypes["default"].bool,
  token: _devConstants.TOKEN_SHAPE
};
AmountInput.defaultProps = {
  amount: {},
  isErrorPlaceholder: false,
  isBig: false,
  readOnly: false,
  token: null
};
var _default = AmountInput;
exports["default"] = _default;