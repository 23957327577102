"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _Header = _interopRequireDefault(require("./Header"));

var _Row = _interopRequireDefault(require("./Row"));

var _Item = _interopRequireDefault(require("./Item"));

var _Footer = _interopRequireDefault(require("./Footer"));

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: grid;\n    grid-gap: 1rem;\n    grid-template-columns: 1fr;\n    padding: 0rem 0.5rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: grid;\n    grid-gap: 1rem;\n    grid-template-columns: 1fr 1fr;\n    padding: 0rem 1rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  width: 100%;\n\n  ", "\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex: 1;\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Wrapper = _styledComponents["default"].div(_templateObject());

var Content = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.mediaWidth.upToMedium(_templateObject3());
}, function (props) {
  return props.theme.mediaWidth.upToSmall(_templateObject4());
});

function Table(_ref) {
  var columns = _ref.columns,
      data = _ref.data,
      actions = _ref.actions;
  var render = (0, _react.useCallback)(function () {
    return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Header["default"], {
      columns: (0, _typy["default"])(columns).safeArray
    }), _react["default"].createElement(Content, null, (0, _typy["default"])(data).safeArray.map(function (row) {
      return _react["default"].createElement(_Row["default"], {
        key: row.id,
        onClick: function onClick() {
          (0, _typy["default"])(actions, "onRowClick").safeFunction(row.id);
        }
      }, (0, _typy["default"])(columns).safeArray.map(function (_ref2) {
        var getter = _ref2.getter,
            id = _ref2.id,
            label = _ref2.label,
            otherProps = (0, _objectWithoutProperties2["default"])(_ref2, ["getter", "id", "label"]);
        var safeGetter = (0, _typy["default"])(getter).isFunction ? (0, _typy["default"])(getter).safeFunction : function () {
          return {
            value: ""
          };
        };

        var itemProps = _objectSpread({}, otherProps, {
          actions: actions,
          id: id,
          label: label,
          value: safeGetter(row)
        });

        return _react["default"].createElement(_Item["default"], (0, _extends2["default"])({
          key: "".concat(id, "-").concat(row.id)
        }, itemProps));
      }));
    })), _react["default"].createElement(_Footer["default"], null));
  }, [columns, data, actions]);
  return _react["default"].createElement(Wrapper, null, render());
}

Table.propTypes = {
  columns: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    id: _propTypes["default"].string.isRequired
    /** Unique identifier for column */
    ,
    label: _propTypes["default"].string.isRequired,
    type: _propTypes["default"].string.isRequired,
    value: _propTypes["default"].oneOfType([_propTypes["default"].shape, _propTypes["default"].func]),
    reorder: _propTypes["default"].number
  })).isRequired,
  data: _propTypes["default"].arrayOf(_propTypes["default"].shape({
    id: _propTypes["default"].string.isRequired
    /** Unique identifier for row */

  })),
  actions: _propTypes["default"].shape({
    onRowClick: _propTypes["default"].func
  })
};
Table.defaultProps = {
  data: [],
  actions: {
    onRowClick: function onRowClick() {}
  }
};
var _default = Table;
exports["default"] = _default;