"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _typy = _interopRequireDefault(require("typy"));

var _bignumber = require("bignumber.js");

var _devConstants = require("@sablier/dev-constants");
/* eslint-disable no-lonely-if */


function _default(_ref, _args, context) {
  var cancellation = _ref.cancellation,
      startTime = _ref.startTime,
      stopTime = _ref.stopTime;
  var now = new _bignumber.BigNumber((0, _dayjs["default"])().unix());

  if ((0, _typy["default"])(cancellation).isFalsy) {
    if (now.isLessThan(startTime)) {
      context.status = _devConstants.STREAM_STATUS.created;
    } else if (now.isGreaterThanOrEqualTo(startTime) && now.isLessThanOrEqualTo(stopTime)) {
      context.status = _devConstants.STREAM_STATUS.streaming;
    } else {
      context.status = _devConstants.STREAM_STATUS.ended;
    }
  } else {
    /**
     * It is more intuitive for a stream to be called "Ended" when the stream has successfully paid
     * the recipient the initial deposit in full.
     */
    var senderBalance = new _bignumber.BigNumber((0, _typy["default"])(cancellation, "senderBalance").safeString);

    if (senderBalance.isZero()) {
      context.status = _devConstants.STREAM_STATUS.ended;
    } else {
      context.status = _devConstants.STREAM_STATUS.cancelled;
    }
  }

  return context.status;
}