"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _qrcode = _interopRequireDefault(require("qrcode.react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _typy = _interopRequireDefault(require("typy"));

var _theme = require("@sablier/theme");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  align-items: center;\n  border-radius: 0.75rem;\n  justify-content: center;\n  margin-bottom: 2rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var QRCodeWrapper = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.theme.flexColumnNoWrap;
});

function WalletConnectData(_ref) {
  var backgroundColor = _ref.backgroundColor,
      foregroundColor = _ref.foregroundColor,
      size = _ref.size,
      uri = _ref.uri;
  return _react["default"].createElement(QRCodeWrapper, null, (0, _typy["default"])(uri).isTruthy && _react["default"].createElement(_qrcode["default"], {
    size: size,
    value: uri,
    bgColor: backgroundColor,
    fgColor: foregroundColor
  }));
}

WalletConnectData.propTypes = {
  backgroundColor: _propTypes["default"].string,
  foregroundColor: _propTypes["default"].string,
  size: _propTypes["default"].number,
  uri: _propTypes["default"].string
};
WalletConnectData.defaultProps = {
  backgroundColor: _theme.colors.white,
  foregroundColor: _theme.colors.black,
  size: 224,
  uri: ""
};
var _default = WalletConnectData;
exports["default"] = _default;